import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { PermittedNavLinksProvider } from "./context/permittedNavLinks";
import { PermittedAdminNavLinksProvider } from "./context/permittedAdminLinks";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <PermittedAdminNavLinksProvider>
      <PermittedNavLinksProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PermittedNavLinksProvider>
    </PermittedAdminNavLinksProvider>
  </Provider>
);

reportWebVitals();
