import React, { useState } from "react";
import { useSelector } from "react-redux";
import InstitutionAccountHistory from "../institutionAccount/institutionAccountHistory";
import MainSettlement from "./components/mainSettlement/mainSettlement";
import OtherSettlements from "./components/otherSettlements/otherSettlements";
import { Button } from "react-bootstrap";
import CreateSettlement from "./components/createSettlement/createSettlement";
import SuccessModal from "../../../../../../component/modal/successModal";

const SettlementAccounts = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [isHistoryViewed, setIsHistoryViewed] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [activePage, setActivePage] = useState("Main Settlement Account");

  const pages = [
    "Main Settlement Account",
    "Other Settlement Accounts",
    "View History",
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          alignItems: "end",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ fontSize: "14px", fontWeight: 600 }}>
          Multiple Settlement{" "}
        </h6>

        {userAuthorizedPages?.find(
          (page) => page?.action === "SET_SETTLEMENT_ACCOUNT"
        ) && (
          <Button
            className="button"
            size="md"
            style={{
              backgroundColor: "#F14810",
              color: "#ffffff",
              border: "none",
              outline: "none",
              fontSize: 0.8 + "rem",
            }}
            onClick={() => setShowCreateModal(true)}
          >
            Create Settlement Account
          </Button>
        )}
      </div>

      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "10px",
          padding: "20px",
          minHeight: "95%",
        }}
      >
        {userAuthorizedPages?.find(
          (page) => page?.action === "VIEW_SETTLEMENT_ACCOUNT"
        ) ? (
          <>
            {activePage !== "View History" && (
              <div>
                <div style={{ marginBottom: "20px", display: "flex" }}>
                  {pages?.map((page) => (
                    <div
                      onClick={() => {
                        setActivePage(page);
                      }}
                      style={{
                        backgroundColor: `${
                          activePage === page ? "#ffede8" : "#f4f4f4"
                        }`,
                        border: `1px solid ${
                          activePage === page ? "#f89a7c" : "#dbdbdb"
                        }`,
                        marginRight: "10px",
                        padding: "8px 18px",
                        borderRadius: "20px",
                        cursor: "pointer",
                        fontWeight: "500",
                      }}
                    >
                      {page}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {activePage === "Main Settlement Account" && <MainSettlement />}

            {activePage === "Other Settlement Accounts" && <OtherSettlements />}

            {activePage === "View History" && (
              <InstitutionAccountHistory
                setIsHistoryViewed={() => {
                  setIsHistoryViewed(!isHistoryViewed);
                  setActivePage("Main Settlement Account");
                }}
              />
            )}
          </>
        ) : (
          <p style={{ fontSize: "14px", fontStyle: "italic" }}>
            you dont have permission to view details of this page
          </p>
        )}
      </div>

      {showCreateModal && (
        <CreateSettlement
          popup={showCreateModal}
          setShowCreateModal={setShowCreateModal}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          message="Settlement account set successfully"
        />
      )}
    </div>
  );
};

export default SettlementAccounts;
