import { Container } from "react-bootstrap";
import DashboardSidebar from "../components/dashboardSidebar/sidebar";
import DashboardTopSection from "../components/dashboardTopSection";
import {
  useGetInstitutionQuery,
  useGetSetupProgressQuery,
} from "../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
// import Profile from "./components/profile";
import InstitutionDetailsPage from "./components/institutionDetailsPage";
import ContactPersonPage from "./components/contactPerson";
import InstitutionAccountPage from "./components/institutionAccount";
import Appearance from "./components/appearance";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import InstitutionLevel from "./components/institutionLevel/institutionLevel";
import InstitutionCategory from "./components/institutionCategory";
import InstitutionCalendar from "./components/schoolCalendar";
import InstitutionFeeComponent from "./components/feeComponent";
import InstitutionFee from "./components/fee";
import backArrow from "../../../../assets/svgs/backArrow.svg";
import menuIcon from "../../../../assets/svgs/menuIcon.svg";
import tickIcon from "../../../../assets/svgs/tickIcon.svg";
import successGIF from "../../../../assets/svgs/successGIF.gif";
import "../settingsPage/settings.css";
import { Offcanvas } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import UploadCredentials from "./components/uploadCredentials/uploadCredentials";
import ModalComponent from "../../../../component/modal/modal";

const setupStages = [
  // { stage: "School Details" },
  // { stage: "Contact Person" },
  { stage: "Appearance", identifier: "appearance" },
  { stage: "Documents", identifier: "documents" },
  // { stage: "Class Management" },
  // { stage: "Calendar Set-up" },
  // { stage: "Fee Component" },
  // { stage: "Fee Management" },
  { stage: "Settlement Account", identifier: "settlementAccount" },
];

const SetupInstitutionPage = () => {
  // const [activeStage, setActiveStage] = useState("School Details");
  const [activeStage, setActiveStage] = useState("Appearance");
  const [showMenu, setShowMenu] = useState(false);
  const [showSetupComplete, setShowSetupComplete] = useState(false);

  console.log(showMenu);

  const navigate = useNavigate();

  const handleSetStage = (stage) => {
    setActiveStage(stage);
    setShowMenu(false);
  };

  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: institutionDetails,
    refetch: refetchDetails,
    isLoading: detailsLoading,
  } = useGetInstitutionQuery(id);
  const { data: setupProgress, isLoading: progressLoading } =
    useGetSetupProgressQuery(id);

  console.log(setupProgress);

  useEffect(() => {
    if (
      setupProgress &&
      setupProgress["appearance"] &&
      setupProgress["documents"] &&
      setupProgress["settlementAccount"]
    ) {
      refetchDetails();
      setShowSetupComplete(true);
    }
  }, [setupProgress]);

  if (detailsLoading || progressLoading) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <section style={{ height: "80%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              marginBottom: "10px",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <img src={backArrow} alt="back arrow" />
            <h5 style={{ marginBottom: 0, fontSize: "16px", fontWeight: 600 }}>
              School Setup
            </h5>
          </div>

          <section
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              height: "100%",
              display: "flex",
              position: "relative",
            }}
          >
            <div
              style={{
                borderRight: "1px solid #F5F5F5",
                flexBasis: "20%",
                padding: "30px 15px",
                minWidth: "150px",
                overflowY: "auto",
              }}
              className="settingsMenu"
            >
              {setupStages?.map(({ stage, identifier }, i) => (
                <div
                  key={i}
                  style={{
                    borderRadius: "8px",
                    padding: "8px",
                    marginBottom: "10px",
                    cursor: "pointer",
                    border: `${activeStage === stage
                        ? "1px solid #F14810"
                        : "1px solid #F5F5F5"
                      }`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                    backgroundColor: `${activeStage === stage ? "#ffe6de" : "#e8e8e8"
                      }`,
                  }}
                  onClick={() => setActiveStage(stage)}
                >
                  <p style={{ marginBottom: 0 }}>{stage}</p>
                  {setupProgress && setupProgress[identifier] === true && (
                    <img src={tickIcon} alt="tick Icon" />
                  )}
                </div>
              ))}
            </div>

            <div
              style={{
                flexBasis: "80%",
                flexGrow: 1,
                padding: "15px 30px 30px",
                overflowY: "auto",
              }}
              className="right-box-settings"
            >
              <img
                src={menuIcon}
                alt="menu icon"
                onClick={() => setShowMenu(true)}
                className="menuIcon"
                style={{ marginBottom: "10px", cursor: "pointer" }}
              />
              {/* {activeStage === "School Details" && (
                <InstitutionDetailsPage
                  institutionDetails={institutionDetails}
                />
              )}
              {activeStage === "Contact Person" && (
                <ContactPersonPage institutionDetails={institutionDetails} />
              )}
              {activeStage === "Settlement Account" && (
                <InstitutionAccountPage />
              )} */}
              {activeStage === "Appearance" && (
                <Appearance institutionDetails={institutionDetails} />
              )}
              {activeStage === "Documents" && (
                <UploadCredentials institutionDetails={institutionDetails} />
              )}

              {activeStage === "Settlement Account" && (
                <InstitutionAccountPage />
              )}{" "}
            </div>

            {/* offcanvas for mobile menu */}
            <Offcanvas
              show={showMenu}
              onHide={() => setShowMenu(false)}
              placement={"start"}
              backdrop={true}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                width: "100%",
                maxWidth: "180px",
                height: "100%",
              }}
            >
              <Offcanvas.Body style={{ padding: "20px 0" }}>
                <div
                  style={{
                    borderRight: "1px solid #F5F5F5",
                    flexBasis: "20%",
                    padding: "30px 15px",
                    minWidth: "150px",
                    overflowY: "auto",
                  }}
                >
                  {setupStages.map(({ stage }, i) => (
                    <div
                      key={i}
                      style={{
                        borderRadius: "8px",
                        padding: "8px",
                        marginBottom: "10px",
                        cursor: "pointer",
                        border: `${activeStage === stage
                            ? "1px solid #F14810"
                            : "1px solid #F5F5F5"
                          }`,
                        backgroundColor: `${activeStage === stage ? "#ffe6de" : "#e8e8e8"
                          }`,
                      }}
                      onClick={() => handleSetStage(stage)}
                    >
                      <p style={{ marginBottom: 0 }}>{stage}</p>
                    </div>
                  ))}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </section>
        </section>
      </Container>

      <ModalComponent
        showModal={showSetupComplete}
        closeModal={() => {
          setShowSetupComplete(false);
          navigate("/settings");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>
            Setup Complete!!!
          </h3>
          <p style={{ fontSize: "18px" }}>
            {" "}
            Your application has been submitted for review and approval. You will only have access to all features after your application has been approved by the Admin
          </p>
          <Link to="/settings">
            <button
              style={{
                backgroundColor: "#F14810",
                padding: "7px 20px",
                color: "#fff",
                outline: "none",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Ok
            </button>
          </Link>
        </div>
      </ModalComponent>
    </Container>
  );
};

export default SetupInstitutionPage;
