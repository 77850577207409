import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import InstitutionFeeComponent from "../feeComponent";
import InstitutionFee from "../fee";

const ManageFeeLayout = () => {
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const [activePage, setActivePage] = useState("Fee Component");

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer",
          marginTop: "10px",
          marginBottom: "5px",
        }}
      >
        <h6 style={{ marginBottom: 0 }}>Manage Fees</h6>
      </div>

      <div
        style={{
          paddingTop: 1 + "rem",
          marginBottom: 2 + "rem",
        }}
      >
        <div
          style={{
            border: "2px solid #EDEDED",
            borderRadius: "8px",
            gap: "30px",
            backgroundColor: "#FFFFFF",
            padding: "30px",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <span
              onClick={() => setActivePage("Fee Component")}
              style={{
                backgroundColor: `${
                  activePage === "Fee Component" ? "#ffede8" : "#f4f4f4"
                }`,
                border: `1px solid ${
                  activePage === "Fee Component" ? "#f89a7c" : "#dbdbdb"
                }`,
                marginRight: "10px",
                padding: "8px 18px",
                borderRadius: "20px",
                cursor: "pointer",
                fontWeight: "500",
              }}
            >
              Fee Components
            </span>

            <span
              onClick={() => setActivePage("Fee Management")}
              style={{
                backgroundColor: `${
                  activePage === "Fee Management" ? "#ffede8" : "#f4f4f4"
                }`,
                border: `1px solid ${
                  activePage === "Fee Management" ? "#f89a7c" : "#dbdbdb"
                }`,
                marginRight: "10px",
                padding: "8px 18px",
                borderRadius: "20px",
                cursor: "pointer",
                fontWeight: "500",
              }}
            >
              Fees
            </span>
          </div>

          {activePage === "Fee Component" && (
            <section
              style={{
                marginTop: "20px",
              }}
            >
              <InstitutionFeeComponent />
            </section>
          )}

          {activePage === "Fee Management" && (
            <section
              style={{
                marginTop: "20px",
              }}
            >
              <InstitutionFee />
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageFeeLayout;
