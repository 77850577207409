import { useState, useEffect } from "react";
import eyeActionIcon from "../../../../../../assets/svgs/eyeActionIcon.svg";
import DashboardTopSection from "../../../dashboardTopSection";
import DashboardSidebar from "../../../dashboardSideBar/sidebar";
import TableComponent from "../../../../../../component/table/table";
import Button from "../../../../../../component/button/button";
import "./settlement.css";
import { useNavigate } from "react-router-dom";
import { addTrailingCommas } from "../../../../../../utils/utils";
import CreateCommissionCanvas from "./components/createCommissionCanvas";
import { Offcanvas } from "react-bootstrap";
import GlobalCommissionCanvas from "./components/globalCommissionCanvas";
import { useGetInstitutionSettlementListQuery } from "../../../../../../redux/features/superAdmin/adminApiSlice";
import SettlementDetailsPage from "./components/settlementDetails";
import { useSelector } from "react-redux";

const SettlementPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const navigate = useNavigate();
  const [showRateCanvas, setShowRateCanvas] = useState(false);
  const [showGlobalRateCanvas, setShowGlobalRateCanvas] = useState(false);

  const [isSettlementDetailViewed, setIsSettlementDetailViewed] =
    useState(false);
  const [viewedSettlementDetail, setViewedSettlementDetail] = useState(null);

  const { data: institutionList, refetch } =
    useGetInstitutionSettlementListQuery();
  console.log("INSTITUTIONLIST", institutionList);

  const columns = [
    // { name: "id", label: "ID" },
    { name: "institutionName", label: "School Name" },
    // { name: "institutionType", label: "Type" },
    {
      name: "contactPerson",
      label: "Contact Person",
      options: {
        customBodyRender: (contactPerson) =>
          contactPerson
            ? contactPerson?.firstName + " " + contactPerson?.lastName
            : "N/A",
      },
    },
    {
      name: "contactPerson",
      label: "Phone Number",
      options: {
        customBodyRender: (contactPerson) =>
          contactPerson ? contactPerson?.phoneNumber : "N/A",
      },
    },
    // {
    //   name: "balance",
    //   label: "Wallet Balance",
    //   options: {
    //     customBodyRender: (balance) =>
    //       balance ? addTrailingCommas(balance) : 0,
    //   },
    // },
    {
      name: "commission",
      label: "Processing Fee",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { commission } = institutionList[tableMeta.rowIndex];
          return `${
            commission?.type === "RATE"
              ? `${commission?.rate} %`
              : `₦ ${addTrailingCommas(commission?.fixedAmount)}`
          }`;
        },
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <img
            src={eyeActionIcon}
            alt="eye icon"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsSettlementDetailViewed(!isSettlementDetailViewed);
              setViewedSettlementDetail(institutionList[tableMeta.rowIndex]);
            }}
          />
        ),
      },
    },
  ];

  const handleDetailView = (obj) => {
    setIsSettlementDetailViewed(!isSettlementDetailViewed);
    setViewedSettlementDetail(obj);
  };

  useEffect(() => {
    if (institutionList) {
      refetch();
    }
  }, [showRateCanvas, showGlobalRateCanvas]);

  return (
    <div className="no-scrollbar">
      <div>
        {/* <DashboardTopSection /> */}
        {isSettlementDetailViewed ? (
          <SettlementDetailsPage
            settlementDetailsView={viewedSettlementDetail}
            setIsSettlementDetailViewed={setIsSettlementDetailViewed}
          />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h5>Processing Fee</h5>
              
              {userAuthorizedPages?.find(
                (page) => page?.action === "ADMIN_SET_PROCESSING_FEE"
              ) && (
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button onClick={() => setShowGlobalRateCanvas(true)}>
                    Set Global Processing Fee
                  </Button>

                  <Button
                    onClick={() => setShowRateCanvas(true)}
                    backgroundColor="#ffffff"
                    border="rgb(241, 72, 16)"
                    color="rgb(241, 72, 16)"
                  >
                    Set Processing Fee
                  </Button>
                </div>
              )}
            </div>

            <div
              style={{
                border: "1px solid #F5F5F5",
                borderRadius: "8px",
                padding: "15px",
                height: "95%",
              }}
            >
              <TableComponent
                data={institutionList}
                title=""
                columns={columns}
                search={true}
                hasAuthority={userAuthorizedPages?.find(
                  (page) => page?.action === "ADMIN_VIEW_PROCESSING_FEE"
                )}
              />
            </div>
          </>
        )}
      </div>

      <Offcanvas
        show={showGlobalRateCanvas}
        onHide={() => setShowGlobalRateCanvas(false)}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Global Processing Fee</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <GlobalCommissionCanvas />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showRateCanvas}
        onHide={() => setShowRateCanvas(false)}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Set Processing Fee</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <CreateCommissionCanvas />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SettlementPage;
