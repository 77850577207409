import { Col, Container, Image, Row } from "react-bootstrap";
import DashboardSidebar from "../dashboardSideBar/sidebar";
import DashboardTopSection from "../dashboardTopSection";
import backArrow from "../../../../assets/svgs/backArrow.svg";
import Expectedrevenueicon from "../../../../assets/svgs/expectedRevenueicon.svg";
import generatedrevenueicon from "../../../../assets/svgs/generatedrevenueicon.svg";
import { useNavigate } from "react-router-dom";
import { AddComment } from "@mui/icons-material";
import { addTrailingCommas } from "../../../../utils/utils";
import TableComponent from "../../../../component/table/table";
import { useGetNotificationsSummaryQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import { useSelector } from "react-redux";

const NotificationsSummaryPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const navigate = useNavigate();
  const {
    data: summaryData,
    isLoading: summaryDataLoading,
    refetch,
  } = useGetNotificationsSummaryQuery();

  const revenueDetails = [
    {
      title: "Total Revenue",
      amount: addTrailingCommas(summaryData?.totalRevenue),
      icon: Expectedrevenueicon,
      style: "Expectedrevenuestyle",
    },
    {
      title: "Today's Revenue",
      amount: addTrailingCommas(summaryData?.dailyRevenue),
      icon: generatedrevenueicon,
      style: "Generatedrevenuestyle",
    },
  ];

  const dailyTransactionsColumns = [
    // { name: "id", label: "S/N" },
    {
      name: "institutionName",
      label: "School Name",
    },
    { name: "messageSent", label: "No. of Notifications Sent" },
    {
      name: "totalAmount",
      label: "Total Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          return `${"₦ " + addTrailingCommas(value)}`;
        },
      },
    },
  ];

  if (summaryDataLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <Container
        fluid
        style={{ display: "flex" }}
        className="dashboardContainer"
      >
        <DashboardSidebar />
        <Container fluid className="bodycontainer">
          <DashboardTopSection />

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              onClick={() => navigate("/principal/notifications")}
            >
              <img src={backArrow} alt="Go back" />
              <h6 style={{ marginBottom: 0 }}>Notifications Summary</h6>
            </div>
            <div>
              <Row style={{ marginTop: 0.4 + "rem" }}>
                <Col xs={12} md={12} lg={7} className="otherbox">
                  <Row>
                    {revenueDetails.map(({ icon, amount, title, style }, i) => (
                      <Col xs={6} md={6} lg={6} id={style} key={i}>
                        <div className="card">
                          <div className="card-inner">
                            <div>
                              <Image src={icon} alt="img" fluid />
                            </div>
                            <div className="card-inner-value">
                              <h5 style={{ marginTop: 1 + "rem" }}>
                                <b> ₦ {amount?.toLocaleString()}</b>
                              </h5>
                              <p style={{ marginTop: -0.61 + "rem" }}>
                                {title}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          <div
            style={{
              paddingTop: 1 + "rem",
              marginBottom: 2 + "rem",
            }}
          >
            <div
              style={{
                border: "2px solid #EDEDED",
                borderRadius: "8px",
                gap: "30px",
                backgroundColor: "#FFFFFF",
                padding: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 10,
                }}
              >
                <h5>Daily Notifications History</h5>
              </div>

              <section
                style={{
                  marginTop: "5px",
                }}
              >
                <TableComponent
                  data={summaryData?.dailyTransactions}
                  title=""
                  columns={dailyTransactionsColumns}
                  search={true}
                  pagination={10}
                  hasAuthority={userAuthorizedPages?.find(
                    (page) => page?.action === "ADMIN_VIEW_NOTIFICATIONS"
                  )}
                />
              </section>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default NotificationsSummaryPage;
