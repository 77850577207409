import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../../../redux/features/auth/authSlice";
import notificationIcon from "../../../../assets/svgs/notificationIcon.svg";
import dropdownIcon from "../../../../assets/svgs/dropdownIcon.svg";
import menuIcon from "../../../../assets/svgs/menuIcon.svg";
import userDisplayPhoto from "../../../../assets/images/userDisplayPhoto.png";
import femaleAvatar from "../../../../assets/svgs/femaleAvatar.svg";
import { Offcanvas } from "react-bootstrap";
import { Sidebar, MenuItem, Menu } from "react-pro-sidebar";
import { Navbar, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../../assets/svgs/logo.svg";
import { useLocation } from "react-router-dom";
import DashboardIcon from "../../../../assets/svgs/dashboardIcon";
import ReportingIcon from "../../../../assets/svgs/reportingIcon";
import SettlementIcon from "../../../../assets/svgs/settlementIcon";
import AdminIcon from "../../../../assets/svgs/adminIcon";
import SchoolsIcon from "../../../../assets/svgs/schoolsIcon";
import SettingsIcon from "../../../../assets/svgs/settingsIcon";
import ApplicationIcon from "../../../../assets/svgs/applicationIcon";
import AdminProfile from "./profile";
import { usePermittedAdminLinks } from "../../../../context/permittedAdminLinks";

const DashboardTopSection = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [dropdownProfile, setDropdownProfile] = useState(false);
  const dispatch = useDispatch();
  const { permittedLinks } = usePermittedAdminLinks();

  const [showProfileCanvas, setShowProfileCanvas] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const handleCloseCanvas = () => {
    setShowProfileCanvas(false);
  };

  const handleShowCanvas = () => {
    setDropdownProfile(false);
    setShowProfileCanvas(true);
  };

  const handleLogOut = () => {
    dispatch(logOut());
  };
  const username = useSelector((state) => state?.auth?.userInfo?.username);

  const menuItem = {
    height: 2.7 + "rem",
    fontSize: 0.87 + "rem",
  };

  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(
    window.matchMedia("(max-width: 950px)").matches
  );

  const location = useLocation();

  const date = new Date();
  const hour = date.getHours();

  // const username = "Admin";
  // const gender = (sex) => sex ? "male" : "female";
  const gender = "male";

  // useEffect(() => {
  //   const handleClose = (e) => {
  //     // close menu when they click outside
  //     if (e.target.className) {
  //       if (
  //         showMenu &&
  //         !e.target.className.includes("mobileDashboardNavbar") &&
  //         !e.target.className.includes("menuIcon")
  //       ) {
  //         setShowMenu(false);
  //       }
  //     }
  //   };

  //   window.addEventListener("click", handleClose);

  //   return () => {
  //     window.removeEventListener("click", handleClose);
  //   };
  // }, [showMenu]);

  return (
    <div className="top-section">
      {/* <h3 style={{ paddingLeft: "auto" }}>Welcome {username}</h3> */}
      <h3 style={{ paddingLeft: "auto" }}>Welcome</h3>
      <img
        src={menuIcon}
        alt="menu icon"
        onClick={() => setShowMenu(!showMenu)}
        className="menuIcon"
      />
      <div className="user-details">
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {hour > 12 ? (
              hour >= 16 ? (
                <span style={{ flexShrink: "0" }}>Good Evening, </span>
              ) : (
                <span style={{ flexShrink: "0" }}>Good Afternoon, </span>
              )
            ) : (
              <span style={{ flexShrink: "0" }}>Good Morning, </span>
            )}
            <span>{username}</span>
            <div
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "100%",
                cursor: "pointer",
              }}
              onClick={() => setDropdownProfile(!dropdownProfile)}
            >
              <img
                src={gender === "male" ? userDisplayPhoto : femaleAvatar}
                alt="user"
                style={{ width: "100%" }}
              />
            </div>

            <img
              src={dropdownIcon}
              alt="drop down"
              onClick={() => setDropdownProfile(!dropdownProfile)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        {dropdownProfile && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              right: 0,
              width: "130px",
              zIndex: 10,
              backgroundColor: "#fff2ee",
              padding: "15px",
            }}
            className="profile-dropdown"
          >
            <span
              onClick={handleShowCanvas}
              style={{ cursor: "pointer", fontWeight: 500 }}
            >
              Profile
            </span>
            <hr />
            <span
              onClick={handleLogOut}
              style={{ cursor: "pointer", fontWeight: 500 }}
            >
              LogOut
            </span>
          </div>
        )}
      </div>

      <Offcanvas
        show={showProfileCanvas}
        onHide={handleCloseCanvas}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Profile</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <AdminProfile />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showMenu}
        onHide={() => setShowMenu(false)}
        placement={"start"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "250px" }}
      >
        <Offcanvas.Body style={{ padding: "20px 0" }}>
          <Navbar.Brand className="logo">
            <div className="navbar-brand-space">
              <br />
            </div>
            <Image src={logo} fluid />
          </Navbar.Brand>

          <Menu
            className="menu"
            menuItemStyles={{
              button: ({ active }) => ({
                color: active ? "white" : "#464646",
                backgroundColor: active ? "#FD5821" : "transparent",
                "&:hover": {
                  backgroundColor: active ? "#FD5821" : "#F5F5F5",
                  color: active ? "white" : "#FFFFFF",
                },
              }),
            }}
          >
            {permittedLinks?.map(({ path, label, icon: Icon }) => (
              <Link key={path} to={path}>
                <MenuItem
                  style={menuItem}
                  active={
                    location.pathname === path || // Exact match for the main route
                    (path !== "/principal" &&
                      location.pathname.startsWith(path)) // Starts with but excludes the main route
                      ? "active"
                      : null
                  }
                  icon={
                    <Icon
                      active={
                        location.pathname === path ||
                        (path !== "/principal" &&
                          location.pathname.startsWith(path))
                          ? "active"
                          : null
                      }
                      size={18}
                    />
                  }
                >
                  {label}
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default DashboardTopSection;
