import { apiSlice } from "../../api/apiSlice";

export const institutionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateInstitution: builder.mutation({
      providesTags: ["updateInstitution"],
      invalidatesTags: ["updateInstitution"],
      query: (body) => ({
        url: "/api/institution_setup",
        method: "POST",
        body,
      }),
    }),
    completeInstitutionSetup: builder.mutation({
      providesTags: ["completeInstitutionSetup"],
      invalidatesTags: ["completeInstitutionSetup"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/complete`,
        method: "POST",
      }),
    }),
    getInstitution: builder.query({
      providesTags: ["institution"],
      invalidatesTags: ["institution"],
      query: (institutionId) => ({
        // url: `/api/getinstitution?institutionId=${institutionId}`,
        url: `/api/institutions/${institutionId}`,
        method: "GET",
      }),
    }),
    getInstitutionType: builder.query({
      providesTags: ["institutionType"],
      invalidatesTags: ["institutionType"],
      query: () => ({
        // url: "/api/getinstitutiontypes",
        // url: "/api/institution_types",
        url: "/anon/institution_types",
        method: "GET",
      }),
    }),
    getInstitutionLogo: builder.query({
      providesTags: ["institutionLogo"],
      invalidatesTags: ["institutionLogo"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/download_logo`,
        method: "GET",
      }),
    }),
    getDocumentCategories: builder.query({
      providesTags: ["documentCategories"],
      invalidatesTags: ["documentCategories"],
      query: (institutionId) => ({
        url: `api/document_categories/${institutionId}`,
        method: "GET",
      }),
    }),
    uploadDocument: builder.mutation({
      providesTags: ["uploadDocument"],
      invalidatesTags: ["uploadDocument"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/documents`,
        method: "POST",
        body,
      }),
    }),
    getUploadedDocuments: builder.query({
      providesTags: ["getUploadedDocuments"],
      invalidatesTags: ["getUploadedDocuments"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/documents`,
        method: "GET",
      }),
    }),
    getInstitutionLevels: builder.query({
      providesTags: ["institutionLevels"],
      invalidatesTags: ["institutionLevels"],
      query: (institutionId) => ({
        // url: `/api/getinstitutionlevels?institutionId=${institutionId}`,
        url: `api/institutions/${institutionId}/institution_levels`,
        method: "GET",
      }),
    }),
    getInstitutionCategories: builder.query({
      providesTags: ["institutionCategories"],
      invalidatesTags: ["institutionCategories"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/institution_categories`,
        method: "GET",
      }),
    }),
    getCategoriesInALevel: builder.query({
      providesTags: ["getCategoriesInALevel"],
      invalidatesTags: ["getCategoriesInALevel"],
      query: ({ institutionId, levelId }) => ({
        url: `/api/institutions/${institutionId}/institution_categories?levelId=${levelId}`,
        method: "GET",
      }),
    }),
    getInstitutionFees: builder.query({
      providesTags: ["institutionFee"],
      invalidatesTags: ["institutionFee"],
      query: (institutionFeeComponentId) => ({
        url: `/api/getinstitutionfees?institutionFeeComponentId=${institutionFeeComponentId}`,
        method: "GET",
      }),
    }),
    getInstitutionFeeComponent: builder.query({
      providesTags: ["institutionFeeComponent"],
      invalidatesTags: ["institutionFeeComponent"],
      query: (institutionId) => ({
        // url: `/api/getinstitutionfeecomponents?institutionId==${institutionId}`,
        url: `/api/institutions/${institutionId}/fee_components`,
        method: "GET",
      }),
    }),
    getInstitutionDocumentList: builder.query({
      providesTags: ["institutionDocumentList"],
      invalidatesTags: ["institutionDocumentList"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/documents`,
        method: "GET",
      }),
    }),
    getInstitutionDocument: builder.query({
      providesTags: ["institutionDocument"],
      invalidatesTags: ["institutionDocument"],
      query: ({ institutionId, documentId }) => ({
        url: `/api/institutions/${institutionId}/documents/${documentId}`,
        method: "GET",
      }),
    }),
    addInstitutionLevel: builder.mutation({
      providesTags: ["addInstitutionLevel"],
      invalidatesTags: ["addInstitutionLevel"],
      query: ({ institutionId, body }) => ({
        // url: "/api/addinstitutionlevel",
        url: `/api/institutions/${institutionId}/institution_levels`,
        method: "POST",
        body,
      }),
    }),
    addInstitutionCategory: builder.mutation({
      providesTags: ["addInstitutionCategory"],
      invalidatesTags: ["addInstitutionCategory"],
      query: ({ institutionId, body }) => ({
        // url: "/api/addinstitutioncategory",
        url: `/api/institutions/${institutionId}/institution_categories`,
        method: "POST",
        body,
      }),
    }),
    getInstitutionYear: builder.query({
      providesTags: ["getInstitutionYear"],
      invalidatesTags: ["getInstitutionYear"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/school_years`,
        method: "GET",
      }),
    }),
    createInstitutionYear: builder.mutation({
      providesTags: ["createInstitutionYear"],
      invalidatesTags: ["createInstitutionYear"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/school_years`,
        method: "POST",
        body,
      }),
    }),
    updateInstitutionYear: builder.mutation({
      providesTags: ["editInstitutionYear"],
      invalidatesTags: ["editInstitutionYear"],
      query: ({ yearId, body }) => ({
        url: `/api/school_years/${yearId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteInstitutionYear: builder.mutation({
      providesTags: ["deleteInstitutionYear"],
      invalidatesTags: ["deleteInstitutionYear"],
      query: ({ yearId, body }) => ({
        url: `/api/school_years/${yearId}`,
        method: "DELETE",
        body,
      }),
    }),
    getInstitutionPeriods: builder.query({
      providesTags: ["getInstitutionPeriods"],
      invalidatesTags: ["getInstitutionPeriods"],
      query: ({ institutionId, schoolYearId }) => ({
        url: `/api/institutions/${institutionId}/calendars`,
        // url: `/api/institutions/${institutionId}/calendars?schoolYearId=${schoolYearId}`,
        method: "GET",
      }),
    }),
    createInstitutionPeriod: builder.mutation({
      providesTags: ["createInstitutionPeriod"],
      invalidatesTags: ["createInstitutionPeriod"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/calendars`,
        method: "POST",
        body,
      }),
    }),
    updateInstitutionPeriod: builder.mutation({
      providesTags: ["editInstitutionPeriod"],
      invalidatesTags: ["editInstitutionPeriod"],
      query: ({ schoolYearId, institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/calendars/${schoolYearId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteInstitutionPeriod: builder.mutation({
      providesTags: ["deleteInstitutionPeriod"],
      invalidatesTags: ["deleteInstitutionPeriod"],
      query: ({ periodId }) => ({
        url: `/api/calendars/${periodId}`,
        method: "DELETE",
        // body,
      }),
    }),
    deleteSchoolYear: builder.mutation({
      providesTags: ["deleteSchoolYear"],
      invalidatesTags: ["deleteSchoolYear"],
      query: ({ yearId, body }) => ({
        url: `/api/school_years/${yearId}`,
        method: "DELETE",
        body,
      }),
    }),
    getSchoolYears: builder.query({
      providesTags: ["getSchoolYears"],
      invalidatesTags: ["getSchoolYears"],
      query: ({ institutionId }) => ({
        url: `/api/institutions/${institutionId}/school_years`,
        method: "GET",
      }),
    }),
    getPeriodHolidays: builder.query({
      providesTags: ["getPeriodHolidays"],
      invalidatesTags: ["getPeriodHolidays"],
      query: ({ institutionId, calendarId }) => ({
        url: `/api/institutions/${institutionId}/holiday/${calendarId}`,
        method: "GET",
      }),
    }),
    createPeriodHolidays: builder.mutation({
      providesTags: ["createPeriodHolidays"],
      invalidatesTags: ["createPeriodHolidays"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/holiday`,
        method: "POST",
        body,
      }),
    }),
    updatePeriodHolidays: builder.mutation({
      providesTags: ["updatePeriodHolidays"],
      invalidatesTags: ["updatePeriodHolidays"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/holiday`,
        method: "PUT",
        body,
      }),
    }),
    deletePeriodHoliday: builder.mutation({
      providesTags: ["deletePeriodHoliday"],
      invalidatesTags: ["deletePeriodHoliday"],
      query: ({ institutionId, holidayId }) => ({
        url: `/api/institutions/${institutionId}/holiday/${holidayId}`,
        method: "DELETE",
      }),
    }),
    addInstitutionFeeComponent: builder.mutation({
      invalidatesTags: ["addInstitutionFeeComponent"],
      providesTags: ["addInstitutionFeeComponent"],
      query: ({ institutionId, body }) => ({
        // url: "/api/addinstitutionfeecomponent",
        url: `/api/institutions/${institutionId}/fee_components`,
        method: "POST",
        body,
      }),
    }),
    updateInstitutionFeeComponent: builder.mutation({
      invalidatesTags: ["updateInstitutionFeeComponent"],
      providesTags: ["updateInstitutionFeeComponent"],
      query: ({ feeCompId, body }) => ({
        url: `/api/fee_components/${feeCompId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteInstitutionFeeComponent: builder.mutation({
      invalidatesTags: ["deleteInstitutionFeeComponent"],
      providesTags: ["deleteInstitutionFeeComponent"],
      query: ({ feeCompId, body }) => ({
        url: `/api/fee_components/${feeCompId}`,
        method: "DELETE",
        body,
      }),
    }),
    addInstitutionFee: builder.mutation({
      invalidatesTags: ["addInstitutionFee"],
      providesTags: ["addInstitutionFee"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/fees`,
        method: "POST",
        body,
      }),
    }),
    getAllInstitutionFees: builder.query({
      invalidatesTags: ["getAllInstitutionFees"],
      providesTags: ["getAllInstitutionFees"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/fees`,
        method: "GET",
      }),
    }),
    getInstitutionFee: builder.query({
      invalidatesTags: ["getInstitutionFee"],
      providesTags: ["getInstitutionFee"],
      query: (feeId) => ({
        url: `/api/fees/${feeId}`,
        method: "GET",
      }),
    }),
    updateInstitutionFee: builder.mutation({
      invalidatesTags: ["updateInstitutionFee"],
      providesTags: ["updateInstitutionFee"],
      query: ({ feeId, body }) => ({
        url: `/api/fees/${feeId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteInstitutionFee: builder.mutation({
      invalidatesTags: ["deleteInstitutionFee"],
      providesTags: ["deleteInstitutionFee"],
      query: (feeId) => ({
        url: `/api/fees/${feeId}`,
        method: "DELETE",
      }),
    }),
    editInstitutionLevel: builder.mutation({
      invalidatesTags: ["editInstitutionLevel"],
      providesTags: ["editInstitutionLevel"],
      query: ({ levelId, body }) => ({
        url: `/api/institution_levels/${levelId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteInstitutionLevel: builder.mutation({
      invalidatesTags: ["deleteInstitutionLevel"],
      providesTags: ["deleteInstitutionLevel"],
      query: ({ levelId, body }) => ({
        url: `/api/institution_levels/${levelId}`,
        method: "DELETE",
        body,
      }),
    }),
    editInstitutionCategory: builder.mutation({
      invalidatesTags: ["editInstitutionCategory"],
      providesTags: ["editInstitutionCategory"],
      query: ({ categoryId, body }) => ({
        url: `/api/institution_categories/${categoryId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteInstitutionCategory: builder.mutation({
      invalidatesTags: ["deleteInstitutionCategory"],
      providesTags: ["deleteInstitutionCategory"],
      query: ({ categoryId, body }) => ({
        url: `/api/institution_categories/${categoryId}`,
        method: "DELETE",
        body,
      }),
    }),
    deleteDocument: builder.mutation({
      invalidatesTags: ["deleteDocument"],
      providesTags: ["deleteDocument"],
      query: ({ institutionId, documentId }) => ({
        url: `/api/institutions/${institutionId}/documents/${documentId}`,
        method: "DELETE",
      }),
    }),
    editDocument: builder.mutation({
      invalidatesTags: ["editDocument"],
      providesTags: ["editDocument"],
      query: ({ institutionId, documentId, body }) => ({
        url: `/api/institutions/${institutionId}/documents/${documentId}`,
        method: "PUT",
        body,
      }),
    }),
    getAllBranches: builder.query({
      invalidatesTags: ["getBranches"],
      providesTags: ["getBranches"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/branches`,
        method: "GET",
      }),
    }),
    addBranch: builder.mutation({
      invalidatesTags: ["addBranch"],
      providesTags: ["addBranch"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/branches`,
        method: "POST",
        body,
      }),
    }),
    getStudentsByBranch: builder.query({
      invalidatesTags: ["getStudentsByBranch"],
      providesTags: ["getStudentsByBranch"],
      query: (branchId) => ({
        url: `/api/branches/${branchId}/students?page=0&size=20`,
        method: "GET",
      }),
    }),
    getSchoolCalendar: builder.query({
      invalidatesTags: ["getSchoolCalendar"],
      providesTags: ["getSchoolCalendar"],
      query: (calendarId) => ({
        url: `/api/schoolcalendar/${calendarId}`,
        method: "GET",
      }),
    }),
    // Report Section
    getWalletTransactions: builder.query({
      invalidatesTags: ["getWalletTransactions"],
      providesTags: ["getWalletTransactions"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/payment_history`,
        method: "GET",
      }),
    }),
    getPaymentHistory: builder.query({
      invalidatesTags: ["getPaymentHistory"],
      providesTags: ["getPaymentHistory"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/wallet_payments`,
        method: "GET",
      }),
    }),
    getLevelsSummary: builder.query({
      invalidatesTags: ["getLevelsSummary"],
      providesTags: ["getLevelsSummary"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/level-reports`,
        method: "GET",
      }),
    }),
    getLevelDetail: builder.query({
      invalidatesTags: ["getLevelDetail"],
      providesTags: ["getLevelDetail"],
      query: ({ institutionId, levelId }) => ({
        url: `/api/institutions/${institutionId}/level-reports/${levelId}`,
        method: "GET",
      }),
    }),
    getCategoriesSummary: builder.query({
      invalidatesTags: ["getCategoriesSummary"],
      providesTags: ["getCategoriesSummary"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/categories-reports`,
        method: "GET",
      }),
    }),
    getCategoryDetail: builder.query({
      invalidatesTags: ["getCategoryDetail"],
      providesTags: ["getCategoryDetail"],
      query: ({ institutionId, categoryId }) => ({
        url: `/api/institutions/${institutionId}/categories-reports/${categoryId}`,
        method: "GET",
      }),
    }),
    // Dashboard Report
    getDashboardReport: builder.query({
      invalidatesTags: ["getDashboardReport"],
      providesTags: ["getDashboardReport"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/dashboard_report`,
        method: "GET",
      }),
    }),
    // Settelment Section
    getSettlementHistory: builder.query({
      invalidatesTags: ["getSettlementHistory"],
      providesTags: ["getSettlementHistory"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/settlements`,
        method: "GET",
      }),
    }),
    getSettlementAccounts: builder.query({
      invalidatesTags: ["getSettlementAccounts"],
      providesTags: ["getSettlementAccounts"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/settlement-accounts`,
        method: "GET",
      }),
    }),
    getServiceAccountHistory: builder.query({
      invalidatesTags: ["getServiceAccountHistory"],
      providesTags: ["getServiceAccountHistory"],
      query: (institutionId) => ({
        //url: `/api/institutions/${institutionId}/settlements`,
        url: `/api/serviceaccount/transactions/${institutionId}`,
        method: "GET",
      }),
    }),
    getBalanceEnquiry: builder.query({
      invalidatesTags: ["getBalanceEnquiry"],
      providesTags: ["getBalanceEnquiry"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/balance_inquiry`,
        method: "GET",
      }),
    }),
    accountNameEnquiry: builder.mutation({
      invalidatesTags: ["accountNameEnquiry"],
      providesTags: ["accountNameEnquiry"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/get_account_details`,
        method: "POST",
        body,
      }),
    }),
    getBanks: builder.query({
      invalidatesTags: ["getBanks"],
      providesTags: ["getBanks"],
      query: () => ({
        url: `/api/banks`,
        method: "GET",
      }),
    }),
    addSettlementAccount: builder.mutation({
      invalidatesTags: ["addSettlementAccount"],
      providesTags: ["addSettlementAccount"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/set_settlement_account`,
        method: "POST",
        body,
      }),
    }),
    changeSettlementAccount: builder.mutation({
      invalidatesTags: ["changeSettlementAccount"],
      providesTags: ["changeSettlementAccount"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/change_settlement_account`,
        method: "POST",
        body,
      }),
    }),
    deleteSettlementAccount: builder.mutation({
      invalidatesTags: ["deleteSettlementAccount"],
      providesTags: ["deleteSettlementAccount"],
      query: ({ institutionId, feeComponentId }) => ({
        url: `/api/institutions/${institutionId}/settlement-accounts/${feeComponentId}`,
        method: "DELETE",
      }),
    }),
    updateInstitutionDetails: builder.mutation({
      invalidatesTags: ["updateInstitutionDetails"],
      providesTags: ["updateInstitutionDetails"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}`,
        method: "PUT",
        body,
      }),
    }),
    createClass: builder.mutation({
      invalidatesTags: ["createClass"],
      providesTags: ["createClass"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/institution_levels`,
        method: "POST",
        body,
      }),
    }),
    updateClass: builder.mutation({
      invalidatesTags: ["updateClass"],
      providesTags: ["updateClass"],
      query: ({ classId, institutionId, body }) => ({
        // url: `/api/institution_levels/${classId}`,
        url: `/api/institutions/${institutionId}/institution_levels/${classId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteClass: builder.mutation({
      invalidatesTags: ["deleteClass"],
      providesTags: ["deleteClass"],
      query: ({ classId, body }) => ({
        url: `/api/institution_levels/${classId}`,
        method: "DELETE",
        body,
      }),
    }),
    getAllClasses: builder.query({
      invalidatesTags: ["getAllClasses"],
      providesTags: ["getAllClasses"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/institution_levels`,
        method: "GET",
      }),
    }),
    createCategory: builder.mutation({
      invalidatesTags: ["createCategory"],
      providesTags: ["createCategory"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/institution_categories`,
        method: "POST",
        body,
      }),
    }),
    updateCategory: builder.mutation({
      invalidatesTags: ["updateCategory"],
      providesTags: ["updateCategory"],
      query: ({ categoryId, body }) => ({
        url: `/api/institution_categories/${categoryId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteCategory: builder.mutation({
      invalidatesTags: ["deleteCategory"],
      providesTags: ["deleteCategory"],
      query: ({ categoryId, body }) => ({
        url: `/api/institution_categories/${categoryId}`,
        method: "DELETE",
        body,
      }),
    }),
    getAllCategories: builder.query({
      invalidatesTags: ["getAllCategories"],
      providesTags: ["getAllCategories"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/institution_categories`,
        method: "GET",
      }),
    }),
    getAllCategoriesInAClass: builder.query({
      invalidatesTags: ["getAllCategoriesInAClass"],
      providesTags: ["getAllCategoriesInAClass"],
      query: ({ institutionId, levelId }) => ({
        url: `/api/institutions/${institutionId}/institution_categories?levelId=${levelId}`,
        method: "GET",
      }),
    }),
    getAllTeachers: builder.query({
      invalidatesTags: ["getAllTeachers"],
      providesTags: ["getAllTeachers"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/teachers`,
        method: "GET",
      }),
    }),
    createTeacher: builder.mutation({
      invalidatesTags: ["createTeacher"],
      providesTags: ["createTeacher"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/teachers`,
        method: "POST",
        body,
      }),
    }),
    assignTeacher: builder.mutation({
      invalidatesTags: ["assignTeacher"],
      providesTags: ["assignTeacher"],
      query: ({ categoryId, body }) => ({
        url: `/api/institution_categories/${categoryId}/assign_teacher`,
        method: "POST",
        body,
      }),
    }),
    getAllFees: builder.query({
      invalidatesTags: ["getAllFees"],
      providesTags: ["getAllFees"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/fees`,
        method: "GET",
      }),
    }),
    createFee: builder.mutation({
      invalidatesTags: ["createFee"],
      providesTags: ["createFee"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/fees`,
        method: "POST",
        body,
      }),
    }),
    deleteFee: builder.mutation({
      invalidatesTags: ["deleteFee"],
      providesTags: ["deleteFee"],
      query: (feeId) => ({
        url: `/api/fees/${feeId}`,
        method: "DELETE",
      }),
    }),
    updateFee: builder.mutation({
      invalidatesTags: ["updateFee"],
      providesTags: ["updateFee"],
      query: ({ institutionId, feeId, body }) => ({
        url: `/api/fees/${institutionId}/${feeId}`,
        method: "PUT",
        body,
      }),
    }),
    getAllFeeComponents: builder.query({
      invalidatesTags: ["getAllFeeComponents"],
      providesTags: ["getAllFeeComponents"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/fee_components`,
        method: "GET",
      }),
    }),
    updateUserProfile: builder.mutation({
      invalidatesTags: ["updateUserProfile"],
      providesTags: ["updateUserProfile"],
      query: ({ userId, body }) => ({
        url: `/api/user/profile/${userId}`,
        method: "PUT",
        body,
      }),
    }),
    changePassword: builder.mutation({
      invalidatesTags: ["changePassword"],
      providesTags: ["changePassword"],
      query: (body) => ({
        url: `/api/user/change_password`,
        method: "POST",
        body,
      }),
    }),
    getUserProfile: builder.query({
      invalidatesTags: ["getUserProfile"],
      providesTags: ["getUserProfile"],
      query: () => ({
        url: `/api/user/profile`,
        method: "GET",
      }),
    }),
    // getAllPermissions: builder.query({
    //   invalidatesTags: ["getAllPermissions"],
    //   providesTags: ["getAllPermissions"],
    //   query: () => ({
    //     url: `/api/pages`,
    //     method: "GET",
    //   }),
    // }),
    getAllPermissions: builder.query({
      invalidatesTags: ["getAllPermissions"],
      providesTags: ["getAllPermissions"],
      query: () => ({
        url: `/api/permissions`,
        method: "GET",
      }),
    }),
    getAllRoles: builder.query({
      invalidatesTags: ["getAllRoles"],
      providesTags: ["getAllRoles"],
      query: (institutionID) => ({
        url: `/api/roles/${institutionID}`,
        method: "GET",
      }),
    }),
    createSchoolAdminRole: builder.mutation({
      invalidatesTags: ["createSchoolAdminRole"],
      providesTags: ["createSchoolAdminRole"],
      query: ({ institutionId, body }) => ({
        url: `/api/roles/${institutionId}`,
        method: "POST",
        body,
      }),
    }),
    editSchoolAdminRole: builder.mutation({
      invalidatesTags: ["editSchoolAdminRole"],
      providesTags: ["editSchoolAdminRole"],
      query: ({ institutionId, roleId, body }) => ({
        url: `/api/roles/${institutionId}/${roleId}`,
        method: "PUT",
        body,
      }),
    }),
    getSchoolAdminUsers: builder.query({
      providesTags: ["getSchoolAdminUsers"],
      invalidatesTags: ["getSchoolAdminUsers"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/admins`,
        method: "GET",
      }),
    }),
    addSchoolAdminUser: builder.mutation({
      providesTags: ["addSchoolAdminUsers"],
      invalidatesTags: ["addSchoolAdminUsers"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/school_admin`,
        method: "POST",
        body,
      }),
    }),
    updateSchoolAdminUser: builder.mutation({
      providesTags: ["updateSchoolAdminUsers"],
      invalidatesTags: ["updateSchoolAdminUsers"],
      query: ({ institutionId, userId, body }) => ({
        url: `/api/institutions/${institutionId}/${userId}/school_admin`,
        method: "PUT",
        body,
      }),
    }),
    deleteSchoolAdminUser: builder.mutation({
      providesTags: ["deleteSchoolAdminUsers"],
      invalidatesTags: ["deleteSchoolAdminUsers"],
      query: ({ institutionId, userId }) => ({
        url: `/api/institutions/${institutionId}/${userId}/school_admin`,
        method: "DELETE",
      }),
    }),
    getSchoolFeedbackSections: builder.query({
      providesTags: ["getSchoolFeedbackSections"],
      invalidatesTags: ["getSchoolFeedbackSections"],
      query: () => ({
        url: "/api/feedback_sections",
        method: "GET",
      }),
    }),
    getSchoolFeedback: builder.query({
      providesTags: ["getSchoolFeedback"],
      invalidatesTags: ["getSchoolFeedback"],
      query: ({ institutionId, section }) => ({
        url: `/api/institutions/${institutionId}/${section}/get_feedbacks`,
        method: "GET",
      }),
    }),
    getSetupProgress: builder.query({
      providesTags: ["getSetupProgress"],
      invalidatesTags: ["getSetupProgress"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/setup_progress`,
        method: "GET",
      }),
    }),
    submitApplication: builder.mutation({
      providesTags: ["submitApplication"],
      invalidatesTags: ["submitApplication"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/submit_application`,
        method: "POST",
      }),
    }),
    editInstitutionDetails: builder.mutation({
      providesTags: ["editInstitutionDetails"],
      invalidatesTags: ["editInstitutionDetails"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/edit`,
        method: "PUT",
        body,
      }),
    }),
    editContactPersons: builder.mutation({
      providesTags: ["editContactPersons"],
      invalidatesTags: ["editContactPersons"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/conctact_persons`,
        method: "POST",
        body,
      }),
    }),
    editOtpValidation: builder.mutation({
      providesTags: ["editOtpValidation"],
      invalidatesTags: ["editOtpValidation"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/otp_validation`,
        method: "POST",
        body,
      }),
    }),
    updateDocumentUpload: builder.mutation({
      providesTags: ["updateDocumentUpload"],
      invalidatesTags: ["updateDocumentUpload"],
      query: ({ institutionId, documentId, body }) => ({
        url: `/api/institutions/${institutionId}/documents/${documentId}`,
        method: "PUT",
        body,
      }),
    }),
    getStudentsByCategories: builder.query({
      providesTags: ["getStudentsByCategories"],
      invalidatesTags: ["getStudentsByCategories"],
      query: ({ institutionId, body }) => ({
        url: `/api/students/${institutionId}/bycategories`,
        method: "POST",
        body: body,
      }),
    }),

    getAllStudents: builder.query({
      providesTags: ["getAllStudents"],
      invalidatesTags: ["getAllStudents"],
      query: (institutionId) => ({
        // url: `/api/students/${institutionId}/`,
        url: `/api/students/active/${institutionId}`,
        method: "GET",
      }),
    }),

    previewInvoiceDetails: builder.query({
      providesTags: ["previewInvoiceDetails"],
      invalidatesTags: ["previewInvoiceDetails"],
      query: ({ institutionId, body }) => ({
        url: `/api/invoice/${institutionId}/preview`,
        method: "POST",
        body: body,
      }),
    }),
    createInvoice: builder.mutation({
      invalidatesTags: ["createInvoice"],
      providesTags: ["createInvoice"],
      query: ({ institutionId, body }) => ({
        url: `/api/invoice/${institutionId}`,
        method: "POST",
        body: body,
      }),
    }),
    editInvoice: builder.mutation({
      invalidatesTags: ["editInvoice"],
      providesTags: ["editInvoice"],
      query: ({ institutionId, invoiceId, body }) => ({
        url: `/api/invoice/${institutionId}/${invoiceId}`,
        method: "PUT",
        body,
      }),
    }),
    verifySettlementAcctChange: builder.mutation({
      invalidatesTags: ["verifySettlementAcctChange"],
      providesTags: ["verifySettlementAcctChange"],
      query: ({ institutionId, body }) => ({
        url: `/api/institutions/${institutionId}/verify_settlement_change`,
        method: "POST",
        body,
      }),
    }),
    getSettlementAcctDetails: builder.query({
      providesTags: ["getSettlementAcctDetails"],
      invalidatesTags: ["getSettlementAcctDetails"],
      query: (institutionId) => ({
        url: `/api/settlement_details/${institutionId}`,
        method: "GET",
      }),
    }),

    getSettlementInterval: builder.query({
      providesTags: ["getSettlementInterval"],
      invalidatesTags: ["getSettlementInterval"],
      query: (institutionId) => ({
        url: `/api/settlement_interval/${institutionId}`,
        method: "GET",
      }),
    }),

    setSettlementInterval: builder.mutation({
      invalidatesTags: ["setSettlementInterval"],
      providesTags: ["setSettlementInterval"],
      query: ({ institutionId, body }) => ({
        url: `/api/settlement_interval/${institutionId}`,
        method: "POST",
        body,
      }),
    }),

    settleInstitution: builder.mutation({
      invalidatesTags: ["settleInstitution"],
      providesTags: ["settleInstitution"],
      query: ({ body }) => ({
        url: `/api/settleinstitution`,
        method: "POST",
        body,
      }),
    }),

    getServiceAcctDetails: builder.query({
      providesTags: ["getServiceAcctDetails"],
      invalidatesTags: ["getServiceAcctDetails"],
      query: (institutionId) => ({
        url: `/api/serviceaccount/${institutionId}`,
        method: "GET",
      }),
    }),

    verifyFundTransfer: builder.mutation({
      invalidatesTags: ["verifyFundTransfer"],
      providesTags: ["verifyFundTransfer"],
      query: ({ body }) => ({
        url: `/api/serviceaccount/verify_transfer`,
        method: "POST",
        body,
      }),
    }),
    transferFundToServiceAccount: builder.mutation({
      invalidatesTags: ["transferFundToServiceAccount"],
      providesTags: ["transferFundToServiceAccount"],
      query: ({ body }) => ({
        url: `/api/serviceaccount/transfer`,
        method: "POST",
        body,
      }),
    }),

    getSchoolMessageReminders: builder.query({
      providesTags: ["getSchoolMessageReminders"],
      invalidatesTags: ["getSchoolMessageReminders"],
      query: ({ institutionId, type }) => ({
        //url: `/api/reminders/${institutionId}?type=${type}`,
        url: `/api/reminders/${institutionId}`,
        method: "GET",
      }),
    }),
    createFeeReminderMessage: builder.mutation({
      providesTags: ["createFeeReminderMessage"],
      invalidatesTags: ["createFeeReminderMessage"],
      query: ({ body }) => ({
        url: `/api/reminder/fee`,
        method: "POST",
        body,
      }),
    }),
    updateFeeReminderMessage: builder.mutation({
      providesTags: ["updateFeeReminderMessage"],
      invalidatesTags: ["updateFeeReminderMessage"],
      query: (body) => ({
        url: `/api/reminder/fee`,
        method: "PUT",
        body,
      }),
    }),
    getFeeReminderMessagePreview: builder.query({
      providesTags: ["getFeeReminderMessagePreview"],
      invalidatesTags: ["getFeeReminderMessagePreview"],
      query: (body) => ({
        url: `/api/reminder/fee/preview`,
        method: "POST",
        body,
      }),
    }),

    createOtherReminderMessage: builder.mutation({
      providesTags: ["createOtherReminderMessage"],
      invalidatesTags: ["createOtherReminderMessage"],
      query: ({ body }) => ({
        url: `/api/reminder`,
        method: "POST",
        body,
      }),
    }),

    getOtherReminderMessagePreview: builder.query({
      providesTags: ["getOtherReminderMessagePreview"],
      invalidatesTags: ["getOtherReminderMessagePreview"],
      query: (body) => ({
        url: `/api/reminder/preview`,
        method: "POST",
        body,
      }),
    }),
    getOneMessageReminder: builder.query({
      providesTags: ["getOneMessageReminder"],
      invalidatesTags: ["getOneMessageReminder"],
      query: (reminderId) => ({
        url: `/api/reminder/${reminderId}`,
        method: "GET",
      }),
    }),

    getClassExpectedRevenueSummary: builder.query({
      providesTags: ["getClassExpectedRevenueSummary"],
      invalidatesTags: ["getClassExpectedRevenueSummary"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/class-expected-revenue-reports`,
        method: "GET",
      }),
    }),
    getClassExpectedRevenueReport: builder.query({
      providesTags: ["getClassExpectedRevenueReport"],
      invalidatesTags: ["getClassExpectedRevenueReport"],
      query: ({ institutionId, classId }) => ({
        url: `/api/institutions/${institutionId}/class-expected-revenue-reports/${classId}`,
        method: "GET",
      }),
    }),
    getClassRevenueSummary: builder.query({
      providesTags: ["getClassRevenueSummary"],
      invalidatesTags: ["getClassRevenueSummary"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/class-revenue-reports`,
        method: "GET",
      }),
    }),
    getClassRevenueReport: builder.query({
      providesTags: ["getClassRevenueReport"],
      invalidatesTags: ["getClassRevenueReport"],
      query: ({ institutionId, classId }) => ({
        url: `/api/institutions/${institutionId}/class-revenue-reports/${classId}`,
        method: "GET",
      }),
    }),
    getClassPendingPaymentSummary: builder.query({
      providesTags: ["getClassPendingPaymentSummary"],
      invalidatesTags: ["getClassPendingPaymentSummary"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/class-pending-payment-reports`,
        method: "GET",
      }),
    }),
    getClassPendingPaymentReport: builder.query({
      providesTags: ["getClassPendingPaymentReport"],
      invalidatesTags: ["getClassPendingPaymentReport"],
      query: ({ institutionId, classId }) => ({
        url: `/api/institutions/${institutionId}/class-pending-payment-reports/${classId}`,
        method: "GET",
      }),
    }),
    getClassOverduePaymentSummary: builder.query({
      providesTags: ["getClassOverduePaymentSummary"],
      invalidatesTags: ["getClassOverduePaymentSummary"],
      query: (institutionId) => ({
        url: `/api/institutions/${institutionId}/class-overdue-payment-reports`,
        method: "GET",
      }),
    }),
    getClassOverduePaymentReport: builder.query({
      providesTags: ["getClassOverduePaymentReport"],
      invalidatesTags: ["getClassOverduePaymentReport"],
      query: ({ institutionId, classId }) => ({
        url: `/api/institutions/${institutionId}/class-overdue-payment-reports/${classId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetInstitutionQuery,
  useUpdateInstitutionMutation,
  useCompleteInstitutionSetupMutation,
  useGetDocumentCategoriesQuery,
  useGetInstitutionLogoQuery,
  useGetInstitutionDocumentListQuery,
  useGetInstitutionDocumentQuery,
  useGetInstitutionTypeQuery,
  useGetInstitutionFeeComponentQuery,
  useGetInstitutionFeesQuery,
  useAddInstitutionLevelMutation,
  useAddInstitutionCategoryMutation,
  useAddInstitutionFeeComponentMutation,
  useGetInstitutionCategoriesQuery,
  useGetInstitutionLevelsQuery,
  useEditInstitutionLevelMutation,
  useDeleteInstitutionLevelMutation,
  useDeleteInstitutionCategoryMutation,
  useEditInstitutionCategoryMutation,
  useCreateInstitutionYearMutation,
  useDeleteInstitutionYearMutation,
  useGetInstitutionYearQuery,
  useUpdateInstitutionYearMutation,
  useCreateInstitutionPeriodMutation,
  useDeleteInstitutionPeriodMutation,
  useUpdateInstitutionPeriodMutation,
  useGetInstitutionPeriodsQuery,
  useGetPeriodHolidaysQuery,
  useCreatePeriodHolidaysMutation,
  useUpdatePeriodHolidaysMutation,
  useDeleteInstitutionFeeComponentMutation,
  useUpdateInstitutionFeeComponentMutation,
  useAddInstitutionFeeMutation,
  useGetAllInstitutionFeesQuery,
  useGetInstitutionFeeQuery,
  useUpdateInstitutionFeeMutation,
  useDeleteInstitutionFeeMutation,
  useDeleteDocumentMutation,
  useEditDocumentMutation,
  useGetAllBranchesQuery,
  useAddBranchMutation,
  useGetStudentsByBranchQuery,
  useGetSchoolCalendarQuery,
  useGetWalletTransactionsQuery,
  useGetPaymentHistoryQuery,
  useGetLevelsSummaryQuery,
  useGetLevelDetailQuery,
  useGetCategoriesSummaryQuery,
  useGetCategoryDetailQuery,
  useGetDashboardReportQuery,
  useGetSettlementHistoryQuery,
  useGetBalanceEnquiryQuery,
  useAccountNameEnquiryMutation,
  useAddSettlementAccountMutation,
  useUpdateInstitutionDetailsMutation,
  useCreateClassMutation,
  useUpdateClassMutation,
  useDeleteClassMutation,
  useGetAllClassesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetAllCategoriesQuery,
  useGetAllCategoriesInAClassQuery,
  useCreateTeacherMutation,
  useAssignTeacherMutation,
  useGetAllTeachersQuery,
  useGetAllFeesQuery,
  useGetAllFeeComponentsQuery,
  useCreateFeeMutation,
  useDeleteFeeMutation,
  useUpdateFeeMutation,
  useUpdateUserProfileMutation,
  useChangePasswordMutation,
  useGetUserProfileQuery,
  useUploadDocumentMutation,
  useGetUploadedDocumentsQuery,
  useGetBanksQuery,
  useGetAllPermissionsQuery,
  useCreateSchoolAdminRoleMutation,
  useEditSchoolAdminRoleMutation,
  useGetAllRolesQuery,
  useGetSchoolAdminUsersQuery,
  useAddSchoolAdminUserMutation,
  useUpdateSchoolAdminUserMutation,
  useDeleteSchoolAdminUserMutation,
  useGetSchoolFeedbackSectionsQuery,
  useGetSchoolFeedbackQuery,
  useGetSetupProgressQuery,
  useSubmitApplicationMutation,
  useEditInstitutionDetailsMutation,
  useEditContactPersonsMutation,
  useEditOtpValidationMutation,
  useUpdateDocumentUploadMutation,
  useGetStudentsByCategoriesQuery,
  usePreviewInvoiceDetailsQuery,
  useCreateInvoiceMutation,
  useEditInvoiceMutation,
  useVerifySettlementAcctChangeMutation,
  useChangeSettlementAccountMutation,
  useDeleteSettlementAccountMutation,
  useGetSettlementAcctDetailsQuery,
  useGetSettlementAccountsQuery,
  useGetSettlementIntervalQuery,
  useSetSettlementIntervalMutation,
  useSettleInstitutionMutation,
  useGetServiceAcctDetailsQuery,
  useVerifyFundTransferMutation,
  useTransferFundToServiceAccountMutation,
  useGetSchoolMessageRemindersQuery,
  useCreateFeeReminderMessageMutation,
  useGetFeeReminderMessagePreviewQuery,
  useGetAllStudentsQuery,
  useCreateOtherReminderMessageMutation,
  useGetOtherReminderMessagePreviewQuery,
  useGetOneMessageReminderQuery,
  useUpdateFeeReminderMessageMutation,
  useCreateAndSendInvoiceMutation,
  useGetCategoriesInALevelQuery,
  useGetClassExpectedRevenueSummaryQuery,
  useGetClassExpectedRevenueReportQuery,
  useGetClassRevenueReportQuery,
  useGetClassRevenueSummaryQuery,
  useGetClassPendingPaymentReportQuery,
  useGetClassPendingPaymentSummaryQuery,
  useGetClassOverduePaymentReportQuery,
  useGetClassOverduePaymentSummaryQuery,
  useDeletePeriodHolidayMutation,
  useDeleteSchoolYearMutation,
  useGetSchoolYearsQuery,
  useGetServiceAccountHistoryQuery,
} = institutionSlice;
