import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import ActionIcon from "../../../../../../../../component/actionbutton/ActionIcon";
import CreateSettlement from "../createSettlement/createSettlement";
import alertIcon from "../../../../../../../../assets/svgs/alertIcon.svg";
import { Button } from "react-bootstrap";
import {
  useGetSettlementAccountsQuery,
  useDeleteSettlementAccountMutation,
} from "../../../../../../../../redux/features/institution/institutionApiSlice";
import ModalComponent from "../../../../../../../../component/modal/modal";
import OnboardingLoader from "../../../../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../../../../component/toast/toast";

const OtherSettlements = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [clickedSettlement, setClickedSettlement] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: settlementAccounts,
    isLoading: settlementAccountsLoading,
    refetch,
  } = useGetSettlementAccountsQuery(instId);

  const [deleteSettlementAccount, { isLoading: deleting }] =
    useDeleteSettlementAccountMutation();

  console.log(settlementAccounts);

  const confirmDelete = (el) => {
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeleteSettlementAcct = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);

    try {
      const parameters = {
        institutionId: instId,
        feeComponentId: elementToDelete?.feeComponent?.id,
      };

      await deleteSettlementAccount(parameters).unwrap();
      refetch();

      setShowToast(true);
      setToastMessage("Settlement account deleted successfully");
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (deleting) {
    return <OnboardingLoader />;
  }

  return (
    <div>
      {settlementAccounts?.otherAccounts?.length > 0 ? (
        <Table
          // responsive
          striped
          size="sm"
          style={{
            fontFamily: "jost, sans-serif",
            fontSize: "14px",
          }}
        >
          <thead>
            <tr align="center">
              <th>Settlement Account</th>
              <th>Bank</th>
              <th>Account Number</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {settlementAccounts?.otherAccounts?.map((settlementAccount, i) => (
              <tr key={i} className={i % 2 === 0 ? "even-row" : "odd-row"}>
                <td align="center">{settlementAccount?.feeComponent?.name}</td>
                <td align="center">{settlementAccount?.settlementBankName}</td>
                <td align="center">
                  {settlementAccount?.settlementAccountNumber}
                </td>
                <td align="center">
                  <ActionIcon
                    actionsArr={[
                      {
                        label: "Edit",
                        action: () => {
                          setClickedSettlement(settlementAccount);
                          setShowCreateModal(true);
                        },
                      },
                      {
                        label: "Delete",
                        action: () => confirmDelete(settlementAccount),
                        // isDisabled: !userAuthorizedPages?.find(
                        //   (page) => page?.action === "DELETE_FEE"
                        // ),
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <span style={{ fontStyle: "italic", marginBottom: "20px" }}>
          No other settlement account available
        </span>
      )}

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this settlement account?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              marginLeft: "30%",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={() => handleDeleteSettlementAcct()}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>

      {showCreateModal && (
        <CreateSettlement
          popup={true}
          setShowCreateModal={setShowCreateModal}
          clickedSettlement={clickedSettlement}
        />
      )}

      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
    </div>
  );
};

export default OtherSettlements;
