import React, { useState, useEffect } from "react";
import ModalComponent from "../../../../../../component/modal/modal";
import {
  useUpdateClassMutation,
  useCreateClassMutation,
  useGetInstitutionLevelsQuery,
  useGetAllClassesQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import {
  returnCategories,
  returnCategoryName,
  returnLevelName,
} from "../../../../../../utils/utils";
import { Form, Button } from "react-bootstrap";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";

const CreateClass = ({ showModal, setShowModal }) => {
  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const [createClass, { isLoading: addLoading }] = useCreateClassMutation();
  const [updateClass, { isLoading: editLoading }] = useUpdateClassMutation();
  const { refetch: levelsRefetch } = useGetInstitutionLevelsQuery(instId);
  const { refetch: refetchClasses } = useGetAllClassesQuery(instId);

  const [errorMessage, setErrorMessage] = useState(null);
  const [savedLevelsList, setSavedLevelsList] = useState([]);
  const [levelsCreated, setLevelsCreated] = useState(false);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [isCategoryBlured, setIsCategoryBlured] = useState(false);
  const [categoryNames, setCategoryNames] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [newData, setNewData] = useState(null);

  const schoolTypes = useSelector(
    (state) => state?.auth?.userInfo?.institutionTypes
  );

  const [arrayOfCategories, setArrayOfCategories] = useState([]);

  const [newLevelData, setNewLevelData] = useState({
    name: "",
    institutionTypeId: "",
    noOfCategories: "",
    categories: "",
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingElement(null);
    setEditingValue({});
    setNewLevelData({});
    setArrayOfCategories([]);
  };

  const handleCreateOtherCategories = () => {
    if (!isCategoryBlured) {
      setCategoryNames(
        returnCategories(noOfCategories, categoryNames[0]?.value)
      );
    }
  };

  const handleCategoriesChange = (e, i) => {
    const updatedArr = [...categoryNames];
    updatedArr[i] = { id: i + 1, value: e.target.value };
    setCategoryNames(updatedArr);
  };

  const handleEditLevel = (el, parentType) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowModal(true);

    // map through institutionTypes to find out the institutionTypeId of the parent element
    const schoolType = schoolTypes.find((inst) => inst.typeName == parentType);

    setEditingValue({
      name: el.name,
      noOfCategories: el.noOfCategories,
      categories: el.categories,
      institutionTypeId: schoolType.id,
    });
  };

  const { name, institutionTypeId, noOfCategories, categories } = newLevelData;

  useEffect(() => {
    setCategoryNames(returnCategories(noOfCategories, categoryNames[0]?.value));
  }, [noOfCategories]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewLevelData({ ...newLevelData, [name]: value });
    // setEditingValue(value);
    setEditingValue({ ...editingValue, [name]: value });
  };

  const handleCreateOrUpdateLevel = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      //   if (editingValue === "") {
      //     setIsFormNotFilled(true);

      //     setTimeout(() => {
      //       setIsFormNotFilled(false);
      //     }, 3000);

      //     return;
      //   }

      // newLevelData.name = editingElement.name
      const parameters = {
        classId: editingElement?.id,
        body: {
          name: editingValue.name,
          institutionTypeId: editingValue.institutionTypeId,
          // noOfCategories: editingValue.noOfCategories,
          // categories: editingValue.categories,
        },
      };

      try {
        const res = await updateClass(parameters);
        console.log(res);
        if (res) {
          setNewLevelData({
            name: "",
          });
          setEditedElement(res);
          setEditingValue("");
          setShowModal(false);
          levelsRefetch();
          refetchClasses();
        }
        setEditingElement(null);
        setCategoryNames([]);
        setIsCategoryBlured(false);
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      try {
        const { categories, institutionTypeId, ...rest } = newLevelData;
        const obj = {
          institutionId: instId,
          body: {
            ...rest,
            // if its just a type of school i. just nursetry school then there should not be a need to ask the user select the school type
            institutionTypeId:
              schoolTypes.length > 1 ? institutionTypeId : schoolTypes[0].id,
            // categories: arrayOfCategories.map((item) => item.letter),
            categories: categoryNames.map((item) => item.value),
          },
        };

        const data = await createClass(obj).unwrap();

        if (data) {
          setNewData(data);
          levelsRefetch();
          refetchClasses();
        }
        setNewLevelData({
          name: "",
        });
        setShowModal(false);
        setEditingElement(null);

        setCategoryNames([]);
        setIsCategoryBlured(false);
      } catch (error) {
        console.log(error.data);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  if (editLoading || addLoading) {
    return <OnboardingLoader />;
  }

  return (
    <ModalComponent
      title={editingElement ? "Edit Class" : "Create Class"}
      showModal={showModal}
      closeModal={handleCloseModal}
    >
      <Form>
        {schoolTypes && schoolTypes?.length > 1 && (
          <>
            <Form.Label className="formClasslabel">
              School Type{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </Form.Label>
            
            <Form.Select
              size="md"
              className="custom-focus mb-3"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              name="institutionTypeId"
              value={
                editingElement
                  ? editingValue?.institutionTypeId
                  : institutionTypeId
              }
              onChange={handleOnChange}
              required
            >
              <option value="" disabled selected>
                Select School Type
              </option>
              {schoolTypes.map(({ id, typeName }, i) => (
                <option key={id} value={id}>
                  {typeName}
                </option>
              ))}
            </Form.Select>
          </>
        )}

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            Class Name {/* {returnLevelName(schoolTypes)} Name{" "} */}
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="text"
            name="name"
            value={editingElement ? editingValue?.name : name}
            onChange={handleOnChange}
            placeholder="Enter Class Name (e.g Primary 1)"
          />
        </Form.Group>

        {/* was here before */}

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="formClasslabel">
            No of Categories
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            type="number"
            name="noOfCategories"
            value={editingElement ? editingValue : noOfCategories}
            onChange={handleOnChange}
            onBlur={handleCreateOtherCategories}
            placeholder="Enter Number of Categories (e.g 1, 2, 3)"
          />
        </Form.Group>

        {/* Render input fields based on the number entered */}
        {categoryNames.map(({ id, value }, i) => (
          <Form.Group key={id} controlId={`categoryInput${i}`}>
            <Form.Label className="formClasslabel">
              Category {i + 1}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
                marginBottom: "8px",
              }}
              type="text"
              name={`categoryInput${i}`}
              value={value}
              onChange={(e) => handleCategoriesChange(e, i)}
              onBlur={() => {
                handleCreateOtherCategories();
                setIsCategoryBlured(true);
              }}
              placeholder={`Enter Category Name (e.g A, Gold, Art)`}
            />
          </Form.Group>
        ))}

        <div style={{ marginTop: "30px" }}>
          {editingElement ? (
            <Button
              onClick={(e) => handleCreateOrUpdateLevel(e, "edit")}
              variant="primary"
              style={{
                backgroundColor: `${
                  !editingValue?.name || !editingValue?.institutionTypeId
                    ? "#FFCAB8"
                    : "#F14810"
                }`,
                borderColor: `${
                  !editingValue?.name || !editingValue?.institutionTypeId
                    ? "#FFCAB8"
                    : "#F14810"
                }`,
                cursor: `${
                  !editingValue?.name || !editingValue?.institutionTypeId
                    ? "not-allowed"
                    : "pointer"
                }`,
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Edit Level
              </span>
            </Button>
          ) : (
            <Button
              onClick={
                !noOfCategories ||
                !name ||
                // !institutionTypeId ||
                arrayOfCategories?.find((cat) => cat.letter === "")
                  ? null
                  : (e) => handleCreateOrUpdateLevel(e, "")
              }
              variant="primary"
              style={{
                backgroundColor: `${
                  !noOfCategories ||
                  !name ||
                  // !institutionTypeId ||
                  arrayOfCategories?.find((cat) => cat.letter === "")
                    ? "#FFCAB8"
                    : "#F14810"
                }`,
                borderColor: `${
                  !noOfCategories ||
                  !name ||
                  // !institutionTypeId ||
                  arrayOfCategories?.find((cat) => cat.letter === "")
                    ? "#FFCAB8"
                    : "#F14810"
                }`,
                cursor: `${
                  !noOfCategories ||
                  !name ||
                  // !institutionTypeId ||
                  arrayOfCategories?.find((cat) => cat.letter === "")
                    ? "not-allowed"
                    : "pointer"
                }`,
                float: "left",
              }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Save Level
              </span>
            </Button>
          )}
        </div>
      </Form>
    </ModalComponent>
  );
};

export default CreateClass;
