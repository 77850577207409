import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import "./index.css";

// import Auth from "./routes/index.js";
// import Institution from "./routes/index.js";

import LoginPage from "./pages/auth/login/login.page.js";
import LandingPage from "./pages/auth/landing/landingPage";
import ForgotPasswordPage from "./pages/auth/forgotPassword/forgotPassword";
import ResetPasswordPage from "./pages/auth/resetPassword/resetPassword";

import RegistrationStepper from "./pages/auth/register/registrationStepper";

import SetupInstitutionPage from "./pages/institution/setupInstitution/setupInstitution";

import ContinueSetupInstitutionPage from "./pages/institution/continueSetupInstitution/setupInstitution/continueSetupInstitution";
import HomePage from "./pages/HomePage.js";
import ProtectedRoute from "./protectedRoutes";

import InstitutionOnboardSuccessPage from "./pages/institution/institutionOnboardSuccess/institutionOnboardSuccessPage";
import InstitutionAdminDashBoardPage from "./pages/institution/institutionDashboard/institutionDashBoardPage";
import ValidationPage from "./pages/institution/setupInstitution/validationPage/validationPage";
import SuperAdminDashboard from "./pages/superAdmin/superAdminDashboard";
import UnauthorizedPage from "./unauthorizedPage";
// import StudentInformationPage from "./pages/institution/institutionDashboard/components/students/studentInformation/components/studentInfo/studentInformationPage";
// import StudentInformationStepper from "./pages/institution/institutionDashboard/components/students/studentInformation/studentInformationStepper";
// import Students from "./pages/institution/institutionDashboard/components/students/students";
import InstitutionStudentPage from "./pages/institution/institutionDashboard/institutionStudentPage";
import InstitutionInvoicingPage from "./pages/institution/institutionDashboard/invoicingPage/institutionInvoicingPage";
import InvoicingBranchStudentList from "./pages/institution/institutionDashboard/invoicingPage/components/invoicingBranchStudentList.js";
import StudentInvoiceDetails from "./pages/institution/institutionDashboard/invoicingPage/components/viewInvoiceDetails";
import ParentPage from "./pages/parent/parent";
import UnapprovedStudentsPage from "./pages/institution/institutionDashboard/components/students/unapprovedStudentsPage";
import InstitutionBranchPage from "./pages/institution/institutionDashboard/institutionBranchPage";
import BranchStudentListPage from "./pages/institution/institutionDashboard/components/branches/components/branchStudentList";
// import InstitutionSchoolCalendarPage from "./pages/institution/institutionDashboard/institutionSchoolCalendarPage";
import SchoolCalendarList from "./pages/institution/institutionDashboard/components/schoolCalendar/schoolCalendarList";
import InstitutionSchoolYearPage from "./pages/institution/institutionDashboard/institutionSchoolYearPage";
import InstitutionReportPage from "./pages/institution/institutionDashboard/components/reporting/institutionReportPage";
import InstitutionSettingsPage from "./pages/institution/institutionDashboard/settingsPage/institutionSettingsPage";
import SchoolPage from "./pages/superAdmin/components/schools/school";
import ClassesPage from "./pages/institution/institutionDashboard/classes/classesPage";
import ClassDetailsPage from "./pages/institution/institutionDashboard/classes/components/classDetailsPage";
import SettlementPage from "./pages/superAdmin/components/settings/components/settlement/settlement.js";
import SettlementDetailsPage from "./pages/superAdmin/components/settings/components/settlement/components/settlementDetails.js";
import ReportingPage from "./pages/superAdmin/components/reporting/reporting";
import ReportWalletTransactions from "./pages/superAdmin/components/reporting/components/reportWalletTransactions.js";
import ReportPaymentsPage from "./pages/superAdmin/components/reporting/components/reportPaymentsPage.js";
import PaymentHistoryPage from "./pages/institution/institutionDashboard/components/reporting/components/paymentHistory";
import LevelSummaryPage from "./pages/institution/institutionDashboard/components/reporting/components/levelSummary";
import CategorySummaryPage from "./pages/institution/institutionDashboard/components/reporting/components/categorySummary";
import LevelSummaryDetailPage from "./pages/institution/institutionDashboard/components/reporting/components/levelSummaryDetail";
import CategorySummaryDetailPage from "./pages/institution/institutionDashboard/components/reporting/components/categorySummaryDetail";
import AdvanceReportPage from "./pages/superAdmin/components/reporting/components/advanceReport/advanceReportPage";
import GenerateReportPage from "./pages/superAdmin/components/reporting/components/advanceReport/components/generateReportPage";
import SuperAdminSettingsPage from "./pages/superAdmin/components/settings/superAdminSettings";
import UserManagement from "./pages/superAdmin/components/userManagement/userManagement";
import ReportHistoryPage from "./pages/superAdmin/components/reporting/components/advanceReport/components/reportHistory";
import ReportHistoryDetailPage from "./pages/superAdmin/components/reporting/components/advanceReport/components/reportHistoryDetail";
import PreviewApplicationPage from "./pages/institution/setupInstitution/components/previewApplication/previewApplicationPage.js";
import SchoolSetupPage from "./pages/institution/institutionDashboard/setupInstitution/setupInstitutionPage.js";
import InstitutionHelpPage from "./pages/institution/institutionDashboard/components/help/help";
import ApplicationsPage from "./pages/superAdmin/components/applications/applications.js";
import PendingApplications from "./pages/superAdmin/components/applications/components/pendingApplications.js";
import RejectedApplications from "./pages/superAdmin/components/applications/components/rejectedApplications.js";
import UsersSetPasswordPage from "./pages/auth/usersSetPassword/usersSetPassword.js";
import SchoolAdminUsersPage from "./pages/superAdmin/components/schools/viewSchool/schoolAdmins/schoolAdminUsers.js";
import ApplicationRejected from "./pages/institution/institutionDashboard/settingsPage/component/applicationRejected/applicationRejected.js";
import BranchesPage from "./pages/superAdmin/components/schools/viewSchool/branches/branches.js";
import UpdateApplications from "./pages/superAdmin/components/applications/components/updateApplications.js";
import CreateInvoice from "./pages/institution/institutionDashboard/invoicingPage/components/createInvoice";
// import EditInvoice from "./pages/institution/institutionDashboard/invoicingPage/components/editInvoice.js";
import SubmittedApplications from "./pages/superAdmin/components/applications/components/submittedApplications.js";
import StudentsPage from "./pages/superAdmin/components/schools/viewSchool/students/students.js";
import WalletTransactionsPage from "./pages/institution/institutionDashboard/components/reporting/components/walletTransactions.js";
import SendMessagePage from "./pages/superAdmin/components/sendMessage/sendMessagePage.js";
import ViewSentMessages from "./pages/superAdmin/components/sendMessage/viewSentMessages.js";
import ReminderManagementPage from "./pages/institution/institutionDashboard/components/reminderMgt/reminderManagement.js";
import ViewMessages from "./pages/institution/institutionDashboard/components/reminderMgt/viewMessages.js";
import ApprovedStudents from "./pages/institution/institutionDashboard/components/students/approvedStudent.js";
import ExpectedRevenuePage from "./pages/institution/institutionDashboard/components/dashboard/subPages/expectedRevenue.js";
import RevenuePage from "./pages/institution/institutionDashboard/components/dashboard/subPages/revenue.js";
import PendingPaymentPage from "./pages/institution/institutionDashboard/components/dashboard/subPages/pendingPayment.js";
import OverduePaymentPage from "./pages/institution/institutionDashboard/components/dashboard/subPages/overduePayment.js";
import NotificationsPage from "./pages/parent/notification.js";
import RemindersHomePage from "./pages/superAdmin/components/sendMessage/remindersHomePage.js";
import NotificationsSummaryPage from "./pages/superAdmin/components/sendMessage/notificationsSummary.js";
import ReportingTypes from "./pages/superAdmin/components/reporting/reportingTypes.js";

function RootApplication() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/register" exact element={<RegistrationStepper />} />
        <Route path="/login" exact element={<LoginPage />} />
        <Route path="/forgot-password" exact element={<ForgotPasswordPage />} />

        {/* <Route path="/reset-password" exact element={<ResetPasswordPage />} /> */}
        <Route
          path="/reset_password/:email/:code"
          exact
          element={<ResetPasswordPage />}
        />

        {/* Other Users Set Password Page */}
        <Route
          path="/set_password/:email/:accessToken"
          exact
          element={<UsersSetPasswordPage />}
        />

        <Route path="/unauthorized" element={<UnauthorizedPage />}></Route>

        {/* protect this route*/}
        <Route path="/parent/invoice/:param" exact element={<ParentPage />} />

        <Route
          path="/parent/notifications/:param"
          exact
          element={<NotificationsPage />}
        />

        <Route path="/settings" exact element={<InstitutionSettingsPage />} />
        <Route path="/help" exact element={<InstitutionHelpPage />} />

        {/* Dashboard Group */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute requiredKeywords="dashboard" element={<Outlet />} />
          }
        >
          <Route path="" element={<InstitutionAdminDashBoardPage />} />
          <Route path="expectedRevenue" element={<ExpectedRevenuePage />} />
          <Route path="revenue" element={<RevenuePage />} />
          <Route path="overduePayment" element={<OverduePaymentPage />} />
          <Route path="pendingPayment" element={<PendingPaymentPage />} />
        </Route>

        {/* Branch Group */}
        <Route
          path="/branch"
          element={
            <ProtectedRoute requiredKeywords="branch" element={<Outlet />} />
          }
        >
          <Route path="" element={<InstitutionBranchPage />} />
          <Route path=":id" element={<BranchStudentListPage />} />
        </Route>

        <Route path="/landing" exact element={<LandingPage />} />

        <Route path="/school-setup" exact element={<SchoolSetupPage />} />

        {/* Reporting Group */}
        <Route
          path="/reporting"
          element={
            <ProtectedRoute requiredKeywords="report" element={<Outlet />} />
          }
        >
          <Route path="" element={<InstitutionReportPage />} />

          <Route path="paymentHistory" element={<PaymentHistoryPage />} />
          <Route
            path="walletTransactions"
            element={<WalletTransactionsPage />}
          />

          <Route path="levelSummary" element={<Outlet />}>
            <Route path="" element={<LevelSummaryPage />} />
            <Route path=":levelId" element={<LevelSummaryDetailPage />} />
          </Route>

          <Route path="categorySummary" element={<Outlet />}>
            <Route path="" element={<CategorySummaryPage />} />
            <Route path=":categoryId" element={<CategorySummaryDetailPage />} />
          </Route>
        </Route>

        <Route
          path="/settings/application-rejected"
          exact
          element={<ApplicationRejected />}
        />

        {/* student group */}
        <Route
          path="/student"
          element={
            <ProtectedRoute requiredKeywords="student" element={<Outlet />} />
          }
        >
          <Route path="" element={<InstitutionStudentPage />} />
          <Route path="unapproved" element={<UnapprovedStudentsPage />} />
          <Route path="approved" element={<ApprovedStudents />} />
        </Route>

        {/* School year group */}
        <Route
          path="/schoolYear"
          element={
            <ProtectedRoute requiredKeywords="calendar" element={<Outlet />} />
          }
        >
          <Route path="" element={<InstitutionSchoolYearPage />} />
          <Route path=":param" element={<SchoolCalendarList />} />
        </Route>

        {/* Messaging group */}
        <Route
          path="/messaging"
          element={
            <ProtectedRoute
              requiredKeywords={["reminder", "notification"]}
              element={<Outlet />}
            />
          }
        >
          <Route path="" element={<ReminderManagementPage />} />
          <Route path=":reminderId" element={<ReminderManagementPage />} />
          <Route path="viewmessages" element={<ViewMessages />} />
        </Route>

        {/* Messaging group */}
        <Route
          path="/classes"
          element={
            <ProtectedRoute requiredKeywords="class" element={<Outlet />} />
          }
        >
          <Route path="" element={<ClassesPage />} />
          <Route path=":classId" element={<ClassDetailsPage />} />
        </Route>

        {/* Invoicing group */}
        <Route
          path="/invoicing"
          element={
            <ProtectedRoute requiredKeywords="invoice" element={<Outlet />} />
          }
        >
          <Route path="" element={<InstitutionInvoicingPage />} />
          <Route path="createinvoice" element={<CreateInvoice />} />
          <Route path="createinvoice/:invoiceId" element={<CreateInvoice />} />
          <Route path=":invoiceId" element={<InvoicingBranchStudentList />} />
          <Route
            path=":invoiceId/:studentId"
            element={<StudentInvoiceDetails />}
          />
        </Route>

        <Route
          path="/onboard-success"
          exact
          element={<InstitutionOnboardSuccessPage />}
        />

        {/* admin links */}

        {/*  Dashboard  */}
        <Route
          path="/principal"
          element={
            <ProtectedRoute
              requiredKeywords="dashboard"
              type="admin"
              element={<Outlet />}
            />
          }
        >
          <Route path="" element={<SuperAdminDashboard />} />
        </Route>

        {/*  User management  */}
        <Route
          path="/principal/user-management"
          element={
            <ProtectedRoute
              requiredKeywords={["user", "role"]}
              type="admin"
              element={<Outlet />}
            />
          }
        >
          <Route path="" element={<UserManagement />} />
        </Route>

        {/*  Application */}
        <Route
          path="/principal/applications"
          element={
            <ProtectedRoute
              requiredKeywords="applications"
              type="admin"
              element={<Outlet />}
            />
          }
        >
          <Route path="" element={<ApplicationsPage />} />
          <Route path="pending" element={<PendingApplications />} />
          <Route path="submitted" element={<SubmittedApplications />} />
          <Route path="rejected" element={<RejectedApplications />} />
          <Route path="institution_update" element={<UpdateApplications />} />
        </Route>

        {/*  Schools */}
        <Route
          path="/principal/schools"
          element={
            <ProtectedRoute
              requiredKeywords="schools"
              type="admin"
              element={<Outlet />}
            />
          }
        >
          <Route path="" element={<SchoolPage />} />
          <Route
            path="schooladmin/:institutionId"
            element={<SchoolAdminUsersPage />}
          />
          <Route path="students/:institutionId" element={<StudentsPage />} />
          <Route path="branches/:institutionId" element={<BranchesPage />} />
        </Route>

        {/*  Reporting */}
        <Route
          path="/principal/reporting"
          element={
            <ProtectedRoute
              requiredKeywords="report"
              type="admin"
              element={<Outlet />}
            />
          }
        >
          <Route path="" element={<ReportingPage />} />
          <Route path="types/:institutionId" element={<ReportingTypes />} />
          {/* <Route path="type" element={<ReportingPage />} /> */}
          <Route
            path="payments/:institutionId"
            element={<ReportPaymentsPage />}
          />
          <Route
            path="wallet/:institutionId"
            element={<ReportWalletTransactions />}
          />
          <Route path="advanceReport" element={<AdvanceReportPage />} />
          <Route
            path="advanceReport/generateReport"
            element={<GenerateReportPage />}
          />
          <Route path="advanceReport/reports" element={<ReportHistoryPage />} />
          <Route
            path="advanceReport/reports/:reportId"
            element={<ReportHistoryDetailPage />}
          />
        </Route>

        {/*  Notification */}
        <Route
          path="/principal/notifications"
          element={
            <ProtectedRoute
              requiredKeywords={["notification", "reminder"]}
              type="admin"
              element={<Outlet />}
            />
          }
        >
          <Route path="" element={<RemindersHomePage />} />
          <Route path="messaging" element={<SendMessagePage />} />
          <Route path="summary" element={<NotificationsSummaryPage />} />
          <Route path="messaging/:reminderId" element={<SendMessagePage />} />
          <Route path="messaging/viewall" element={<ViewSentMessages />} />
        </Route>

        <Route
          path="/principal/settings"
          element={<SuperAdminSettingsPage />}
        />
      </Routes>
    </>
  );
}

const App = () => {
  return (
    <div
      className="App"
      style={{
        width: 100 + "%",
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <RootApplication />
    </div>
  );
};

export default App;
