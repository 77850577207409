import React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import heroimage from '../assets/images/heroimage.png';

function HeroSection() {
    return (
        <Container fluid id="hero" className="heroSection">
            <Row>
                <Col xs={12} md={6} lg={6} className="heroFirstSection" >
                    <div style={{ backgroundColor: '#EAEAEA', borderRadius: 5 + 'rem', height: 1.9 + 'rem', width: 10+'rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <svg width="21" height="19" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 10.15V22.475C0 22.8756 0.324437 23.2 0.725 23.2H4.35V8.7H1.45C0.649328 8.7 0 9.34933 0 10.15ZM16.3125 7.975H15.225V6.1625C15.225 5.96222 15.0628 5.8 14.8625 5.8H14.1375C13.9372 5.8 13.775 5.96222 13.775 6.1625V9.0625C13.775 9.26279 13.9372 9.425 14.1375 9.425H16.3125C16.5128 9.425 16.675 9.26279 16.675 9.0625V8.3375C16.675 8.13722 16.5128 7.975 16.3125 7.975ZM22.5543 5.07682L15.3043 0.243332C15.0661 0.084662 14.7862 0 14.5 0C14.2138 0 13.9339 0.084662 13.6957 0.243332L6.4457 5.07682C6.24712 5.20921 6.08429 5.38857 5.97165 5.59899C5.85901 5.8094 5.80005 6.04437 5.8 6.28304V23.2H11.6V16.675C11.6 16.2744 11.9244 15.95 12.325 15.95H16.675C17.0756 15.95 17.4 16.2744 17.4 16.675V23.2H23.2V6.28349C23.2 5.79865 22.9576 5.34552 22.5543 5.07682ZM14.5 11.6C12.4981 11.6 10.875 9.97691 10.875 7.975C10.875 5.9731 12.4981 4.35 14.5 4.35C16.5019 4.35 18.125 5.9731 18.125 7.975C18.125 9.97691 16.5019 11.6 14.5 11.6ZM27.55 8.7H24.65V23.2H28.275C28.6756 23.2 29 22.8756 29 22.475V10.15C29 9.34933 28.3507 8.7 27.55 8.7Z" fill="#404040" />
                        </svg>
                        <span style={{marginLeft:0.3+'rem', fontSize:0.8+'rem'}}>Adept for Education</span>
                    </div>
                    <h2 className="headertext"><b>Customizable <br />Cutting Edge Solution <span style={{ color: '#F14810' }}>Tailored</span> for Educational Institutions.</b></h2>
                    <p  className="herosectioncontent">
                        An AI-powered comprehensive payment solution for educational institutions by assigning a unique account number to every student for fees,
                        levies and other school related payments.
                        This helps to eliminate manual fees collection processes and ensures efficient tracking of student payments.
                    </p>
                    <Link to="/register">
                            <Button variant="dark"  className="buttonx" size="md" >
                                <span style={{ fontSize: 0.8 + 'rem',marginLeft:0.5+'rem', marginRight:0.5+'rem' }}>Get Started</span>
                            </Button>
                        </Link>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <Image src={heroimage} fluid />
                </Col>
            </Row>
        </Container>
    )
}

export default HeroSection