import React, { useState, useRef, useEffect } from "react";
import { Container, Form, InputGroup, Spinner, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import dropdownIcon from "../../../../../../../../assets/svgs/dropdownIcon.svg";
import ToastComponent from "../../../../../../../../component/toast/toast";
import SuccessModal from "../../../../../../../../component/modal/successModal";
import OnboardingLoader from "../../../../../../../../component/onboardingLoader/onboardingLoader";
import {
  useGetInstitutionQuery,
  useGetBanksQuery,
  useAccountNameEnquiryMutation,
  useVerifySettlementAcctChangeMutation,
  useChangeSettlementAccountMutation,
  useGetAllFeeComponentsQuery,
  useGetSettlementAccountsQuery,
} from "../../../../../../../../redux/features/institution/institutionApiSlice";
import OtpModal from "../../../../../../../../component/modal/otpModal";

const MainSettlement = () => {
  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const dropdownRef = useRef(null);

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showBanks, setShowBanks] = useState(false);

  const [selectedBankName, setSelectedBankName] = useState(null);
  const [selectedBankCode, setSelectedBankCode] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [accountObject, setAccountObject] = useState(null);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [settlementDetails, setSettlementDetails] = useState({
    bankCode: "",
    accountName: "",
    accountNumber: "",
  });

  const { accountName, accountNumber, bankCode } = settlementDetails;

  const { data: allBanks, isLoading: banksLoading } = useGetBanksQuery();
  const {
    data: settlementAccounts,
    isLoading: settlementAccountsLoading,
    refetch: refetchSettlementAccounts,
  } = useGetSettlementAccountsQuery(instId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);
  const [verifyAccountChange, { isLoading: verifyAccountChangeLoading }] =
    useVerifySettlementAcctChangeMutation();
  const {
    data: allFeeComponents,
    refetch: refetchFeeComponents,
    isLoading: feeComponentsLoading,
  } = useGetAllFeeComponentsQuery(instId);

  const [
    accountNameEnquiry,
    { isLoading: accountNameLoading, isSuccess: accountNameSuccess },
  ] = useAccountNameEnquiryMutation();

  const [changeAccount, { isLoading: changeAccountLoading }] =
    useChangeSettlementAccountMutation();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSettlementDetails({ ...settlementDetails, [name]: value });

    if (name === "searchText") {
      setSearchText(value);
    }
  };

  const handleChangeAccount = async () => {
    try {
      const params = {
        institutionId: instId,
        body: {
          ...accountObject,
          mainSettlementAccount: true,
          // ...(settlementType && { feeComponentId: settlementType }), // Add only if settlementType exists
        },
      };

      const res = await changeAccount(params).unwrap();

      if (res) {
        setShowOTPModal(true);
      }

      // if (settlementAcctDetails) refetchSettlementAcctDetails();
    } catch (error) {
      setShowToast(true);
      setToastMessage(error.data.detail);
    }
  };

  const handleResendCode = async (e) => {
    e.preventDefault();

    try {
      const params = {
        institutionId: instId,
        body: accountObject,
      };
      const res = await changeAccount(params).unwrap();

      if (res) {
        setShowToast(true);
        setToastMessage("Sending code again...");
      }
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  //to be used for validation of OTP
  const handleValidate = async (otp) => {
    try {
      const params = {
        institutionId: institutionDetails.id,
        body: { validation_code: otp },
      };
      const result = await verifyAccountChange(params).unwrap();

      console.log(result);

      if (result) {
        // refetchSettlementAcctDetails();
        refetchSettlementAccounts();
        setShowSuccessModal(true);
        setShowOTPModal(false);
        setSettlementDetails({});
        return true; // Indicate success so i can use that to clear the otp
      }
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
      return false; // Indicate failure
    }
  };

  useEffect(() => {
    const filteredBanks = allBanks?.filter((bank) =>
      bank.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredBanks(filteredBanks);
  }, [searchText, allBanks]);

  useEffect(() => {
    setAccountObject(null);

    if (accountNumber?.length === 10) {
      let params = {
        institutionId: instId,
        body: {
          bankCode: selectedBankCode,
          accountNumber: accountNumber,
        },
      };
      console.log("bank.code Params", params);
      const fetchName = async () => {
        try {
          const res = await accountNameEnquiry(params).unwrap();
          setAccountObject(res);
        } catch (error) {
          console.log(error);
        }
      };
      fetchName();
    }
  }, [accountNumber, bankCode, selectedBankCode]);

  if (banksLoading) {
    return <OnboardingLoader />;
  }

  const isDisabled =
    !accountObject?.accountName || accountNumber?.length !== 10;

  return (
    <>
      <div
        style={{ display: "flex", gap: "30px" }}
        className="account-in-settings"
      >
        <div style={{ flexBasis: "40%" }}>
          <h6>Main Settlement Account</h6>

          <p style={{ marginBottom: "5px", color: "#6A6A6A" }}>
            provide the account details to receive your settlement and you can
            click the "view history" button bellow to view your settlement
            history
          </p>

          <br />

          <p style={{ margin: "5px 0" }}>
            Click the button below to watch how to edit your set-up.
          </p>

          <span
            style={{
              color: "#F14810",
              fontWeight: "600",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => setShowVideoModal(true)}
          >
            Watch Video
          </span>
        </div>

        <div style={{ flexBasis: "60%" }}>
          <Form className="form">
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">Bank Name</Form.Label>
              <div ref={dropdownRef} style={{ position: "relative" }}>
                <div style={{ position: "relative" }}>
                  <label
                    style={{
                      width: "100%",
                      backgroundColor: "#ededed",
                      borderRadius: "5px",
                      padding: "7px 10px",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                    onClick={() => setShowBanks(!showBanks)}
                  >
                    {selectedBankName ? selectedBankName : "Select Bank"}
                  </label>
                  <img
                    src={dropdownIcon}
                    alt="dropdown icon"
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "40%",
                    }}
                  />
                </div>

                {showBanks && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: "10",
                      transition: "all",
                      transitionTimingFunction: "ease-in-out",
                      transitionDuration: "0.3s",
                      backgroundColor: "white",
                      maxHeight: "200px",
                      overflow: "auto",
                      width: "100%",
                      border: "1px solid rgb(221, 221, 221)",
                      borderRadius: "5px",
                      padding: "5px 10px",
                    }}
                  >
                    <input
                      type="search"
                      name="searchText"
                      value={searchText}
                      onChange={handleOnChange}
                      placeholder="Search for bank"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#ededed",
                        width: "100%",
                        padding: "4px 8px",
                        marginBottom: "10px",
                      }}
                    />

                    {filteredBanks?.map((bank) => (
                      <div
                        style={{ width: "100%", cursor: "pointer" }}
                        onClick={() => {
                          setSelectedBankName(bank?.name);
                          //console.log("bank.code", bank?.code );
                          setSelectedBankCode(bank?.code);
                          setShowBanks(false);
                        }}
                      >
                        {bank?.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">Account Number</Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="text"
                  pattern="\d{10}"
                  maxLength="10"
                  name="accountNumber"
                  // value={
                  //   institutionDetails?.settlementAccountNumber
                  //     ? institutionDetails?.settlementAccountNumber
                  //     : accountNumber
                  // }
                  value={accountNumber}
                  onChange={handleOnChange}
                  placeholder="Enter your Account Number (e.g 0123456789)"
                  // disabled={institutionDetails?.settlementAccountNumber}
                  required
                />
              </InputGroup>
            </Form.Group>
            {accountNameLoading && (
              <Spinner animation="border" variant="danger" color="#F14810" />
            )}
            {(accountObject || settlementAccounts?.mainAccount) && (
              <p style={{ color: "#F14810", fontWeight: "600px" }}>
                <p style={{ padding: "1px", marginBottom: "0px" }}>
                  {accountObject?.accountName ||
                    settlementAccounts?.mainAccount?.settlementAccountName}
                </p>
                <p style={{ padding: "1px", marginBottom: "0px" }}>
                  {accountObject?.accountNumber ||
                    settlementAccounts?.mainAccount?.settlementAccountNumber}
                </p>
                <p style={{ padding: "1px", marginBottom: "0px" }}>
                  {accountObject?.bankName ||
                    settlementAccounts?.mainAccount?.settlementBankName}
                </p>
              </p>
            )}
            {accountNumber?.length >= 10 &&
              accountNameSuccess == false &&
              accountNameLoading == false && (
                <p style={{ color: "red", fontStyle: "italic" }}>
                  Invalid account number.
                </p>
              )}
          </Form>

          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginTop: "30px",
            }}
          >
            <Button
              className="button"
              size="md"
              onClick={isDisabled ? null : () => handleChangeAccount()}
              style={{
                backgroundColor: `${isDisabled ? "#FFCAB8" : "#F14810"}`,
                borderColor: `${isDisabled ? "#FFCAB8" : "#F14810"}`,
                cursor: `${isDisabled ? "not-allowed" : "pointer"}`,
                fontSize: 0.8 + "rem",
                marginLeft: 0.3 + "rem",
                marginRight: 0.3 + "rem",
                float: "left",
              }}
              // disabled={addAccountLoading}
            >
              {changeAccountLoading ? (
                <>
                  <span>Loading...</span>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </>
              ) : (
                <span>Save Account Details</span>
              )}
            </Button>
          </div>
        </div>
      </div>

      <OtpModal
        showOTPModal={showOTPModal}
        handleCloseOTPModal={() => {
          setShowOTPModal(false);
        }}
        handleResendCode={handleResendCode}
        handleValidate={handleValidate}
        loading={verifyAccountChangeLoading}
      />

      <SuccessModal
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        message="Settlement account set successfully"
      />

      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
    </>
  );
};

export default MainSettlement;
