import React, { useState, useRef, useEffect } from "react";
import { Container, Form, InputGroup, Spinner, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import dropdownIcon from "../../../../../../../../assets/svgs/dropdownIcon.svg";
import {
  useGetBalanceEnquiryQuery,
  useGetInstitutionQuery,
  useGetBanksQuery,
  useAccountNameEnquiryMutation,
  useVerifySettlementAcctChangeMutation,
  useChangeSettlementAccountMutation,
  useGetSettlementAcctDetailsQuery,
  useGetSettlementIntervalQuery,
  useSetSettlementIntervalMutation,
  useSettleInstitutionMutation,
  useGetAllFeeComponentsQuery,
  useGetSettlementAccountsQuery,
} from "../../../../../../../../redux/features/institution/institutionApiSlice";
import OtpModal from "../../../../../../../../component/modal/otpModal";
import ToastComponent from "../../../../../../../../component/toast/toast";
import SuccessModal from "../../../../../../../../component/modal/successModal";
import OnboardingLoader from "../../../../../../../../component/onboardingLoader/onboardingLoader";
import ModalComponent from "../../../../../../../../component/modal/modal";

const CreateSettlement = ({
  popup,
  setShowCreateModal,
  clickedSettlement,
  setShowSuccessModal,
}) => {
  const dropdownRef = useRef(null);
  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showBanks, setShowBanks] = useState(false);
  const [selectedBankName, setSelectedBankName] = useState(
    clickedSettlement?.settlementBankName || null
  );
  const [selectedBankCode, setSelectedBankCode] = useState(
    clickedSettlement?.settlementBankCode || null
  );
  const [searchText, setSearchText] = useState("");
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [accountObject, setAccountObject] = useState(null);
  const [newAllBanks, setNewAllBanks] = useState([]);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  // const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [settlementDetails, setSettlementDetails] = useState({
    bankCode: clickedSettlement?.settlementBankCode || "",
    accountName: clickedSettlement?.settlementAccountName || "",
    accountNumber: clickedSettlement?.settlementAccountNumber || "",
    settlementType: clickedSettlement?.feeComponent?.id || "",
  });

  const { accountName, accountNumber, bankCode, settlementType } =
    settlementDetails;

  const { data: allBanks, isLoading: banksLoading } = useGetBanksQuery();
  const {
    data: settlementAccounts,
    isLoading: settlementAccountsLoading,
    refetch: refetchSettlementAccounts,
  } = useGetSettlementAccountsQuery(instId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);
  const [verifyAccountChange, { isLoading: verifyAccountChangeLoading }] =
    useVerifySettlementAcctChangeMutation();
  const {
    data: allFeeComponents,
    refetch: refetchFeeComponents,
    isLoading: feeComponentsLoading,
  } = useGetAllFeeComponentsQuery(instId);

  const [
    accountNameEnquiry,
    { isLoading: accountNameLoading, isSuccess: accountNameSuccess },
  ] = useAccountNameEnquiryMutation();

  const [changeAccount, { isLoading: changeAccountLoading }] =
    useChangeSettlementAccountMutation();

  // const {
  //   data: settlementAcctDetails,
  //   isLoading: settlementAcctDetailsLoading,
  //   refetch: refetchSettlementAcctDetails,
  // } = useGetSettlementAcctDetailsQuery(instId);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSettlementDetails({ ...settlementDetails, [name]: value });

    if (name === "searchText") {
      setSearchText(value);
    }
  };

  const handleChangeAccount = async () => {
    try {
      const params = {
        institutionId: instId,
        body: {
          ...accountObject,
          mainSettlementAccount: !popup,
          ...(settlementType && { feeComponentId: settlementType }), // Add only if settlementType exists
        },
      };

      const res = await changeAccount(params).unwrap();

      if (res) {
        setShowOTPModal(true);
      }

      // if (settlementAcctDetails) refetchSettlementAcctDetails();
    } catch (error) {
      setShowToast(true);
      setToastMessage(error.data.detail);
    }
  };

  const handleResendCode = async (e) => {
    e.preventDefault();

    try {
      const params = {
        institutionId: instId,
        body: accountObject,
      };
      const res = await changeAccount(params).unwrap();

      if (res) {
        setShowToast(true);
        setToastMessage("Sending code again...");
      }
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  //to be used for validation of OTP
  const handleValidate = async (otp) => {
    try {
      const params = {
        institutionId: institutionDetails.id,
        body: { validation_code: otp },
      };
      const result = await verifyAccountChange(params).unwrap();

      console.log(result);

      if (result) {
        // refetchSettlementAcctDetails();
        refetchSettlementAccounts();
        setShowCreateModal(false);
        setShowSuccessModal(true);
        setShowOTPModal(false);
        setSettlementDetails({});
        return true; // Indicate success so i can use that to clear the otp
      }
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
      return false; // Indicate failure
    }
  };

  useEffect(() => {
    const filteredBanks = allBanks?.filter((bank) =>
      bank.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredBanks(filteredBanks);
  }, [searchText, allBanks]);

  // useEffect(() => {
  //   if (instId) {
  //     refetchSettlementAcctDetails();
  //     setAccountObject(settlementAcctDetails);
  //   }

  //   if (settlementAccounts?.mainAccount) {
  //     setAccountObject(settlementAccounts?.mainAccount);
  //   }
  // }, [settlementAcctDetails, instId, settlementAccounts]);

  console.log(accountObject);

  useEffect(() => {
    setAccountObject(null);

    if (accountNumber?.length === 10) {
      let params = {
        institutionId: instId,
        body: {
          bankCode: selectedBankCode,
          accountNumber: accountNumber,
        },
      };
      console.log("bank.code Params", params);
      const fetchName = async () => {
        try {
          const res = await accountNameEnquiry(params).unwrap();
          setAccountObject(res);
        } catch (error) {
          console.log(error);
        }
      };
      fetchName();
    }
  }, [accountNumber, bankCode, selectedBankCode]);

  const isDisabled =
    !accountObject?.accountName ||
    accountNumber?.length !== 10 ||
    !settlementType;

  return (
    <div>
      <ModalComponent
        title={`${clickedSettlement ? "Edit" : "Create"} Settlement Account`}
        showModal={popup}
        closeModal={() => {
          setShowCreateModal(false);
        }}
      >
        {banksLoading ? (
          <OnboardingLoader />
        ) : (
          <div style={{ width: "100%" }}>
            <Form className="form">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Select Fee Component
                </Form.Label>

                <Form.Select
                  size="md"
                  className="custom-focus mb-3"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name="settlementType"
                  value={settlementType}
                  onChange={handleOnChange}
                  required
                >
                  <option value="" disabled selected>
                    Select Fee Component
                  </option>

                  {allFeeComponents?.map(({ id, name }, i) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">Bank Name</Form.Label>
                <div ref={dropdownRef} style={{ position: "relative" }}>
                  <div style={{ position: "relative" }}>
                    <label
                      style={{
                        width: "100%",
                        backgroundColor: "#ededed",
                        borderRadius: "5px",
                        padding: "7px 10px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() => setShowBanks(!showBanks)}
                    >
                      {selectedBankName ? selectedBankName : "Select Bank"}
                    </label>
                    <img
                      src={dropdownIcon}
                      alt="dropdown icon"
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "40%",
                      }}
                    />
                  </div>

                  {showBanks && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        zIndex: "10",
                        transition: "all",
                        transitionTimingFunction: "ease-in-out",
                        transitionDuration: "0.3s",
                        backgroundColor: "white",
                        maxHeight: "200px",
                        overflow: "auto",
                        width: "100%",
                        border: "1px solid rgb(221, 221, 221)",
                        borderRadius: "5px",
                        padding: "5px 10px",
                      }}
                    >
                      <input
                        type="search"
                        name="searchText"
                        value={searchText}
                        onChange={handleOnChange}
                        placeholder="Search for bank"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#ededed",
                          width: "100%",
                          padding: "4px 8px",
                          marginBottom: "10px",
                        }}
                      />

                      {filteredBanks?.map((bank) => (
                        <div
                          style={{ width: "100%", cursor: "pointer" }}
                          onClick={() => {
                            setSelectedBankName(bank?.name);
                            //console.log("bank.code", bank?.code );
                            setSelectedBankCode(bank?.code);
                            setShowBanks(false);
                          }}
                        >
                          {bank?.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Form.Group>

              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Account Number
                </Form.Label>
                <InputGroup className="mb-2">
                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="text"
                    pattern="\d{10}"
                    maxLength="10"
                    name="accountNumber"
                    // value={
                    //   institutionDetails?.settlementAccountNumber
                    //     ? institutionDetails?.settlementAccountNumber
                    //     : accountNumber
                    // }
                    value={accountNumber}
                    onChange={handleOnChange}
                    placeholder="Enter your Account Number (e.g 0123456789)"
                    // disabled={institutionDetails?.settlementAccountNumber}
                    required
                  />
                </InputGroup>
              </Form.Group>

              {accountNameLoading && (
                <Spinner animation="border" variant="danger" color="#F14810" />
              )}

              {accountObject && (
                <p style={{ color: "#F14810", fontWeight: "600px" }}>
                  <p style={{ padding: "1px", marginBottom: "0px" }}>
                    {accountObject?.accountName}
                  </p>
                  <p style={{ padding: "1px", marginBottom: "0px" }}>
                    {accountObject?.accountNumber}
                  </p>
                  <p style={{ padding: "1px", marginBottom: "0px" }}>
                    {accountObject?.bankName}
                  </p>
                </p>
              )}

              {accountNumber?.length >= 10 &&
                accountNameSuccess == false &&
                accountNameLoading == false && (
                  <p style={{ color: "red", fontStyle: "italic" }}>
                    Invalid account number.
                  </p>
                )}
            </Form>

            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginTop: "30px",
              }}
            >
              <Button
                className="button"
                size="md"
                onClick={isDisabled ? null : () => handleChangeAccount()}
                style={{
                  backgroundColor: `${isDisabled ? "#FFCAB8" : "#F14810"}`,
                  borderColor: `${isDisabled ? "#FFCAB8" : "#F14810"}`,
                  cursor: `${isDisabled ? "not-allowed" : "pointer"}`,
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                  float: "left",
                }}
                // disabled={addAccountLoading}
              >
                {changeAccountLoading ? (
                  <>
                    <span>Loading...</span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                ) : (
                  <span>Save Account Details</span>
                )}
              </Button>
            </div>

            <ToastComponent
              showToast={showToast}
              setShowToast={setShowToast}
              toastMessage={toastMessage}
            />
          </div>
        )}
      </ModalComponent>

      <OtpModal
        showOTPModal={showOTPModal}
        handleCloseOTPModal={() => {
          setShowOTPModal(false);
        }}
        handleResendCode={handleResendCode}
        handleValidate={handleValidate}
        loading={verifyAccountChangeLoading}
      />
    </div>
  );
};

export default CreateSettlement;
