import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import DashboardTopSection from "../../dashboardTopSection";
import DashboardSidebar from "../../dashboardSideBar/sidebar";
import TableComponent from "../../../../../component/table/table";
import { addTrailingCommas } from "../../../../../utils/utils";
import backArrow from "../../../../../assets/svgs/backArrow.svg";
import { useGetSuperWalletTransactionsQuery } from "../../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { useSelector } from "react-redux";

const ReportWalletTransactions = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const { institutionId } = useParams();
  const navigate = useNavigate();

  const { data: walletTransactions, isLoading } =
    useGetSuperWalletTransactionsQuery(institutionId, { skip: !institutionId });

  console.log(walletTransactions);

  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        customBodyRender: (date) => (date ? date?.split("T")[0] : "N/A"),
      },
    },
    {
      name: "studentName",
      label: "Student Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstName, middleName, lastName } =
            walletTransactions[tableMeta.rowIndex];
          return `${firstName} ${middleName ? middleName : ""} ${lastName}`;
        },
      },
    },
    {
      name: "paidAmount",
      label: "Paid Amount",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "feeTotal",
      label: "Fee Total",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },

    { name: "class", label: "Class" },
    { name: "calendar", label: "Calendar" },
    { name: "transactionReference", label: "Transaction Ref" },
    { name: "studentNo", label: "Student No" },
    {
      name: "paid",
      label: "Status",
      options: {
        customBodyRender: (status) => (status ? "Paid" : "Not Paid"),
      },
    },
  ];

  if (isLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="schools-page no-scrollbar">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          <img src={backArrow} alt="back arrow" />
          <h6 style={{ marginBottom: "0px" }}>Wallet Transactions</h6>
        </div>

        <TableComponent
          data={walletTransactions}
          title=""
          columns={columns}
          hasAuthority={userAuthorizedPages?.find(
            (page) => page?.action === "ADMIN_VIEW_REPORTS"
          )}
          filter={true}
          search={true}
        />

        <br />
        <br />
      </div>
    </div>
  );
};

export default ReportWalletTransactions;
