import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto"; // Use the auto version of Chart.js
import { useDispatch, useSelector } from "react-redux";

// import { useGetDashboardReportQuery } from '../../../../../redux/features/institution/institutionApiSlice';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 1.1,

  plugins: {
    legend: {
      position: "top",
      align: "end",
      labels: {
        boxWidth: 6,
        boxHeight: 6,
        usePointStyle: "circle",
      },
    },
    title: {
      display: true,
      text: "Financial Report",
      align: "start",
      padding: {
        top: 4,
        bottom: -10,
      },
      font: {
        size: 13,
        family: " 'Jost', sans-serif",
        color: "#263238",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#606060",
      },
      grid: {
        color: "#E2E2E2",
      },
    },
    x: {
      ticks: {
        color: "#606060",
      },
      grid: {
        color: "white",
      },
    },
  },
};

const labels = [
  "Grd I",
  "Grd II",
  "Grd III",
  "Grd IV",
  "Pri1",
  "Pri2",
  "Pri3",
  "Pri4",
  "Pri5",
];

function LineDemo() {
  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  // console.log(instId);

  // const { data: dashboardData, isLoading: dashboardDataLoading } =
  //   useGetDashboardReportQuery(instId);

  const dashboardRec = useSelector(
    (state) => state?.institution?.dashboardData
  );
  // console.log("LineDemo", dashboardRec);

  let labels = [];
  let paidLabelData = [];
  let outstandingLabelData = [];

  const levelFinancialList = dashboardRec?.classFinancialReports?.map(
    (item) => {
      labels = [...labels, item.className];
      paidLabelData = [...paidLabelData, item.paidAmount];
      outstandingLabelData = [...outstandingLabelData, item.outstandingAmount];
    }
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Paid",
        data: paidLabelData,
        borderColor: "#3174FD",
        backgroundColor: "#3174FD",
        borderWidth: 1.5,
        hitRadius: 0.3,
        tension: 0.4,
      },
      {
        label: "Outstanding",
        data: outstandingLabelData,
        borderColor: "#FD0E0E",
        backgroundColor: "#FD0E0E",
        borderWidth: 1.5,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="cardgraph">
      <Line options={options} data={data} usePointStyle={true} />
    </div>
  );
}

export default LineDemo;
