import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ModalComponent from "./modal";
import { useGetInstitutionQuery } from "../../redux/features/institution/institutionApiSlice";
import { Spinner } from "react-bootstrap";
import OnboardingLoader from "../onboardingLoader/onboardingLoader";

const OtpModal = ({
  showOTPModal,
  handleCloseOTPModal,
  handleResendCode,
  loading,
  handleValidate,
}) => {
  const otpBoxReference = useRef([]);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);

  const handleOTPChange = async (value, index) => {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }

    // if (index === numberOfDigits - 1) {
    //   if (isOtpValid) {
    //     setIsOtpValid(true);
    //     setShowOTPModal(false);
    //   }
    // }
  };

  const handleOTPBackspaceAndEnter = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  useEffect(() => {
    if (otp.join("").length == 6) {

      handleValidate(otp.join("")).then((isValid) => {
        if (isValid) {
          setOtp(new Array(6).fill("")); // Clear OTP is validation was successful
        }
      });
    }
  }, [otp]);

  return (
    <ModalComponent
      title=""
      showModal={showOTPModal}
      closeModal={handleCloseOTPModal}
    >
      {detailsLoading || loading ? (
        <OnboardingLoader />
      ) : (
        <div className="validate-page">
          <div className="validate-email-container">
            <h3 style={{ marginBottom: "15px" }}>
              Enter the 6-digit code sent to{" "}
              {institutionDetails?.createdBy?.email}
            </h3>

            {/* {otpError && (
            <span style={{ color: "red", fontStyle: "italic" }}>
              {otpError}
            </span>
          )} */}

            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  value={digit}
                  maxLength={1}
                  onChange={(e) => handleOTPChange(e.target.value, index)}
                  onKeyUp={(e) => handleOTPBackspaceAndEnter(e, index)}
                  ref={(reference) =>
                    (otpBoxReference.current[index] = reference)
                  }
                  className={`border h-auto text-black p-2 px-1 rounded-md block bg-grey focus:border-2 focus:outline-none appearance-none`}
                />
              ))}
            </div>

            <div>
              <p
                style={{
                  fontFamily: "jost, sans-serif",
                  fontSize: 0.85 + "rem",
                  marginTop: 1 + "rem",
                  marginRight: -2 + "rem",
                }}
              >
                Haven’t received code,
                <span
                  style={{ color: "#3174fd", cursor: "pointer" }}
                  onClick={handleResendCode}
                >
                  Resend
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

export default OtpModal;
