import { useState } from "react";
import { Container, Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import DashboardSidebar from "../../components/dashboardSidebar/sidebar";
import DashboardTopSection from "../../components/dashboardTopSection/index";
import { useGetInstitutionQuery } from "../../../../../redux/features/institution/institutionApiSlice";
import helpVideos from "../../../../../constants/helpVideos.json";
import { formatTitle } from "../../../../../utils/utils";
import menuIcon from "../../../../../assets/svgs/menuIcon.svg";
import HelpVideoDisplayPage from "./components/helpVideoDisplay";

const helpVideosArray = Object.entries(helpVideos).map(([key, value]) => {
  return { header: formatTitle(key), ...value };
});

// console.log("TITLE", helpVideosArray);
// const defaultStage = formatTitle(helpVideos.introduction)

const InstitutionHelpPage = () => {
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(id);

  const [activeStage, setActiveStage] = useState("Introduction");
  const [activeHeader, setActiveHeader] = useState("Introduction");
  const [activeTitle, setActiveTitle] = useState(
    "Introduction to Adept for Education (A4E)"
  );
  const [activeVideoId, setActiveVideoId] = useState(
    "cGT6WMx72Mk?si=9FFHCYKf1YWEtN10"
  );
  const [showMenu, setShowMenu] = useState(false);

  const handleSetStage = (stage, title, videoId) => {
    setActiveStage(stage);
    setActiveHeader(stage);
    setActiveTitle(title);
    setActiveVideoId(videoId);
    setShowMenu(false);
  };

  if (detailsLoading) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <section style={{ height: "85%" }}>
          <h5>Help</h5>
          <section
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              height: "100%",
              display: "flex",
              position: "relative",
            }}
          >
            <div
              style={{
                borderRight: "1px solid #E7E1E1",
                flexBasis: "20%",
                padding: "30px 15px",
                minWidth: "150px",
                overflowY: "auto",
              }}
              className="settingsMenu"
            >
              {helpVideosArray.map(({ header, title, videoId }, i) => (
                <div
                  key={i}
                  style={{
                    borderRadius: "8px",
                    padding: "8px",
                    marginBottom: "10px",
                    cursor: "pointer",
                    border: `${
                      activeStage === header
                        ? "1px solid #F14810"
                        : "1px solid #F5F5F5"
                    }`,
                    backgroundColor: `${
                      activeStage === header ? "#ffe6de" : "#e8e8e8"
                    }`,
                  }}
                  // onClick={() => setActiveStage(header)}
                  onClick={() => handleSetStage(header, title, videoId)}
                >
                  <p style={{ marginBottom: 0 }}>{header}</p>
                </div>
              ))}
            </div>
            <div
              style={{
                flexBasis: "80%",
                flexGrow: 1,
                padding: "15px 30px 30px",
                overflowY: "auto",
              }}
              className="right-box-settings"
            >
              <img
                src={menuIcon}
                alt="menu icon"
                onClick={() => setShowMenu(true)}
                className="menuIcon"
                style={{ marginBottom: "10px", cursor: "pointer" }}
              />

              {activeStage === activeHeader && (
                <HelpVideoDisplayPage
                  videoId={activeVideoId}
                  videoTitle={activeTitle}
                />
              )}
            </div>

            <Offcanvas
              show={showMenu}
              onHide={() => setShowMenu(false)}
              placement={"start"}
              backdrop={true}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                width: "100%",
                maxWidth: "180px",
                height: "100%",
              }}
            >
              <Offcanvas.Body style={{ padding: "20px 0" }}>
                <div
                  style={{
                    borderRight: "1px solid #F5F5F5",
                    flexBasis: "20%",
                    padding: "30px 15px",
                    minWidth: "150px",
                    overflowY: "auto",
                  }}
                >
                  {helpVideosArray.map(({ header, title, videoId }, i) => (
                    <div
                      key={i}
                      style={{
                        borderRadius: "8px",
                        padding: "8px",
                        marginBottom: "10px",
                        cursor: "pointer",
                        border: `${
                          activeStage === header
                            ? "1px solid #F14810"
                            : "1px solid #F5F5F5"
                        }`,
                        backgroundColor: `${
                          activeStage === header ? "#ffe6de" : "#e8e8e8"
                        }`,
                      }}
                      onClick={() => handleSetStage(header, title, videoId)}
                    >
                      <p style={{ marginBottom: 0 }}>{header}</p>
                    </div>
                  ))}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </section>
        </section>
      </Container>
    </Container>
  );
};

export default InstitutionHelpPage;
