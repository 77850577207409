import React, { useState, useEffect } from "react";
import { Table, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import ActionIcon from "../actionbutton/ActionIcon";
import { useSelector } from "react-redux";
import ModalComponent from "../modal/modal";
import OnboardingLoader from "../onboardingLoader/onboardingLoader";
import {
  storeCategoryToManage,
  storeCategoryToDelete,
} from "../../redux/features/institution/institutionSlice";
import {
  useGetAllCategoriesInAClassQuery,
  useGetAllTeachersQuery,
  useAssignTeacherMutation,
  useCreateTeacherMutation,
  useGetAllClassesQuery,
  useDeleteCategoryMutation,
  useDeleteClassMutation,
  useGetInstitutionYearQuery,
  useCreateInstitutionYearMutation,
  useCreateInstitutionPeriodMutation,
  useGetInstitutionPeriodsQuery,
  useDeleteInstitutionPeriodMutation,
  useUpdateInstitutionPeriodMutation,
  useGetPeriodHolidaysQuery,
  useCreatePeriodHolidaysMutation,
  useUpdatePeriodHolidaysMutation,
  useDeletePeriodHolidayMutation,
  useDeleteSchoolYearMutation,
  useGetSchoolYearsQuery,
  useGetDashboardReportQuery,
} from "../../redux/features/institution/institutionApiSlice";
import dropdownIcon from "../../assets/svgs/dropdownIcon.svg";
import alertIcon from "../../assets/svgs/alertIcon.svg";
import successGIF from "../../assets/svgs/successGIF.gif";
import ToastComponent from "../toast/toast";
import { Calendar } from "react-bootstrap-icons";
import EditIcon from "../../assets/svgs/editIcon";
import DeleteIcon from "../../assets/svgs/deleteIcon";

const ExpandableCalendarTable = ({ data }) => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [expandedRows, setExpandedRows] = useState([]);

  const handleToggleRow = (row) => {
    if (expandedRows.includes(row)) {
      setExpandedRows(
        expandedRows.filter((expandedRow) => expandedRow !== row)
      );
    } else {
      setExpandedRows([...expandedRows, row]);
    }
  };

  const [showCreateHolidaySuccess, setShowCreateHolidaySuccess] =
    useState(false);
  const [showCreateHolidayModal, setShowCreateHolidayModal] = useState(false);
  const [showEditHolidayModal, setShowEditHolidayModal] = useState(false);
  const [showEditCalendarModal, setShowEditCalendarModal] = useState(false);
  const [showEditSessionModal, setShowEditSessionModal] = useState(false);
  const [showConfirmDeleteCalendar, setShowConfirmDeleteCalendar] =
    useState(false);
  const [showConfirmDeleteHoliday, setShowConfirmDeleteHoliday] =
    useState(false);
  const [calendarDeleteType, setCalendarDeleteType] = useState(null);

  const [rowToCreateHoliday, setRowToCreateHoliday] = useState(null);
  const [calendarToEdit, setCalendarToEdit] = useState(null);
  const [calendarToDelete, setCalendarToDelete] = useState(null);
  const [isCalendarActive, setIsCalendarActive] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [schoolYearName, setSchoolYearName] = useState("");

  const [holidayToDelete, setHolidayToDelete] = useState(null);
  const [holidayToEdit, setHolidayToEdit] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showCalendarModal, setShowCalendarModal] = useState(false);

  const [yearId, setYearId] = useState(null);
  const [holidays, setHolidays] = useState([
    { id: "1", name: "", startDate: "", endDate: "" },
  ]);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: institutionYears,
    refetch: refetchYears,
    isLoading: yearsLoading,
  } = useGetInstitutionYearQuery(instId);

  const {
    data: dashboardRec,
    isLoading: dashboardDataLoading,
    refetch: refetchDashboardDetails,
  } = useGetDashboardReportQuery(instId);

  // console.log(rowToCreateHoliday);
  console.log(expandedRows);

  useEffect(() => {
    if (institutionYears) {
      setYearId(institutionYears[0]?.id);
    }
  }, [institutionYears]);

  console.log(institutionYears);
  console.log(yearId);
  // console.log(institutionYears);

  const parameters = {
    institutionId: instId,
    // schoolYearId: yearId,
  };

  const {
    data: institutionPeriods,
    refetch: refetchPeriods,
    isLoading: periodsLoading,
  } = useGetInstitutionPeriodsQuery(parameters);

  console.log(institutionPeriods);

  const [createPeriodHolidays, { isLoading: createPeriodLoading }] =
    useCreatePeriodHolidaysMutation();
  const [updatePeriodHolidays, { isLoading: updateHolidaysLoading }] =
    useUpdatePeriodHolidaysMutation();
  const [updateInstitutionPeriod, { isLoading: updatePeriodLoading }] =
    useUpdateInstitutionPeriodMutation();
  const [deleteInstitutionPeriod, { isLoading: deletePeriodLoading }] =
    useDeleteInstitutionPeriodMutation();
  const [deletePeriodHoliday, { isLoading: deleteHolidayLoading }] =
    useDeletePeriodHolidayMutation();
  const [deleteSchoolYear, { isLoading: deleteYearLoading }] =
    useDeleteSchoolYearMutation();

  const handleEditCalendar = async (type) => {
    const params = {
      institutionId: instId,
      schoolYearId: calendarToEdit?.schoolYearId,
      body: {
        id: calendarToEdit?.schoolYearId,
        schoolYearName: schoolYearName,
        calendars: calendars?.map((calendar) => {
          const { schoolYearId, schoolYearName, ...rest } = calendar;

          return { ...rest };
        }),
      },
    };
    try {
      const res = await updateInstitutionPeriod(params).unwrap();
      console.log(res);
      setShowToast(true);
      setToastMessage("Calendar has been updated");
      if (type === "calendar") {
        setShowEditCalendarModal(false);
      } else {
        setShowEditSessionModal(false);
      }
      refetchPeriods();
      if (dashboardRec) refetchDashboardDetails();
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  const addMoreHoliday = () => {
    setHolidays([
      ...holidays,
      { id: `${holidays?.length + 1}`, name: "", startDate: "", endDate: "" },
    ]);
  };

  const addMoreCalendars = () => {
    setCalendars([
      ...calendars,
      {
        periodName: "",
        startDate: "",
        endDate: "",
        open: false,
      },
    ]);
  };

  const handleHolidayChange = (e, id) => {
    const { name, value } = e.target;
    setHolidays(
      holidays?.map((holiday) => {
        if (holiday.id === id) {
          return { ...holiday, [name]: value };
        }
        return holiday;
      })
    );
  };

  const handleCreateHoliday = async () => {
    const parameters = {
      institutionId: instId,
      body: {
        calendarId: rowToCreateHoliday,
        holidays: holidays?.map((holiday) => {
          const { id, ...rest } = holiday;
          return { ...rest };
        }),
      },
    };
    try {
      const res = await createPeriodHolidays(parameters);
      console.log(res);
      setShowCreateHolidayModal(false);
      setShowCreateHolidaySuccess(true);
      setHolidays([{ id: "1", name: "", startDate: "", endDate: "" }]);
      if (res) refetchPeriods();
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  const handleEditHoliday = async () => {
    const parameters = {
      institutionId: instId,
      body: {
        calendarId: holidayToEdit?.calendarId,
        holidays: holidays,
      },
    };

    try {
      const res = await updatePeriodHolidays(parameters);
      console.log(res);
      setShowCreateHolidayModal(false);
      setShowCreateHolidaySuccess(true);
      setHolidays([{ id: "1", name: "", startDate: "", endDate: "" }]);
      refetchPeriods();
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  console.log(institutionYears);
  console.log(calendarToDelete);

  const handleDeleteCalendar = async () => {
    setShowConfirmDeleteCalendar(false);

    const yearToDelete = institutionYears?.find(
      (year) => year.id === calendarToDelete?.id
    );

    let parameters;

    try {
      if (calendarDeleteType === "year") {
        parameters = {
          yearId: yearToDelete?.id,
          body: { name: yearToDelete?.name },
        };
        await deleteSchoolYear(parameters).unwrap();
      } else {
        parameters = {
          periodId: calendarToDelete?.id,
        };
        await deleteInstitutionPeriod(parameters).unwrap();
      }

      setShowToast(true);
      setToastMessage("Calendar Deleted Successfully");

      refetchPeriods();
      refetchYears();

      if (dashboardRec) refetchDashboardDetails();
      // setElementToDelete(categoryToDelete.id);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleDeleteHoliday = async () => {
    setShowConfirmDeleteHoliday(false);

    try {
      const parameters = {
        institutionId: instId,
        holidayId: holidayToDelete?.id,
      };

      await deletePeriodHoliday(parameters).unwrap();
      setShowToast(true);
      setToastMessage("Holiday Deleted Successfully");
      refetchPeriods();
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleCalendarChange = (e, id) => {
    const { name, value } = e.target;
    setCalendars(
      calendars?.map((calendar) => {
        if (calendar.id === id) {
          return { ...calendar, [name]: value };
        }
        return calendar;
      })
    );
  };

  useEffect(() => {
    if (calendarToEdit) {
      setIsCalendarActive(calendarToEdit?.open);
      setCalendars([calendarToEdit]);
      setSchoolYearName(calendarToEdit?.schoolYearName);

      // if its not session i am editing...
      if (!calendarToEdit?.editingSession) {
        setCalendars(calendarToEdit?.calendars);
      }
    }
  }, [calendarToEdit]);

  // console.log(calendars);

  useEffect(() => {
    if (holidayToEdit) {
      setHolidays([holidayToEdit]);
    }
  }, [holidayToEdit]);

  if (
    createPeriodLoading ||
    updatePeriodLoading ||
    deletePeriodLoading ||
    deleteHolidayLoading ||
    updateHolidaysLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      {data?.map(({ id, calendars, schoolYearName }) => (
        <div key={id} style={{ marginBottom: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <h5 style={{ fontSize: "16px" }}>{schoolYearName}</h5>

            <div style={{ display: "flex", gap: "3px" }}>
              {userAuthorizedPages?.find(
                (page) => page?.action === "CREATE_CALENDAR"
              ) && (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "35px",
                    width: "35px",
                    borderRadius: "100%",
                    backgroundColor: "#E7E7E7",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowEditCalendarModal(true);
                    setCalendarToEdit({
                      // ...row,
                      calendars: calendars,
                      schoolYearName: schoolYearName,
                      schoolYearId: id,
                      editingSession: false,
                    });
                  }}
                >
                  <EditIcon />
                </span>
              )}

              {userAuthorizedPages?.find(
                (page) => page?.action === "DELETE_CALENDAR"
              ) && (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "35px",
                    width: "35px",
                    borderRadius: "100%",
                    backgroundColor: "#E7E7E7",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowConfirmDeleteCalendar(true);
                    setCalendarToDelete({ id: id });
                    setCalendarDeleteType("year");
                  }}
                >
                  <DeleteIcon />
                </span>
              )}
            </div>
          </div>

          {calendars?.length > 0 ? (
            <Table striped bordered>
              <thead>
                <tr>
                  <th align="center">*</th>
                  <th align="center">Term Name</th>
                  <th align="center">Start Name</th>
                  <th align="center">End Name</th>
                  <th align="center">Status</th>
                  <th align="center">Action</th>
                </tr>
              </thead>
              <tbody>
                {calendars?.map((row, i) => (
                  <React.Fragment key={row.id}>
                    <tr
                      style={{
                        backgroundColor: `${
                          i % 2 === 0 ? "#fff4f1" : "#ffffff"
                        }`,
                      }}
                    >
                      <td>
                        <span onClick={() => handleToggleRow(row)}>
                          {expandedRows.includes(row) ? (
                            <img src={dropdownIcon} alt="dropdown icon" />
                          ) : (
                            <img
                              src={dropdownIcon}
                              alt="dropdown icon"
                              style={{ transform: "rotate(270deg)" }}
                            />
                          )}
                        </span>
                      </td>
                      <td>{row.periodName}</td>
                      <td>{row.startDate}</td>
                      <td>{row.endDate}</td>
                      <td>{row.open ? "Active" : "Inactive"}</td>
                      <td style={{ transform: "rotate" }}>
                        <ActionIcon
                          actionsArr={[
                            {
                              label: "Create Holiday",
                              action: () => {
                                setShowCreateHolidayModal(true);
                                setRowToCreateHoliday(row.id);
                              },
                            },
                            {
                              label: "Edit",
                              action: () => {
                                setShowEditSessionModal(true);
                                setCalendarToEdit({
                                  ...row,
                                  schoolYearName: schoolYearName,
                                  schoolYearId: id,
                                  editingSession: true,
                                });
                              },
                              isDisabled: !userAuthorizedPages?.find(
                                (page) => page?.action === "CREATE_CALENDAR"
                              ),
                            },
                            {
                              label: "Delete",
                              action: () => {
                                setShowConfirmDeleteCalendar(true);
                                setCalendarToDelete(row);
                                setCalendarDeleteType("term");
                              },
                              isDisabled: !userAuthorizedPages?.find(
                                (page) => page?.action === "DELETE_CALENDAR"
                              ),
                            },
                          ]}
                        />
                      </td>
                    </tr>

                    {expandedRows.includes(row) && (
                      <>
                        <tr>
                          <th align="center" style={{ fontSize: "14px" }}></th>
                          <th align="center" style={{ fontSize: "14px" }}>
                            Holiday Name
                          </th>
                          <th align="center" style={{ fontSize: "14px" }}>
                            start Date
                          </th>
                          <th align="center" style={{ fontSize: "14px" }}>
                            End Date
                          </th>
                          <th align="center" style={{ fontSize: "14px" }}>
                            Action
                          </th>
                          <th align="center" style={{ fontSize: "14px" }}></th>
                        </tr>
                        {row?.holidays?.map((holiday, i) => (
                          <tr
                            key={i}
                            style={{
                              backgroundColor: `${
                                i % 2 === 0 ? "#dfdfdf" : "#ffffff"
                              }`,
                            }}
                          >
                            <td></td>
                            <td>{holiday?.name}</td>
                            <td>{holiday?.startDate}</td>
                            <td>{holiday?.endDate}</td>
                            <td>
                              <ActionIcon
                                style={{ transform: "rotate(90deg)" }}
                                actionsArr={[
                                  {
                                    label: "Edit",
                                    action: () => {
                                      setShowCreateHolidayModal(true);
                                      setHolidayToEdit({
                                        ...holiday,
                                        calendarId: row.id,
                                      });
                                    },
                                  },
                                  {
                                    label: "Delete",
                                    action: () => {
                                      setShowConfirmDeleteHoliday(true);
                                      setHolidayToDelete({ ...holiday });
                                    },
                                  },
                                ]}
                              />
                            </td>
                            <td></td>
                          </tr>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          ) : (
            <span style={{ fontStyle: "italic", marginBottom: "20px" }}>
              Nothing to show, add a session/term
            </span>
          )}
        </div>
      ))}

      {/* edit calendar */}
      <ModalComponent
        title="Edit Calendar"
        showModal={showEditCalendarModal}
        closeModal={() => setShowEditCalendarModal(false)}
      >
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              School Year{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              value={schoolYearName}
              onChange={(e) => setSchoolYearName(e.target.value)}
              placeholder="Enter School Year"
            />
          </Form.Group>

          {calendars?.map(({ id, periodName, startDate, endDate, open }) => (
            <div
              key={id}
              style={{
                padding: "10px",
                border: "1px solid #bdbdbd",
                borderRadius: "8px",
                marginBottom: "15px",
              }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Session/Term Name{" "}
                  <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
                </Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="text"
                  name="periodName"
                  value={periodName}
                  onChange={(e) => handleCalendarChange(e, id)}
                  placeholder="Enter Session/Term Name"
                />
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Start Date{" "}
                      <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                        *
                      </span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => handleCalendarChange(e, id)}
                      placeholder="Enter Start Date"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      End Date{" "}
                      <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                        *
                      </span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => handleCalendarChange(e, id)}
                      // onChange={(e) => handleHolidayChange(e, id)}
                      placeholder="Enter End Date"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div
                className="calender-switch-container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    // checked={isToggled}
                    checked={open}
                    onChange={() =>
                      setCalendars(
                        calendars?.map((calendar) => {
                          if (id === calendar.id) {
                            return { ...calendar, open: !calendar.open };
                          }
                          return calendar;
                        })
                      )
                    }
                    className="toggle"
                  />
                  <span className="slider round"></span>
                </label>
                <label className="toggle-label">Set Calendar to Active</label>
              </div>
            </div>
          ))}

          <div style={{ marginTop: "10px" }}>
            <span
              style={{
                color: "#F14810",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={addMoreCalendars}
            >
              + Add More Session / Term
            </span>
          </div>

          <div style={{ marginTop: "30px" }}>
            <Button
              onClick={() => handleEditCalendar("calendar")}
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
                fontSize: "14px",
              }}
              className="button"
              size="md"
            >
              Edit Calendar
            </Button>
          </div>
        </Form>
      </ModalComponent>

      {/* edit session */}
      <ModalComponent
        title="Edit Session/Term"
        showModal={showEditSessionModal}
        closeModal={() => setShowEditSessionModal(false)}
      >
        <Form>
          {calendars?.map(({ id, periodName, startDate, endDate, open }) => (
            <div
              key={id}
              style={{
                marginBottom: "15px",
              }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Session/Term Name{" "}
                  <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
                </Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="text"
                  name="periodName"
                  value={periodName}
                  onChange={(e) => handleCalendarChange(e, id)}
                  placeholder="Enter Session/Term Name"
                />
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Start Date{" "}
                      <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                        *
                      </span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => handleCalendarChange(e, id)}
                      placeholder="Enter Start Date"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      End Date{" "}
                      <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                        *
                      </span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => handleCalendarChange(e, id)}
                      // onChange={(e) => handleHolidayChange(e, id)}
                      placeholder="Enter End Date"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div
                className="calender-switch-container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    // checked={isToggled}
                    checked={open}
                    onChange={() =>
                      setCalendars(
                        calendars?.map((calendar) => {
                          if (id === calendar.id) {
                            return { ...calendar, open: !calendar.open };
                          }
                          return calendar;
                        })
                      )
                    }
                    className="toggle"
                  />
                  <span className="slider round"></span>
                </label>
                <label className="toggle-label">Set Calendar to Active</label>
              </div>
            </div>
          ))}

          <div style={{ marginTop: "30px" }}>
            <Button
              onClick={() => handleEditCalendar("session")}
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
                fontSize: "14px",
              }}
              className="button"
              size="md"
            >
              Save Changes
            </Button>
          </div>
        </Form>
      </ModalComponent>

      {/* create or edit holiday */}
      <ModalComponent
        title={holidayToEdit ? "Edit Holiday" : "Create Holiday"}
        showModal={showCreateHolidayModal}
        closeModal={() => {
          setShowCreateHolidayModal(false);
          setHolidays([{ id: "1", name: "", startDate: "", endDate: "" }]);
          setHolidayToEdit(null);
        }}
      >
        <Form>
          {holidays?.map(({ id, name, startDate, endDate }, i) => (
            <div key={id}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">
                  Holiday Name{" "}
                  <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
                </Form.Label>

                <Form.Control
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => handleHolidayChange(e, id)}
                  placeholder="Enter Holiday Name"
                />
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Start Date{" "}
                      <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                        *
                      </span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => handleHolidayChange(e, id)}
                      placeholder="Enter Start Date"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      End Date{" "}
                      <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                        *
                      </span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => handleHolidayChange(e, id)}
                      placeholder="Enter End Date"
                    />
                  </Form.Group>
                </Col>
              </Row>

              {i < holidays.length - 1 && <hr />}
            </div>
          ))}

          {!holidayToEdit && (
            <div style={{ marginTop: "10px" }}>
              <span
                style={{
                  color: "#F14810",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={addMoreHoliday}
              >
                + Add More Holiday
              </span>
            </div>
          )}

          <div style={{ marginTop: "30px" }}>
            <Button
              onClick={
                holidayToEdit
                  ? () => handleEditHoliday()
                  : () => handleCreateHoliday()
              }
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                float: "left",
                fontSize: "14px",
              }}
              className="button"
              size="md"
            >
              {holidayToEdit ? "Edit Holiday" : "Save Holiday"}
            </Button>
          </div>
        </Form>
      </ModalComponent>

      {/*create holiday success modal */}
      <ModalComponent
        showModal={showCreateHolidaySuccess}
        closeModal={() => {
          setShowCreateHolidaySuccess(false);
          // setTeacherCreated(null);
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            {holidayToEdit
              ? "Holiday has been edited successfully"
              : "Holiday has been created successfully"}
          </p>
        </div>
      </ModalComponent>

      {/* delete calendar confirmation modal */}
      <ModalComponent
        showModal={showConfirmDeleteCalendar}
        closeModal={() => {
          setShowConfirmDeleteCalendar(false);
          setCalendarToDelete(null);
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "15px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this Calendar?{" "}
          </p>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => setShowConfirmDeleteCalendar(false)}
              type="button"
              variant="outline-primary"
              className="button"
              size="md"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                borderColor: "#F14810",
                color: "#F14810",
              }}
            >
              No
            </Button>

            <Button
              onClick={() => handleDeleteCalendar()}
              type="button"
              variant="primary"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                color: "white",
              }}
              className="button"
              size="md"
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalComponent>

      {/* delete holiday confirmation modal */}
      <ModalComponent
        showModal={showConfirmDeleteHoliday}
        closeModal={() => {
          setShowConfirmDeleteHoliday(false);
          setHolidayToDelete(null);
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "15px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this Holiday?{" "}
          </p>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => setShowConfirmDeleteHoliday(false)}
              type="button"
              variant="outline-primary"
              className="button"
              size="md"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                borderColor: "#F14810",
                color: "#F14810",
              }}
            >
              No
            </Button>

            <Button
              onClick={() => handleDeleteHoliday()}
              type="button"
              variant="primary"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                color: "white",
              }}
              className="button"
              size="md"
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default ExpandableCalendarTable;
