import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import successGIF from "../../assets/svgs/successGIF.gif";

const SuccessModal = ({ show, onClose, message }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body style={{ textAlign: "center" }}>
        <img
          src={successGIF}
          alt="success-GIF"
          width="150px"
          style={{ margin: "0 auto" }}
        />
        <h3 style={{ color: "#F14810", fontWeight: "600", marginTop: "15px" }}>
          Successful!!!
        </h3>
        <p style={{ fontSize: "18px", marginTop: "10px" }}>{message}</p>
      </Modal.Body>
    </Modal>
  );
};

// SuccessModal.propTypes = {
//   show: PropTypes.bool.isRequired, // Whether the modal is visible
//   onClose: PropTypes.func.isRequired, // Function to close the modal
//   message: PropTypes.string.isRequired, // The success message to display
// };

export default SuccessModal;
