import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

//checks if the user has the required authority before rendering the route
const ProtectedRoute = ({ element, requiredKeywords }) => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  // treat required keywords as an array
  const requiredKeywordsArray = Array.isArray(requiredKeywords)
    ? requiredKeywords
    : [requiredKeywords];

  // Check if the user has any of the required permissions
  const hasAuthority = requiredKeywords
    ? requiredKeywordsArray.some((keyword) =>
        userAuthorizedPages?.some((authority) =>
          authority?.name?.toLowerCase()?.includes(keyword)
        )
      )
    : false;

  // console.log(userAuthorizedPages);
  // console.log(hasAuthority);
  // console.log(requiredKeywords);

  if (!userAuthorizedPages) {
    return <Navigate to="/login" replace />;
  }

  if (!hasAuthority) {
    return <Navigate to="/unauthorized" replace />;
  }

  return element;
};

export default ProtectedRoute;
