import { Sidebar, MenuItem, Menu } from "react-pro-sidebar";
import { Navbar, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import logo from "../../../../assets/svgs/logo.svg";
import { usePermittedAdminLinks } from "../../../../context/permittedAdminLinks";

const DashboardSidebar = () => {
  const menuItem = {
    height: "2.7rem",
    fontSize: "0.87rem",
  };

  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(
    window.matchMedia("(max-width: 950px)").matches
  );

  const location = useLocation();

  const { permittedLinks } = usePermittedAdminLinks();

  console.log(permittedLinks);

  return (
    <Sidebar
      backgroundColor="#FFFDFC"
      className="sidebar"
      onBackdropClick={() => setToggled(false)}
      breakPoint="always"
      toggled={toggled}
      customBreakPoint="1000px"
      onBreakPoint={setBroken}
    >
      <Navbar.Brand className="logo">
        <Image src={logo} fluid style={{ margin: "10px" }} />
      </Navbar.Brand>

      <Menu
        className="menu"
        menuItemStyles={{
          button: ({ active }) => ({
            color: active ? "white" : "#464646",
            backgroundColor: active ? "#FD5821" : "transparent",
            "&:hover": {
              backgroundColor: active ? "#FD5821" : "#F5F5F5",
              color: active ? "white" : "#FFFFFF",
            },
          }),
        }}
      >
        {permittedLinks?.map(({ path, label, icon: Icon }) => (
          <Link key={path} to={path}>
            <MenuItem
              style={menuItem}
              active={
                location.pathname === path || // Exact match for the main route
                (path !== "/principal" && location.pathname.startsWith(path)) // Starts with but excludes the main route
                  ? "active"
                  : null
              }
              icon={
                <Icon
                  active={
                    location.pathname === path ||
                    (path !== "/principal" &&
                      location.pathname.startsWith(path))
                      ? "active"
                      : null
                  }
                  size={18}
                />
              }
            >
              {label}
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </Sidebar>
  );
};

export default DashboardSidebar;
