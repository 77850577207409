import React from "react";
import { useState, useEffect } from "react";
import DashboardTopSection from "../../../dashboardTopSection";
import DashboardSidebar from "../../../dashboardSideBar/sidebar";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";
import ButtonX from "../../../../../../component/button/button";
import { Row, Col, Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import ModalComponent from "../../../../../../component/modal/modal";
import { useNavigate } from "react-router-dom";
import "./advanceReport.css";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";

// import {
//     useGetInstitutionYearQuery,
//     useCreateInstitutionYearMutation,
// } from "../../../../../../redux/features/institution/institutionApiSlice";

const AdvanceReportPage = () => {
  // const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  // console.log(instId);

  const reportGroupList = [
    {
      id: 1,
      title: "Generate Report",
      //   text: "239 Students",
      style: "branchsidecard-x1",
    },
    {
      id: 2,
      title: "Reports",
      //   text: "546 Students",
      style: "branchsidecard-x1",
    },
  ];

  const navigate = useNavigate();

  //Navigate to Various Page
  const handleViewReport = (id) => {
    // console.log(id, 7777788888888)
    if (id == 1) {
      navigate(`/principal/reporting/advanceReport/generateReport`);
    } else {
      navigate(`/principal/reporting/advanceReport/reports`);
    }
  };

  return (
    <div className="schools-page no-scrollbar">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        <section
          style={{
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              gap: "5px",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            <img src={backArrow} alt="back arrow" />
            <h6 style={{ marginBottom: "0px" }}>Reporting</h6>
          </div>

          <Row style={{ marginTop: 0.4 + "rem" }}>
            <Col xs={12} md={12} lg={12} className="otherbox">
              <Row>
                {reportGroupList.map(({ id, title }, i) => (
                  <Col
                    xs={4}
                    md={4}
                    lg={4}
                    id="advanceReportsidecard-x1"
                    key={id}
                  >
                    <div
                      className="advanceReportsidecard-x1"
                      style={{ cursor: "pointer", height: 6 + "rem" }}
                      onClick={() => handleViewReport(id)}
                    >
                      <div className="card-inner-value">
                        <h6
                          style={{
                            marginTop: 2 + "rem",
                            fontSize: "18px",
                          }}
                        >
                          <b>{title}</b>
                        </h6>
                        <p
                          className="card-inner-text"
                          style={{ marginTop: -0.61 + "rem" }}
                        ></p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
};

export default AdvanceReportPage;
