import React, { createContext, useContext, useState, useEffect } from "react";
import DashboardIcon from "../assets/svgs/dashboardIcon";
import StudentIcon from "../assets/svgs/studentIcon";
import BranchIcon from "../assets/svgs/BranchIcon";
import InvoicingIcon from "../assets/svgs/Invoicing";
import ClassIcon from "../assets/svgs/ClassIcon";
import SettlementIcon from "../assets/svgs/settlementIcon";
import ReportingIcon from "../assets/svgs/reportingIcon";
import SettingsIcon from "../assets/svgs/settingsIcon";
import HelpIcon from "../assets/svgs/helpicon";
import { useSelector } from "react-redux";

const PermittedLinksContext = createContext(undefined);

const menuItems = [
  { label: "Dashboard", path: "/dashboard", Icon: DashboardIcon },
  { label: "Branch Management", path: "/branch", Icon: BranchIcon },
  { label: "Students Management", path: "/student", Icon: StudentIcon },
  { label: "Invoicing", path: "/invoicing", Icon: InvoicingIcon },
  { label: "Classes", path: "/classes", Icon: ClassIcon },
  {
    label: "Reminders & Notifications",
    path: "/messaging",
    Icon: SettlementIcon,
  },
  { label: "Reporting", path: "/reporting", Icon: ReportingIcon },
  { label: "Settings", path: "/settings", Icon: SettingsIcon },
  { label: "Help", path: "/help", Icon: HelpIcon },
];

const authorityKeywordMap = {
  Dashboard: "dashboard",
  "Branch Management": "branch",
  "Students Management": "student",
  Invoicing: "invoice",
  Classes: "class",
  "Reminders & Notifications": ["reminders", "notification"],
  Reporting: "report",
  Settings: "",
  Help: "",
};

export const PermittedNavLinksProvider = ({ children }) => {
  const [permittedLinks, setPermittedLinks] = useState([]);

  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  useEffect(() => {
    const filteredNavLinks = menuItems.filter(({ label }) => {
      const requiredKeywords = authorityKeywordMap[label];

      // Ensure that requiredKeywords is treated as an array
      const requiredKeywordsArray = Array.isArray(requiredKeywords)
        ? requiredKeywords
        : [requiredKeywords];

      return (
        requiredKeywordsArray.includes("") || // Always include items with empty keyword
        (requiredKeywordsArray.length > 0 &&
          userAuthorizedPages?.some((authority) =>
            requiredKeywordsArray?.some((keyword) =>
              authority?.name?.toLowerCase().includes(keyword)
            )
          ))
      );
    });

    setPermittedLinks(filteredNavLinks);
  }, [userAuthorizedPages]);

  // console.log(permittedLinks);
  // console.log(userAuthorizedPages);

  return (
    <PermittedLinksContext.Provider
      value={{
        permittedLinks,
        setPermittedLinks,
      }}
    >
      {children}
    </PermittedLinksContext.Provider>
  );
};

export const usePermittedLinks = () => {
  const context = useContext(PermittedLinksContext);

  return context;
};
