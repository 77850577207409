import { Bell, List } from "react-bootstrap-icons";
import { Row, Col } from "react-bootstrap";
import { Sidebar, MenuItem, Menu } from "react-pro-sidebar";
import { Navbar, Container, Image, Spinner, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dropdownIcon from "../../../../../assets/svgs/dropdownIcon.svg";
import userDisplayPhoto from "../../../../../assets/images/userDisplayPhoto.png";
import femaleAvatar from "../../../../../assets/svgs/femaleAvatar.svg";
import { logOut } from "../../../../../redux/features/auth/authSlice";
import { Offcanvas } from "react-bootstrap";
import logo from "../../../../../assets/svgs/logo.svg";
import menuIcon from "../../../../../assets/svgs/menuIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Profile from "./profile";
import {
  useGetInstitutionQuery,
  useGetDashboardReportQuery,
  useGetInstitutionLogoQuery,
  useGetSetupProgressQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { capitalizeWords } from "../../../../../utils/utils";
import Notifications from "./notifications.js";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import placeholderLogo from "../../../../../assets/svgs/institutionPlaceholderLogo.svg";
import { usePermittedLinks } from "../../../../../context/permittedNavLinks.js";

const DashboardTopSection = () => {
  const [dropdownProfile, setDropdownProfile] = useState(false);
  // console.log(details)

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const { permittedLinks } = usePermittedLinks();

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);

  const {
    data: institutionLogo,
    refetch,
    isLoading,
  } = useGetInstitutionLogoQuery(instId);

  const { data: setupProgress, isLoading: progressLoading } =
    useGetSetupProgressQuery(instId);

  // const { data: dashboardData, isLoading: dashboardDataLoading } =
  //   useGetDashboardReportQuery(instId);

  const date = new Date();
  const hour = date.getHours();

  // const dashboardRec = useSelector((state) => state?.institution?.dashboardData);

  const {
    data: dashboardRec,
    isLoading: dashboardDataLoading,
    refetch: refetchDashboardDetails,
  } = useGetDashboardReportQuery(instId);

  const [showProfileCanvas, setShowProfileCanvas] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const handleCloseCanvas = () => {
    setShowProfileCanvas(false);
  };
  const handleShowCanvas = () => {
    setDropdownProfile(false);
    setShowProfileCanvas(true);
  };

  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logOut());
  };

  const goToSetupPage = () => {
    navigate("/school-setup");
  };

  const location = useLocation();
  const navigate = useNavigate();

  const username = useSelector((state) => state?.auth?.userInfo?.username);
  const gender = useSelector((state) => state?.auth?.userInfo?.gender);

  const [showMenu, setShowMenu] = useState(false);

  const menuItem = {
    height: 2.7 + "rem",
    fontSize: 0.87 + "rem",
  };

  // useEffect(() => {
  //   const handleClose = (e) => {
  //     // close menu when the click outside
  //     if (
  //       showMenu &&
  //       !e.target.className.includes("mobileDashboardNavbar") &&
  //       !e.target.className.includes("menuIcon")
  //     ) {
  //       setShowMenu(false);
  //     }
  //   };

  //   window.addEventListener("click", handleClose);

  //   return () => {
  //     window.removeEventListener("click", handleClose);
  //   };
  // }, [showMenu]);

  return (
    <>
      <Row style={{ width: 100 + "%", position: "relative" }}>
        <Col>
          <img
            src={menuIcon}
            style={{ cursor: "pointer" }}
            alt="menu icon"
            onClick={() => setShowMenu(!showMenu)}
            className="menuIcon"
          />
          <div className="session-text-container-web">
            <h5 style={{ marginBottom: "3px" }}>
              <b>
                <span>
                  {dashboardRec &&
                    capitalizeWords(dashboardRec?.activeCalendar?.schoolYear)}
                </span>
              </b>
            </h5>
            {dashboardRec?.activeCalendar?.schoolYear && (
              <h6 style={{ color: "#b9b9b9" }}>Academic Session</h6>
            )}
          </div>
        </Col>

        <Col>
          <div className="profile-container">
            <div
              className="notificationbell"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              <Bell size="21" />
            </div>

            <div className="user-details">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {hour > 12 ? (
                  hour >= 16 ? (
                    <span style={{ flexShrink: "0" }}>Good Evening, </span>
                  ) : (
                    <span style={{ flexShrink: "0" }}>Good Afternoon, </span>
                  )
                ) : (
                  <span style={{ flexShrink: "0" }}>Good Morning, </span>
                )}
                <span>{username}</span>
                {/* <span>Hi, {username}</span> */}
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => setDropdownProfile(!dropdownProfile)}
                >
                  <img
                    src={gender === "male" ? userDisplayPhoto : femaleAvatar}
                    alt="user"
                    style={{ width: "100%" }}
                  />
                </div>

                <img
                  src={dropdownIcon}
                  alt="drop down"
                  onClick={() => setDropdownProfile(!dropdownProfile)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            {dropdownProfile && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  width: "130px",
                  zIndex: 10,
                  backgroundColor: "#fff2ee",
                  padding: "15px",
                }}
                className="profile-dropdown"
              >
                <span
                  onClick={handleShowCanvas}
                  style={{ cursor: "pointer", fontWeight: 500 }}
                >
                  Profile
                </span>
                <hr />
                <span
                  onClick={handleLogOut}
                  style={{ cursor: "pointer", fontWeight: 500 }}
                >
                  LogOut
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Offcanvas
        show={showProfileCanvas}
        onHide={handleCloseCanvas}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Profile</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <Profile institutionDetails={institutionDetails} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showNotifications}
        onHide={() => setShowNotifications(false)}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notification</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <Notifications
            institutionDetails={institutionDetails}
            setShowNotifications={setShowNotifications}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showMenu}
        onHide={() => setShowMenu(false)}
        placement={"start"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "250px" }}
      >
        <Offcanvas.Body
          style={{
            padding: "20px 0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Institution Details */}
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              paddingLeft: "15px",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                minWidth: "40px",
                height: "50px",
                borderRadius: "100%",
                overflow: "hidden",
              }}
            >
              {isLoading ? (
                <Spinner animation="border" variant="danger" color="#F14810" />
              ) : (
                <img
                  src={
                    institutionLogo ? institutionLogo?.logo : placeholderLogo
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              )}
            </div>

            <div style={{ marginTop: "10px" }}>
              <h4
                style={{
                  fontSize: "1rem",
                  marginBottom: "0px",
                }}
              >
                {capitalizeWords(institutionDetails?.institutionName)}
              </h4>
              <p
                style={{
                  fontSize: "0.85rem",
                  margin: "0px",
                }}
              >
                {institutionDetails?.address}
              </p>
            </div>
          </div>

          {/* Setup Progress */}
          {institutionDetails?.setupComplete === false && (
            <div
              className="school-setup"
              style={{
                backgroundColor: "#ffe6de",
                border: "1px solid #DBDBDB",
                borderRadius: "8px",
                margin: "1rem 10px",
                padding: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 6,
                }}
              >
                <img src={alertIcon} style={{ width: "12%" }} />
                <p
                  style={{
                    fontSize: "0.7rem",
                    marginTop: "10px",
                  }}
                >
                  Complete your school setup here to enable settlements
                </p>
              </div>

              <h6
                style={{
                  marginLeft: "20px",
                  marginTop: "-2px",
                }}
              >
                {setupProgress?.progress} Completion
              </h6>

              <Button
                variant="primary"
                style={{
                  marginLeft: "20px",
                  marginBottom: "10px",
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                }}
                className="button"
                size="sm"
                onClick={goToSetupPage}
              >
                Complete Setup
              </Button>
            </div>
          )}

          {/* Menu Items */}
          <Menu
            className="menu"
            style={{ marginBottom: "2rem", marginTop: "2rem" }}
            menuItemStyles={{
              button: ({ active }) => ({
                color: active ? "white" : "#464646",
                backgroundColor: active ? "#FD5821" : "transparent",
                "&:hover": {
                  backgroundColor: active ? "#FD5821" : "#F5F5F5",
                  color: active ? "white" : "#FFFFFF",
                },
              }),
            }}
          >
            {permittedLinks.map(({ label, path, Icon }) => (
              <Link key={path} to={path}>
                <MenuItem
                  style={{
                    height: "2.7rem",
                    fontSize: "0.87rem",
                  }}
                  active={location.pathname.startsWith(path) ? "active" : null}
                  icon={
                    <Icon
                      active={
                        location.pathname.startsWith(path) ? "active" : null
                      }
                      size={18}
                    />
                  }
                >
                  {label}
                </MenuItem>
              </Link>
            ))}
          </Menu>

          <div
            style={{
              width: "auto",
              height: "auto",
              marginTop: "auto",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              borderTop: "1px solid #DBDBDB",
            }}
          >
            <Image src={logo} fluid />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DashboardTopSection;
