import { useState, useEffect, useRef } from "react";
import "./userManagement.css";
// import Button from "../../../../../../component/button/button";
import {
  Table,
  Button,
  Form,
  InputGroup,
  Offcanvas,
  Row,
  Col,
} from "react-bootstrap";
import dropdownIcon from "../../../../assets/svgs/dropdownIcon.svg";

import { useSelector } from "react-redux";

import ModalComponent from "../../../../component/modal/modal";

import {
  useGetSuperUsersListQuery,
  useAddSuperUserMutation,
  useUpdateSuperUserMutation,
  useEnableSuperUserMutation,
  useDisableSuperUserMutation,
  useGetInstitutionListQuery,
  useGetRolesQuery,
  useGetAssignedInstitutionsListQuery,
} from "../../../../redux/features/superAdmin/adminApiSlice";

import ActionIcon from "../actionbutton/ActionIcon.js";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../component/successScreen/successScreen";
import DashboardSidebar from "../dashboardSideBar/sidebar";
import DashboardTopSection from "../dashboardTopSection";
import ToastComponent from "../../../../component/toast/toast";
import TableComponent from "../../../../component/table/table";
import institutionSlice from "../../../../redux/features/institution/institutionSlice.js";
import MultiSelectDropdown from "../../../../component/select/multiSelectDropdown.js";

const tableHeaders = [
  "ID",
  "First Name",
  "Last Name",
  "Email",
  "Phone Number",
  "Gender",
  "Action",
];

const UserManagement = ({ setActivePage }) => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [savedUsersList, setSavedUsersList] = useState([]);
  const [newData, setNewData] = useState(null);
  const [enabledElementID, setEnabledElementID] = useState(null);
  const [disabledElementID, setDisabledElementID] = useState(null);
  const [deletedElementID, setDeletedElementID] = useState(null);
  const [editedElement, setEditedElement] = useState(null);
  const [viewedElement, setViewedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [viewingValue, setViewingValue] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [showSchools, setShowSchools] = useState(false);
  const [selectedSchoolName, setSelectedSchoolName] = useState(null);
  const [selectedStudentOptions, setSelectedStudentOptions] = useState([]);
  const [selectedSchoolOptions, setSelectedSchoolOptions] = useState([]);
  const [accountObject, setAccountObject] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [newAllSchools, setNewAllSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);

  const [showViewAdminCanvas, setShowViewAdminCanvas] = useState(false);

  const handleCloseAdmin = () => {
    setShowViewAdminCanvas(false);
  };

  const {
    data: superUsersList,
    refetch: superUsersListRefetch,
    isLoading: superUsersLoading,
  } = useGetSuperUsersListQuery();

  console.log(superUsersList);

  const {
    data: institutionList,
    refetch: institutionListRefetch,
    isLoading: institutionListLoading,
  } = useGetInstitutionListQuery("APPROVED");

  // console.log("Schools", institutionList);

  const schoolOptions = institutionList?.map((item) => {
    return { id: item?.id, name: item?.institutionName };
  });

  const { data: roleList, isLoading: roleListLoading } = useGetRolesQuery();

  const columns = [
    // { name: "id", label: "ID" },
    {
      name: "name",
      label: "Full Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstName, lastName } = superUsersList[tableMeta.rowIndex];
          return `${firstName} ${lastName}`;
        },
      },
    },
    { name: "email", label: "Email" },
    { name: "phoneNumber", label: "Phone Number" },
    {
      name: "activated",
      label: "Active",
      options: {
        customBodyRender: (active) => (active ? "true" : "false"),
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <ActionIcon
            actionsArr={[
              {
                label: "View",
                action: () => {
                  handleViewSuperUser(superUsersList[tableMeta.rowIndex]);
                },
              },
              {
                label: "Edit",
                action: () =>
                  handleEditSuperUser(superUsersList[tableMeta.rowIndex]),
              },
              {
                label: "Enable",
                action: () =>
                  handleEnableSuperUser(superUsersList[tableMeta.rowIndex]),
              },
              {
                label: "Disable",
                action: () =>
                  handleDisableSuperUser(superUsersList[tableMeta.rowIndex]),
              },
            ]}
          />
        ),
      },
    },
  ];

  const [addSuperUser, { isLoading: addUserLoading }] =
    useAddSuperUserMutation();
  const [editSuperUser, { isLoading: editUserLoading }] =
    useUpdateSuperUserMutation();
  const [enableSuperUser, { isLoading: enableUserLoading }] =
    useEnableSuperUserMutation();
  const [disableSuperUser, { isLoading: disableUserLoading }] =
    useDisableSuperUserMutation();

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingElement(null);
    setEditingValue("");
  };

  const handleViewSuperUser = (el) => {
    console.log("view was clicked for ", el);
    setEditingElement(el);
    setShowViewAdminCanvas(true);
    setViewingValue({
      viewedId: el.id,
      viewedFirstName: el.firstName,
      viewedLastName: el.lastName,
      viewedEmail: el.email,
      viewedPhoneNumber: el.phoneNumber,
      viewedStatus: el.activated,
      viewedRole: el.role,
    });
  };

  const {
    viewedId,
    viewedFirstName,
    viewedLastName,
    viewedEmail,
    viewedRole,
    viewedPhoneNumber,
    viewedStatus,
  } = viewingValue;

  const {
    data: assignedInstitutionList,
    isLoading: assignedInstitutionLoading,
  } = useGetAssignedInstitutionsListQuery(viewedId);

  const handleEditSuperUser = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowModal(true);
    setEditingValue({
      editedFirstName: el.firstName,
      editedLastName: el.lastName,
      editedEmail: el.email,
      editedPhoneNumber: el.phoneNumber,
      editedroleId: el.roleId,
    });
  };

  const {
    editedFirstName,
    editedLastName,
    // editedFullName,
    editedEmail,
    // editedPassword,
    editedPhoneNumber,
    editedroleId,
    // editedGender,
  } = editingValue;

  const handleEnableSuperUser = async (el) => {
    console.log("enable was toggled for ", el);
    try {
      // const parameters = {
      //   id: el?.id,
      // };
      await enableSuperUser(el?.id).unwrap();
      setEnabledElementID(el?.id);
      setShowToast(true);
      setToastMessage("User enabled successfully!");
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleDisableSuperUser = async (el) => {
    console.log("disable was toggled for ", el);
    try {
      // const parameters = {
      //   id: el?.id,
      // };
      await disableSuperUser(el?.id).unwrap();
      setDisabledElementID(el?.id);
      setShowToast(true);
      setToastMessage("User disabled successfully!");
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const [newSuperUserData, setNewSuperUserData] = useState({
    firstName: "",
    lastName: "",
    roleId: "",
    email: "",
    phoneNumber: "",
    // password: "",
    // gender: "",
  });

  const { firstName, lastName, email, phoneNumber, roleId } = newSuperUserData;

  // const { fullName, email, phoneNumber, password, gender } = newSuperUserData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewSuperUserData({ ...newSuperUserData, [name]: value });
    setEditingValue({ ...editingValue, [name]: value });

    if (name === "searchText") {
      setSearchText(value);
    }
  };

  const handleSchoolOptionsChange = (selected) => {
    setSelectedSchoolOptions(selected);
    console.log(selected, 77777);
    const institutionIds = selected?.map((item) => {
      return item;
    });
    newSuperUserData.selectedInstitutionId = institutionIds;
    console.log(newSuperUserData, 66666);
  };

  const handleCreateOrUpdateSuperUser = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      if (editingValue === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }

      const parameters = {
        userId: editingElement?.id,
        body: {
          id: editingElement?.id,
          firstName: editedFirstName,
          lastName: editedLastName,
          // fullName: editingValue,
          email: editedEmail,
          phoneNumber: editedPhoneNumber,
          // password: editingValue,
          // gender: editingValue,
          roleId: editedroleId,
        },
      };
      try {
        const res = await editSuperUser(parameters);
        console.log(res);
        if (res) {
          setNewSuperUserData({
            firstName: "",
            lastName: "",
            // fullName: "",
            email: "",
            phoneNumber: "",
            // password: "",
            // gender: "",
            roleId: "",
          });
          setEditedElement(res);
          setEditingValue("");
          setShowModal(false);
        }
        setEditingElement(null);
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      if (
        (firstName === "", lastName === "", email === "", phoneNumber === "")
      ) {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      try {
        console.log(newSuperUserData);
        const obj = {
          firstName: newSuperUserData?.firstName,
          lastName: newSuperUserData?.lastName,
          email: newSuperUserData?.email,
          phoneNumber: newSuperUserData?.phoneNumber,
          roleId: newSuperUserData?.roleId,
          // selectedInstitutionId: newSuperUserData?.selectedInstitutionId,
        };

        const data = await addSuperUser(obj).unwrap();

        if (data) {
          setNewData(data);
        }
        setNewSuperUserData({
          firstName: "",
          lastName: "",
          // fullName: "",
          email: "",
          phoneNumber: "",
          // password: "",
          // gender: "",
          roleId: "",
        });
        setShowModal(false);
      } catch (error) {
        console.log(error.data);
        // setErrorMessage(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  useEffect(() => {
    if (superUsersList) {
      superUsersListRefetch();
    }
  }, [newSuperUserData, editedElement]);

  useEffect(() => {
    if (institutionList) {
      institutionListRefetch();
    }
  }, [showModal]);

  useEffect(() => {
    setNewAllSchools(institutionList || []);
  }, [institutionList]);

  useEffect(() => {
    const filterInstitutions = institutionList?.filter((school) =>
      school.institutionName.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredSchools(filterInstitutions);
  }, [searchText]);

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSchools(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (
    superUsersLoading ||
    addUserLoading ||
    editUserLoading ||
    enableUserLoading ||
    disableUserLoading ||
    institutionListLoading ||
    assignedInstitutionLoading
  ) {
    return <OnboardingLoader />;
  }

  // if (isSuccess) {
  // return (
  //   <SuccessScreen successMessage="User created successfully!" interval={4000} />
  // )
  // }

  return (
    <div className="superUser">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        <ToastComponent
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        <ModalComponent
          title={editingElement ? "Edit Admin User" : "Create Admin User"}
          showModal={showModal}
          closeModal={handleCloseModal}
        >
          {isFormNotFilled && (
            <span
              style={{
                color: "red",
                fontStyle: "italic",
                transition: "ease-in-out",
              }}
            >
              Please fill all fields
            </span>
          )}

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                First Name{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                name={editingElement ? "editedFirstName" : "firstName"}
                value={editingElement ? editedFirstName : firstName}
                onChange={handleOnChange}
                placeholder="Enter Full Name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Last Name{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                name={editingElement ? "editedLastName" : "lastName"}
                value={editingElement ? editedLastName : lastName}
                onChange={handleOnChange}
                placeholder="Enter Full Name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Email{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="email"
                name={editingElement ? "editedEmail" : "email"}
                value={editingElement ? editedEmail : email}
                onChange={handleOnChange}
                placeholder="Enter Email"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Phone Number{" "}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type="text"
                name={editingElement ? "editedPhoneNumber" : "phoneNumber"}
                value={editingElement ? editedPhoneNumber : phoneNumber}
                onChange={handleOnChange}
                placeholder="Enter Phone Number"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">Role</Form.Label>

              <Form.Select
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                name={editingElement ? "editedroleId" : "roleId"}
                value={editingElement ? editedroleId : roleId}
                onChange={handleOnChange}
                required
              >
                <option value="" disabled selected>
                  Select Role
                </option>
                {roleList &&
                  roleList?.map(({ name, id }) => (
                    <option
                      key={id}
                      value={id}
                      selected={editingElement?.role?.id === id}
                    >
                      {name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="formClasslabel">
                Assign Institution
              </Form.Label>

              <MultiSelectDropdown
                selectedItems={selectedSchoolOptions}
                options={schoolOptions}
                onSelect={handleSchoolOptionsChange}
              />
            </Form.Group> */}

            <div style={{ marginTop: "30px" }}>
              {editingElement ? (
                <Button
                  onClick={(e) => handleCreateOrUpdateSuperUser(e, "edit")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Update User
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={(e) => handleCreateOrUpdateSuperUser(e, "")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Save User
                  </span>
                </Button>
              )}
            </div>
          </Form>
        </ModalComponent>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "40px 0 20px",
          }}
        >
          <h6 style={{ fontSize: "18px" }}>User Management</h6>
          <Button
            className="button"
            size="md"
            style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
            onClick={() => setShowModal(true)}
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                // marginLeft: 0.3 + "rem",
                // marginRight: 0.3 + "rem",
              }}
            >
              Create New Admin
            </span>
          </Button>
        </div>

        <TableComponent
          data={superUsersList}
          title=""
          columns={columns}
          hasAuthority={userAuthorizedPages?.find(
            (page) => page?.action === "ADMIN_VIEW_USER"
          )}
        />

        <Offcanvas
          show={showViewAdminCanvas}
          onHide={handleCloseAdmin}
          placement={"end"}
          backdrop={true}
          style={{ width: "100%", maxWidth: "500px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Admin User Details</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ paddingTop: "0px" }}>
            <div style={{ display: "flex", gap: 0.8 + "rem" }}>
              <div
                style={{
                  border: "2px solid #ffe6de",
                  borderRadius: "50px",
                  backgroundColor: "#ffe6de",
                  padding: "4px 8px",
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    color: "#F14810",
                  }}
                >
                  {viewedStatus ? "Active" : "Not Active"}
                </span>
              </div>
            </div>

            <div
              style={{
                border: "2px solid #F5F5F5",
                borderRadius: "8px",
                padding: "15px",
                height: "auto",
                marginTop: 1 + "rem",
              }}
            >
              <div>
                <div style={{ display: "flex", gap: 0.8 + "rem" }}>
                  <div>
                    <h6>Personal Information</h6>
                  </div>
                </div>
              </div>

              <div style={{ padding: "10px" }}>
                <p style={{ fontWeight: "600" }}>
                  Full Name:{" "}
                  <span style={{ fontWeight: "400" }}>
                    {viewedFirstName} {viewedLastName}
                  </span>
                </p>
                <p style={{ fontWeight: "600" }}>
                  Email:{" "}
                  <span style={{ fontWeight: "400" }}>{viewedEmail}</span>
                </p>
                <p style={{ fontWeight: "600" }}>
                  Phone Number:{" "}
                  <span style={{ fontWeight: "400" }}>{viewedPhoneNumber}</span>
                </p>
              </div>
            </div>

            <div
              style={{
                border: "2px solid #F5F5F5",
                borderRadius: "8px",
                padding: "15px",
                height: "auto",
                marginTop: 1 + "rem",
                marginBottom: 1 + "rem",
              }}
            >
              <div
                style={{
                  marginTop: 1 + "rem",
                }}
              >
                <div>
                  <h6>Role and Assigned Institutions</h6>
                </div>

                <p style={{ fontWeight: "600" }}>
                  Role:{" "}
                  <span style={{ fontWeight: "400" }}>{viewedRole?.name}</span>
                </p>

                <p style={{ fontWeight: "600" }}>
                  Assigned Schools:{" "}
                  <span style={{ fontWeight: "400" }}>
                    {assignedInstitutionList?.map((institution, i, arr) => (
                      <span>
                        {institution.institutionName}{" "}
                        {i != arr.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </span>
                </p>
              </div>
            </div>

            {/* <div style={{ display: "flex", gap: 1.1 + "rem", marginTop: "30px" }}>
              <Button
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                onClick={() => handleEditSuperUser(viewedId)}
                >
                  Edit
                </span>
              </Button>

              <Button
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                onClick={() => handleDeactivateUser(viewedId)}
                >
                  Deactivate
                </span>
              </Button>
            </div> */}
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <br />
      <br />
    </div>
  );
};

export default UserManagement;
