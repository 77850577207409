import React, { useEffect, useState } from "react";
import { Button, Col, Row, Offcanvas } from "react-bootstrap";
import CreateRoleCanvas from "./createRoleCanvas";
import { useGetRolesQuery } from "../../../../../../redux/features/superAdmin/adminApiSlice";
import { useSelector } from "react-redux";
import ToastComponent from "../../../../../../component/toast/toast";

const RolesPage = () => {
  const [roleToEdit, setRoleToEdit] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const {
    data: allRoles,
    isLoading: allRolesLoading,
    refetch: refetchRoles,
  } = useGetRolesQuery();

  console.log(allRoles);

  const handleEditRole = (role) => {
    setRoleToEdit(role);
    setShowCanvas(true);
  };

  useEffect(() => {
    if (allRoles) {
      refetchRoles();
    }
  }, [showCanvas]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ fontSize: "16px", fontWeight: 600, marginBottom: "0px" }}>
          Roles and Permission
        </h6>
        <Button
          className="button"
          size="md"
          style={{
            backgroundColor: "#F14810",
            color: "#ffffff",
            border: "none",
            outline: "none",
            fontSize: "14px",
          }}
          onClick={() => setShowCanvas(true)}
        >
          Create New Role
        </Button>
      </div>

      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div
        style={{
          border: "1px solid #E7E1E1",
          borderRadius: "8px",
          padding: "15px",
          minHeight: "95%",
        }}
      >
        <Row>
          <Col xs={12} md={12} lg={12} className="otherbox">
            <Row>
              {allRoles?.map((role, i) => (
                <Col xs={4} md={4} lg={4} id="branchsidecard-x1" key={role?.id}>
                  <div
                    className="branchsidecard-x1"
                    style={{
                      cursor: "pointer",
                      height: 6 + "rem",
                      width: "100%",
                    }}
                    onClick={() => handleEditRole(role)}
                  >
                    <div className="card-inner-value">
                      <h6 style={{ marginTop: 2 + "rem" }}>
                        <b>{role?.name}</b>
                      </h6>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>

      <Offcanvas
        show={showCanvas}
        onHide={() => {
          setShowCanvas(false);
          setRoleToEdit(null);
        }}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {roleToEdit ? "Edit Role" : "Create New Role"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <CreateRoleCanvas
            roleToEdit={roleToEdit}
            setShowCanvas={setShowCanvas}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default RolesPage;
