import backArrow from "../../../../../../assets/svgs/backArrow.svg";
import ButtonX from "../../../../../../component/button/button";
import { Table, Offcanvas, Form, Button } from "react-bootstrap";

import { useEffect, useState } from "react";
import ActionIcon from "../../../../../../component/actionbutton/ActionIcon";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import actionIcon from "../../../../../../assets/svgs/actionIcon.svg";
import alertIcon from "../../../../../../assets/svgs/alertIcon.svg";
import {
  useApproveStudentRegisterMutation,
  useDeleteStudentInRegisterMutation,
  useGetStudentsRegisterQuery,
  useGetAllParentsQuery,
} from "../../../../../../redux/features/institution/studentApiSlice";
import {
  storeStudentInfo,
  storeParentInfo,
} from "../../../../../../redux/features/institution/studentSlice";
import successGIF from "../../../../../../assets/svgs/successGIF.gif";
import ModalComponent from "../../../../../../component/modal/modal";
import TableComponent from "../../../../../../component/table/table";
import { useSelector, useDispatch } from "react-redux";
import StudentInformationStepper from "../studentInformation/studentInformationStepper";
import ToastComponent from "../../../../../../component/toast/toast";
import { useNavigate } from "react-router-dom";
import UploadStudentsPage from "../studentInformation/components/uploadStudents/uploadStudentsPage";

const ViewedStudentsBatch = ({ batchDetails, setIsBatchViewed }) => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [newBatchDetails, setNewBatchDetails] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [idOfStudentToDelete, setIdOfStudentToDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [showCreateStudentCanvas, setShowCreateStudentCanvas] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [askCreateStudentMethod, setAskCreateStudentMethod] = useState(false);
  const [student, setStudent] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showConfirmApprove, setShowConfirmApprove] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseStudent = () => {
    setShowCreateStudentCanvas(false);
    setStudent(null);
    dispatch(storeParentInfo(null));
    dispatch(storeStudentInfo(null));
  };

  const handleShowStudent = () => {
    setStudent(null);
    setShowCreateStudentCanvas(true);
    setShowUploadModal(false);
    setAskCreateStudentMethod(false);
  };

  console.log(student);

  const handleShowUpload = () => {
    setShowUploadModal(true);
    setShowCreateStudentCanvas(false);
    setAskCreateStudentMethod(false);
  };

  const handleCloseUpload = () => {
    setShowUploadModal(false);
  };

  const handleClose = () => {
    setShow(false);
    setIsBatchViewed(false);
    navigate("/student");
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const {
    data: studentRegister,
    refetch: refetchStudents,
    isLoading: registersLoading,
  } = useGetStudentsRegisterQuery(instId);

  const { data: parents, refetch: refetchParents } =
    useGetAllParentsQuery(instId);

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstname, lastname } = batchDetails[tableMeta.rowIndex];
          return `${firstname} ${lastname}`;
        },
      },
    },
    { name: "gender", label: "Gender" },
    { name: "studentNo", label: "Student No" },
    {
      name: "institutionCategory",
      label: "Class",
      options: {
        customBodyRender: (category) =>
          category
            ? category.institutionLevel.name + " - " + category.name
            : "N/A",
      },
    },
    {
      name: "parentName",
      label: "Parent Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { newParent, existingParent } =
            batchDetails[tableMeta.rowIndex];
          return `${newParent ? newParent?.name : existingParent?.name}`;
        },
      },
    },
    {
      name: "parentPhoneNo",
      label: "Parent Phone",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { newParent, existingParent } =
            batchDetails[tableMeta.rowIndex];
          return `${
            newParent ? newParent?.phoneNumber : existingParent?.phoneNumber
          }`;
        },
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <ActionIcon
            actionsArr={[
              {
                label: "Edit",
                action: () => {
                  handleEditStudentDetail(batchDetails[tableMeta.rowIndex]);
                },
              },
              {
                label: "Delete",
                action: () => {
                  setIdOfStudentToDelete(batchDetails[tableMeta.rowIndex]?.id);
                  setShowConfirmDelete(true);
                },
              },
            ]}
          />
        ),
      },
    },
  ];

  const handleEditStudentDetail = (student) => {
    console.log(student);
    setStudent(null);
    //alert(student.firstname);
    setStudent(student);
    setShowCreateStudentCanvas(true);
  };

  const [
    approveStudentRegister,
    { isLoading: approveLoading, isSuccess: approveSuccess },
  ] = useApproveStudentRegisterMutation();

  const [
    deleteStudentInRegister,
    { isLoading: deleteLoading, isSuccess: deleteSuccess },
  ] = useDeleteStudentInRegisterMutation();

  const handleDeleteStudent = async () => {
    setShowConfirmDelete(false);
    try {
      const res = await deleteStudentInRegister(idOfStudentToDelete).unwrap();
      setShowDeleteSuccess(true);
      refetchStudents();
      // console.log(res);
    } catch (error) {
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const onApprove = async () => {
    try {
      const res = await approveStudentRegister(instId).unwrap();
      console.log(res);
      setShow(true);
      if (res) {
        refetchParents();
        setShowConfirmApprove(false);
      }
    } catch (error) {
      // alert(error.data.detail);
      setShowConfirmApprove(false);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  // useEffect(() => {
  //   if (registerDetail) {
  //     refetch();
  //     const { students } = registerDetail;
  //     setStudents(students);
  //   }
  // }, [registerDetail, newBatchDetails, studentRegister, showCreateStudentCanvas]);

  if (approveLoading || deleteLoading || registersLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this student?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              style={{
                backgroundColor: "#fff",
                borderColor: "#F14810",
                color: "#F14810",
              }}
            >
              No
            </Button>

            <Button
              onClick={() => handleDeleteStudent()}
              style={{
                backgroundColor: "#f14810",
                borderColor: "#F14810",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalComponent>

      {/* success modal */}
      <ModalComponent showModal={show} closeModal={handleClose}>
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            Student List has been verified. Account information will be sent to
            your email
          </p>
        </div>
      </ModalComponent>

      <ModalComponent
        showModal={showDeleteSuccess}
        closeModal={() => setShowDeleteSuccess(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            Student has been deleted successfully
          </p>
        </div>
      </ModalComponent>

      {/* prompt for create student */}
      <ModalComponent
        showModal={askCreateStudentMethod}
        closeModal={() => setAskCreateStudentMethod(false)}
      >
        <div style={{ padding: "15px" }}>
          <h5
            style={{
              textAlign: "center",
              color: "#626262",
              fontSize: "25px",
            }}
          >
            Create Students
          </h5>
          <p style={{ textAlign: "center" }}>
            Creating students can be done in 2 ways; You can either click on
            "Upload Students" button to add existing students record to the
            platform OR click on "Create Students" button to add a new student
            record.{" "}
          </p>

          {userAuthorizedPages?.find(
            (page) => page?.action === "CREATE_STUDENT"
          ) && (
            <div
              style={{ display: "flex", gap: "10px", justifyContent: "center" }}
            >
              <ButtonX
                backgroundColor="#f14810"
                border="#f14810"
                color="#fff"
                onClick={handleShowUpload}
              >
                Upload Students
              </ButtonX>

              <ButtonX
                backgroundColor="#fff"
                border="#f14810"
                color="#f14810"
                onClick={handleShowStudent}
              >
                Create Students
              </ButtonX>
            </div>
          )}
        </div>
      </ModalComponent>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          margin: "10px 0 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
          onClick={() => navigate("/student")}
        >
          <img src={backArrow} alt="Go back" />
          <h6 style={{ marginBottom: 0 }}>Pending List</h6>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          {userAuthorizedPages?.find(
            (page) => page?.action === "APPROVE_STUDENT"
          ) && (
            <ButtonX
              backgroundColor="#f14810"
              border="#f14810"
              color="#fff"
              onClick={() => setShowConfirmApprove(true)}
            >
              Approve List
            </ButtonX>
          )}

          {userAuthorizedPages?.find(
            (page) => page?.action === "CREATE_STUDENT"
          ) && (
            <ButtonX
              backgroundColor="#fff"
              border="#f14810"
              color="#f14810"
              onClick={() => setAskCreateStudentMethod(true)}
            >
              Add Students
            </ButtonX>
          )}
        </div>
      </div>

      <TableComponent
        data={batchDetails}
        title=""
        columns={columns}
        filter={true}
        search={true}
        hasAuthority={userAuthorizedPages?.find(
          (page) => page?.action === "VIEW_STUDENT"
        )}
      />

      <br />
      <br />

      {/* Register Student Canvas */}
      <Offcanvas
        show={showCreateStudentCanvas}
        onHide={handleCloseStudent}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {student ? "Edit Student Details" : "Create Student"}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          {/* <StudentInformationPage /> */}
          <StudentInformationStepper
            student={student}
            closeCanvas={handleCloseStudent}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Upload Canvas */}
      <Offcanvas
        show={showUploadModal}
        onHide={handleCloseUpload}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Upload Students</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <UploadStudentsPage
            showUploadModal={showUploadModal}
            setShowUploadModal={setShowUploadModal}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {/*confirm approve student  */}
      <ModalComponent
        showModal={showConfirmApprove}
        closeModal={() => {
          setShowConfirmApprove(false);
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "15px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to approve this students list?{" "}
          </p>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => setShowConfirmApprove(false)}
              type="button"
              variant="outline-primary"
              className="button"
              size="md"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                borderColor: "#F14810",
                color: "#F14810",
              }}
            >
              No
            </Button>

            <Button
              onClick={onApprove}
              type="button"
              variant="primary"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                color: "white",
              }}
              className="button"
              size="md"
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ViewedStudentsBatch;
