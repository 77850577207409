import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import RegistrationLayout from "../../../component/layout/registrationLayout";
import showPasswordIcon from "../../../assets/svgs/showPassword.svg";
import hidePasswordIcon from "../../../assets/svgs/hidePassword.svg";
import "../../../styles/formpage.css";
import { useLoginMutation } from "../../../redux/features/auth/authApiSlice";
import OnboardingLoader from "../../../component/onboardingLoader/onboardingLoader";
import { useDispatch } from "react-redux";
import { userInfo } from "../../../redux/features/auth/authSlice";
import ToastComponent from "../../../component/toast/toast";
import { usePermittedLinks } from "../../../context/permittedNavLinks";
import { usePermittedAdminLinks } from "../../../context/permittedAdminLinks";

function SignInPage() {
  const dispatch = useDispatch();
  const { permittedLinks } = usePermittedLinks();
  const { permittedLinks: adminPermittedLinks } = usePermittedAdminLinks();

  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [userDetails, setUserDetails] = useState(null);

  const { email, password } = formData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [login, { isLoading }] = useLoginMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const navigate = useNavigate();

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      const userData = await login({
        email,
        password,
      }).unwrap();

      if (userData) {
        setToastMessage("Login successful!");
        setUserDetails(userData);
        setShowToast(true);
        dispatch(userInfo(userData));
        setShouldNavigate(true);
      }

      if (userData?.userType === "SUPER_USER") {
        // navigate("/principal");
      } else {
        // setShouldNavigate(true);
        // navigate("/dashboard");
      }
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  // Effect to handle navigation when permittedLinks are available and navigation should happen
  useEffect(() => {
    if (shouldNavigate) {
      // if (permittedLinks.length > 0) {
      //   navigate(permittedLinks[0]?.path);
      // } else {
      //   navigate("/settings");
      // }

      if (userDetails?.userType === "SUPER_USER") {
        adminPermittedLinks?.length > 0
          ? navigate(adminPermittedLinks[0]?.path)
          : navigate("/settings");
      } else {
        permittedLinks?.length > 0
          ? navigate(permittedLinks[0]?.path)
          : navigate("/settings");
      }
    }
  }, [shouldNavigate, permittedLinks, navigate]); // Trigger when either `shouldNavigate` or `permittedLinks` changes

  if (isLoading) {
    return <OnboardingLoader />;
  }

  // console.log(shouldNavigate);
  // console.log(permittedLinks);

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <RegistrationLayout>
        <br />

        <br />

        <div>
          <h3 className="FormHeader" style={{ marginTop: "15px" }}>
            Login to Your Institution
          </h3>
          <p className="FormParagraph">Fill in your login credentials</p>
        </div>

        <br />

        <Form className="form" onSubmit={handleOnSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Email Address <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="email"
              name="email"
              value={email}
              onChange={handleOnChange}
              placeholder="Enter Your Email Address"
              required
            />
          </Form.Group>

          <br />

          <Form.Group className="">
            <Form.Label className="formClasslabel">
              Password <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <div style={{ position: "relative" }} className="password-box">
              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                }}
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                autoComplete="off"
                onChange={handleOnChange}
                placeholder="Enter Your Password"
              />
              {showPassword ? (
                <img
                  src={hidePasswordIcon}
                  alt="showPassord Icon"
                  onClick={() => setShowPassword(false)}
                  className="hidePasswordIcon"
                />
              ) : (
                <img
                  src={showPasswordIcon}
                  alt="showPassord Icon"
                  onClick={() => setShowPassword(true)}
                  className="showPasswordIcon"
                />
              )}
            </div>
          </Form.Group>

          <div>
            <a
              href="/forgot-password"
              className="btn btn-link"
              role="button"
              data-bs-toggle="button"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                // marginTop: 0.85 + "rem",
                marginBottom: 1.15 + "rem",
                paddingLeft: 0.3 + "rem",
                paddingRight: 1.2 + "rem",
              }}
            >
              <span style={{ color: "#3174fd" }}>Forgot Password?</span>
            </a>
          </div>

          {/* <br /> */}

          <Button
            variant="primary"
            type="submit"
            style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
            className="button"
            size="md"
          >
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.5 + "rem",
                marginRight: 0.5 + "rem",
              }}
            >
              Login
            </span>
          </Button>
        </Form>

        <div>
          <p
            style={{
              fontFamily: "jost, sans-serif",
              fontSize: 0.85 + "rem",
              marginTop: 1 + "rem",
              marginRight: -2 + "rem",
            }}
          >
            Don't have an account?
            <a
              href="/register"
              className="btn btn-link"
              role="button"
              data-bs-toggle="button"
              style={{
                fontFamily: "jost, sans-serif",
                fontSize: 0.85 + "rem",
                marginTop: 0.85 + "rem",
                marginBottom: 1.15 + "rem",
                paddingLeft: 0.3 + "rem",
                paddingRight: 1.2 + "rem",
              }}
            >
              <span style={{ color: "#3174fd" }}>Register Now</span>
            </a>
          </p>
        </div>
      </RegistrationLayout>
    </>
  );
}

export default SignInPage;
