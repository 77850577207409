import { apiSlice } from "../../api/apiSlice";

export const adminSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardDetails: builder.query({
      providesTags: ["getDashboardDetails"],
      invalidatesTags: ["getDashboardDetails"],
      query: () => ({
        url: `/admin/dashboard_reports`,
        method: "GET",
      }),
    }),
    getAllInstitutionList: builder.query({
      providesTags: ["allInstitutionList"],
      invalidatesTags: ["allInstitutionList"],
      query: () => ({
        url: `/admin/institutions`,
        method: "GET",
      }),
    }),
    getInstitutionList: builder.query({
      providesTags: ["institutionList"],
      invalidatesTags: ["institutionList"],
      query: (status) => ({
        url: `/admin/institutions?status=${status}`,
        method: "GET",
      }),
    }),
    getInstitutionDetail: builder.query({
      providesTags: ["institutionDetail"],
      invalidatesTags: ["institutionDetail"],
      query: (institutionId) => ({
        // url: `/api/getinstitution?institutionId=${institutionId}`,
        url: `/admin/institutions/${institutionId}`,
        method: "GET",
      }),
    }),
    downloadLogo: builder.query({
      providesTags: ["logoDownload"],
      invalidatesTags: ["logoDownload"],
      query: (institutionId) => ({
        url: `/admin/institution/${institutionId}/download_logo`,
        method: "GET",
      }),
    }),
    addDocument: builder.mutation({
      providesTags: ["addDocument"],
      invalidatesTags: ["addDocument"],
      query: ({ institutionId, body }) => ({
        url: `/admin/institutions/${institutionId}/documents`,
        method: "POST",
        body,
      }),
    }),
    updateUploadDocument: builder.mutation({
      providesTags: ["updateUploadDocument"],
      invalidatesTags: ["updateUploadDocument"],
      query: ({ institutionId, documentId, body }) => ({
        url: `/admin/institutions/${institutionId}/documents/${documentId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteAdminDocument: builder.mutation({
      providesTags: ["deleteAdminDocument"],
      invalidatesTags: ["deleteAdminDocument"],
      query: ({ institutionId, documentId, body }) => ({
        url: `/admin/institutions/${institutionId}/documents/${documentId}`,
        method: "DELETE",
        body,
      }),
    }),
    downloadDocument: builder.query({
      providesTags: ["documentDownload"],
      invalidatesTags: ["documentDownload"],
      query: ({ institutionId, documentId }) => ({
        url: `/admin/institutions/${institutionId}/documents/${documentId}/download`,
        method: "GET",
      }),
    }),
    getAllDocumentCategories: builder.query({
      providesTags: ["getAllDocumentCategories"],
      invalidatesTags: ["getAllDocumentCategories"],
      query: (institutionId) => ({
        url: `admin/document_categories?institutionId=${institutionId}`,
        method: "GET",
      }),
    }),
    getAdminUploadedDocuments: builder.query({
      providesTags: ["getAdminUploadedDocuments"],
      invalidatesTags: ["getAdminUploadedDocuments"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/documents`,
        method: "GET",
      }),
    }),
    getInstitutionType: builder.query({
      providesTags: ["institutionType"],
      invalidatesTags: ["institutionType"],
      query: () => ({
        url: "/anon/institution_types",
        method: "GET",
      }),
    }),
    editInstitution: builder.mutation({
      providesTags: ["editInstitution"],
      invalidatesTags: ["editInstitution"],
      query: ({ institutionId, body }) => ({
        url: `/admin/institution_update/${institutionId}`,
        method: "POST",
        body,
      }),
    }),
    verifyInstitution: builder.mutation({
      providesTags: ["verifyInstitution"],
      invalidatesTags: ["verifyInstitution"],
      query: ({ id, body }) => ({
        url: `/admin/institution/verify/${id}`,
        method: "POST",
        body,
      }),
    }),
    addInstitutionFeedback: builder.mutation({
      providesTags: ["addInstitutionFeedback"],
      invalidatesTags: ["addInstitutionFeedback"],
      query: (body) => ({
        url: `/admin/${body.institutionId}/feedback`,
        method: "POST",
        body: body.body,
      }),
    }),
    enableInstitution: builder.mutation({
      providesTags: ["enableInstitution"],
      invalidatesTags: ["enableInstitution"],
      query: (institutionId) => ({
        url: `/admin/institution/enable/${institutionId}`,
        method: "POST",
      }),
    }),
    disableInstitution: builder.mutation({
      providesTags: ["disableInstitution"],
      invalidatesTags: ["disableInstitution"],
      query: (institutionId) => ({
        url: `/admin/institution/disable/${institutionId}`,
        method: "POST",
      }),
    }),
    getSuperUsersList: builder.query({
      providesTags: ["superUsersList"],
      invalidatesTags: ["superUsersList"],
      query: () => ({
        // url: `/api/getinstitution?institutionId=${institutionId}`,
        url: `/admin/users`,
        method: "GET",
      }),
    }),
    getAssignedInstitutionsList: builder.query({
      providesTags: ["getAssignedInstitutionsList"],
      invalidatesTags: ["getAssignedInstitutionsList"],
      query: (userId) => ({
        url: `/admin/user/${userId}/assigned_institutions`,
        method: "GET",
      }),
    }),
    addSuperUser: builder.mutation({
      providesTags: ["addSuperUser"],
      invalidatesTags: ["addSuperUser"],
      query: (body) => ({
        url: "/admin/user",
        method: "POST",
        body,
      }),
    }),
    updateSuperUser: builder.mutation({
      providesTags: ["updateSuperUser"],
      invalidatesTags: ["updateSuperUser"],
      query: ({ userId, body }) => ({
        // query: (body) => ({
        url: `/admin/user/${userId}`,
        // url: `/admin/user`,
        method: "PUT",
        body,
      }),
    }),
    enableSuperUser: builder.mutation({
      providesTags: ["enableSuperUser"],
      invalidatesTags: ["enableSuperUser"],
      query: (id) => ({
        url: `/admin/user/enable/${id}`,
        method: "POST",
      }),
    }),
    disableSuperUser: builder.mutation({
      providesTags: ["disableSuperUser"],
      invalidatesTags: ["disableSuperUser"],
      query: (id) => ({
        url: `/admin/user/disable/${id}`,
        method: "POST",
      }),
    }),
    getSuperReportSummary: builder.query({
      providesTags: ["getSuperReportSummary"],
      invalidatesTags: ["getSuperReportSummary"],
      query: (page) => ({
        url: `/admin/reports?page=${page}`,
        // url: `/admin/reports`,
        method: "GET",
      }),
    }),
    getSuperPaymentHistory: builder.query({
      providesTags: ["getSuperPaymentHistory"],
      invalidatesTags: ["getSuperPaymentHistory"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/payment_history`,
        method: "GET",
      }),
    }),
    getSuperWalletTransactions: builder.query({
      providesTags: ["getSuperWalletTransactions"],
      invalidatesTags: ["getSuperWalletTransactions"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/wallet_payments`,
        method: "GET",
      }),
    }),
    getSuperSettlementHistory: builder.query({
      providesTags: ["getSuperSettlementHistory"],
      invalidatesTags: ["getSuperSettlementHistory"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/settlements`,
        method: "GET",
      }),
    }),
    getInstitutionSettlementList: builder.query({
      providesTags: ["getInstitutionSettlementList"],
      invalidatesTags: ["getInstitutionSettlementList"],
      query: () => ({
        url: `/admin/settlement_details_list`,
        method: "GET",
      }),
    }),
    getInstitutionSettlementDetails: builder.query({
      providesTags: ["getInstitutionSettlementDetails"],
      invalidatesTags: ["getInstitutionSettlementDetails"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/settlement_details`,
        method: "GET",
      }),
    }),
    getSuperBalanceInquiry: builder.query({
      providesTags: ["getSuperBalanceInquiry"],
      invalidatesTags: ["getSuperBalanceInquiry"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/balance_inquiry`,
        method: "GET",
      }),
    }),
    getGlobalCommission: builder.query({
      providesTags: ["getGlobalCommission"],
      invalidatesTags: ["getGlobalCommission"],
      query: () => ({
        url: "/admin/commissions/global",
        method: "GET",
      }),
    }),
    getCommission: builder.query({
      providesTags: ["getCommission"],
      invalidatesTags: ["getCommission"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/commissions`,
        method: "GET",
      }),
    }),
    setGlobalCommission: builder.mutation({
      providesTags: ["setGlobalCommission"],
      invalidatesTags: ["setGlobalCommission"],
      query: (body) => ({
        url: "/admin/commissions/global",
        method: "POST",
        body,
      }),
    }),
    applyCommission: builder.mutation({
      providesTags: ["applyCommission"],
      invalidatesTags: ["applyCommission"],
      query: (body) => ({
        url: "/admin/commissions",
        method: "POST",
        body,
      }),
    }),
    settleInstitution: builder.mutation({
      providesTags: ["settleInstitution"],
      invalidatesTags: ["settleInstitution"],
      query: (body) => ({
        url: "/admin/settleinstitution",
        method: "POST",
        body,
      }),
    }),
    setInterval: builder.mutation({
      providesTags: ["setInterval"],
      invalidatesTags: ["setInterval"],
      query: (body) => ({
        url: "/admin/settlement_interval",
        method: "POST",
        body,
      }),
    }),
    getInterval: builder.query({
      providesTags: ["getInterval"],
      invalidatesTags: ["getInterval"],
      query: () => ({
        url: "/admin/settlement_interval",
        method: "GET",
      }),
    }),
    getIntervals: builder.query({
      providesTags: ["getIntervals"],
      invalidatesTags: ["getIntervals"],
      query: () => ({
        url: "/admin/settlement_interval_types",
        method: "GET",
      }),
    }),
    addDocumentCategory: builder.mutation({
      providesTags: ["addDocumentCategory"],
      invalidatesTags: ["addDocumentCategory"],
      query: (body) => ({
        url: "/admin/document_category",
        method: "POST",
        body,
      }),
    }),
    updateDocumentCategory: builder.mutation({
      providesTags: ["updateDocumentCategory"],
      invalidatesTags: ["updateDocumentCategory"],
      query: (body) => ({
        url: `/admin/document_category`,
        method: "PUT",
        body,
      }),
    }),
    getAdminDocumentCategories: builder.query({
      providesTags: ["getAdminDocumentCategories"],
      invalidatesTags: ["getAdminDocumentCategories"],
      query: () => ({
        url: `/admin/document_categories`,
        method: "GET",
      }),
    }),
    deleteDocumentCategory: builder.mutation({
      providesTags: ["deleteDocumentCategory"],
      invalidatesTags: ["deleteDocumentCategory"],
      query: (id) => ({
        url: `/admin/document_category/${id}`,
        method: "DELETE",
      }),
    }),
    getApplicationCount: builder.query({
      providesTags: ["getApplicationCount"],
      invalidatesTags: ["getApplicationCount"],
      query: () => ({
        url: "/admin/status_count",
        method: "GET",
      }),
    }),
    getFeedbackSections: builder.query({
      providesTags: ["getFeedbackSections"],
      invalidatesTags: ["getFeedbackSections"],
      query: () => ({
        url: "/admin/feedback_sections",
        method: "GET",
      }),
    }),
    getFeedback: builder.query({
      providesTags: ["getFeedback"],
      invalidatesTags: ["getFeedback"],
      query: ({ institutionId, section }) => ({
        url: `/admin/institutions/${institutionId}/${section}/get_feedbacks`,
        method: "GET",
      }),
    }),
    getInstitutionUpdate: builder.query({
      providesTags: ["getInstitutionUpdate"],
      invalidatesTags: ["getInstitutionUpdate"],
      query: (institutionId) => ({
        url: `/admin/institutions/institution_update/${institutionId}`,
        method: "GET",
      }),
    }),
    approveInstitutionUpdate: builder.mutation({
      providesTags: ["approveInstitutionUpdate"],
      invalidatesTags: ["approveInstitutionUpdate"],
      query: (body) => ({
        url: "/admin/institutions/institution_update/approve",
        method: "POST",
        body,
      }),
    }),
    declineInstitutionUpdate: builder.mutation({
      providesTags: ["declineInstitutionUpdate"],
      invalidatesTags: ["declineInstitutionUpdate"],
      query: (body) => ({
        url: "/admin/institutions/institution_update/decline",
        method: "POST",
        body,
      }),
    }),
    getPermissionPages: builder.query({
      providesTags: ["getPermissionPages"],
      invalidatesTags: ["getPermissionPages"],
      query: () => ({
        url: `/admin/permissions`,
        method: "GET",
      }),
    }),
    getPermissionPage: builder.query({
      providesTags: ["getPermissionPage"],
      invalidatesTags: ["getPermissionPage"],
      query: (pageId) => ({
        url: `/admin/pages/${pageId}`,
        method: "GET",
      }),
    }),
    addPermissionPage: builder.mutation({
      providesTags: ["addPermissionPage"],
      invalidatesTags: ["addPermissionPage"],
      query: (body) => ({
        url: "/admin/pages",
        method: "POST",
        body,
      }),
    }),
    updatePermissionPage: builder.mutation({
      providesTags: ["updatePermissionPage"],
      invalidatesTags: ["updatePermissionPage"],
      query: ({ pageId, body }) => ({
        url: `/admin/pages/${pageId}`,
        method: "PUT",
        body,
      }),
    }),
    deletePermissionPage: builder.mutation({
      providesTags: ["deletePermissionPage"],
      invalidatesTags: ["deletePermissionPage"],
      query: (pageId) => ({
        url: `/admin/pages/${pageId}`,
        method: "DELETE",
      }),
    }),
    getAllActions: builder.query({
      providesTags: ["getAllActions"],
      invalidatesTags: ["getAllActions"],
      query: () => ({
        url: `/admin/actions`,
        method: "GET",
      }),
    }),
    getRoles: builder.query({
      providesTags: ["getRoles"],
      invalidatesTags: ["getRoles"],
      query: () => ({
        url: `/admin/roles`,
        method: "GET",
      }),
    }),
    getInstitutionRoles: builder.query({
      providesTags: ["getInstitutionRoles"],
      invalidatesTags: ["getInstitutionRoles"],
      query: (institutionId) => ({
        url: `/admin/roles?institutionId=${institutionId}`,
        method: "GET",
      }),
    }),
    getRole: builder.query({
      providesTags: ["getRole"],
      invalidatesTags: ["getRole"],
      query: (roleId) => ({
        url: `/admin/roles/${roleId}`,
        method: "GET",
      }),
    }),
    addRole: builder.mutation({
      providesTags: ["addRole"],
      invalidatesTags: ["addRole"],
      query: (body) => ({
        url: "/admin/roles",
        method: "POST",
        body,
      }),
    }),
    updateRole: builder.mutation({
      providesTags: ["updateRole"],
      invalidatesTags: ["updateRole"],
      query: ({ roleId, body }) => ({
        url: `/admin/roles/${roleId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteRole: builder.mutation({
      providesTags: ["deleteRole"],
      invalidatesTags: ["deleteRole"],
      query: (roleId) => ({
        url: `/admin/roles/${roleId}`,
        method: "DELETE",
      }),
    }),
    getBranches: builder.query({
      providesTags: ["getBranches"],
      invalidatesTags: ["getBranches"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/branches`,
        method: "GET",
      }),
    }),
    createBranch: builder.mutation({
      providesTags: ["createBranch"],
      invalidatesTags: ["createBranch"],
      query: ({ institutionId, body }) => ({
        url: `/admin/institutions/${institutionId}/branches`,
        method: "POST",
        body,
      }),
    }),
    updateBranch: builder.mutation({
      providesTags: ["updateBranch"],
      invalidatesTags: ["updateBranch"],
      query: ({ institutionId, body, branchId }) => ({
        url: `/admin/institutions/${institutionId}/branches/${branchId}`,
        method: "PUT",
        body,
      }),
    }),
    getSchoolAdmins: builder.query({
      providesTags: ["getSchoolAdmins"],
      invalidatesTags: ["getSchoolAdmins"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/admins`,
        method: "GET",
      }),
    }),
    addSchoolAdmins: builder.mutation({
      providesTags: ["addSchoolAdmins"],
      invalidatesTags: ["addSchoolAdmins"],
      query: ({ institutionId, body }) => ({
        url: `/admin/institutions/${institutionId}/school_admin`,
        method: "POST",
        body,
      }),
    }),
    updateSchoolAdmins: builder.mutation({
      providesTags: ["updateSchoolAdmins"],
      invalidatesTags: ["updateSchoolAdmins"],
      query: ({ institutionId, userId, body }) => ({
        url: `/admin/institutions/${institutionId}/${userId}/school_admin`,
        method: "PUT",
        body,
      }),
    }),
    getSchoolDashboardReport: builder.query({
      providesTags: ["getSchoolDashboardReport"],
      invalidatesTags: ["getSchoolDashboardReport"],
      query: (institutionId) => ({
        url: `/admin/institutions/${institutionId}/dashboard_report`,
        method: "GET",
      }),
    }),

    getReminderCharges: builder.query({
      providesTags: ["getReminderCharges"],
      invalidatesTags: ["getReminderCharges"],
      query: () => ({
        url: `/admin/reminder/charges`,
        method: "GET",
      }),
    }),

    setReminderCharges: builder.mutation({
      providesTags: ["setReminderCharges"],
      invalidatesTags: ["setReminderCharges"],
      query: ({ body }) => ({
        url: `/admin/reminder/charges`,
        method: "POST",
        body,
      }),
    }),
    createMessageReminder: builder.mutation({
      providesTags: ["createMessageReminder"],
      invalidatesTags: ["createMessageReminder"],
      query: ({ body }) => ({
        url: `/admin/reminder`,
        method: "POST",
        body,
      }),
    }),
    updateMessageReminder: builder.mutation({
      providesTags: ["updateMessageReminder"],
      invalidatesTags: ["updateMessageReminder"],
      query: ({ body }) => ({
        url: `/admin/reminder`,
        method: "PUT",
        body,
      }),
    }),
    getAdminMessageReminders: builder.query({
      providesTags: ["getAdminMessageReminders"],
      invalidatesTags: ["getAdminMessageReminders"],
      query: () => ({
        url: `/admin/reminders`,
        method: "GET",
      }),
    }),
    getOneAdminMessageReminder: builder.query({
      providesTags: ["getOneAdminMessageReminder"],
      invalidatesTags: ["getOneAdminMessageReminder"],
      query: (reminderId) => ({
        url: `/admin/reminder/${reminderId}`,
        method: "GET",
      }),
    }),
    getNotificationsSummary: builder.query({
      providesTags: ["getNotificationsSummary"],
      invalidatesTags: ["getNotificationsSummary"],
      query: () => ({
        url: `/admin/reminder/report`,
        method: "GET",
      }),
    }),
    getAdminUserProfile: builder.query({
      invalidatesTags: ["getAdminUserProfile"],
      providesTags: ["getAdminUserProfile"],
      query: () => ({
        // url: `/admin/user/profile/${userId}`,
        url: `/admin/user/profile`,
        method: "GET",
      }),
    }),
    updateAdminUserProfile: builder.mutation({
      invalidatesTags: ["updateAdminUserProfile"],
      providesTags: ["updateAdminUserProfile"],
      // query: ({ userId, body }) => ({
      query: (body) => ({
        // url: `/admin/user/profile/${userId}`,
        // url: `/admin/user_profile`,
        url: `/admin/user/profile`,
        method: "PUT",
        body,
      }),
    }),
    changeAdminPassword: builder.mutation({
      invalidatesTags: ["changeAdminPassword"],
      providesTags: ["changeAdminPassword"],
      query: (body) => ({
        url: `/admin/user/change_password`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetDashboardDetailsQuery,
  useGetAllInstitutionListQuery,
  useGetInstitutionListQuery,
  useGetInstitutionDetailQuery,
  useDownloadDocumentQuery,
  useDownloadLogoQuery,
  useGetAdminUploadedDocumentsQuery,
  useEditInstitutionMutation,
  useVerifyInstitutionMutation,
  useAddInstitutionFeedbackMutation,
  useEnableInstitutionMutation,
  useDisableInstitutionMutation,
  useGetSuperUsersListQuery,
  useGetAssignedInstitutionsListQuery,
  useAddSuperUserMutation,
  useUpdateSuperUserMutation,
  useEnableSuperUserMutation,
  useDisableSuperUserMutation,
  useGetSuperPaymentHistoryQuery,
  useGetSuperWalletTransactionsQuery,
  useGetSuperReportSummaryQuery,
  useSetGlobalCommissionMutation,
  useApplyCommissionMutation,
  useGetSuperSettlementHistoryQuery,
  useGetSuperBalanceInquiryQuery,
  useGetInstitutionSettlementListQuery,
  useGetInstitutionSettlementDetailsQuery,
  useSettleInstitutionMutation,
  useGetGlobalCommissionQuery,
  useGetCommissionQuery,
  useGetIntervalQuery,
  useSetIntervalMutation,
  useGetIntervalsQuery,
  useAddDocumentCategoryMutation,
  useUpdateDocumentCategoryMutation,
  useGetAdminDocumentCategoriesQuery,
  useDeleteDocumentCategoryMutation,
  useGetApplicationCountQuery,
  useGetFeedbackSectionsQuery,
  useGetFeedbackQuery,
  useGetInstitutionUpdateQuery,
  useApproveInstitutionUpdateMutation,
  useDeclineInstitutionUpdateMutation,
  useAddDocumentMutation,
  useUpdateUploadDocumentMutation,
  useDeleteAdminDocumentMutation,
  useGetAllDocumentCategoriesQuery,
  useGetInstitutionTypeQuery,
  useGetPermissionPagesQuery,
  useAddPermissionPageMutation,
  useGetPermissionPageQuery,
  useUpdatePermissionPageMutation,
  useDeletePermissionPageMutation,
  useGetAllActionsQuery,
  useGetRolesQuery,
  useGetInstitutionRolesQuery,
  useGetRoleQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useGetBranchesQuery,
  useCreateBranchMutation,
  useUpdateBranchMutation,
  useGetSchoolAdminsQuery,
  useAddSchoolAdminsMutation,
  useUpdateSchoolAdminsMutation,
  useGetSchoolDashboardReportQuery,
  useGetReminderChargesQuery,
  useSetReminderChargesMutation,
  useCreateMessageReminderMutation,
  useGetAdminMessageRemindersQuery,
  useGetOneAdminMessageReminderQuery,
  useUpdateMessageReminderMutation,
  useGetAdminUserProfileQuery,
  useUpdateAdminUserProfileMutation,
  useChangeAdminPasswordMutation,
  useGetNotificationsSummaryQuery,
} = adminSlice;
