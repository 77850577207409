import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import '../index.css';

function WhatweOffer() {


    const location = useLocation();

    useEffect(() => {
        const feature = document.getElementById('feature');

        if (feature && location.state?.scrollToSection) {
            feature.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location.state?.scrollToSection]);


    return (
        <Container fluid id="feature" className="whatweoffer">
            <Row>
                <h2 className="headertext" style={{ color: '#F14810' }}><b>What we offer</b></h2>
                <p className="whyadeptparagraph">With Adept for Education, you will be able to explore the following array of features and benefits tailored to meet the unique needs of educators, parents, students, and LMS startups.
                </p>

            </Row>
            <br />
            <Row >
                <Col xs={12} md={6} lg={3}>

                    <div className="featurecardbody">
                        <svg width="55" height="55" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.873 74.6058C58.3599 74.6058 74.9679 57.9978 74.9679 37.5109C74.9679 17.024 58.3599 0.416016 37.873 0.416016C17.386 0.416016 0.778076 17.024 0.778076 37.5109C0.778076 57.9978 17.386 74.6058 37.873 74.6058Z" fill="#FFECE6" />
                            <path d="M22.8889 39.8889H34.2222C35.2611 39.8889 36.1111 39.0389 36.1111 38V22.8889C36.1111 21.85 35.2611 21 34.2222 21H22.8889C21.85 21 21 21.85 21 22.8889V38C21 39.0389 21.85 39.8889 22.8889 39.8889ZM22.8889 55H34.2222C35.2611 55 36.1111 54.15 36.1111 53.1111V45.5556C36.1111 44.5167 35.2611 43.6667 34.2222 43.6667H22.8889C21.85 43.6667 21 44.5167 21 45.5556V53.1111C21 54.15 21.85 55 22.8889 55ZM41.7778 55H53.1111C54.15 55 55 54.15 55 53.1111V38C55 36.9611 54.15 36.1111 53.1111 36.1111H41.7778C40.7389 36.1111 39.8889 36.9611 39.8889 38V53.1111C39.8889 54.15 40.7389 55 41.7778 55ZM39.8889 22.8889V30.4444C39.8889 31.4833 40.7389 32.3333 41.7778 32.3333H53.1111C54.15 32.3333 55 31.4833 55 30.4444V22.8889C55 21.85 54.15 21 53.1111 21H41.7778C40.7389 21 39.8889 21.85 39.8889 22.8889Z" fill="#F14810" />
                        </svg>

                        <div style={{ marginTop: 1.5 + 'rem' }}>
                            <h6 className="featureeachhead">Data-Driven Dashboard</h6>
                            <p className="featureeachparagraph">Educational Organizations can effortlessly search and access the complete payment history of parents, including parent’s comprehensive details and contact information.</p>
                            <br />
                        </div>
                    </div>

                </Col>
                <Col xs={12} md={6} lg={3}>

                    <div className="featurecardbody">
                        <svg width="55" height="55" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.4803 74.6058C57.9672 74.6058 74.5752 57.9978 74.5752 37.5109C74.5752 17.024 57.9672 0.416016 37.4803 0.416016C16.9933 0.416016 0.385376 17.024 0.385376 37.5109C0.385376 57.9978 16.9933 74.6058 37.4803 74.6058Z" fill="#FFECE5" />
                            <path d="M15 35.3125V53.9063C15 54.5105 15.4922 55 16.1 55H21.6V33.125H17.2C15.9852 33.125 15 34.1046 15 35.3125ZM39.75 32.0313H38.1V29.2969C38.1 28.9947 37.8539 28.75 37.55 28.75H36.45C36.1461 28.75 35.9 28.9947 35.9 29.2969V33.6719C35.9 33.974 36.1461 34.2188 36.45 34.2188H39.75C40.0539 34.2188 40.3 33.974 40.3 33.6719V32.5781C40.3 32.276 40.0539 32.0313 39.75 32.0313ZM49.2203 27.659L38.2203 20.3671C37.8589 20.1277 37.4343 20 37 20C36.5657 20 36.1411 20.1277 35.7797 20.3671L24.7797 27.659C24.4784 27.8587 24.2313 28.1293 24.0604 28.4467C23.8895 28.7642 23.8001 29.1187 23.8 29.4787V55H32.6V45.1563C32.6 44.552 33.0923 44.0625 33.7 44.0625H40.3C40.9078 44.0625 41.4 44.552 41.4 45.1563V55H50.2V29.4794C50.2 28.748 49.8322 28.0644 49.2203 27.659ZM37 37.5C33.9626 37.5 31.5 35.0514 31.5 32.0313C31.5 29.0111 33.9626 26.5625 37 26.5625C40.0374 26.5625 42.5 29.0111 42.5 32.0313C42.5 35.0514 40.0374 37.5 37 37.5ZM56.8 33.125H52.4V55H57.9C58.5078 55 59 54.5105 59 53.9063V35.3125C59 34.1046 58.0148 33.125 56.8 33.125Z" fill="#F14810" />
                        </svg>


                        <div style={{ marginTop: 1.5 + 'rem' }}>
                            <h6 className="featureeachhead">School Customization</h6>
                            <p className="featureeachparagraph">Administrative access to set up the number of classes and their respective structures.</p>
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>

                </Col>
                <Col xs={12} md={6} lg={3}>

                    <div className="featurecardbody">
                        <svg width="55" height="55" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.6686 74.6058C58.1556 74.6058 74.7635 57.9978 74.7635 37.5109C74.7635 17.024 58.1556 0.416016 37.6686 0.416016C17.1817 0.416016 0.57373 17.024 0.57373 37.5109C0.57373 57.9978 17.1817 74.6058 37.6686 74.6058Z" fill="#FFECE5" />
                            <path d="M64.1649 42.8993C64.1649 44.2241 61.5153 45.1073 58.4241 45.5489C56.4368 41.7952 52.4624 38.9248 47.8255 36.9376C48.2671 36.2752 48.7087 35.8336 49.1503 35.1711H50.9168C57.7617 34.9503 64.1649 39.1456 64.1649 42.8993ZM26.1868 34.9503H24.4204C17.5755 34.9503 11.1722 39.1456 11.1722 42.8993C11.1722 44.2241 13.8219 45.1073 16.9131 45.5489C18.9003 41.7952 22.8748 38.9248 27.5117 36.9376L26.1868 34.9503ZM37.6686 37.1584C42.5263 37.1584 46.5007 33.1839 46.5007 28.3263C46.5007 23.4686 42.5263 19.4941 37.6686 19.4941C32.8109 19.4941 28.8365 23.4686 28.8365 28.3263C28.8365 33.1839 32.8109 37.1584 37.6686 37.1584ZM37.6686 39.3664C28.6157 39.3664 20.0044 45.1073 20.0044 50.4065C20.0044 54.8226 37.6686 54.8226 37.6686 54.8226C37.6686 54.8226 55.3328 54.8226 55.3328 50.4065C55.3328 45.1073 46.7215 39.3664 37.6686 39.3664ZM50.2544 32.7423H50.9168C54.6704 32.7423 57.5409 29.8719 57.5409 26.1182C57.5409 22.3646 54.6704 19.4941 50.9168 19.4941C49.8128 19.4941 48.9295 19.7149 48.0463 20.1565C49.8128 22.3646 50.9168 25.235 50.9168 28.3263C50.9168 29.8719 50.696 31.4175 50.2544 32.7423ZM24.4204 32.7423H25.0828C24.6412 31.4175 24.4204 29.8719 24.4204 28.3263C24.4204 25.235 25.5244 22.3646 27.2909 20.1565C26.4076 19.7149 25.5244 19.4941 24.4204 19.4941C20.6668 19.4941 17.7963 22.3646 17.7963 26.1182C17.7963 29.8719 20.6668 32.7423 24.4204 32.7423Z" fill="#F14810" />
                        </svg>


                        <div style={{ marginTop: 1.5 + 'rem' }}>
                            <h6 className="featureeachhead">Payment Processing System</h6>
                            <p className="featureeachparagraph">Convenience of instant transactions and real-time updates on payments made and received.</p>
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>

                </Col>
                <Col xs={12} md={6} lg={3}>

                    <div className="featurecardbody">

                        <svg width="55" height="55" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.8569 74.6058C58.3438 74.6058 74.9517 57.9978 74.9517 37.5109C74.9517 17.024 58.3438 0.416016 37.8569 0.416016C17.3699 0.416016 0.761963 17.024 0.761963 37.5109C0.761963 57.9978 17.3699 74.6058 37.8569 74.6058Z" fill="#FFECE5" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M25.776 21.0125C28.4817 19.6579 33.0543 18 38.0736 18H38.0748C42.9985 18 47.4622 19.5968 50.1547 20.9369C50.8018 21.2519 51.4287 21.607 52.0315 22.0003L49.1212 26.2551L47.4767 27.1085C41.8128 29.9654 34.2189 29.9654 28.5562 27.1085L27.1072 26.5318L24.0292 22.0003C24.3136 21.8125 24.6714 21.5992 25.0915 21.3703C25.3037 21.2547 25.5315 21.1347 25.776 21.0125ZM27.6917 22.3204C28.7488 22.6552 29.8253 22.9254 30.9153 23.1293C33.3133 23.5727 35.729 23.6371 37.7958 23.0393C40.4227 22.2804 43.1796 21.6636 45.7632 21.3003C43.5585 20.5735 40.8928 20.0002 38.0736 20.0002C34.0033 20.0002 30.2263 21.1947 27.6917 22.3204ZM48.3767 28.8942L48.6501 28.7564C57.9353 38.6773 65.2548 58 38.0736 58C10.8925 58 18.0375 39.0317 27.3961 28.7631L27.655 28.8942C33.8844 32.0367 42.1473 32.0367 48.3767 28.8942ZM34.0188 36.9369C34.454 36.8048 34.9247 36.9756 35.1762 37.3528L39.6865 44.1134H41.5403V37.9235C41.5403 37.3528 42.0013 36.8918 42.5719 36.8918C43.1426 36.8918 43.6036 37.3528 43.6036 37.9235V44.1134H44.6352C45.2059 44.1134 45.6669 44.5745 45.6669 45.1451C45.6669 45.7157 45.2059 46.1768 44.6352 46.1768H43.6036V50.3066C43.6036 50.7612 43.307 51.161 42.8717 51.2931C42.4365 51.4253 41.9658 51.2545 41.7144 50.8773L38.5807 46.1768H35.3503V50.3034C35.3503 50.874 34.8893 51.3351 34.3186 51.3351C33.748 51.3351 33.287 50.874 33.287 50.3034V46.1768H32.2553C31.6847 46.1768 31.2236 45.7157 31.2236 45.1451C31.2236 44.5745 31.6847 44.1134 32.2553 44.1134H33.287V37.9235C33.287 37.4689 33.5836 37.0691 34.0188 36.9369ZM41.5403 46.1768H41.0599L41.5403 46.8957V46.1768ZM35.3503 44.1134H37.2041L35.3503 41.3312V44.1134Z" fill="#F14810" />
                        </svg>

                        <div style={{ marginTop: 1.5 + 'rem' }}>
                            <h6 className="featureeachhead">Wallet System</h6>
                            <p className="featureeachparagraph">Virtual wallets for users to securely store surplus funds. Users enjoy the convenience of assigning these funds to different fee payments for their children, offering flexibility and control over their financial transactions.</p>
                            
                        </div>
                    </div>

                </Col>
            </Row>
        </Container>
    )
}

export default WhatweOffer