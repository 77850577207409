import { useState, useEffect } from "react";
import alertIcon from "../../../../../../assets/svgs/alertIcon.svg";
import { Table, Button, Form } from "react-bootstrap";

import { useSelector } from "react-redux";

import ModalComponent from "../../../../../../component/modal/modal";
import ExpandableTable from "../../../../../../component/table/expandableTable";
import {
  useGetInstitutionLevelsQuery,
  useAddInstitutionLevelMutation,
  useEditInstitutionLevelMutation,
  useDeleteInstitutionLevelMutation,
  useGetInstitutionTypeQuery,
  useDeleteClassMutation,
  useUpdateClassMutation,
  useCreateClassMutation,
  useGetAllClassesQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../../component/toast/toast";
import CreateClass from "../../../components/classes/createClass/createClass";

const InstitutionLevel = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [deletedElementID, setDeletedElementID] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: levelsData,
    refetch: levelsRefetch,
    isLoading: levelsLoading,
  } = useGetInstitutionLevelsQuery(instId);

  const {
    data: classes,
    isLoading: classesLoading,
    refetch: refetchClasses,
  } = useGetAllClassesQuery(instId);

  console.log(classes);

  const [deleteClass, { isLoading: deleteLoading }] = useDeleteClassMutation();

  //.......................... actions .........................

  // console.log(classes);
  // console.log(editingValue);

  const confirmDelete = (el) => {
    console.log(el);
    setElementToDelete(el);
    setShowConfirmDelete(true);
  };

  const handleDeleteLevel = async () => {
    console.log("delete was clicked for ", elementToDelete);
    setShowConfirmDelete(false);
    try {
      const parameters = {
        classId: elementToDelete?.id,
        body: {
          name: elementToDelete.name,
          institutionTypeId: elementToDelete.institutionTypeId,
          // noOfCategories: elementToDelete.noOfCategories,
          // categories: elementToDelete.categories,
        },
      };
      await deleteClass(parameters).unwrap();
      setDeletedElementID(elementToDelete.id);
      setShowToast(true);
      setToastMessage("Level Deleted");
    } catch (error) {
      console.log(error);
      // alert(error.data);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  // const handleCreateOtherCategories = () => {
  //   // console.log(returnCategories(noOfCategories, categories));
  //   setArrayOfCategories(returnCategories(noOfCategories, categories));
  //   // setLevelsCreated(true);
  // };

  // const handleCategoriesChange = (e, i) => {
  //   const updatedArr = [...arrayOfCategories];
  //   updatedArr[i] = { ...updatedArr[i], letter: e.target.value };
  //   setArrayOfCategories(updatedArr);
  // };

  // console.log(newLevelData);
  // console.log(isCategoryBlured);
  // console.log(categoryNames);

  // console.log(noOfCategories);
  // console.log(name);
  // console.log(schoolTypes);
  // console.log(institutionTypeId);
  // console.log(arrayOfCategories);

  useEffect(() => {
    if (classes) {
      refetchClasses();
    }
  }, [deletedElementID]);

  if (levelsLoading || deleteLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ fontSize: "14px", fontWeight: 600, marginBottom: "0px" }}>
          Class Management
        </h6>

        {userAuthorizedPages?.find(
          (page) => page?.action === "CREATE_CLASS"
        ) && (
          <Button
            className="button"
            size="md"
            style={{
              backgroundColor: "#F14810",
              color: "#ffffff",
              border: "none",
              outline: "none",
              fontSize: 0.8 + "rem",
            }}
            onClick={() => setShowModal(true)}
            title="Click this button to create a class"
          >
            Create New Class
          </Button>
        )}
      </div>

      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          minHeight: "95%",
        }}
      >
        {/* delete confirmation modal */}
        <ModalComponent
          showModal={showConfirmDelete}
          closeModal={() => setShowConfirmDelete(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={alertIcon}
              alt="alert Icon"
              style={{ margin: "0 auto" }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "500",
                fontSize: 1.0 + "rem",
              }}
            >
              Are you sure you want to delete this level?{" "}
            </p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                onClick={() => setShowConfirmDelete(false)}
                type="button"
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  No
                </span>
              </Button>
              <Button
                onClick={() => handleDeleteLevel()}
                type="button"
                variant="primary"
                style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Yes
                </span>
              </Button>
            </div>
          </div>
        </ModalComponent>

        <CreateClass showModal={showModal} setShowModal={setShowModal} />

        {/* Make sure the classes are ordered in this form */}
        {classes && Object.keys(classes).length > 0 ? (
          ["NURSERY", "PRIMARY", "SECONDARY", "TERTIARY", "OTHERS"].map(
            (key, i) => {
              if (classes[key]) {
                return (
                  <div key={key} id={key} style={{ marginBottom: "20px" }}>
                    <ExpandableTable
                      data={classes[key]}
                      nameOfClass={key}
                      editPermission={userAuthorizedPages?.find(
                        (page) => page?.action === "CREATE_CLASS"
                      )}
                      deletePermission={userAuthorizedPages?.find(
                        (page) => page?.action === "DELETE_CLASS"
                      )}
                    />
                  </div>
                );
              }
              return null;
            }
          )
        ) : (
          <span style={{ fontStyle: "italic", marginBottom: "20px" }}>
            Nothing to show, create a class
          </span>
        )}
      </div>
    </>
  );
};

export default InstitutionLevel;
