import { Container } from "react-bootstrap";
import DashboardSidebar from "../components/dashboardSidebar/sidebar";
import DashboardTopSection from "../components/dashboardTopSection";
import { useGetInstitutionQuery } from "../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import Profile from "./component/profile";
import InstitutionDetailsPage from "./component/institutionDetailsPage";
import InstitutionAccountPage from "./component/institutionAccount/institutionAccount";
import ContactPersonPage from "../setupInstitution/components/contactPerson";
import Appearance from "./component/appearance";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import InstitutionLevel from "./component/institutionLevel/institutionLevel";
import InstitutionCategory from "./component/institutionCategory";
import InstitutionCalendar from "./component/schoolCalendar";
import InstitutionFeeComponent from "./component/feeComponent";
import InstitutionFee from "./component/fee";
import menuIcon from "../../../../assets/svgs/menuIcon.svg";
import UploadCredentials from "./component/uploadCredentials/uploadCredentials";
import "./settings.css";
import { Offcanvas } from "react-bootstrap";
import RolesAndPermissions from "./component/rolesAndPermission/rolesAndPermissions";
import UserManagementPage from "./component/userManagement/userManagement";
import InstitutionServiceAccountPage from "./component/institutionAccount/institutionServiceAccount";
import ManageFeeLayout from "./component/manageFee/manageFeeLayout";
import SettlementAccounts from "./component/settlementAccounts/settlementAccounts";

const settingsStages = [
  { stage: "School Details" },
  { stage: "Contact Person" },
  { stage: "Collection Account" },
  { stage: "Service Account" },
  { stage: "Appearance" },
  { stage: "Documents" },
  { stage: "Class Management" },
  { stage: "Calendar Management" },
  { stage: "Settlement Accounts" },
  { stage: "Manage Fees" },
  { stage: "Roles & Permissions" },
  { stage: "User Management" },
];

const InstitutionSettingsPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const [activeStage, setActiveStage] = useState("Calendar Management");
  const [showMenu, setShowMenu] = useState(false);

  const handleSetStage = (stage) => {
    setActiveStage(stage);
    setShowMenu(false);
  };

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(id);

  if (detailsLoading) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <section style={{ height: "80%" }}>
          <h5>Settings</h5>
          <section
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              height: "100%",
              display: "flex",
              position: "relative",
            }}
          >
            <div
              style={{
                borderRight: "1px solid #E7E1E1",
                flexBasis: "20%",
                padding: "30px 15px",
                minWidth: "150px",
                overflowY: "auto",
              }}
              className="settingsMenu"
            >
              {settingsStages.map(({ stage }, i) => (
                <div
                  key={i}
                  style={{
                    borderRadius: "8px",
                    padding: "8px",
                    marginBottom: "10px",
                    cursor: "pointer",
                    border: `${
                      activeStage === stage
                        ? "1px solid #F14810"
                        : "1px solid #F5F5F5"
                    }`,
                    backgroundColor: `${
                      activeStage === stage ? "#ffe6de" : "#e8e8e8"
                    }`,
                  }}
                  onClick={() => setActiveStage(stage)}
                >
                  <p style={{ marginBottom: 0 }}>{stage}</p>
                </div>
              ))}
            </div>

            <div
              style={{
                flexBasis: "80%",
                flexGrow: 1,
                padding: "15px 30px 30px",
                overflowY: "auto",
                height: "100%",
              }}
              className="right-box-settings"
            >
              <img
                src={menuIcon}
                alt="menu icon"
                onClick={() => setShowMenu(true)}
                className="menuIcon"
                style={{ marginBottom: "10px", cursor: "pointer" }}
              />

              {activeStage === "School Details" && (
                <InstitutionDetailsPage
                  institutionDetails={institutionDetails}
                />
              )}
              {activeStage === "Contact Person" && (
                <ContactPersonPage institutionDetails={institutionDetails} />
              )}

              {activeStage === "Collection Account" && (
                <InstitutionAccountPage
                  institutionDetails={institutionDetails}
                />
              )}

              {activeStage === "Service Account" && (
                <InstitutionServiceAccountPage
                  institutionDetails={institutionDetails}
                />
              )}
              {activeStage === "Appearance" && (
                <Appearance institutionDetails={institutionDetails} />
              )}
              {activeStage === "Documents" && (
                <UploadCredentials institutionDetails={institutionDetails} />
              )}
              {activeStage === "Class Management" && <InstitutionLevel />}
              {activeStage === "School Category" && <InstitutionCategory />}
              {activeStage === "Calendar Management" && <InstitutionCalendar />}
              {activeStage === "Settlement Accounts" && <SettlementAccounts />}
              {activeStage === "Manage Fees" && <ManageFeeLayout />}
              {activeStage === "Roles & Permissions" && <RolesAndPermissions />}
              {activeStage === "User Management" && <UserManagementPage />}
            </div>

            {/* offcanvas for mobile menu */}
            <Offcanvas
              show={showMenu}
              onHide={() => setShowMenu(false)}
              placement={"start"}
              backdrop={true}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                width: "100%",
                maxWidth: "180px",
                height: "100%",
              }}
            >
              <Offcanvas.Body style={{ padding: "20px 0" }}>
                <div
                  style={{
                    borderRight: "1px solid #F5F5F5",
                    flexBasis: "20%",
                    padding: "30px 15px",
                    minWidth: "150px",
                    overflowY: "auto",
                  }}
                >
                  {settingsStages.map(({ stage }, i) => (
                    <div
                      key={i}
                      style={{
                        borderRadius: "8px",
                        padding: "8px",
                        marginBottom: "10px",
                        cursor: "pointer",
                        border: `${
                          activeStage === stage
                            ? "1px solid #F14810"
                            : "1px solid #F5F5F5"
                        }`,
                        backgroundColor: `${
                          activeStage === stage ? "#ffe6de" : "#e8e8e8"
                        }`,
                      }}
                      onClick={() => handleSetStage(stage)}
                    >
                      <p style={{ marginBottom: 0 }}>{stage}</p>
                    </div>
                  ))}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </section>
        </section>
      </Container>
    </Container>
  );
};

export default InstitutionSettingsPage;
