import React, { useEffect, useState } from "react";
import { Button, Col, Row, Offcanvas } from "react-bootstrap";
import {
  useGetAllRolesQuery,
  useCreateSchoolAdminRoleMutation,
  useGetAllPermissionsQuery,
  useEditSchoolAdminRoleMutation,
} from "../../../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../../component/toast/toast";

const RolesAndPermissions = () => {
  const [roleToEdit, setRoleToEdit] = useState(null);
  const [showCanvas, setShowCanvas] = useState(false);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const {
    data: allRoles,
    isLoading: allRolesLoading,
    refetch: refetchRoles,
  } = useGetAllRolesQuery(instId);

  // console.log(allRoles);

  const handleEditRole = (role) => {
    setRoleToEdit(role);
    setShowCanvas(true);
  };

  const { data: allPermissions, isLoading: allPermissionsLoading } =
    useGetAllPermissionsQuery();

  console.log(allPermissions);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [createSchoolAdminRole, { isLoading: createLoading }] =
    useCreateSchoolAdminRoleMutation();
  const [editSchoolAdminRole, { isLoading: editLoading }] =
    useEditSchoolAdminRoleMutation();

  const [newRole, setNewRole] = useState({
    name: "",
    description: "",
    permissionIds: [],
  });

  const { name, description } = newRole;

  const [newPermissions, setNewPermissions] = useState([]);

  const handleOnChange = (e, id) => {
    const { name, value } = e.target;

    setNewRole({ ...newRole, [name]: value });

    setNewPermissions(
      newPermissions?.map((page) => {
        if (page.id === id) {
          return {
            ...page,
            isActive: !page.isActive,
          };
        }
        return page;
      })
    );
  };

  console.log(newPermissions);

  const handleCreateRole = async () => {
    const params = {
      institutionId: instId,
      body: {
        name: name,
        description: description,
        permissionIds: newPermissions
          ?.filter((page) => page.isActive !== false)
          .map((page) => page.id),
      },
    };

    try {
      const res = await createSchoolAdminRole(params).unwrap();
      console.log(res);

      setNewRole({
        name: "",
        description: "",
        permissionIds: [],
      });
      setNewPermissions(
        allPermissions?.map((page) => {
          return { ...page, isActive: false };
        })
      );
      setShowCanvas(false);
      setShowToast(true);
      setToastMessage("Role has been Created");
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleUpdateRole = async () => {
    const params = {
      institutionId: instId,
      roleId: roleToEdit?.id,
      body: {
        name: name,
        description: description,
        permissionIds: newPermissions
          ?.filter((page) => page.isActive === true)
          .map((page) => page.id),
      },
    };

    try {
      const res = await editSchoolAdminRole(params).unwrap();
      console.log(res);

      setNewRole({
        name: "",
        description: "",
        permissionIds: [],
      });
      setNewPermissions(
        allPermissions?.map((page) => {
          return { ...page, isActive: false };
        })
      );
      setShowCanvas(false);
      setRoleToEdit(null);
      setShowToast(true);
      setToastMessage("Role has been Updated");
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  // console.log(newPermissions);

  useEffect(() => {
    setNewPermissions(
      allPermissions?.map((page) => {
        return { ...page, isActive: false };
      })
    );
  }, [allPermissions]);

  useEffect(() => {
    if (roleToEdit) {
      console.log(roleToEdit);
      setNewRole({ name: roleToEdit?.name });
      setNewPermissions(
        allPermissions?.map((page) => {
          console.log(page);
          if (
            roleToEdit?.permissions?.find(
              (permission) => page.id === permission.id
            )
          ) {
            return { ...page, isActive: true };
          }
          return page;
        })
      );
    }
  }, [roleToEdit]);

  useEffect(() => {
    if (allRoles) {
      refetchRoles();
    }
  }, [showCanvas]);

  console.log(roleToEdit);

  if (allRolesLoading || editLoading || createLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ fontSize: "16px", fontWeight: 600, marginBottom: "0px" }}>
          Roles and Permission
        </h6>

        {userAuthorizedPages?.find(
          (page) => page?.action === "CREATE_ROLE"
        ) && (
          <Button
            className="button"
            size="md"
            style={{
              backgroundColor: "#F14810",
              color: "#ffffff",
              border: "none",
              outline: "none",
              fontSize: "14px",
            }}
            onClick={() => setShowCanvas(true)}
          >
            Create New Role
          </Button>
        )}
      </div>

      <div
        style={{
          border: "1px solid #E7E1E1",
          borderRadius: "8px",
          padding: "15px",
          minHeight: "95%",
        }}
      >
        <section className="rolesContainer">
          {allRoles?.length > 0 ? (
            allRoles?.map((role, i) => (
              <div
                // className="branchsidecard-x1"
                className="role"
                onClick={() => handleEditRole(role)}
              >
                <h6 style={{ marginBottom: "0px" }}>{role?.name}</h6>
              </div>
            ))
          ) : (
            <span style={{ fontStyle: "italic", marginBottom: "20px" }}>
              Nothing to show, create a role
            </span>
          )}
        </section>
      </div>

      <Offcanvas
        show={showCanvas}
        onHide={() => {
          setShowCanvas(false);
          setRoleToEdit(null);
          setNewRole({ name: "" });
          setNewPermissions(
            allPermissions?.map((page) => {
              return { ...page, isActive: false };
            })
          );
        }}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {roleToEdit ? "Edit Role" : "Create New Role"}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ marginBottom: "5px", fontWeight: "500" }}>
              Role Title{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>
            <input
              type="text"
              placeholder="Enter role title"
              name="name"
              value={name}
              onChange={(e) => handleOnChange(e)}
              style={{
                borderRadius: "5px",
                backgroundColor: "#ededed",
                width: "100%",
                marginBottom: "15px",
                padding: "8px",
                outline: "none",
                border: "none",
              }}
            />

            <p style={{ marginBottom: "10px", fontWeight: "500" }}>
              Assign Permission{" "}
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </p>

            <div
              style={{
                padding: "15px",
                border: "1px solid #DBDBDB",
                borderRadius: "10px",
              }}
            >
              {newPermissions?.map(
                ({ id, name, action, isActive, description }) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      gap: "20px",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>
                      <h6 style={{ marginBottom: "5px" }}>{name}</h6>
                      <p style={{ marginBottom: "0px", color: "#7b7b7b" }}>
                        {description}
                      </p>
                    </div>

                    <div
                      className="calender-switch-container"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isActive}
                          // checked={editingElement ? editedToggled : isToggled}
                          onChange={(e) => handleOnChange(e, id)}
                          className="toggle"
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                )
              )}
            </div>

            {userAuthorizedPages?.find(
              (page) => page?.action === "CREATE_ROLE"
            ) && (
              <Button
                className="button"
                size="md"
                style={{
                  backgroundColor: "#F14810",
                  color: "#ffffff",
                  border: "none",
                  outline: "none",
                  fontSize: "14px",
                  padding: "8px 20px",
                  marginTop: "20px",
                  marginLeft: "auto",
                }}
                onClick={
                  roleToEdit
                    ? () => handleUpdateRole()
                    : () => handleCreateRole()
                }
              >
                {roleToEdit ? "Save Changes" : "Save Role"}
              </Button>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default RolesAndPermissions;
