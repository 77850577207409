import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h3>Sorry! You dont have access to view this page</h3>
      <Button
        onClick={() => navigate(-1)}
        style={{
          backgroundColor: "#F14810",
          borderColor: "#F14810",
          float: "left",
        }}
      >
        Go Back
      </Button>
    </div>
  );
};

export default UnauthorizedPage;
