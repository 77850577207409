import { useState, useEffect } from "react";
// import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import { Container } from "react-bootstrap";
import DashboardTopSection from "../../../../../components/dashboardTopSection/index";
import DashboardSidebar from "../../../../../components/dashboardSideBar/sidebar";
import TableComponent from "../../../../../../../component/table/table";
import backArrow from "../../../../../../../assets/svgs/backArrow.svg";
// import "./settlement.css";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
// import {
//     useGetInstitutionQuery,
//     useGetPaymentHistoryQuery,
// } from "../../../../../../redux/features/institution/institutionApiSlice";

const ReportHistoryDetailPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const { reportId } = useParams();

  const navigate = useNavigate();

  const selectedReportTitle = useSelector(
    (state) => state?.institution?.selectedReportTitle
  );

  const columns = [
    { name: "id", label: "ID" },
    { name: "date", label: "Date" },
    { name: "reportRef", label: "Report REF" },
    { name: "paidAmount", label: "Paid Amount" },
    { name: "feeTotal", label: "Fee Total" },
    // {
    //     name: "name",
    //     label: "Student Name",
    //     options: {
    //         customBodyRender: (value, tableMeta) => {
    //             const { firstName, middleName, lastName } =
    //                 payments[tableMeta.rowIndex];
    //             return `${firstName} ${middleName ? middleName : ""} ${lastName}`;
    //         },
    //     },
    // },
    { name: "studentName", label: "Student Name" },
    { name: "studentNo", label: "Student No" },
    // {
    //     name: "name",
    //     label: "Level-Category",
    //     options: {
    //         customBodyRender: (value, tableMeta) => {
    //             const { level, category } = payments[tableMeta.rowIndex];
    //             return `${level} - ${category}`;
    //         },
    //     },
    // },
    { name: "calendar", label: "Calendar" },
    { name: "transactionReference", label: "Transaction Ref" },
    {
      name: "paid",
      label: "Status",
      options: {
        customBodyRender: (paid) => (paid ? "Paid" : "Not Paid"),
      },
    },
    // {
    //     name: "actions",
    //     label: "Action",
    //     options: {
    //         customBodyRender: (value, tableMeta) => (
    //             <img
    //                 src={eyeActionIcon}
    //                 alt="eye icon"
    //                 style={{ cursor: "pointer" }}
    //                 onClick={() => navigate(`${institutionList[tableMeta.rowIndex].id}`)}
    //             />
    //         ),
    //     },
    // },
  ];

  const reportHistory = [
    {
      id: 1,
      date: "02 - 10 - 2023",
      reportRef: "1058892911430",
      paidAmount: "835,000",
      feeTotal: "210,000",
      studentName: "Bankole Johnson",
      studentNo: "001232",
      calendar: "2022/2023",
      transactionReference: "1058892911430",
      status: "Paid",
    },
    {
      id: 2,
      date: "02 - 10 - 2023",
      reportRef: "1058892911430",
      paidAmount: "835,000",
      feeTotal: "273,000",
      studentName: "Usman Garuba",
      studentNo: "001232",
      calendar: "2022/2023",
      transactionReference: "1058892911430",
      status: "Unpaid",
    },
    {
      id: 3,
      date: "02 - 10 - 2023",
      reportRef: "1058892911430",
      paidAmount: "835,000",
      feeTotal: "235,000",
      studentName: "Kenneth Ugbechi",
      studentNo: "001232",
      calendar: "2022/2023",
      transactionReference: "1058892911430",
      status: "Paid",
    },
  ];

  // if (reportsLoading) {
  //     return <OnboardingLoader />;
  // }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              marginTop: "30px",
              // marginBottom: "10px",
            }}
            onClick={() => navigate(-1)}
          >
            <img src={backArrow} alt="back arrow" />
            <h5 style={{ marginBottom: 0 }}>
              Report History ({selectedReportTitle})
            </h5>
          </div>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <TableComponent
              data={reportHistory}
              title=""
              columns={columns}
              hasAuthority={userAuthorizedPages?.find(
                (page) => page?.action === "ADMIN_VIEW_REPORTS"
              )}
            />
          </div>
        </>
      </Container>
    </Container>
  );
};

export default ReportHistoryDetailPage;
