import React from "react";
import { useState, useEffect } from "react";
import EyeActionIcon from "../../../../../assets/svgs/eyeActionIcon.svg";
import successGIF from "../../../../../assets/svgs/successGIF.gif";
import Button from "../../../../../component/button/button";
import { useSelector } from "react-redux";
import {
  useGenerateInvoiceMutation,
  useGetInvoicesQuery,
} from "../../../../../redux/features/institution/studentApiSlice";
import TableComponent from "../../../../../component/table/table";
import InvoicingBranchStudentList from "./invoicingBranchStudentList";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ModalComponent from "../../../../../component/modal/modal";
import { addTrailingCommas } from "../../../../../utils/utils";
import ToastComponent from "../../../../../component/toast/toast";
import { Link, useNavigate } from "react-router-dom";
import ActionIcon from "./actionbutton/ActionIcon";

const InvoicingBatchList = ({ institutionDetails, institutionLogo }) => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const columns = [
    {
      name: "title",
      label: "Invoice Title",
    },
    {
      name: "compulsoryAmount",
      label: "Compulsory Amount",
      options: {
        customBodyRender: (value) => (value ? addTrailingCommas(value) : 0),
      },
    },
    {
      name: "optionalAmount",
      label: "Optional Amount",
      options: {
        customBodyRender: (value) => (value ? addTrailingCommas(value) : 0),
      },
    },
    {
      name: "expectedAmount",
      label: "Expected Amount",
      options: {
        customBodyRender: (value) => (value ? addTrailingCommas(value) : 0),
      },
    },
    { name: "noOfStudents", label: "No of Students" },
    { name: "dueDate", label: "Due Date" },
    {
      name: "generatedBy",
      label: "Generated By",
      options: {
        customBodyRender: (data) =>
          data ? data?.firstName + " " + data?.lastName : "N/A",
      },
    },
    {
      name: "generatedAt",
      label: "Date Generated",
    },
    // { name: "status", label: "Status" },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <ActionIcon
            actionsArr={[
              {
                label: "View",
                action: () => {
                  // setIsInVoiceBranchViewed(!isInVoiceBranchViewed);
                  // setViewedInVoiceBranchDetails(invoices[tableMeta.rowIndex]);
                  navigate(`/invoicing/${invoices[tableMeta.rowIndex].id}`);
                },
              },
              {
                label: "Edit",
                action: () => {
                  handleEditInvoice(invoices[tableMeta.rowIndex]);
                  setViewedInVoiceBranchDetails(invoices[tableMeta.rowIndex]);
                },
              },
            ]}
          />
        ),
      },
    },
  ];

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: invoices,
    refetch,
    isLoading: fetchingInvoices,
  } = useGetInvoicesQuery(instId);


  const [invoiceData, setInvoiceData] = useState(null);
  const [isInVoiceBranchViewed, setIsInVoiceBranchViewed] = useState(false);
  const [invoiceGenerationSuccess, setInvoiceGenerationSuccess] =
    useState(false);
  const [viewedInVoiceBranchDetails, setViewedInVoiceBranchDetails] =
    useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();

  const handleGenerateInvoices = async () => {
    navigate(`/invoicing/createinvoice`);
  };

  const handleEditInvoice = (invoice) => {
    navigate(`/invoicing/createinvoice/${invoice.id}`);
  };

  useEffect(() => {
    if (invoices) {
      refetch();
    }
  }, []);

  if (fetchingInvoices) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <ModalComponent
        showModal={invoiceGenerationSuccess}
        closeModal={() => setInvoiceGenerationSuccess(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>Invoice is being prepared</p>
        </div>
      </ModalComponent>

      <div>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              marginTop: "30px",
            }}
          >
            <h5>Invoicing</h5>

            {userAuthorizedPages?.find(
              (page) => page?.action === "GENERATE_INVOICE"
            ) && (
              <Button onClick={handleGenerateInvoices}>Create Invoice</Button>
            )}
          </div>

          <section
            style={{
              marginTop: "30px",
            }}
          >
            <TableComponent
              data={invoices}
              title=""
              columns={columns}
              pagination={10}
              search={true}
              hasAuthority={userAuthorizedPages?.find(
                (page) => page?.action === "VIEW_INVOICE"
              )}
            />

            <br />
            <br />
          </section>
        </>
      </div>
    </>
  );
};

export default InvoicingBatchList;
