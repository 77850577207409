import { Col, Container, Row } from "react-bootstrap";
import DashboardSidebar from "../dashboardSideBar/sidebar";
import DashboardTopSection from "../dashboardTopSection";
import backArrow from "../../../../assets/svgs/backArrow.svg";
import { useNavigate } from "react-router-dom";
import { useGetAdminMessageRemindersQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import EditIcon from "../../../../assets/svgs/editIcon";
import { useEffect } from "react";

const ViewSentMessages = () => {
  const navigate = useNavigate();
  const {
    data: remindersList,
    isLoading: remindersLoading,
    refetch: refetchReminders,
  } = useGetAdminMessageRemindersQuery();
  console.log(remindersList);

  useEffect(() => {
    if (!remindersList) {
      refetchReminders();
    }
  }, [remindersList]);

  const handleShowEdit = (id) => {
    navigate(`/principal/notifications/messaging/${id}`);
  };

  if (remindersLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div>
      <Container
        fluid
        style={{ display: "flex" }}
        className="dashboardContainer"
      >
        <DashboardSidebar />
        <Container fluid className="bodycontainer">
          <DashboardTopSection />

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              onClick={() => navigate("/principal/notifications/messaging")}
            >
              <img src={backArrow} alt="Go back" />
              <h6 style={{ marginBottom: 0 }}>Sent Messages</h6>
            </div>

            <div
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                height: "100%",
                //display: "flex",
                position: "relative",
                paddingTop: 1 + "rem",
                paddingBottom: 1 + "rem",
                marginBottom: 1.5 + "rem",
              }}
            >
              {remindersList &&
                remindersList?.map(
                  ({
                    id,
                    messageType,
                    messageTitle,
                    messageBody,
                    messageDate,
                    sentBy,
                    sent,
                    selectedInstitutions,
                  }) => (
                    <div
                      style={{
                        border: "2px solid #EDEDED",
                        borderRadius: "8px",
                        gap: "30px",
                        backgroundColor: "#EDEDED",
                        margin: "25px",
                        display: "flex",
                        //width: "100%"
                      }}
                    >
                      <div
                        style={{
                          borderRight: "0px solid #F5F5F5",
                          flexBasis: "50%",
                          padding: "15px 15px",
                          minWidth: "150px",
                          //overflowY: "auto",
                        }}
                      >
                        <p style={{ fontWeight: "600", color: "#F14810" }}>
                          Message Title: <br />
                          <span
                            style={{
                              fontWeight: "400",
                              color: "black",
                              textJustify: "inter-word",
                              textAlignLast: "justify",
                            }}
                          >
                            {messageTitle}
                          </span>
                        </p>
                        <p style={{ fontWeight: "600", color: "#F14810" }}>
                          Message Body: <br />
                          <span
                            style={{
                              fontWeight: "400",
                              color: "black",
                              textJustify: "inter-word",
                              textAlignLast: "justify",
                            }}
                          >
                            {messageBody}
                          </span>
                        </p>
                        <div
                          style={
                            {
                              //borderRadius: "8px",
                              //display: "flex",
                              //position: "relative",
                            }
                          }
                        >
                          <Row>
                            <Col xs={6} md={6} lg={6} sm={6}>
                              <p
                                style={{ fontWeight: "600", color: "#F14810" }}
                              >
                                Message Type: <br />
                                <span
                                  style={{
                                    fontWeight: "400",
                                    color: "black",
                                    textJustify: "inter-word",
                                    textAlignLast: "justify",
                                  }}
                                >
                                  {messageType}
                                </span>
                              </p>
                            </Col>
                            <Col xs={6} md={6} lg={6} sm={6}>
                              <p
                                style={{ fontWeight: "600", color: "#F14810" }}
                              >
                                Message Date: <br />
                                <span
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  {messageDate}
                                </span>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div
                        style={{
                          flexBasis: "50%",
                          padding: "15px 15px",
                          minWidth: "150px",
                          overflowY: "auto",
                        }}
                      >
                        <p style={{ fontWeight: "600", color: "#F14810" }}>
                          Message Summary: <br />
                          <span
                            style={{
                              fontWeight: "400",
                              color: "black",
                              textJustify: "inter-word",
                              textAlignLast: "justify",
                            }}
                          ></span>
                        </p>

                        <Row>
                          <Col xs={5} md={5} lg={5} sm={5}>
                            <p style={{ fontWeight: "600" }}>
                              Message Status: <br />
                              <span
                                style={{
                                  fontWeight: "400",
                                  color: "black",
                                  textJustify: "inter-word",
                                  textAlignLast: "justify",
                                }}
                              >
                                {sent ? "Sent" : "Not Sent"}
                              </span>
                            </p>
                          </Col>
                          <Col xs={5} md={5} lg={5} sm={5}>
                            <p style={{ fontWeight: "600" }}>
                              Sent By: <br />
                              <span
                                style={{ fontWeight: "400", color: "black" }}
                              >
                                {sentBy?.firstName + " " + sentBy?.lastName}
                              </span>
                            </p>
                          </Col>
                          <Col>
                            {!sent && (
                              <div style={{ display: "flex", gap: "3px" }}>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "35px",
                                    width: "35px",
                                    borderRadius: "100%",
                                    backgroundColor: "#E7E7E7",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShowEdit(id)}
                                >
                                  <EditIcon />
                                </span>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <div>
                            <p style={{ fontWeight: "600", color: "#F14810" }}>
                              Selected Schools: <br />
                              {/* <span style={{ fontWeight: "400", color: "black", textJustify: 'inter-word', textAlignLast: 'justify' }}>

                                                    </span> */}
                            </p>
                            {selectedInstitutions?.length !== 0 &&
                              selectedInstitutions?.map(
                                ({ institutionName }) => (
                                  <span
                                    //onClick={() => setActivePage("Edit Account")}
                                    style={{
                                      // backgroundColor: `${activePage === "Edit Account" ? "#ffede8" : "#f4f4f4"
                                      //     }`,
                                      // border: `1px solid ${activePage === "Edit Account" ? "#f89a7c" : "#dbdbdb"
                                      //     }`,
                                      backgroundColor: "#848884",
                                      border: "#848884",
                                      marginRight: "10px",
                                      padding: "6px 10px",
                                      borderRadius: "20px",
                                      color: "#fff",
                                      fontSize: "10px",
                                      //cursor: "pointer",
                                      //fontWeight: "500",
                                    }}
                                  >
                                    {institutionName}
                                  </span>
                                )
                              )}
                          </div>
                        </Row>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>

          <br />
          <br />
        </Container>
      </Container>
    </div>
  );
};

export default ViewSentMessages;
