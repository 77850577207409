import { useState, useEffect } from "react";
import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import DashboardTopSection from "../dashboardTopSection";
import DashboardSidebar from "../dashboardSideBar/sidebar";
import TableComponent from "../../../../component/table/table";
import Button from "../../../../component/button/button";
import { addTrailingCommas } from "../../../../utils/utils";
import { useGetSuperReportSummaryQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import { useSelector } from "react-redux";
import backArrow from "../../../../assets/svgs/backArrow.svg";
import { useParams, useNavigate } from "react-router-dom";
import { useGetInstitutionListQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
import { useGetInstitutionDetailQuery } from "../../../../redux/features/superAdmin/adminApiSlice";

const ReportingTypes = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const navigate = useNavigate();
  const { institutionId } = useParams();
  const { data: institution, isLoading } =
    useGetInstitutionDetailQuery(institutionId);

  console.log(institution);

  const reportTypes = [
    {
      title: "Payment History",
      url: "payments",
    },
    {
      title: "Wallet Transactions",
      url: "wallet",
    },
  ];

  if (isLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="schools-page no-scrollbar">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          <img src={backArrow} alt="back arrow" />
          <h6 style={{ marginBottom: "0px" }}>
            {institution?.institutionName}
          </h6>
        </div>

        <div style={{ display: "flex", gap: "30px" }}>
          {reportTypes.map(({ url, title }, i) => (
            <div
              className="reportGroupsidecard-x1"
              style={{ cursor: "pointer", width: "100%", maxWidth: "320px" }}
              key={i}
              onClick={() =>
                navigate(`/principal/reporting/${url}/${institutionId}`)
              }
            >
              <div className="card-inner-value">
                <h6
                  style={{
                    marginTop: 1.2 + "rem",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  {title}
                </h6>
              </div>
            </div>
          ))}
        </div>

        <br />
        <br />
      </div>
    </div>
  );
};

export default ReportingTypes;
