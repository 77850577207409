import { Container, Offcanvas, Form } from "react-bootstrap";

import { useSelector } from "react-redux";
import { useState } from "react";
import DashboardSidebar from "../dashboardSideBar/sidebar"
import DashboardTopSection from "../dashboardTopSection/index";

import menuIcon from "../../../../assets/svgs/menuIcon.svg";
import Notifications from "./components/notification";
import SettlementInterval from "./components/settlementInterval";
import DocumentPage from "./components/document";
import SettlementPage from "./components/settlement/settlement";
import PermissionPage from "./components/permission";
import RolesPage from "./components/roles/roles";
import ReminderChargesPage from "./components/remindercharges/remindercharges";


const settingsStages = [
  { stage: "Notification" },
  { stage: "Processing Fee" },
  { stage: "Settlement Interval" },
  { stage: "Reminder Charges" },
  { stage: "Document" },
  { stage: "Permission" },
  { stage: "Roles" },
];

const SuperAdminSettingsPage = () => {
  const [activeStage, setActiveStage] = useState("Notification");
  const [showMenu, setShowMenu] = useState(false);

  console.log(showMenu);

  const handleSetStage = (stage) => {
    setActiveStage(stage);
    setShowMenu(false);
  };


  // if (detailsLoading) {
  //   return <OnboardingLoader />;
  // }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <section style={{ height: "80%" }}>
          <h5>Settings</h5>
          <section
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              height: "100%",
              display: "flex",
              position: "relative",
            }}
          >
            <div
              style={{
                borderRight: "1px solid #F5F5F5",
                flexBasis: "20%",
                padding: "30px 15px",
                minWidth: "150px",
                overflowY: "auto",
              }}
              className="settingsMenu"
            >
              {settingsStages.map(({ stage }, i) => (
                <div
                  key={i}
                  style={{
                    borderRadius: "8px",
                    padding: "8px",
                    marginBottom: "10px",
                    cursor: "pointer",
                    border: `${activeStage === stage
                      ? "1px solid #F14810"
                      : "1px solid #F5F5F5"
                      }`,
                    backgroundColor: `${activeStage === stage ? "#ffe6de" : "#e8e8e8"
                      }`,
                  }}
                  onClick={() => setActiveStage(stage)}
                >
                  <p style={{ marginBottom: 0 }}>{stage}</p>
                </div>
              ))}
            </div>
            <div
              style={{
                flexBasis: "80%",
                flexGrow: 1,
                padding: "15px 30px 30px",
                overflowY: "auto",
              }}
              className="right-box-settings"
            >
              <img
                src={menuIcon}
                alt="menu icon"
                onClick={() => setShowMenu(true)}
                className="menuIcon"
                style={{ marginBottom: "10px", cursor: "pointer" }}
              />

              {activeStage === "Notification" && <Notifications />}
              {activeStage === "Processing Fee" && <SettlementPage />}
              {activeStage === "Settlement Interval" && <SettlementInterval />}
              {activeStage === "Reminder Charges" && <ReminderChargesPage />}
              {activeStage === "Document" && <DocumentPage />}
              {/* {activeStage === "Permission" && <PermissionPage />} */}
              {activeStage === "Roles" && <RolesPage />}
            </div>

            {/* offcanvas for mobile menu */}
            <Offcanvas
              show={showMenu}
              onHide={() => setShowMenu(false)}
              placement={"start"}
              backdrop={true}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                width: "100%",
                maxWidth: "150px",
                height: "100%",
              }}
            >
              <Offcanvas.Body style={{ padding: "20px 0" }}>
                <div
                  style={{
                    borderRight: "1px solid #F5F5F5",
                    flexBasis: "20%",
                    padding: "30px 15px",
                    minWidth: "150px",
                    overflowY: "auto",
                  }}
                >
                  {settingsStages.map(({ stage }, i) => (
                    <div
                      key={i}
                      style={{
                        borderRadius: "8px",
                        padding: "8px",
                        marginBottom: "10px",
                        cursor: "pointer",
                        border: `${activeStage === stage
                          ? "1px solid #F14810"
                          : "1px solid #F5F5F5"
                          }`,
                        backgroundColor: `${activeStage === stage ? "#ffe6de" : "#e8e8e8"
                          }`,
                      }}
                      onClick={() => handleSetStage(stage)}
                    >
                      <p style={{ marginBottom: 0 }}>{stage}</p>
                    </div>
                  ))}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </section>
        </section>

        
      </Container>
    </Container>
  );
};

export default SuperAdminSettingsPage;
