import { useState, useEffect } from "react";
import eyeActionIcon from "../../../../../../../assets/svgs/eyeActionIcon.svg";
import DashboardTopSection from "../../../../dashboardTopSection";
import DashboardSidebar from "../../../../dashboardSideBar/sidebar";
import TableComponent from "../../../../../../../component/table/table";
import Button from "../../../../../../../component/button/button";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import backArrow from "../../../../../../../assets/svgs/backArrow.svg";
import { storeSelectedReportTitle } from "../../../../../../../redux/features/institution/institutionSlice";
// import { addTrailingCommas } from "../../../../utils/utils";
// import { useGetSuperReportSummaryQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
// import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";

const ReportHistoryPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    { name: "id", label: "ID" },
    { name: "reportTitle", label: "Report Title" },
    { name: "reportType", label: "Report Type" },
    { name: "date", label: "Date" },
    { name: "interval", label: "Interval" },
    { name: "createdBy", label: "Created By" },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <img
            src={eyeActionIcon}
            alt="eye icon"
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewReportDetail(`${reports[tableMeta.rowIndex].id}`)
            }
          />
        ),
      },
    },
  ];

  const reports = [
    {
      id: 1,
      reportTitle: "Report on School Fee Payment",
      reportType: "Settlement",
      date: "02 - 10 - 2023",
      interval: "2 Days",
      createdBy: "Bankole Johnson",
    },
    {
      id: 2,
      reportTitle: "Report on School Fee Payment",
      reportType: "Payment Report",
      date: "03 - 10 - 2023",
      interval: "2 Days",
      createdBy: "Usman Garuba",
    },
    {
      id: 3,
      reportTitle: "Report on School Fee Payment",
      reportType: "Settlement",
      date: "04 - 10 - 2023",
      interval: "2 Days",
      createdBy: "Kenneth Ugbechi",
    },
  ];

  const handleViewReportDetail = (id, reportTitle) => {
    dispatch(storeSelectedReportTitle(reportTitle));
    navigate(`/principal/reporting/advanceReport/reports/${id}`);
  };

  // if (isLoading) {
  //     return <OnboardingLoader />
  // }

  return (
    <div className="schools-page no-scrollbar">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        <section
          style={{
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              gap: "5px",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            <img src={backArrow} alt="back arrow" />
            <h6 style={{ marginBottom: "0px" }}>Reports</h6>
          </div>
        </section>

        <TableComponent
          data={reports}
          title=""
          columns={columns}
          hasAuthority={userAuthorizedPages?.find(
            (page) => page?.action === "ADMIN_VIEW_REPORTS"
          )}
        />
      </div>
    </div>
  );
};

export default ReportHistoryPage;
