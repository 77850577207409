import { useState, useEffect } from "react";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";
import { useSelector } from "react-redux";
import { useGetSettlementHistoryQuery } from "../../../../../../redux/features/institution/institutionApiSlice";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import TableComponent from "../../../../../../component/table/table";
import { addTrailingCommas } from "../../../../../../utils/utils";

const SettlementPage = ({ setIsHistoryViewed }) => {
  const [settlementsToShow, setSettlementsToShow] = useState([]);
  const [showAllSettlements, setShowAllSettlements] = useState(false);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const { data: settlementHistory, isLoading } = useGetSettlementHistoryQuery(
    instId,
    {
      skip: !instId,
    }
  );

  // Toggle between showing all and only successful settlements
  const toggleSettlementView = () => {
    setShowAllSettlements((prevState) => !prevState);
  };

  // Update table data based on the toggle state
  useEffect(() => {
    if (settlementHistory) {
      const settlements = settlementHistory?.settlements || [];
      setSettlementsToShow(
        showAllSettlements
          ? settlements
          : settlements.filter((settlement) => settlement?.status === "SUCCESS")
      );
    }
  }, [settlementHistory, showAllSettlements]);

  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        customBodyRender: (date) => (date ? date?.split("T")[0] : "N/A"),
      },
    },
    { name: "type", label: "Settlement Type" },
    {
      name: "amount",
      label: "Amount",
      options: {
        customBodyRender: (amount) =>
          amount ? "₦ " + addTrailingCommas(amount) : 0,
      },
    },
    {
      name: "settlementAmount",
      label: "Settlement Amount",
      options: {
        customBodyRender: (amount) =>
          amount ? "₦ " + addTrailingCommas(amount) : 0,
      },
    },
    {
      name: "commission",
      label: "Processing Fee",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { commission, commissionType } =
            settlementsToShow[tableMeta.rowIndex];
          return `${commissionType}` !== "RATE"
            ? "₦ " + `${commission}`
            : `${commission} %`;
        },
      },
    },
    {
      name: "commissionAmount",
      label: "Processing Fee Amount",
      options: {
        customBodyRender: (amount) =>
          amount ? "₦ " + addTrailingCommas(amount) : 0,
      },
    },
    { name: "bankName", label: "Bank Name" },
    { name: "accountName", label: "Account Name" },
    { name: "accountNumber", label: "Account Number" },
    { name: "status", label: "Status" },
  ];

  if (isLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
        onClick={() => setIsHistoryViewed(false)}
      >
        <img src={backArrow} alt="back arrow" />
        <h5 style={{ marginBottom: 0, fontSize: "14px", fontWeight: 600 }}>
          Institution Account
        </h5>
      </div>

      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <div>
              <h5
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                }}
              >
                ₦ {addTrailingCommas(settlementHistory?.totalSettlementAmount)}
              </h5>
              <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                Total Settlement Amount
              </p>
            </div>
            <div>
              <h5
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                }}
              >
                ₦ {addTrailingCommas(settlementHistory?.totalCommissionAmount)}
              </h5>
              <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                Total Processing Fee Amount
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              cursor: "pointer",
              fontSize: 0.8 + "rem",
              textDecoration: "underline",
            }}
            onClick={toggleSettlementView}
          >
            {showAllSettlements ? "Show Successful Only" : "Show All"}
          </span>
        </div>

        <TableComponent
          data={settlementsToShow || []}
          title="Settlement History"
          columns={columns}
          hasAuthority={userAuthorizedPages?.find(
            (page) => page?.action === "VIEW_SETTLEMENT"
          )}
        />
      </div>
    </>
  );
};

export default SettlementPage;
