import DashboardSidebar from "../../components/dashboardSidebar/sidebar";
import DashboardTopSection from "../../components/dashboardTopSection";
import { Container, Form, Button, Offcanvas } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetInstitutionQuery,
  useGetAllClassesQuery,
  useGetInstitutionPeriodsQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import {
  useGetStudentsByLevelQuery,
  usePromoteStudentsMutation,
  useViewStudentFullDetailsQuery,
} from "../../../../../redux/features/institution/studentApiSlice";
import TableComponent from "../../../../../component/table/table";
import backArrow from "../../../../../assets/svgs/backArrow.svg";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { useEffect, useState } from "react";
import { addTrailingCommas } from "../../../../../utils/utils";
import ButtonX from "../../../../../component/button/button";
import ToastComponent from "../../../../../component/toast/toast";
import ModalComponent from "../../../../../component/modal/modal";
import { color } from "framer-motion";
import ActionIcon from "../../../../../component/actionbutton/ActionIcon";
import StudentFullDetailsPage from "./studentFullDetails";
import StudentInformationStepper from "../../components/students/studentInformation/studentInformationStepper";
import UploadStudentsPage from "../../components/students/studentInformation/components/uploadStudents/uploadStudentsPage";
import SuccessScreen from "../../../../../component/successScreen/successScreen";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import successGIF from "../../../../../assets/svgs/successGIF.gif";

const ClassDetailsPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const { classId } = useParams();
  const navigate = useNavigate();

  const [students, setStudents] = useState([]);
  const [selectedStudentsIds, setSelectedStudentsIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [showCreateStudentModal, setShowCreateStudentModal] = useState(false);

  const [showConfirmPromote, setShowConfirmPromote] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [allClasses, setAllClasses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [askCreateStudentMethod, setAskCreateStudentMethod] = useState(false);
  const [showCreateStudentCanvas, setShowCreateStudentCanvas] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [student, setStudent] = useState({});

  const [isStudentViewed, setIsStudentViewed] = useState(false);
  const [viewedStudentDetails, setViewedStudentDetails] = useState(null);

  const handleCloseModal = () => {
    setShowPromoteModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleCloseStudent = () => {
    setShowCreateStudentCanvas(false);
    setStudent(null);
  };

  const handleShowStudent = () => {
    setShowCreateStudentCanvas(true);
    setShowUploadModal(false);
    setAskCreateStudentMethod(false);
  };

  const handleCloseUpload = () => {
    setShowUploadModal(false);
  };

  const handleShowUpload = () => {
    setShowUploadModal(true);
    setShowCreateStudentCanvas(false);
    setAskCreateStudentMethod(false);
  };

  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const selectedClass = useSelector(
    (state) => state?.institution?.selectedClassName
  );

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(id);

  const {
    data: levelStudents,
    isLoading: studentsLoading,
    refetch: refetchLevelStudents,
  } = useGetStudentsByLevelQuery(classId, {
    skip: !classId,
  });

  console.log(students);

  const { data: classes, isLoading: classesLoading } = useGetAllClassesQuery(
    id,
    {
      skip: !levelStudents,
    }
  );

  // const { data: calendars, isLoading: calendarsLoading } =
  //   useGetInstitutionPeriodsQuery(id, {
  //     skip: !classes,
  //   });

  // console.log(calendars)

  const [promoteStudents, { isLoading: promoteLoading }] =
    usePromoteStudentsMutation();

  const columns = [
    // { name: "id", label: "ID" },
    {
      name: "firstname",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstname, middlename, lastname } =
            students[tableMeta.rowIndex];
          return `${firstname} ${middlename ? middlename : ""} ${lastname}`;
        },
      },
    },
    { name: "gender", label: "Gender" },
    { name: "studentNo", label: "Student No" },
    { name: "nuban", label: "Nuban" },
    { name: "bankName", label: "Bank Name" },
    {
      name: "walletBalance",
      label: "Wallet Balance",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { walletBalance } = students[tableMeta.rowIndex];
          return `${"₦ " + addTrailingCommas(walletBalance)}`;
        },
      },
    },
    {
      name: "parent",
      label: "Parent Name",
      options: {
        customBodyRender: (parent) =>
          parent && parent.name ? parent.name : "N/A",
      },
    },
    {
      name: "parent",
      label: "Parent Email",
      options: {
        customBodyRender: (parent) =>
          parent && parent.email ? parent.email : "N/A",
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <ActionIcon
            actionsArr={[
              {
                label: "View",
                // action: () => handleDeleteStudent(students[tableMeta.rowIndex]),
                action: () => {
                  setIsStudentViewed(!isStudentViewed);
                  setViewedStudentDetails(students[tableMeta.rowIndex]);
                },
              },
            ]}
          />
        ),
      },
    },
  ];

  useEffect(() => {
    if (classes) {
      const allCategories = Object.keys(classes)
        .map((key) => {
          return classes[key].map((eachClass) => {
            return eachClass.categories.map((category) => {
              return {
                name: `${eachClass.name + " - " + category.name}`,
                id: category.id,
              };
            });
          });
        })
        .flat();

      console.log(allCategories.flat());
      setAllClasses(allCategories.flat());
    }
  }, [classes]);

  useEffect(() => {
    setStudents(levelStudents);
  }, [levelStudents]);

  const handleSelectedRows = (selectedRowsData) => {
    setSelectedRows(selectedRowsData);
  };

  console.log(selectedRows);

  useEffect(() => {
    // get all the ids of the items selected
    const selectedStudentsIds = selectedRows.map((row) => row.id);
    console.log(selectedStudentsIds);
    setSelectedStudentsIds(selectedStudentsIds);
  }, [selectedRows]);

  const [newPromotionData, setNewPromotionData] = useState({
    categoryId: "",
    // calendarId: "",
    studentIds: "",
  });

  const { categoryId, studentIds } = newPromotionData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setNewPromotionData({ ...newPromotionData, [name]: value });
  };

  //  const querys = {
  //    institutionId: id,
  //    studentId: studentDetails?.id,
  //  };

  //  const {
  //    data: studentInfo,
  //    refetch: refetchDetails,
  //    isLoading: studentInfoLoading,
  //  } = useViewStudentFullDetailsQuery(querys);

  const handlePromote = async () => {
    try {
      const params = {
        institutionId: id,
        body: {
          categoryId: categoryId,
          // calendarId: newPromotionData.calendarId,
          studentIds: selectedRows?.map((row) => row.id),
        },
      };

      console.log(params);

      const res = await promoteStudents(params).unwrap();

      if (res) {
        console.log(res);
        setShowSuccessModal(true);
        setShowPromoteModal(false);
        setShowConfirmPromote(false);
        refetchLevelStudents();
        setSelectedRows = [];
      }
    } catch (error) {
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  useEffect(() => {
    if (levelStudents) {
      refetchLevelStudents();
    }
  }, [
    showPromoteModal,
    showSuccessModal,
    askCreateStudentMethod,
    showCreateStudentCanvas,
  ]);

  if (
    detailsLoading ||
    studentsLoading ||
    promoteLoading
    // ||
    // calendarsLoading
  ) {
    return <OnboardingLoader />;
  }

  // if (promoteSuccess) {
  //   return (
  //     <SuccessScreen successMessage="Students promoted successfully!" />
  //   )
  // }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        {isStudentViewed ? (
          <StudentFullDetailsPage
            studentDetails={viewedStudentDetails}
            setIsStudentViewed={setIsStudentViewed}
          />
        ) : (
          <>
            <ToastComponent
              showToast={showToast}
              setShowToast={setShowToast}
              toastMessage={toastMessage}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <div
                className="back-arrow"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  float: "left",
                }}
                onClick={() => navigate("/classes")}
              >
                <img src={backArrow} alt="Go back" />
                <h6
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {selectedClass}
                </h6>
              </div>

              <div style={{ display: "flex", gap: "5px", float: "right" }}>
                {userAuthorizedPages?.find(
                  (page) => page?.action === "PROMOTE_STUDENT"
                ) && (
                  <ButtonX
                    onClick={
                      !selectedRows || selectedRows?.length === 0
                        ? null
                        : () => setShowPromoteModal(true)
                    }
                    backgroundColor={`${
                      !selectedRows || selectedRows?.length === 0
                        ? "#FFCAB8"
                        : "#F14810"
                    }`}
                    overlay={true}
                    overlayText="Select student(s) to promote then click this button to promote"
                  >
                    Promote
                  </ButtonX>
                )}

                {userAuthorizedPages?.find(
                  (page) => page?.action === "CREATE_STUDENT"
                ) && (
                  <ButtonX
                    backgroundColor="#FFF"
                    border="#f14810"
                    color="#f14810"
                    onClick={() => setAskCreateStudentMethod(true)}
                  >
                    Create Students
                  </ButtonX>
                )}
              </div>
            </div>

            <section
              style={{
                marginTop: "20px",
              }}
            >
              <TableComponent
                data={students}
                title=""
                selectableRows="true"
                onRowSelectionChange={handleSelectedRows}
                columns={columns}
                search={true}
                hasAuthority={userAuthorizedPages?.find(
                  (page) => page?.action === "VIEW_CLASS"
                )}
              />

              <br />
              <br />
            </section>

            {/* Promote Modal */}
            <ModalComponent
              title={<h6 style={{ color: "#F14810" }}>Promote Students</h6>}
              showModal={showPromoteModal}
              closeModal={handleCloseModal}
            >
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">Class</Form.Label>

                  <Form.Select
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    name="categoryId"
                    value={categoryId}
                    onChange={handleOnChange}
                  >
                    <option value="" defaultValue disabled>
                      Select Class
                    </option>
                    {allClasses?.map(({ id, name }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <div style={{ marginTop: "30px" }}>
                  <Button
                    onClick={(e) => setShowConfirmPromote(true)}
                    variant="primary"
                    style={{
                      backgroundColor: "#F14810",
                      borderColor: "#F14810",
                      float: "left",
                    }}
                    className="button"
                    size="md"
                  >
                    <span
                      style={{
                        fontSize: 0.8 + "rem",
                        marginLeft: 0.5 + "rem",
                        marginRight: 0.5 + "rem",
                      }}
                    >
                      Save
                    </span>
                  </Button>
                </div>
              </Form>
            </ModalComponent>

            {/* promote confirmation modal */}
            <ModalComponent
              showModal={showConfirmPromote}
              closeModal={() => setShowConfirmPromote(false)}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={alertIcon}
                  alt="alert Icon"
                  style={{ margin: "0 auto" }}
                />
                <p
                  style={{
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "500",
                    fontSize: 1.0 + "rem",
                  }}
                >
                  Are you sure you want to promote the selected student(s)?{" "}
                </p>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    gap: "10px",
                    marginLeft: "30%",
                  }}
                >
                  <Button
                    onClick={() => setShowConfirmPromote(false)}
                    type="button"
                    variant="outline-primary"
                    style={{ borderColor: "#F14810", color: "#F14810" }}
                    className="button"
                    size="md"
                  >
                    <span
                      style={{
                        fontSize: 0.8 + "rem",
                        marginLeft: 0.5 + "rem",
                        marginRight: 0.5 + "rem",
                      }}
                    >
                      No
                    </span>
                  </Button>
                  <Button
                    onClick={() => handlePromote()}
                    type="button"
                    variant="primary"
                    style={{
                      backgroundColor: "#F14810",
                      borderColor: "#F14810",
                    }}
                    className="button"
                    size="md"
                  >
                    <span
                      style={{
                        fontSize: 0.8 + "rem",
                        marginLeft: 0.5 + "rem",
                        marginRight: 0.5 + "rem",
                      }}
                    >
                      Yes
                    </span>
                  </Button>
                </div>
              </div>
            </ModalComponent>

            {/* Promote Success Modal */}
            <ModalComponent
              showModal={showSuccessModal}
              closeModal={handleCloseSuccessModal}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={successGIF}
                  alt="success Gif"
                  width="150px"
                  style={{ margin: "0 auto" }}
                />
                <h3 style={{ color: "#F14810", fontWeight: "600" }}>
                  Successful!!!
                </h3>
                <p style={{ fontSize: "18px" }}>
                  Students promoted successfully!
                </p>
              </div>
            </ModalComponent>

            {/* prompt for create student */}
            <ModalComponent
              showModal={askCreateStudentMethod}
              closeModal={() => setAskCreateStudentMethod(false)}
            >
              <div style={{ padding: "15px" }}>
                <h5
                  style={{
                    textAlign: "center",
                    color: "#626262",
                    fontSize: "25px",
                  }}
                >
                  Create Students
                </h5>
                <p style={{ textAlign: "center" }}>
                  Creating students can be done in 2 ways; You can either click
                  on "Upload Students" button to add existing students record to
                  the platform OR click on "Create Students" button to add a new
                  student record.{" "}
                </p>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <ButtonX
                    backgroundColor="#f14810"
                    border="#f14810"
                    color="#fff"
                    onClick={handleShowUpload}
                  >
                    Upload Students
                  </ButtonX>
                  <ButtonX
                    backgroundColor="#fff"
                    border="#f14810"
                    color="#f14810"
                    onClick={handleShowStudent}
                  >
                    Create Students
                  </ButtonX>
                </div>
              </div>
            </ModalComponent>

            {/* Register Student Canvas */}
            <Offcanvas
              show={showCreateStudentCanvas}
              onHide={handleCloseStudent}
              placement={"end"}
              backdrop={true}
              style={{ width: "100%", maxWidth: "500px" }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  {student ? "Edit Student Details" : "Create Student"}
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                {/* <StudentInformationPage /> */}
                <StudentInformationStepper
                  student={student}
                  closeCanvas={handleCloseStudent}
                />
              </Offcanvas.Body>
            </Offcanvas>

            {/* Upload Canvas */}
            <Offcanvas
              show={showUploadModal}
              onHide={handleCloseUpload}
              placement={"end"}
              backdrop={true}
              style={{ width: "100%", maxWidth: "500px" }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Upload Students</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <UploadStudentsPage showUploadModal={showUploadModal} />
              </Offcanvas.Body>
            </Offcanvas>
          </>
        )}
      </Container>
    </Container>
  );
};

export default ClassDetailsPage;
