import React, { useState, useEffect } from "react";
import { Table, Button, Form } from "react-bootstrap";
import ActionIcon from "../actionbutton/ActionIcon";
import { useSelector } from "react-redux";
import ModalComponent from "../modal/modal";
import OnboardingLoader from "../onboardingLoader/onboardingLoader";
import {
  storeCategoryToManage,
  storeCategoryToDelete,
} from "../../redux/features/institution/institutionSlice";
import {
  useGetAllCategoriesInAClassQuery,
  useGetAllTeachersQuery,
  useAssignTeacherMutation,
  useCreateTeacherMutation,
  useGetAllClassesQuery,
  useDeleteCategoryMutation,
  useDeleteClassMutation,
  useUpdateClassMutation,
} from "../../redux/features/institution/institutionApiSlice";
import dropdownIcon from "../../assets/svgs/dropdownIcon.svg";
import alertIcon from "../../assets/svgs/alertIcon.svg";
import successGIF from "../../assets/svgs/successGIF.gif";
import ToastComponent from "../toast/toast";

const ExpandableClassTable = ({
  data,
  nameOfClass,
  editPermission = false,
  deletePermission = false,
}) => {
  const [expandedRows, setExpandedRows] = useState([]);

  const handleToggleRow = (row) => {
    if (expandedRows.includes(row)) {
      setExpandedRows(
        expandedRows.filter((expandedRow) => expandedRow !== row)
      );
    } else {
      setExpandedRows([...expandedRows, row]);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmDeleteCategory, setShowConfirmDeleteCategory] =
    useState(false);
  const [showConfirmDeleteClass, setShowConfirmDeleteClass] = useState(false);
  const [showEditClassModal, setShowEditClassModal] = useState(false);
  const [teacherCreated, setTeacherCreated] = useState(null);
  const [teacherAssigned, setTeacherAssigned] = useState(null);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [categories, setCategories] = useState([]);
  const [className, setClassName] = useState("");
  const [classToManage, setClassToManage] = useState(null);
  const [classToDelete, setClassToDelete] = useState(null);
  const [classToEdit, setClassToEdit] = useState(null);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  console.log(data);

  const {
    data: classes,
    isLoading: classesLoading,
    refetch: refetchClasses,
  } = useGetAllClassesQuery(instId);

  const {
    data: teachers,
    isLoading: teachersLoading,
    refetch: refetchTeachers,
  } = useGetAllTeachersQuery(instId);

  const [createTeacher, { isLoading: createLoading }] =
    useCreateTeacherMutation();
  const [assignTeacher, { isLoading: assignLoading }] =
    useAssignTeacherMutation();
  const [deleteCategory, { isLoading: deleteLoading }] =
    useDeleteCategoryMutation();
  const [deleteClass, { isLoading: deleteClassLoading }] =
    useDeleteClassMutation();
  const [updateClass, { isLoading: updateClassLoading }] =
    useUpdateClassMutation();

  const [assignInfo, setAssignInfo] = useState({
    categoryId: "",
    categoryName: "",
    teacherId: "",
  });
  const { categoryId, teacherId, categoryName } = assignInfo;

  const handleOnAssignChange = (e) => {
    const { name, value } = e.target;
    setAssignInfo({ ...assignInfo, [name]: value });
  };

  const [teacherInfo, setTeacherInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const { firstName, lastName, email, phoneNumber } = teacherInfo;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setTeacherInfo({ ...teacherInfo, [name]: value });
  };

  const handleCreateTeacher = async () => {
    const parameters = {
      institutionId: instId,
      body: {
        ...teacherInfo,
      },
    };
    try {
      const res = await createTeacher(parameters).unwrap();
      console.log(res);

      // assign teacher immediately
      const assignResponse = await assignTeacher({
        categoryId: classToManage?.id,
        body: {
          teacherId: res?.id,
        },
      }).unwrap();

      // setTeacherCreated(res);
      // setShowSuccessModal(true);

      setShowCreateModal(false);
      refetchClasses();
      refetchTeachers();
      setShowModal(false);
      setShowSuccessModal(true);
      setTeacherInfo({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  const handleAssignTeacher = async () => {
    const parameters = {
      categoryId: categoryId,
      body: {
        teacherId,
      },
    };
    try {
      const res = await assignTeacher(parameters).unwrap();
      console.log(res);
      setTeacherAssigned(res);
      refetchClasses();
      refetchTeachers();
      setShowModal(false);
      setShowSuccessModal(true);
      setAssignInfo({
        categoryId: "",
        categoryName: "",
        teacherId: "",
      });
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        setToastMessage(error.error);
      }
    }
  };

  const handleDeleteCategory = async () => {
    setShowConfirmDeleteCategory(false);

    try {
      const parameters = {
        categoryId: categoryToDelete?.id,
        body: { name: categoryToDelete?.name },
      };
      await deleteCategory(parameters).unwrap();
      setElementToDelete(categoryToDelete.id);
      setShowToast(true);
      setToastMessage("Category Deleted");
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleDeleteClass = async () => {
    setShowConfirmDeleteClass(false);

    try {
      const parameters = {
        classId: classToDelete?.id,
        body: {
          name: classToDelete?.name,
          institutionTypeId: classToDelete?.institutionType?.id,
        },
      };
      await deleteClass(parameters).unwrap();
      setElementToDelete(classToDelete?.id);
      setShowToast(true);
      setToastMessage("Class Deleted Successfully");
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleUpdateClass = async () => {
    setShowEditClassModal(false);

    try {
      const parameters = {
        classId: classToEdit?.id,
        institutionId: instId,
        body: {
          name: className,
          id: classToEdit?.id,
          categories: categories?.map((cat) => {
            return { ...cat, levelId: classToEdit?.id };
          }),
        },
      };

      await updateClass(parameters).unwrap();
      setShowToast(true);
      setToastMessage("Class Edited Successfully");
      refetchClasses();
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  console.log(categories);

  const addMoreCategory = () => {
    setCategories([
      ...categories,
      {
        name: "",
      },
    ]);
  };

  const handleRemoveCategory = (index) => {
    // setCategories(categories.pop(categories[index]))

    setCategories(categories?.filter((cat, i) => i !== index));
  };

  console.log(categories);

  useEffect(() => {
    if (classes) {
      refetchClasses();
    }
  }, [teacherAssigned, elementToDelete]);

  useEffect(() => {
    if (classToManage) {
      setShowModal(true);
      setAssignInfo({
        categoryId: classToManage?.id,
        categoryName: classToManage?.name,
        teacherId: classToManage?.teacher?.id,
      });
    }
  }, [classToManage]);

  useEffect(() => {
    if (classToEdit) {
      setShowEditClassModal(true);
      setCategories(classToEdit?.categories);
      setClassName(classToEdit?.name);
    }
  }, [classToEdit]);

  console.log(classToEdit);
  console.log(categories);

  useEffect(() => {
    if (teachers) {
      refetchTeachers();
    }
  }, [teacherCreated]);

  if (
    teachersLoading ||
    createLoading ||
    assignLoading ||
    deleteLoading ||
    deleteClassLoading ||
    updateClassLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <>
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <h3 style={{ fontSize: "16px" }}>{nameOfClass}</h3>

      {data.length > 0 ? (
        <Table striped bordered>
          <thead>
            <tr>
              <th align="center">*</th>
              <th align="center">Level Name</th>
              <th align="center">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, i) => (
              <React.Fragment key={row.id}>
                <tr
                  style={{
                    backgroundColor: `${i % 2 === 0 ? "#fff4f1" : "#ffffff"}`,
                  }}
                >
                  <td>
                    <span onClick={() => handleToggleRow(row)}>
                      {expandedRows.includes(row) ? (
                        <img src={dropdownIcon} alt="dropdown icon" />
                      ) : (
                        <img
                          src={dropdownIcon}
                          alt="dropdown icon"
                          style={{ transform: "rotate(270deg)" }}
                        />
                      )}
                    </span>
                  </td>
                  <td>{row.name}</td>
                  <td style={{ transform: "rotate" }}>
                    <ActionIcon
                      actionsArr={[
                        {
                          label: "Edit",
                          action: (e) => {
                            setClassToEdit({
                              ...row,
                            });
                          },
                          isDisabled: !editPermission,
                        },
                        {
                          label: "Delete",
                          action: (e) => {
                            setClassToDelete({
                              ...row,
                            });
                            setShowConfirmDeleteClass(true);
                          },
                          isDisabled: !deletePermission,
                        },
                      ]}
                    />
                  </td>
                </tr>

                {expandedRows.includes(row) && (
                  <>
                    <tr>
                      <th align="center" style={{ fontSize: "14px" }}>
                        Category Name
                      </th>
                      <th align="center" style={{ fontSize: "14px" }}>
                        Assigned Teacher
                      </th>
                      <th align="center" style={{ fontSize: "14px" }}>
                        Action
                      </th>
                    </tr>
                    {row?.categories?.map(({ name, teacher, id }, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: `${
                            i % 2 === 0 ? "#dfdfdf" : "#ffffff"
                          }`,
                        }}
                      >
                        <td>{name}</td>
                        <td>
                          {teacher
                            ? teacher?.firstName + " " + teacher?.lastName
                            : "N/A"}
                        </td>
                        <td>
                          <ActionIcon
                            style={{ transform: "rotate(90deg)" }}
                            actionsArr={[
                              {
                                label: "Manage Class",
                                action: () =>
                                  setClassToManage({ name, teacher, id }),
                              },
                              {
                                label: "Delete",
                                action: () => {
                                  setCategoryToDelete({ name, id });
                                  setShowConfirmDeleteCategory(true);
                                },
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      ) : (
        <span style={{ fontStyle: "italic" }}>
          There are no classes under this section
        </span>
      )}

      {/* edit class modal */}
      <ModalComponent
        title="Edit Class"
        showModal={showEditClassModal}
        closeModal={() => setShowEditClassModal(false)}
      >
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="formClasslabel">
              Class Name
              <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
            </Form.Label>

            <Form.Control
              size="md"
              className="custom-focus"
              style={{
                fontFamily: "jost, sans-serif",
                backgroundColor: "#EDEDED",
                fontSize: 0.8 + "rem",
              }}
              type="text"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
              placeholder="Enter Class Name (e.g Primary 1)"
            />
          </Form.Group>

          {categories?.map(({ id, name }, i) => (
            <Form.Group key={i} controlId={`categoryInput${i}`}>
              <Form.Label
                className="formClasslabel"
                style={{ display: "flex", alignItems: "center" }}
              >
                Category {i + 1}
                <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
                <span
                  style={{
                    color: "#F14810",
                    marginLeft: "auto",
                    cursor: "pointer",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                  onClick={() => handleRemoveCategory(i)}
                >
                  delete
                </span>
              </Form.Label>

              <Form.Control
                size="md"
                className="custom-focus"
                style={{
                  fontFamily: "jost, sans-serif",
                  backgroundColor: "#EDEDED",
                  fontSize: 0.8 + "rem",
                  marginBottom: "8px",
                }}
                type="text"
                // name={`categoryInput${i}`}
                value={name}
                onChange={(e) =>
                  setCategories(
                    categories?.map((category) => {
                      if (category.id === id) {
                        return { ...category, name: e.target.value };
                      }
                      return category;
                    })
                  )
                }
                placeholder={`Enter Category Name (e.g A, Gold, Art)`}
              />
            </Form.Group>
          ))}

          <div style={{ marginTop: "10px" }}>
            <span
              style={{
                color: "#F14810",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={addMoreCategory}
            >
              + Add More Category
            </span>
          </div>

          <div style={{ marginTop: "30px" }}>
            <Button
              variant="primary"
              style={{
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                fontSize: "14px",
              }}
              className="button"
              size="md"
              onClick={handleUpdateClass}
            >
              Save Changes
            </Button>
          </div>
        </Form>
      </ModalComponent>

      {/* assign teacher */}
      <ModalComponent
        title="Assign Teacher"
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
          setAssignInfo({});
          setCategoryToEdit(null);
        }}
      >
        <Form>
          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
              marginBottom: "16px",
              cursor: "not-allowed",
            }}
            type="text"
            name="categoryName"
            value={categoryName}
            onChange={(e) => handleOnAssignChange(e)}
            placeholder="Enter Category Name (e.g A, Gold, Art)"
            disabled
          />

          <Form.Label className="formClasslabel">
            Teacher{" "}
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </Form.Label>
          <Form.Select
            size="md"
            className="custom-focus mb-3"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
            }}
            name="teacherId"
            value={teacherId}
            onChange={(e) => handleOnAssignChange(e)}
            required
          >
            <option value="" disabled selected>
              Select Teacher
            </option>
            {teachers?.map(({ id, firstName, lastName }, i) => (
              <option
                key={id}
                value={id}
                selected={id === classToManage?.teacher?.id}
              >
                {firstName + " " + lastName}
              </option>
            ))}
          </Form.Select>

          <div>
            <span
              style={{
                color: "#F14810",
                fontWeight: "500",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowModal(false);
                setShowCreateModal(true);
              }}
            >
              + Create New Teacher
            </span>
          </div>

          <Button
            onClick={handleAssignTeacher}
            variant="primary"
            style={{
              backgroundColor: `${
                !categoryId || !teacherId ? "#FFCAB8" : "#F14810"
              }`,
              borderColor: `${
                !categoryId || !teacherId ? "#FFCAB8" : "#F14810"
              }`,
              cursor: `${
                !categoryId || !teacherId ? "not-allowed" : "pointer"
              }`,
              padding: "6px 18px",
              fontSize: "14px",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            Save
          </Button>
        </Form>
      </ModalComponent>

      {/* create teacher */}
      <ModalComponent
        title="Create New Teacher"
        showModal={showCreateModal}
        closeModal={() => {
          setShowModal(true);
          setShowCreateModal(false);
        }}
      >
        <Form>
          <Form.Label className="formClasslabel">
            First Name
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
              marginBottom: "7px",
            }}
            type="text"
            name="firstName"
            value={firstName}
            onChange={(e) => handleOnChange(e)}
            placeholder="Enter Teacher First Name"
          />

          <Form.Label className="formClasslabel">
            Last Name
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
              marginBottom: "7px",
            }}
            type="text"
            name="lastName"
            value={lastName}
            onChange={(e) => handleOnChange(e)}
            placeholder="Enter Teacher Last Name"
          />

          <Form.Label className="formClasslabel">
            Email
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
              marginBottom: "7px",
            }}
            type="email"
            name="email"
            value={email}
            onChange={(e) => handleOnChange(e)}
            placeholder="Enter Teacher Email"
          />

          <Form.Label className="formClasslabel">
            Phone Number
            <span style={{ color: "#FD0E0E", fontSize: "18px" }}>*</span>
          </Form.Label>

          <Form.Control
            size="md"
            className="custom-focus"
            style={{
              fontFamily: "jost, sans-serif",
              backgroundColor: "#EDEDED",
              fontSize: 0.8 + "rem",
              marginBottom: "7px",
            }}
            type="number"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => handleOnChange(e)}
            placeholder="Enter Teacher Phone Number"
          />

          <Button
            onClick={handleCreateTeacher}
            variant="primary"
            style={{
              backgroundColor: `${
                !firstName || !lastName || !email || !phoneNumber
                  ? "#FFCAB8"
                  : "#F14810"
              }`,
              borderColor: `${
                !firstName || !lastName || !email || !phoneNumber
                  ? "#FFCAB8"
                  : "#F14810"
              }`,
              cursor: `${
                !firstName || !lastName || !email || !phoneNumber
                  ? "not-allowed"
                  : "pointer"
              }`,
              fontSize: "14px",
              padding: "6px 18px",
              marginTop: "20px",
            }}
            className="button"
            size="md"
          >
            Save
          </Button>
        </Form>
      </ModalComponent>

      {/* success modal */}
      <ModalComponent
        showModal={showSuccessModal}
        closeModal={() => {
          setShowSuccessModal(false);
          setTeacherCreated(null);
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            {teacherCreated
              ? "Teacher has been created successfully"
              : "Teacher has been assigned successfully"}
          </p>
        </div>
      </ModalComponent>

      {/* delete confirmation modal */}
      <ModalComponent
        showModal={showConfirmDeleteCategory}
        closeModal={() => {
          setShowConfirmDeleteCategory(false);
          setCategoryToDelete(null);
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "15px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this category?{" "}
          </p>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => setShowConfirmDeleteCategory(false)}
              type="button"
              variant="outline-primary"
              className="button"
              size="md"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                borderColor: "#F14810",
                color: "#F14810",
              }}
            >
              No
            </Button>

            <Button
              onClick={() => handleDeleteCategory()}
              type="button"
              variant="primary"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                color: "white",
              }}
              className="button"
              size="md"
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalComponent>

      {/* delete class */}
      <ModalComponent
        showModal={showConfirmDeleteClass}
        closeModal={() => {
          setShowConfirmDeleteClass(false);
          setClassToDelete(null);
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "15px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to delete this class?{" "}
          </p>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => setShowConfirmDeleteClass(false)}
              type="button"
              variant="outline-primary"
              className="button"
              size="md"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                borderColor: "#F14810",
                color: "#F14810",
              }}
            >
              No
            </Button>

            <Button
              onClick={() => handleDeleteClass()}
              type="button"
              variant="primary"
              style={{
                fontSize: "14px",
                padding: "6px 18px",
                marginTop: "20px",
                backgroundColor: "#F14810",
                borderColor: "#F14810",
                color: "white",
              }}
              className="button"
              size="md"
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default ExpandableClassTable;
