import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonX from "../../../../../../component/button/button";
import uploadIcon from "../../../../../../assets/svgs/uploadIcon.svg";
import ActionIcon from "../../../../../../component/actionbutton/ActionIcon";
import alertIcon from "../../../../../../assets/svgs/alert.svg";

import DashboardTopSection from "../../../dashboardTopSection/index";
import DashboardSidebar from "../../../dashboardSideBar/sidebar";

import { useParams, useNavigate } from "react-router-dom";
import ModalComponent from "../../../../../../component/modal/modal";
import {
  Row,
  Col,
  InputGroup,
  Table,
  Button,
  Form,
  Container,
  Offcanvas,
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import uploadBtn from "../../../../../../assets/svgs/uploadbtn.svg";
import actionIcon from "../../../../../../assets/svgs/institutionLevelActionIcon.svg";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../../component/successScreen/successScreen";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";

import ToastComponent from "../../../../../../component/toast/toast";
import TableComponent from "../../../../../../component/table/table";
import AdminInformationPage from "./schoolAdminInfo/adminInformation";

import {
  useGetSchoolAdminsQuery,
  useGetBranchesQuery,
  useGetInstitutionDetailQuery,
  useGetInstitutionRolesQuery,
  useAddSchoolAdminsMutation,
  useUpdateSchoolAdminsMutation,
} from "../../../../../../redux/features/superAdmin/adminApiSlice";

const SchoolAdminUsersPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const { institutionId } = useParams();

  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showCreateAdminCanvas, setShowCreateAdminCanvas] = useState(false);
  const [editedElement, setEditedElement] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleCloseAdmin = () => {
    setShowCreateAdminCanvas(false);
    setEditingValue("");
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: adminUsers,
    isLoading: adminUsersLoading,
    refetch: refetchAdminUsers,
  } = useGetSchoolAdminsQuery(institutionId);

  console.log("admins", adminUsers);
  console.log(institutionId);

  const {
    data: institutionDetails,
    refetch: refetchInstition,
    isLoading: institutionLoading,
  } = useGetInstitutionDetailQuery(institutionId);

  console.log("institutions", institutionDetails);

  const columns = [
    // { name: "id", label: "S/N" },
    {
      name: "fullname",
      label: "Full Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { firstName, lastName } = adminUsers[tableMeta.rowIndex];
          return `${firstName} ${lastName}`;
        },
      },
    },
    { name: "gender", label: "Gender" },
    { name: "phoneNumber", label: "Phone No." },
    { name: "email", label: "Email" },
    {
      name: "branch",
      label: "Branch",
      options: {
        customBodyRender: (branch) => (branch ? branch?.name : "N/A"),
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        customBodyRender: (role) => (role ? role?.name : "N/A"),
      },
    },
    // { name: "status", label: "Status" },
    {
      name: "activated",
      label: "Active",
      options: {
        customBodyRender: (active) => (active ? "true" : "false"),
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <ActionIcon
            actionsArr={[
              {
                label: "Edit",
                action: () => {
                  handleEditAdminUser(adminUsers[tableMeta.rowIndex]);
                },
              },
              // {
              //     label: "Delete",
              //     // action: () => {
              //     //     confirmDelete(permissionPages[tableMeta.rowIndex]);
              //     // },
              // },
            ]}
          />
        ),
      },
    },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dates = Array.from({ length: 31 }, (_, index) =>
    (index + 1).toString()
  );

  const { data: roleList, isLoading: roleListLoading } =
    useGetInstitutionRolesQuery(institutionId);

  console.log("roles", roleList);

  const { data: branchList, isLoading: branchListLoading } =
    useGetBranchesQuery(institutionId);

  console.log("branches", branchList);

  const [adminData, setAdminData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: {
      dayOfMonth: "",
      month: "",
    },
    email: "",
    phoneNumber: "",
    gender: "",
    roleId: "",
    branchId: "",
  });

  const [addAdmin, { isLoading: addAdminLoading }] =
    useAddSchoolAdminsMutation();

  const [editAdmin, { isLoading: editAdminLoading }] =
    useUpdateSchoolAdminsMutation();

  const {
    firstName,
    lastName,
    dateOfBirth,
    email,
    phoneNumber,
    gender,
    roleId,
    branchId,
  } = adminData;

  const handleEditAdminUser = (el) => {
    console.log("edit was clicked for ", el);
    setEditingElement(el);
    setShowCreateAdminCanvas(true);
    setEditingValue({
      editedFirstName: el?.firstName,
      editedLastName: el?.lastName,
      editedDateOfBirth: {
        dayOfMonth: el?.dateOfBirth?.dayOfMonth,
        month: el?.dateOfBirth?.month,
      },
      editedEmail: el?.email,
      editedPhoneNumber: el?.phoneNumber,
      editedGender: el?.gender,
      editedRoleId: el?.role?.id,
      editedBranchId: el?.branch?.id,
    });
  };

  const {
    editedFirstName,
    editedLastName,
    editedDateOfBirth,
    editedEmail,
    editedPhoneNumber,
    editedGender,
    editedRoleId,
    editedBranchId,
  } = editingValue;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    // setAdminData({ ...adminData, [name]: value });
    // setEditingValue({ ...editingValue, [name]: value });

    // handle the keys that are nested in objects. E.g contactPerson.firstName

    const [objectKey, nestedKey] = name.split(".");
    // If we can split the name by "." it means it is nested
    // spread all the previous data in the entire object, then target the particular object that needs to be mutated. spread all of its contents, then mutate the particular key needed

    if (nestedKey) {
      setAdminData((previousData) => ({
        ...previousData,
        [objectKey]: { ...previousData[objectKey], [nestedKey]: value },
      }));

      setEditingValue((previousData) => ({
        ...previousData,
        [objectKey]: { ...previousData[objectKey], [nestedKey]: value },
      }));
    } else {
      setAdminData({ ...adminData, [name]: value });
      setEditingValue({ ...editingValue, [name]: value });
    }
  };

  console.log(dateOfBirth, "DoB");

  const handleCreateOrUpdateAdmin = async (e, type) => {
    e.preventDefault();

    if (type === "edit") {
      if (editingValue === "") {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }

      const parameters = {
        institutionId: institutionId,
        userId: editingElement?.id,
        body: {
          firstName: editedFirstName,
          lastName: editedLastName,
          dateOfBirth: {
            dayOfMonth: editedDateOfBirth?.dayOfMonth,
            month: editedDateOfBirth?.month,
          },
          email: editedEmail,
          phoneNumber: editedPhoneNumber,
          // password: editingValue?,
          gender: editedGender,
          roleId: editedRoleId,
          branchId: editedBranchId,
        },
      };

      // console.log(parameters, "REQUEST");

      try {
        const res = await editAdmin(parameters);
        console.log(res);
        if (res) {
          setShowToast(true);
          setToastMessage("User updated successfully!");
          setAdminData({
            firstName: "",
            lastName: "",
            dateOfBirth: {
              dayOfMonth: "",
              month: "",
            },
            email: "",
            phoneNumber: "",
            gender: "",
            roleId: "",
            branchId: "",
          });
          setEditedElement(res);
          setEditingValue("");
          setShowCreateAdminCanvas(false);
        }
        setEditingElement(null);
      } catch (error) {
        console.log(error);
        // alert(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
      return;
    } else {
      if (
        (firstName === "", lastName === "", email === "", phoneNumber === "")
      ) {
        setIsFormNotFilled(true);

        setTimeout(() => {
          setIsFormNotFilled(false);
        }, 3000);

        return;
      }
      try {
        console.log(adminData);
        const obj = {
          institutionId: institutionId,
          body: adminData,
        };

        const data = await addAdmin(obj).unwrap();

        if (data) {
          setAdminData(data);
          setShowToast(true);
          setToastMessage("User created successfully!");
        }
        setAdminData({
          firstName: "",
          lastName: "",
          dateOfBirth: {
            dayOfMonth: "",
            month: "",
          },
          email: "",
          phoneNumber: "",
          gender: "",
          roleId: "",
          branchId: "",
        });
        setShowCreateAdminCanvas(false);
      } catch (error) {
        console.log(error.data);
        // setErrorMessage(error.data.detail);
        setShowToast(true);
        if (error?.data?.detail) {
          setToastMessage(error.data.detail);
        } else {
          // other errors
          setToastMessage(error.error);
        }
      }
    }
  };

  // const confirmDelete = (el) => {
  //     setElementToDelete(el);
  //     setShowConfirmDelete(true);
  // };

  // const handleDeleteAdmin = async () => {
  //     setShowConfirmDelete(false);

  //     try {
  //         await deletePage(elementToDelete?.id).unwrap();
  //         setDeletedElementID(elementToDelete.id);
  //         setShowToast(true);
  //         setToastMessage("Permission deleted successfully!");
  //     } catch (error) {
  //         console.log(error);
  //         // alert(error.data);
  //         setShowToast(true);
  //         if (error?.data?.detail) {
  //             setToastMessage(error.data.detail);
  //         } else {
  //             // other errors
  //             setToastMessage(error.error);
  //         }
  //     }
  // };

  useEffect(() => {
    if (adminUsers) {
      refetchAdminUsers();
    }
  }, [adminData, editedElement]);

  if (
    adminUsersLoading ||
    addAdminLoading ||
    editAdminLoading
    // ||
    // deleteAdminLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <div style={{ display: "flex" }}>
      <DashboardSidebar />

      <Container
        fluid
        className="bodycontainer"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <DashboardTopSection />

        <ToastComponent
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        {/* delete confirmation modal */}
        <ModalComponent
          showModal={showConfirmDelete}
          closeModal={() => setShowConfirmDelete(false)}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={alertIcon}
              alt="alert Icon"
              style={{ margin: "0 auto" }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "500",
                fontSize: 1.0 + "rem",
              }}
            >
              Are you sure you want to delete this admin user?{" "}
            </p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "10px",
                marginLeft: "30%",
              }}
            >
              <Button
                // onClick={() => setShowConfirmDelete(false)}
                type="button"
                variant="outline-primary"
                style={{ borderColor: "#F14810", color: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  No
                </span>
              </Button>
              <Button
                // onClick={() => handleDeleteCredential()}
                type="button"
                variant="primary"
                style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.8 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  Yes
                </span>
              </Button>
            </div>
          </div>
        </ModalComponent>

        <Offcanvas
          show={showCreateAdminCanvas}
          onHide={handleCloseAdmin}
          placement={"end"}
          backdrop={true}
          style={{ width: "100%", maxWidth: "500px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {editingElement ? "Edit Admin" : "Create Admin"}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ paddingTop: "0px" }}>
            {/* <AdminInformationPage /> */}

            <div>
              <div style={{ display: "flex", gap: 0.8 + "rem" }}>
                <div>
                  <h5>User Information</h5>
                  <p className="FormParagraph">
                    Provide the following information about the user
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                border: "2px solid #F5F5F5",
                borderRadius: "8px",
                padding: "15px",
                height: "auto",
              }}
            >
              <Form className="form" style={{ paddingTop: "5px" }}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        First Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="text"
                        name={editingElement ? "editedFirstName" : "firstName"}
                        value={editingElement ? editedFirstName : firstName}
                        onChange={handleOnChange}
                        placeholder="Enter First Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        Last Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="text"
                        name={editingElement ? "editedLastName" : "lastName"}
                        value={editingElement ? editedLastName : lastName}
                        onChange={handleOnChange}
                        placeholder="Enter Last Name"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Form.Label className="formClasslabel">
                    Date of Birth
                  </Form.Label>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">Day</Form.Label>

                      <Form.Select
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        name={
                          editingElement
                            ? "editedDateOfBirth.dayOfMonth"
                            : "dateOfBirth.dayOfMonth"
                        }
                        value={
                          editingElement
                            ? editedDateOfBirth?.dayOfMonth
                            : dateOfBirth?.dayOfMonth
                        }
                        onChange={handleOnChange}
                      >
                        <option value="" defaultValue disabled>
                          Select Day
                        </option>
                        {dates.map((date, index) => (
                          <option
                            selected={editedDateOfBirth?.dayOfMonth === date}
                            key={index}
                            value={date}
                          >
                            {date}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">Month</Form.Label>

                      <Form.Select
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        name={
                          editingElement
                            ? "editedDateOfBirth.month"
                            : "dateOfBirth.month"
                        }
                        value={
                          editingElement
                            ? editedDateOfBirth?.month
                            : dateOfBirth?.month
                        }
                        onChange={handleOnChange}
                        placeholder="Select Month"
                      >
                        <option value="" defaultValue disabled>
                          Select Month
                        </option>
                        {months.map((month, index) => (
                          <option
                            selected={editedDateOfBirth?.month === month}
                            key={index}
                            value={month}
                          >
                            {month}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Email Address <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="email"
                    name={editingElement ? "editedEmail" : "email"}
                    value={editingElement ? editedEmail : email}
                    onChange={handleOnChange}
                    placeholder="Enter Email Address"
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <Form.Control
                    size="md"
                    className="custom-focus"
                    style={{
                      fontFamily: "jost, sans-serif",
                      backgroundColor: "#EDEDED",
                      fontSize: 0.8 + "rem",
                    }}
                    type="text"
                    name={editingElement ? "editedPhoneNumber" : "phoneNumber"}
                    value={editingElement ? editedPhoneNumber : phoneNumber}
                    onChange={handleOnChange}
                    placeholder="Enter Phone number"
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="formClasslabel">
                    Gender <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <InputGroup className="mb-3" required>
                    <div key={`inline-radio`}>
                      <Form.Check
                        inline
                        label="Male"
                        name={editingElement ? "editedGender" : "gender"}
                        value="MALE"
                        checked={
                          editingElement
                            ? editedGender?.toUpperCase() === "MALE"
                            : gender?.toUpperCase() === "MALE"
                        }
                        onChange={handleOnChange}
                        type="radio"
                        id={`inline-radio-1`}
                        size={25}
                      />

                      <Form.Check
                        inline
                        label="Female"
                        name={editingElement ? "editedGender" : "gender"}
                        value="FEMALE"
                        checked={
                          editingElement
                            ? editedGender?.toUpperCase() === "FEMALE"
                            : gender?.toUpperCase() === "FEMALE"
                        }
                        onChange={handleOnChange}
                        type="radio"
                        id={`inline-radio-2`}
                        size={25}
                        style={{ variant: "outline-light" }}
                      />
                    </div>
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>

            <div
              style={{
                marginTop: 1 + "rem",
              }}
            >
              <div>
                <h6>Assign Role</h6>
              </div>
            </div>

            <div
              style={{
                border: "2px solid #F5F5F5",
                borderRadius: "8px",
                padding: "15px",
                height: "auto",
                marginTop: 1 + "rem",
                marginBottom: 1 + "rem",
              }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">User Role</Form.Label>

                <Form.Select
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name={editingElement ? "editedRoleId" : "roleId"}
                  value={editingElement ? editedRoleId : roleId}
                  onChange={handleOnChange}
                  // required
                >
                  <option value="" defaultValue disabled>
                    Select Role
                  </option>
                  {roleList &&
                    roleList?.map(({ id, name }) => (
                      <option
                        selected={editedRoleId === id}
                        key={id}
                        value={id}
                      >
                        {name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="formClasslabel">Branch</Form.Label>

                <Form.Select
                  size="md"
                  className="custom-focus"
                  style={{
                    fontFamily: "jost, sans-serif",
                    backgroundColor: "#EDEDED",
                    fontSize: 0.8 + "rem",
                  }}
                  name={editingElement ? "editedBranchId" : "branchId"}
                  value={editingElement ? editedBranchId : branchId}
                  onChange={handleOnChange}
                  placeholder="Select Month"
                  required
                >
                  <option value="" defaultValue disabled>
                    Select Branch
                  </option>
                  {branchList?.map(({ id, name }) => (
                    <option
                      selected={editedBranchId === id}
                      key={id}
                      value={id}
                    >
                      {name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div style={{ marginTop: "30px" }}>
              {editingElement ? (
                <Button
                  onClick={(e) => handleCreateOrUpdateAdmin(e, "edit")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Update User
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={(e) => handleCreateOrUpdateAdmin(e, "")}
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "left",
                  }}
                  className="button"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Save User
                  </span>
                </Button>
              )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 1 + "rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              marginBottom: "10px",
            }}
            onClick={() => navigate(-1)}
          >
            <img src={backArrow} alt="Go back" />
            <h6 style={{ marginBottom: 0 }}>
              {institutionDetails?.institutionName} ( School Admins )
            </h6>
          </div>

          <Button
            className="button"
            size="md"
            style={{
              backgroundColor: "#F14810",
              color: "#ffffff",
              border: "none",
              outline: "none",
              fontSize: 0.8 + "rem",
            }}
            onClick={() => setShowCreateAdminCanvas(true)}
          >
            Create Admin
          </Button>
        </div>

        <TableComponent
          data={adminUsers}
          title=""
          columns={columns}
          pagination={10}
          search={true}
          hasAuthority={userAuthorizedPages?.find(
            (page) => page?.action === "ADMIN_MANAGE_SCHOOLS"
          )}
        />
      </Container>
    </div>
  );
};

export default SchoolAdminUsersPage;
