import { useSelector } from "react-redux";
import { Sidebar, MenuItem, Menu } from "react-pro-sidebar";
import { Navbar, Container, Image, Spinner, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../../../../../assets/svgs/logo.svg";
import alertIcon from "../../../../../assets/svgs/alertIcon.svg";
import placeholderLogo from "../../../../../assets/svgs/institutionPlaceholderLogo.svg";
import {
  useGetInstitutionLogoQuery,
  useGetSetupProgressQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { useLocation } from "react-router-dom";
import { capitalizeWords } from "../../../../../utils/utils";
import SettlementIcon from "../../../../../assets/svgs/settlementIcon.js";
import { usePermittedLinks } from "../../../../../context/permittedNavLinks.js";

const DashboardSidebar = ({ institutionDetails }) => {
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { permittedLinks } = usePermittedLinks();

  const logoChangeModal = useSelector(
    (state) => state?.institution?.logoChange
  );
  const {
    data: institutionLogo,
    refetch,
    isLoading,
  } = useGetInstitutionLogoQuery(id);
  const { data: setupProgress } = useGetSetupProgressQuery(id);

  const navigate = useNavigate();
  const location = useLocation();

  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(
    window.matchMedia("(max-width: 950px)").matches
  );

  useEffect(() => {
    if (institutionLogo) {
      refetch();
    }
  }, [logoChangeModal]);

  const goToSetupPage = () => {
    navigate("/school-setup");
  };

  return (
    <Sidebar
      backgroundColor="#FFFDFC"
      className="sidebar"
      onBackdropClick={() => setToggled(false)}
      breakPoint="always"
      toggled={toggled}
      customBreakPoint="1000px"
      onBreakPoint={setBroken}
    >
      {/* Institution Details */}
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          paddingLeft: "15px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            minWidth: "40px",
            height: "50px",
            borderRadius: "100%",
            overflow: "hidden",
          }}
        >
          {isLoading ? (
            <Spinner animation="border" variant="danger" color="#F14810" />
          ) : (
            <img
              src={institutionLogo ? institutionLogo?.logo : placeholderLogo}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
        </div>

        <div style={{ marginTop: "10px" }}>
          <h4
            style={{
              fontSize: "1rem",
              marginBottom: "0px",
            }}
          >
            {capitalizeWords(institutionDetails?.institutionName)}
          </h4>
          <p
            style={{
              fontSize: "0.85rem",
              margin: "0px",
            }}
          >
            {institutionDetails?.address}
          </p>
        </div>
      </div>

      {/* Setup Progress */}
      {institutionDetails?.setupComplete === false && (
        <div
          className="school-setup"
          style={{
            backgroundColor: "#ffe6de",
            border: "1px solid #DBDBDB",
            borderRadius: "8px",
            margin: "1rem 10px",
            padding: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 6,
            }}
          >
            <img src={alertIcon} style={{ width: "12%" }} />
            <p
              style={{
                fontSize: "0.7rem",
                marginTop: "10px",
              }}
            >
              Complete your school setup here to enable settlements
            </p>
          </div>

          <h6
            style={{
              marginLeft: "20px",
              marginTop: "-2px",
            }}
          >
            {setupProgress?.progress} Completion
          </h6>

          <Button
            variant="primary"
            style={{
              marginLeft: "20px",
              marginBottom: "10px",
              backgroundColor: "#F14810",
              borderColor: "#F14810",
            }}
            className="button"
            size="sm"
            onClick={goToSetupPage}
          >
            Complete Setup
          </Button>
        </div>
      )}

      {/* Menu Items */}
      <Menu
        className="menu"
        style={{ marginBottom: "2rem", marginTop: "2rem" }}
        menuItemStyles={{
          button: ({ active }) => ({
            color: active ? "white" : "#464646",
            backgroundColor: active ? "#FD5821" : "transparent",
            "&:hover": {
              backgroundColor: active ? "#FD5821" : "#F5F5F5",
              color: active ? "white" : "#FFFFFF",
            },
          }),
        }}
      >
        {permittedLinks.map(({ label, path, Icon }) => (
          <Link key={path} to={path}>
            <MenuItem
              style={{
                height: "2.7rem",
                fontSize: "0.87rem",
              }}
              active={location.pathname.startsWith(path) ? "active" : null}
              icon={
                <Icon
                  active={location.pathname.startsWith(path) ? "active" : null}
                  size={18}
                />
              }
            >
              {label}
            </MenuItem>
          </Link>
        ))}
      </Menu>

      <div
        style={{
          width: "auto",
          height: "auto",
          marginTop: "auto",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          borderTop: "1px solid #DBDBDB",
        }}
      >
        <Image src={logo} fluid />
      </div>
    </Sidebar>
  );
};

export default DashboardSidebar;
