import { InputGroup, Form, Row, Col, Accordion, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import {
  useGetCountriesQuery,
  useGetStatesQuery,
} from "../../../../../redux/features/auth/authApiSlice";
import {
  useUpdateInstitutionDetailsMutation,
  useGetInstitutionQuery,
  useGetInstitutionTypeQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import ToastComponent from "../../../../../component/toast/toast";
import { Link45deg, EnvelopeAt } from "react-bootstrap-icons";
import successGIF from "../../../../../assets/svgs/successGIF.gif";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import ModalComponent from "../../../../../component/modal/modal";

import "../../../../institution/setupInstitution/components/contactPerson/contactPerson";
import { useDispatch } from "react-redux";
import { storeContactPersonDetails } from "../../../../../redux/features/institution/institutionSlice";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useNavigate } from "react-router-dom";
import { TelephonePlus } from "react-bootstrap-icons";

const ContactPersonPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [isFormNotFilled, setIsFormNotFilled] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dates = Array.from({ length: 31 }, (_, index) =>
    (index + 1).toString()
  );

  //  get details if they have filled this page before
  const contactPersonDetails = useSelector(
    (state) => state.institution.contactPersonDetails
  );

  // console.log(contactPersonDetails);
  const dispatch = useDispatch();

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: institution,
    isLoading: detailsLoading,
    refetch: refetchDetails,
  } = useGetInstitutionQuery(instId, {
    skip: !instId,
  });

  console.log(institution);

  const [updateInstitutionDetails, { isLoading: updateLoading }] =
    useUpdateInstitutionDetailsMutation();

  const [setupInstitutionInfo, setSetupInstitutionInfo] = useState({
    primaryContact: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      month: "",
      email: "",
      phoneNumber: "",
      gender: "",
    },
    customerSupportNumber: {
      phoneNumber: "",
    },
    secondaryContact: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      month: "",
      email: "",
      phoneNumber: "",
      gender: "",
    },
    technicalContact: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      month: "",
      email: "",
      phoneNumber: "",
      gender: "",
    },
  });

  const {
    primaryContact,
    secondaryContact,
    technicalContact,
    customerSupportNumber,
  } = setupInstitutionInfo;

  useEffect(() => {
    if (institution) {
      console.log(institution);
      setSetupInstitutionInfo((prevInfo) => ({
        ...prevInfo,
        primaryContact: {
          firstName: contactPersonDetails
            ? contactPersonDetails.primaryContact.firstName
            : institution?.primaryContact?.firstName,
          lastName: contactPersonDetails
            ? contactPersonDetails.primaryContact.lastName
            : institution?.primaryContact?.lastName,
          dateOfBirth: {
            dayOfMonth: contactPersonDetails
              ? contactPersonDetails?.primaryContact?.dateOfBirth?.dayOfMonth
              : institution?.primaryContact?.dateOfBirth?.dayOfMonth,
            month: contactPersonDetails
              ? contactPersonDetails?.primaryContact?.dateOfBirth?.month
              : institution?.primaryContact?.dateOfBirth?.month,
          },
          email: contactPersonDetails
            ? contactPersonDetails.primaryContact.email
            : institution?.primaryContact?.email,
          phoneNumber: contactPersonDetails
            ? contactPersonDetails?.primaryContact?.phoneNumber
            : institution?.primaryContact?.phoneNumber,
          gender: contactPersonDetails
            ? contactPersonDetails.primaryContact.gender
            : institution?.primaryContact?.gender,
        },
        customerSupportNumber: {
          phoneNumber: contactPersonDetails
            ? contactPersonDetails?.customerSupportNumber?.phoneNumber
            : institution?.customerSupportNumber,
        },
        secondaryContact: {
          firstName: contactPersonDetails
            ? contactPersonDetails.secondaryContact.firstName
            : institution?.secondaryContact?.firstName,
          lastName: contactPersonDetails
            ? contactPersonDetails.secondaryContact.lastName
            : institution?.secondaryContact?.lastName,
          dateOfBirth: {
            dayOfMonth: contactPersonDetails
              ? contactPersonDetails?.secondaryContact?.dateOfBirth?.dayOfMonth
              : institution?.secondaryContact?.dateOfBirth?.dayOfMonth,
            month: contactPersonDetails
              ? contactPersonDetails?.secondaryContact?.dateOfBirth?.month
              : institution?.secondaryContact?.dateOfBirth?.month,
          },
          email: contactPersonDetails
            ? contactPersonDetails.secondaryContact.email
            : institution?.secondaryContact?.email,
          phoneNumber: contactPersonDetails
            ? contactPersonDetails.secondaryContact.phoneNumber
            : institution?.secondaryContact?.phoneNumber,
          gender: contactPersonDetails
            ? contactPersonDetails.secondaryContact.gender
            : institution?.secondaryContact?.gender,
        },
        technicalContact: {
          firstName: contactPersonDetails
            ? contactPersonDetails?.technicalContact?.firstName
            : institution?.technicalContact?.firstName,
          lastName: contactPersonDetails
            ? contactPersonDetails?.technicalContact?.lastName
            : institution?.technicalContact?.lastName,
          dateOfBirth: {
            dayOfMonth: contactPersonDetails
              ? contactPersonDetails?.technicalContact?.dateOfBirth?.dayOfMonth
              : institution?.technicalContact?.dateOfBirth?.dayOfMonth,
            month: contactPersonDetails
              ? contactPersonDetails?.technicalContact?.dateOfBirth?.month
              : institution?.technicalContact?.dateOfBirth?.month,
          },
          email: contactPersonDetails
            ? contactPersonDetails?.technicalContact?.email
            : institution?.technicalContact?.email,
          phoneNumber: contactPersonDetails
            ? contactPersonDetails?.technicalContact?.phoneNumber
            : institution?.technicalContact?.phoneNumber,
          gender: contactPersonDetails
            ? contactPersonDetails?.technicalContact?.gender
            : institution?.technicalContact?.gender,
        },
      }));
    }
  }, [institution]);

  const handleCloseModal = () => {
    setShowModal(false);
    refetchDetails();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    // handle the keys that are nested in objects. E.g contactPerson.firstName

    const [objectKey, nestedKey, deepNested] = name.split(".");
    // If we can split the name by "." it means it is nested
    // spread all the previous data in the entire object, then target the particular object that needs to be mutated. spread all of its contents, then mutate the particular key needed

    if (deepNested) {
      setSetupInstitutionInfo((previousData) => ({
        ...previousData,
        [objectKey]: {
          ...previousData[objectKey],
          [nestedKey]: {
            ...previousData[objectKey][nestedKey],
            [deepNested]: value,
          },
        },
      }));
    } else {
      setSetupInstitutionInfo((previousData) => ({
        ...previousData,
        [objectKey]: { ...previousData[objectKey], [nestedKey]: value },
      }));
    }
  };

  // check if they filled the necessary fields
  function isContactValid(contact) {
    return (
      contact?.firstName &&
      contact?.lastName &&
      contact?.email &&
      contact?.phoneNumber
    );
  }

  // const handleGoToNextPage = () => {
  //   if (
  //     !isContactValid(primaryContact) ||
  //     !isContactValid(secondaryContact) ||
  //     !customerSupportNumber?.phoneNumber
  //   ) {
  //     setIsFormNotFilled(true);

  //     setTimeout(() => {
  //       setIsFormNotFilled(false);
  //     }, 3000);

  //     return;
  //   }

  //   console.log(setupInstitutionInfo);

  //   // dispatch and store so we can get these details even when user comes back to the page
  //   // if they didnt fill technical details then dont add it to what is sent
  //   if (
  //     !secondaryContact.email ||
  //     !secondaryContact.phoneNumber ||
  //     !technicalContact.email ||
  //     !technicalContact.phoneNumber
  //   ) {
  //     dispatch(
  //       storeContactPersonDetails({
  //         primaryContact,
  //         secondaryContact,
  //         customerSupportNumber,
  //       })
  //     );
  //   } else {
  //     dispatch(storeContactPersonDetails(setupInstitutionInfo));
  //   }
  // };

  const handleSave = async () => {
    const params = {
      institutionId: instId,
      body: {
        primaryContact: {
          firstName: setupInstitutionInfo.primaryContact.firstName,
          lastName: setupInstitutionInfo.primaryContact.lastName,
          dateOfBirth: {
            dayOfMonth:
              setupInstitutionInfo.primaryContact.dateOfBirth.dayOfMonth,
            month: setupInstitutionInfo.primaryContact.dateOfBirth.month,
          },
          email: setupInstitutionInfo.primaryContact.email,
          phoneNumber: setupInstitutionInfo.primaryContact.phoneNumber,
          gender: setupInstitutionInfo.primaryContact.gender,
        },
        customerSupportNumber: {
          phoneNumber: setupInstitutionInfo.customerSupportNumber.phoneNumber,
        },
        secondaryContact: {
          firstName: setupInstitutionInfo.secondaryContact.firstName,
          lastName: setupInstitutionInfo.secondaryContact.lastName,
          dateOfBirth: {
            dayOfMonth:
              setupInstitutionInfo.secondaryContact.dateOfBirth.dayOfMonth,
            month: setupInstitutionInfo.secondaryContact.dateOfBirth.month,
          },
          email: setupInstitutionInfo.secondaryContact.email,
          phoneNumber: setupInstitutionInfo.secondaryContact.phoneNumber,
          gender: setupInstitutionInfo.secondaryContact.gender,
        },
        technicalContact: {
          firstName: setupInstitutionInfo.technicalContact.firstName,
          lastName: setupInstitutionInfo.technicalContact.lastName,
          dateOfBirth: {
            dayOfMonth:
              setupInstitutionInfo.technicalContact.dateOfBirth.dayOfMonth,
            month: setupInstitutionInfo.technicalContact.dateOfBirth.month,
          },
          email: setupInstitutionInfo.technicalContact.email,
          phoneNumber: setupInstitutionInfo.technicalContact.phoneNumber,
          gender: setupInstitutionInfo.technicalContact.gender,
        },
      },
    };

    console.log(params);

    try {
      const res = await updateInstitutionDetails(params).unwrap();
      console.log(res);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Fill the required fields then click this button to save your changes
    </Tooltip>
  );

  if (detailsLoading) {
    return <OnboardingLoader />;
  }

  return (
    <>
      {/* <ToastComponent
                showToast={showToast}
                setShowToast={setShowToast}
                toastMessage={toastMessage}
            /> */}

      <ModalComponent showModal={showModal} closeModal={handleCloseModal}>
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!</h3>
          <p style={{ fontSize: "18px" }}>
            Contact Person updated successfully
          </p>
        </div>
      </ModalComponent>

      <h6 style={{ fontSize: "14px", fontWeight: 600 }}>Contact Person</h6>
      <div
        style={{
          border: "1px solid #F5F5F5",
          borderRadius: "8px",
          padding: "15px",
          display: "flex",
          gap: "30px",
        }}
        className="details-in-settings"
      >
        <div style={{ flexBasis: "40%" }}>
          <h6>Edit Contact Person Details</h6>
          <p>
            Edit the contact person details provided in the onboarding stage
            here. Clicking the "Save changes" button will update the existing
            information{" "}
          </p>

          <p style={{ fontWeight: "500", margin: "5px 0" }}>
            Click the button below to watch how to edit your set-up.
          </p>

          <Link to="/help">
            <span style={{ color: "#F14810", fontWeight: "600" }}>
              Watch Video
            </span>
          </Link>
        </div>

        <div style={{ flexBasis: "60%" }}>
          {isFormNotFilled ? (
            <span
              style={{
                color: "red",
                fontStyle: "italic",
                transition: "ease-in-out",
              }}
            >
              Please fill all required fields
            </span>
          ) : null}

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Primary Contact Details <span style={{ color: "red" }}>*</span>
              </Accordion.Header>
              <Accordion.Body>
                <Form className="form" style={{ paddingTop: "10px" }}>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          First Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="text"
                          name="primaryContact.firstName"
                          value={primaryContact.firstName}
                          onChange={handleOnChange}
                          placeholder="Enter First Name"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Last Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="text"
                          name="primaryContact.lastName"
                          value={primaryContact.lastName}
                          onChange={handleOnChange}
                          placeholder="Enter Last Name"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Form.Label className="formClasslabel">
                      Date of Birth
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </Form.Label>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="primaryContact.dateOfBirth.dayOfMonth"
                          value={primaryContact?.dateOfBirth?.dayOfMonth}
                          onChange={handleOnChange}
                          // required
                        >
                          <option value="" defaultValue disabled>
                            Select Day
                          </option>
                          {dates.map((date, index) => (
                            <option key={index} value={date}>
                              {date}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="primaryContact.dateOfBirth.month"
                          value={primaryContact?.dateOfBirth?.month}
                          onChange={handleOnChange}
                          placeholder="Select Month"
                          required
                        >
                          <option value="" defaultValue disabled>
                            Select Month
                          </option>
                          {months.map((month, index) => (
                            <option key={index} value={month}>
                              {month}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Email Address <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="email"
                      name="primaryContact.email"
                      value={primaryContact.email}
                      onChange={handleOnChange}
                      placeholder="Enter Email Address"
                      required
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="basic-addon1"
                        style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                      >
                        <TelephonePlus size={22} />
                      </InputGroup.Text>
                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="number"
                        name="primaryContact.phoneNumber"
                        value={primaryContact.phoneNumber}
                        onChange={handleOnChange}
                        placeholder="Enter Phone number"
                        required
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Gender <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-3" required>
                      <div key={`inline-radio`}>
                        <Form.Check
                          inline
                          label="Male"
                          name="primaryContact.gender"
                          checked={primaryContact.gender === "male"}
                          onChange={handleOnChange}
                          value="male"
                          type="radio"
                          id={`inline-radio-1`}
                          size={25}
                          // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                        />
                        <Form.Check
                          inline
                          label="Female"
                          name="primaryContact.gender"
                          checked={primaryContact.gender === "female"}
                          onChange={handleOnChange}
                          value="female"
                          type="radio"
                          id={`inline-radio-2`}
                          size={25}
                          style={{ variant: "outline-light" }}
                        />
                      </div>
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Customer Care Details <span style={{ color: "red" }}>*</span>
              </Accordion.Header>
              <Accordion.Body>
                <Form className="form" style={{ paddingTop: "10px" }}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="basic-addon1"
                        style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                      >
                        <TelephonePlus size={22} />
                      </InputGroup.Text>
                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="number"
                        name="customerSupportNumber.phoneNumber"
                        value={customerSupportNumber?.phoneNumber}
                        onChange={handleOnChange}
                        placeholder="Enter Phone number"
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Accountant Contact Details{" "}
                <span style={{ color: "red" }}>*</span>
              </Accordion.Header>
              <Accordion.Body>
                <Form className="form" style={{ paddingTop: "10px" }}>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          First Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="text"
                          name="secondaryContact.firstName"
                          value={secondaryContact.firstName}
                          onChange={handleOnChange}
                          placeholder="Enter First Name"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Last Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="text"
                          name="secondaryContact.lastName"
                          value={secondaryContact.lastName}
                          onChange={handleOnChange}
                          placeholder="Enter Last Name"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Form.Label className="formClasslabel">
                      Date of Birth
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </Form.Label>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="secondaryContact.dateOfBirth.dayOfMonth"
                          value={secondaryContact?.dateOfBirth?.dayOfMonth}
                          onChange={handleOnChange}
                          // required
                        >
                          <option value="" defaultValue disabled>
                            Select Day
                          </option>
                          {dates.map((date, index) => (
                            <option key={index} value={date}>
                              {date}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="secondaryContact.dateOfBirth.month"
                          value={secondaryContact?.dateOfBirth?.month}
                          onChange={handleOnChange}
                          placeholder="Select Month"
                          required
                        >
                          <option value="" defaultValue disabled>
                            Select Month
                          </option>
                          {months.map((month, index) => (
                            <option key={index} value={month}>
                              {month}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Email Address <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="email"
                      name="secondaryContact.email"
                      value={secondaryContact.email}
                      onChange={handleOnChange}
                      placeholder="Enter Email Address"
                      required
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="basic-addon1"
                        style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                      >
                        <TelephonePlus size={22} />
                      </InputGroup.Text>
                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="number"
                        name="secondaryContact.phoneNumber"
                        value={secondaryContact.phoneNumber}
                        onChange={handleOnChange}
                        placeholder="Enter Phone number"
                        required
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Gender <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-3" required>
                      <div key={`inline-radio`}>
                        <Form.Check
                          inline
                          label="Male"
                          name="secondaryContact.gender"
                          checked={secondaryContact.gender === "male"}
                          onChange={handleOnChange}
                          value="male"
                          type="radio"
                          id={`inline-radio-1`}
                          size={25}
                          // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                        />
                        <Form.Check
                          inline
                          label="Female"
                          name="secondaryContact.gender"
                          checked={secondaryContact.gender === "female"}
                          onChange={handleOnChange}
                          value="female"
                          type="radio"
                          id={`inline-radio-2`}
                          size={25}
                          style={{ variant: "outline-light" }}
                        />
                      </div>
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Technical Contact Details</Accordion.Header>
              <Accordion.Body>
                <Form className="form" style={{ paddingTop: "10px" }}>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          First Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="text"
                          name="technicalContact.firstName"
                          value={technicalContact.firstName}
                          onChange={handleOnChange}
                          placeholder="Enter First Name"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Last Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="text"
                          name="technicalContact.lastName"
                          value={technicalContact.lastName}
                          onChange={handleOnChange}
                          placeholder="Enter Last Name"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Form.Label className="formClasslabel">
                      Date of Birth
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </Form.Label>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="technicalContact.dateOfBirth.dayOfMonth"
                          value={technicalContact?.dateOfBirth?.dayOfMonth}
                          onChange={handleOnChange}
                          // required
                        >
                          <option value="" defaultValue disabled>
                            Select Day
                          </option>
                          {dates.map((date, index) => (
                            <option key={index} value={date}>
                              {date}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="technicalContact.dateOfBirth.month"
                          value={technicalContact?.dateOfBirth?.month}
                          onChange={handleOnChange}
                          placeholder="Select Month"
                          required
                        >
                          <option value="" defaultValue disabled>
                            Select Month
                          </option>
                          {months.map((month, index) => (
                            <option key={index} value={month}>
                              {month}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Email Address <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <Form.Control
                      size="md"
                      className="custom-focus"
                      style={{
                        fontFamily: "jost, sans-serif",
                        backgroundColor: "#EDEDED",
                        fontSize: 0.8 + "rem",
                      }}
                      type="email"
                      name="technicalContact.email"
                      value={technicalContact.email}
                      onChange={handleOnChange}
                      placeholder="Enter Email Address"
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="basic-addon1"
                        style={{ backgroundColor: "#F14810", color: "#ffffff" }}
                      >
                        <TelephonePlus size={22} />
                      </InputGroup.Text>
                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="number"
                        name="technicalContact.phoneNumber"
                        value={technicalContact.phoneNumber}
                        onChange={handleOnChange}
                        placeholder="Enter Phone number"
                        required
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="formClasslabel">
                      Gender <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <InputGroup className="mb-3" required>
                      <div key={`inline-radio`}>
                        <Form.Check
                          inline
                          label="Male"
                          name="technicalContact.gender"
                          checked={technicalContact.gender === "male"}
                          onChange={handleOnChange}
                          value="male"
                          type="radio"
                          id={`inline-radio-1`}
                          size={25}
                          // style={{ backgroundColor: '#F14810', color: '#ffffff' }}
                        />
                        <Form.Check
                          inline
                          label="Female"
                          name="technicalContact.gender"
                          checked={technicalContact.gender === "female"}
                          onChange={handleOnChange}
                          value="female"
                          type="radio"
                          id={`inline-radio-2`}
                          size={25}
                          style={{ variant: "outline-light" }}
                        />
                      </div>
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
           VIEW_SETTLEMENT_ACCOUNT
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPersonPage;
