import { useState, useEffect } from "react";
import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import DashboardTopSection from "../dashboardTopSection";
import DashboardSidebar from "../dashboardSideBar/sidebar";
import TableComponent from "../../../../component/table/table";
import Button from "../../../../component/button/button";
import { useNavigate } from "react-router-dom";
import { addTrailingCommas } from "../../../../utils/utils";
import { useGetSuperReportSummaryQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../component/toast/toast";
import { useSelector } from "react-redux";

const ReportingPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const {
    data: reports,
    isLoading,
    refetch,
  } = useGetSuperReportSummaryQuery(pageNumber);

  console.log(pageNumber);

  const columns = [
    { name: "institutionName", label: "School Name" },
    {
      name: "expectedRevenue",
      label: "Expected Revenue",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "generatedRevenue",
      label: "Generated Revenue",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "outstandingRevenue",
      label: "Outstanding Payment",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "totalWalletAmount",
      label: "Wallet",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },

    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <img
            src={eyeActionIcon}
            alt="eye icon"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(
                `types/${reports?.content[tableMeta.rowIndex].institutionId}`
              );
              // setShowReportTypes(true);
              // setClickedSchool(
              //   reports?.content[tableMeta.rowIndex].institutionId
              // );
            }}
          />
        ),
      },
    },
  ];

  const handleViewAdvanceReport = () => {
    navigate(`/principal/reporting/advanceReport`);
  };

  useEffect(() => {
    const fetchReports = async () => {
      try {
        // set loading state to true when refetching
        setReportsLoading(true);
        await refetch();
        // set loading state to false when refetching completes
        setReportsLoading(false);
      } catch (error) {
        console.error("Error while reports transactions:", error);
        setShowToast(true);

        setToastMessage("Error while refetching reports. Please try again");
        // Manually set loading state to false if refetching encounters an error
        setReportsLoading(false);
      }
    };

    fetchReports();
  }, [pageNumber, refetch]);

  if (isLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="schools-page no-scrollbar">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        <section
          style={{
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h5>Reporting</h5>
          </div>
        </section>

        <TableComponent
          data={reports?.content}
          title=""
          columns={columns}
          pagination={10}
          customPagination
          totalPages={reports?.totalPages}
          hasAuthority={userAuthorizedPages?.find(
            (page) => page?.action === "ADMIN_VIEW_REPORTS"
          )}
          onPageChange={(page) => setPageNumber(page - 1)}
          filter={true}
          search={true}
          loading={reportsLoading}
        />

        <br />
        <br />
      </div>

      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
    </div>
  );
};

export default ReportingPage;

// import { useState, useEffect } from "react";
// import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
// import DashboardTopSection from "../dashboardTopSection";
// import DashboardSidebar from "../dashboardSideBar/sidebar";
// import TableComponent from "../../../../component/table/table";
// import Button from "../../../../component/button/button";
// import { useNavigate } from "react-router-dom";
// import { addTrailingCommas } from "../../../../utils/utils";
// import { useGetSuperReportSummaryQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
// import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
// import { useSelector } from "react-redux";
// import ToastComponent from "../../../../component/toast/toast";

// const ReportingPage = () => {
//   const userAuthorizedPages = useSelector(
//     (state) => state?.auth?.userInfo?.permissions
//   );

//   const [pageNumber, setPageNumber] = useState(0);
// const [reportsLoading, setReportsLoading] = useState(false);
// const [showToast, setShowToast] = useState(false);
// const [toastMessage, setToastMessage] = useState("");

//   console.log(pageNumber);

//   const navigate = useNavigate();
//   const {
//     data: reports,
//     isLoading,
//     refetch,
//   } = useGetSuperReportSummaryQuery(pageNumber);

//   const columns = [
//     { name: "institutionName", label: "School Name" },
//     {
//       name: "expectedRevenue",
//       label: "Expected Revenue",
//       options: {
//         customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
//       },
//     },
//     {
//       name: "generatedRevenue",
//       label: "Generated Revenue",
//       options: {
//         customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
//       },
//     },
//     {
//       name: "outstandingPayment",
//       label: "Outstanding Payment",
//       options: {
//         customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
//       },
//     },
//     {
//       name: "walletBalance",
//       label: "Wallet",
//       options: {
//         customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
//       },
//     },
// {
//   name: "actions",
//   label: "Action",
//   options: {
//     customBodyRender: (value, tableMeta) => (
//       <img
//         src={eyeActionIcon}
//         alt="eye icon"
//         style={{ cursor: "pointer" }}
//         onClick={() => {
//           navigate(
//             `types/${reports?.content[tableMeta.rowIndex].institutionId}`
//           );
//           // setShowReportTypes(true);
//           // setClickedSchool(
//           //   reports?.content[tableMeta.rowIndex].institutionId
//           // );
//         }}
//       />
//     ),
//   },
// },
//   ];

// useEffect(() => {
//   const fetchReports = async () => {
//     try {
//       // set loading state to true when refetching
//       setReportsLoading(true);
//       await refetch();
//       // set loading state to false when refetching completes
//       setReportsLoading(false);
//     } catch (error) {
//       console.error("Error while reports transactions:", error);
//       setShowToast(true);

//       setToastMessage("Error while refetching reports. Please try again");
//       // Manually set loading state to false if refetching encounters an error
//       setReportsLoading(false);
//     }
//   };

//   fetchReports();
// }, [pageNumber, refetch]);

//   // console.log(reports);
//   // console.log(pageNumber);

//   if (isLoading) {
//     return <OnboardingLoader />;
//   }

//   return (
//     <div className="schools-page no-scrollbar">
//       <div>
//         <DashboardSidebar />
//       </div>

//       <div>
//         <DashboardTopSection />

//         <div>
//           <h5>Reporting</h5>
//           {/* <TableComponent
//             data={reports?.content}
//             title=""
//             columns={columns}
//             pagination={10}
//             hasAuthority={userAuthorizedPages?.find(
//               (page) => page?.action === "ADMIN_VIEW_REPORTS"
//             )}
//             customPagination={true}

//             onPageChange={(page) => setPageNumber(page - 1)}
//             totalPages={reports?.totalPages}
//           /> */}

//           <TableComponent
//             key={pageNumber} // Force re-render on page change
//             data={reports?.content || []}
//             title=""
//             columns={columns}
//             pagination={10}
//             hasAuthority={userAuthorizedPages?.find(
//               (page) => page?.action === "ADMIN_VIEW_REPORTS"
//             )}
//             customPagination={true}
//             // zero index based
//             onPageChange={(page) => setPageNumber(page - 1)}
//             totalPages={reports?.totalPages || 1}
//             loading={reportsLoading}
//           />
//         </div>

// <ToastComponent
//   showToast={showToast}
//   setShowToast={setShowToast}
//   toastMessage={toastMessage}
// />

//         <br />
//         <br />
//       </div>
//     </div>
//   );
// };

// export default ReportingPage;
