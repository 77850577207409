import React, { createContext, useContext, useState, useEffect } from "react";
import DashboardIcon from "../assets/svgs/dashboardIcon";
import AdminIcon from "../assets/svgs/adminIcon";
import ApplicationIcon from "../assets/svgs/applicationIcon";
import SchoolsIcon from "../assets/svgs/schoolsIcon";
import SettingsIcon from "../assets/svgs/settingsIcon";
import SettlementIcon from "../assets/svgs/settlementIcon";
import ReportingIcon from "../assets/svgs/reportingIcon";
import { useSelector } from "react-redux";

const PermittedAdminLinksContext = createContext(undefined);

const menuItems = [
  {
    path: "/principal",
    label: "Dashboard",
    icon: DashboardIcon,
  },
  {
    path: "/principal/user-management",
    label: "User Management",
    icon: AdminIcon,
  },
  {
    path: "/principal/applications",
    label: "Applications",
    icon: ApplicationIcon,
  },
  {
    path: "/principal/schools",
    label: "Schools",
    icon: SchoolsIcon,
  },
  {
    path: "/principal/reporting",
    label: "Reporting",
    icon: ReportingIcon,
  },
  {
    path: "/principal/notifications",
    label: "Reminders & Notifications",
    icon: SettlementIcon,
  },
  {
    path: "/principal/settings",
    label: "Settings",
    icon: SettingsIcon,
  },
];

const authorityKeywordMap = {
  Dashboard: "dashboard",
  "User Management": ["user", "role"],
  Applications: "application",
  Schools: "schools",
  Reporting: "report",
  "Reminders & Notifications": ["reminders", "notification"],
  Settings: "",
};

export const PermittedAdminNavLinksProvider = ({ children }) => {
  const [permittedLinks, setPermittedLinks] = useState([]);

  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  useEffect(() => {
    // Filter nav links based on user authorities
    const filteredNavLinks = menuItems.filter(({ label }) => {
      const requiredKeywords = authorityKeywordMap[label];

      // Ensure that requiredKeywords is treated as an array
      const requiredKeywordsArray = Array.isArray(requiredKeywords)
        ? requiredKeywords
        : [requiredKeywords];

      // Include links with "" authority keywords or valid permissions
      return (
        requiredKeywordsArray.includes("") || // Always include items with empty keyword
        (requiredKeywordsArray.length > 0 &&
          userAuthorizedPages?.some((authority) =>
            requiredKeywordsArray.some((keyword) =>
              authority?.name?.toLowerCase()?.includes(keyword)
            )
          ))
      );
    });

    setPermittedLinks(filteredNavLinks);
  }, [userAuthorizedPages]);

  console.log(permittedLinks);
  console.log(userAuthorizedPages);

  return (
    <PermittedAdminLinksContext.Provider
      value={{
        permittedLinks,
        setPermittedLinks,
      }}
    >
      {children}
    </PermittedAdminLinksContext.Provider>
  );
};

export const usePermittedAdminLinks = () => {
  const context = useContext(PermittedAdminLinksContext);

  return context;
};
