import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DashboardSidebar from "../dashboardSidebar/sidebar";
import DashboardTopSection from "../dashboardTopSection";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dropdownIcon from "../../../../../assets/svgs/dropdownIcon.svg";
import { useGetInvoicesQuery } from "../../../../../redux/features/institution/studentApiSlice";
import Select from "react-dropdown-select";
import {
  useCreateFeeReminderMessageMutation,
  useCreateOtherReminderMessageMutation,
  useGetAllBranchesQuery,
  useGetAllClassesQuery,
  useGetAllStudentsQuery,
  useGetDashboardReportQuery,
  useGetFeeReminderMessagePreviewQuery,
  useGetInstitutionQuery,
  useGetOneMessageReminderQuery,
  useGetOtherReminderMessagePreviewQuery,
  useGetPeriodHolidaysQuery,
  useUpdateFeeReminderMessageMutation,
  useGetStudentsByCategoriesQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import ToastComponent from "../../../../../component/toast/toast";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { addTrailingCommas } from "../../../../../utils/utils";
import { TroubleshootRounded } from "@mui/icons-material";
import SelectComponent from "../../../../../component/select/select";
import MultiSelectDropdown from "../../../../../component/select/multiSelectDropdown";

const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#EDEDED" : "#1c1a1a",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#EDEDED",
    fontSize: 0.8 + "rem",
    //padding: "0px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    //backgroundColor: "#212529",
    //padding: "10px",
    border: "none",
    boxShadow: "none",
    fontFamily: "jost, sans-serif",
    backgroundColor: "#EDEDED",
    fontSize: 0.8 + "rem",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
};

const charactersPerPage = 160;

const ReminderManagementPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const { reminderId } = useParams();
  const isEditing = !!reminderId;

  //   console.log("MATCH ", reminderId);
  //   console.log("HISTORY ", isEditing);

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const [showFeeReminder, setShowFeeReminder] = useState(true);
  const [showInvoiceDropdown, setShowInvoiceDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsNames, setSelectedItemsNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [scheduleMessage, setScheduleMessage] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const [showApplyToHoliday, setShowApplyToHoliday] = useState(false);
  const [showApplyToBranch, setShowApplyToBranch] = useState(false);
  const [showApplyToClass, setShowApplyToClass] = useState(false);
  const [showApplyToStudent, setShowApplyToStudent] = useState(false);
  const [feeMessageObject, setFeeMessageObject] = useState({
    messageTitle: "",
    messageBody: "",
    messageType: "EMAIL_AND_SMS",
    messageScheduledDate: "",
    invoiceId: "",
    institutionId: instId,
  });
  const [otherMessageObject, setOtherMessageObject] = useState({
    otherMessageTitle: "",
    otherMessageBody: "",
    otherMessageType: "",
    otherMessageScheduledDate: "",
    selectedPublicHolidayId: 0,
    institutionId: 0,
    branchesId: [],
    classesId: [],
    selectedStudentId: [],
  });
  const [selectedMessageType, setSelectedMessageType] =
    useState("EMAIL_AND_SMS");

  const [selectedOtherMessageType, setSelectedOtherMessageType] =
    useState("EMAIL_AND_SMS");

  console.log(selectedOtherMessageType);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [selectedStudentOptions, setSelectedStudentOptions] = useState([]);
  const [selectedClassOptions, setSelectedClassOptions] = useState([]);
  const [selectedBranchOptions, setSelectedBranchOptions] = useState([]);
  const {
    messageTitle,
    messageBody,
    messageType,
    messageScheduledDate,
    invoiceId,
    institutionId,
  } = feeMessageObject;

  const {
    otherMessageTitle,
    otherMessageBody,
    otherMessageType,
    otherMessageScheduledDate,
    selectedPublicHolidayId,
    otherMessageInstitutionId,
  } = otherMessageObject;
  const { otherEmailSMS, otherSms, otherEmail } = selectedOtherMessageType;
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const { data: invoiceList, isLoading: invoiceListLoading } =
    useGetInvoicesQuery(id);

  const [categoryParamsForStudentList, setCategoryParamsForStudentList] =
    useState([]);

  const stud_querys = {
    institutionId: id,
    body: { categoryIds: categoryParamsForStudentList },
  };

  const {
    data: studentsData,
    refetch: refetchStudents,
    isLoading: studentsLoading,
  } = useGetStudentsByCategoriesQuery(stud_querys, {
    skip: categoryParamsForStudentList?.length < 1,
  });

  useEffect(() => {
    if (studentsData && categoryParamsForStudentList?.length > 0) {
      refetchStudents();
    }
  }, [categoryParamsForStudentList]);

  const { data: institutionDetails } = useGetInstitutionQuery(id);

  const {
    data: feeMessagePreviewData,
    isLoading: feeMessagePreviewLoading,
    refetch: refetchFeeMessagePreview,
    error: previewError,
  } = useGetFeeReminderMessagePreviewQuery(
    {
      //...feeMessageObject,
      messageBody: "messageBody",
      messageTitle: "messageTitle",
      messageType: selectedMessageType,
      invoiceId: invoiceId,
      institutionId: id,
    },
    {
      skip: !selectedMessageType || !messageBody || !messageTitle || !invoiceId,
    }
  );

  console.log(feeMessagePreviewData);

  useEffect(() => {
    if (previewError) {
      setShowToast(true);
      // add time out
      setToastMessage(previewError?.detail || previewError?.data?.detail);
      // setToastMessage(previewError?.data?.detail);

      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [previewError]);

  const {
    data: otherMessagePreviewData,
    isLoading: otherMessagePreviewLoading,
    refetch: refetchOtherMessagePreview,
  } = useGetOtherReminderMessagePreviewQuery(
    {
      messageType: selectedOtherMessageType,
      messageBody: "otherMessageBody",
      messageTitle: "otherMessageTitle",
      institutionId: id,
      selectedPublicHolidayId: Number(
        otherMessageObject?.selectedPublicHolidayId
      ),
      selectedStudentId: selectedStudentOptions,
    },
    {
      skip: !otherMessageBody || !otherMessageTitle, //|| !selectedPublicHolidayId,
    }
  );

  console.log(otherMessagePreviewData);

  const { data: dashboardRec, isLoading: dashboardDataLoading } =
    useGetDashboardReportQuery(id);
  //   console.log("dashboardRec", dashboardRec);

  const {
    data: oneReminder,
    isLoading: getOneReminderLoading,
    refetch: refetchOneReminder,
  } = useGetOneMessageReminderQuery(reminderId, { skip: !reminderId });

  const holidayparams = {
    institutionId: id,
    calendarId: dashboardRec?.activeCalendar?.id,
  };

  const { data: holidaysData, isLoading: holidaysDataLoading } =
    useGetPeriodHolidaysQuery(holidayparams, {
      skip: !dashboardRec?.activeCalendar?.id,
    });

  const { data: branchesData, isLoading: branchesDataLoading } =
    useGetAllBranchesQuery(id);

  const studentsOptions = studentsData?.map((item) => {
    return {
      id: item?.id,
      name:
        item?.firstname +
        " " +
        item?.lastname +
        " " +
        `(Reg. No: ${item?.studentNo})`,
    };
  });

  const branchesOptions = branchesData?.map((item) => {
    return { value: item?.id, label: item?.name };
  });

  const [createFeeReminder, { isLoading: createReminderLoading }] =
    useCreateFeeReminderMessageMutation();

  const [updateFeeReminder, { isLoading: updateReminderLoading }] =
    useUpdateFeeReminderMessageMutation();

  const [createOtherReminder, { isLoading: createOtherReminderLoading }] =
    useCreateOtherReminderMessageMutation();

  const invoiceOptions = invoiceList?.map((item) => {
    return { value: item?.id, label: item?.title };
  });
  const {
    data: classesData,
    refetch: refetchClasses,
    isLoading: classesLoading,
  } = useGetAllClassesQuery(id);

  const handleInvoiceOptionsChange = (selected) => {
    //setSelectedStudentOptions(selected);
    console.log(selected, 77777);
    // const studentId = selected?.map((item) => {
    //     return item.value;
    // })
    feeMessageObject.invoiceId = selected[0]?.value;
    console.log(feeMessageObject, 66666);
  };

  const AllClasses = [
    ...Object.values(classesData || {})?.filter((array) =>
      Array.isArray(array)
    ),
  ].flat();

  const classesOptions = AllClasses?.flatMap((item) =>
    item?.categories?.map((category) => ({
      name: `${item.name} - ${category.name}`,
      id: category.id,
    }))
  );

  useEffect(() => {
    // Load record data when editing
    if (isEditing) {
      refetchOneReminder();
      if (oneReminder) {
        // const selectedInvoices = oneReminder?.invoice || [];

        // const invoiceIds = selectedInvoices?.map(invoice => invoice.id);
        // const invoiceTitles = selectedInvoices?.map(invoice => invoice.title);
        // const invoiceIds = [oneReminder?.invoice?.id];
        // const invoiceTitles = [oneReminder?.invoice?.title];
        //console.log("AllInstitutions ", invoiceIds);
        setShowFeeReminder(
          oneReminder?.reminderType === "FEE_REMINDER" ? true : false
        );
        if (showFeeReminder) {
          setFeeMessageObject({
            messageTitle: oneReminder?.messageTitle,
            messageBody: oneReminder?.messageBody,
            messageType: oneReminder?.messageType,
            messageScheduledDate: oneReminder?.scheduledDate,
            invoiceId: oneReminder?.invoice?.id,
          });
          //feeMessageObject.invoiceId = oneReminder?.invoice?.id;
          // setSelectedItems(invoiceIds);
          // setSelectedItemsNames(invoiceTitles);
          if (oneReminder?.messageType === "EMAIL_AND_SMS") {
            setSelectedMessageType({
              emailSMS: true,
              sms: false,
              email: false,
            });
            //messageObject.messageType = "EMAIL_AND_SMS";
          } else if (oneReminder?.messageType === "SMS") {
            setSelectedMessageType({
              emailSMS: false,
              sms: true,
              email: false,
            });
            //messageObject.messageType = "SMS";
          } else {
            setSelectedMessageType({
              emailSMS: false,
              sms: false,
              email: true,
            });
            //messageObject.messageType = "EMAIL";
          }
          if (oneReminder?.scheduledDate !== null) {
            setScheduleMessage(true);
            //selecte
          }
        } else {
          setOtherMessageObject({
            otherMessageTitle: oneReminder?.messageTitle,
            otherMessageBody: oneReminder?.messageBody,
            otherMessageType: oneReminder?.messageType,
            otherMessageScheduledDate: oneReminder?.scheduleMessageDate,
            selectedPublicHolidayId: oneReminder?.publicHoliday?.id,
          });
          //feeMessageObject.invoiceId = oneReminder?.invoice?.id;
          // setSelectedItems(invoiceIds);
          // setSelectedItemsNames(invoiceTitles);
          if (oneReminder?.messageType === "EMAIL_AND_SMS") {
            setSelectedOtherMessageType({
              emailSMS: true,
              sms: false,
              email: false,
            });
            //messageObject.messageType = "EMAIL_AND_SMS";
          } else if (oneReminder?.messageType === "SMS") {
            setSelectedOtherMessageType({
              emailSMS: false,
              sms: true,
              email: false,
            });
            //messageObject.messageType = "SMS";
          } else {
            setSelectedOtherMessageType({
              emailSMS: false,
              sms: false,
              email: true,
            });
            //messageObject.messageType = "EMAIL";
          }
          if (oneReminder?.scheduledDate !== null) {
            setScheduleMessage(true);
            //selecte
          }
          if (
            selectedPublicHolidayId !== null ||
            selectedPublicHolidayId !== undefined
          ) {
            setShowApplyToHoliday(false);
            //selecte
          }
        }
      }
    }
  }, [isEditing, reminderId, oneReminder]);

  const handleOnBranchChange = (selected) => {
    setSelectedBranchOptions(selected);
    console.log(selected, 1111111);
    const branchIds = selected?.map((item) => {
      return item;
    });
    otherMessageObject.branchesId = branchIds;
  };

  const handleOnClassChange = (selected) => {
    setSelectedClassOptions(selected);
    console.log(selected, 9999999);
    const classIds = selected?.map((item) => {
      return item;
    });
    otherMessageObject.classesId = classIds;
    setCategoryParamsForStudentList(classIds);
  };

  const handleStudentsOptionsChange = (selected) => {
    setSelectedStudentOptions(selected);
    console.log(selected, 77777);
    const studentIds = selected?.map((item) => {
      return item;
    });
    otherMessageObject.selectedStudentId = studentIds;
    console.log(otherMessageObject, 66666);
  };

  //   console.log("otherMessageObject", otherMessageObject);

  console.log(selectedMessageType);
  // console.log(selectedOtherMessageType);
  console.log(feeMessageObject);

  const handleOnChange = (e) => {
    //e.preventDefault();
    const { name, value } = e.target;
    setFeeMessageObject({ ...feeMessageObject, [name]: value });
  };

  const handleOtherMessageOnChange = (e) => {
    //e.preventDefault();
    const { name, value } = e.target;
    setOtherMessageObject({ ...otherMessageObject, [name]: value });
    // if (e.key === 'Enter') {
    //   setOtherMessageObject({ ...otherMessageObject, [name]: otherMessageBody + '\n' });
    // } else {
    //   setOtherMessageObject({ ...otherMessageObject, [name]: value });
    // }
  };

  const pageCount = Math.ceil(messageBody.length / charactersPerPage);
  const otherPageCount = Math.ceil(otherMessageBody.length / charactersPerPage);

  const handleApplyToBranchCheckbox = () => {
    setShowApplyToBranch(!showApplyToBranch);
  };
  const handleApplyToClassCheckbox = () => {
    setShowApplyToClass(!showApplyToClass);
  };
  const handleApplyToStudentCheckbox = () => {
    setShowApplyToStudent(!showApplyToStudent);
  };

  console.log(feeMessageObject);

  const handleCreateFeeReminderMessage = async () => {
    // const params = {
    //     body: { ...feeMessageObject, institutionId: id }
    // };

    //console.log(params);
    try {
      if (isEditing) {
        const params = {
          ...feeMessageObject,
          institutionId: id,
          id: reminderId,
        };
        const res = await updateFeeReminder(params).unwrap();
        console.log(res);
      } else {
        const { messageType, ...rest } = feeMessageObject;

        const params = {
          body: {
            ...rest,
            messageType: messageType.toUpperCase(),
            institutionId: id,
            invoiceId: Number(feeMessageObject?.invoiceId),
          },
        };
        const res = await createFeeReminder(params).unwrap();
        console.log(res);
      }
      //const res = await createFeeReminder(params).unwrap();
      //console.log(res);
      setShowToast(true);
      setToastMessage("Message Sent successfully!");
      setFeeMessageObject({
        messageTitle: "",
        messageBody: "",
        invoiceId: "",
        messageScheduledDate: "",
        messageType: "",
      });
      setScheduleMessage(false);
      setSelectedMessageType("EMAIL_AND_SMS");
      navigate("/messaging/viewmessages");
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleCreateOtherReminderMessage = async () => {
    const params = {
      body: {
        institutionId: id,
        selectedPublicHolidayId: Number(
          otherMessageObject?.selectedPublicHolidayId
        ),
        messageBody: otherMessageObject?.otherMessageBody,
        // messageType: otherMessageObject?.otherMessageType,
        messageType: selectedOtherMessageType,
        messageTitle: otherMessageObject?.otherMessageTitle,
        messageScheduledDate: otherMessageObject.otherMessageScheduledDate,
        branchesId: otherMessageObject?.branchesId,
        classesId: otherMessageObject?.classesId,
        selectedStudentId: otherMessageObject?.selectedStudentId,
      },
    };

    console.log(params);
    try {
      const res = await createOtherReminder(params).unwrap();
      setShowToast(true);
      setToastMessage("Message Sent successfully!");
      setOtherMessageObject({
        otherMessageTitle: "",
        otherMessageBody: "",
        branchesId: [],
        otherMessageScheduledDate: "",
        otherMessageType: "",
        selectedPublicHolidayId: 0,
        classesId: [],
        selectedStudentId: [],
      });
      setScheduleMessage(false);
      setSelectedOtherMessageType("EMAIL_AND_SMS");
      setSelectedStudentOptions([]);
      setSelectedClassOptions([]);
      setShowApplyToStudent(false);
      setShowApplyToClass(false);
      // refetchOtherMessagePreview();
      navigate("/messaging/viewmessages");
    } catch (error) {
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleViewMessages = () => {
    //alert("It's working");
    navigate("/messaging/viewmessages");
  };

  if (
    invoiceListLoading ||
    createReminderLoading ||
    holidaysDataLoading ||
    dashboardDataLoading ||
    branchesDataLoading ||
    classesLoading ||
    // studentsLoading ||
    createOtherReminderLoading ||
    getOneReminderLoading ||
    updateReminderLoading
  ) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />
      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // gap: "20px"
            marginTop: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              marginBottom: "15px",
            }}
          >
            <h5 style={{ marginBottom: 0 }}>
              Reminders & Notifications Management
            </h5>
          </div>
        </div>
        <div>
          <Button
            variant="primary"
            style={{
              border: "1px solid",
              borderRadius: "50px",
              borderColor: "#FD5821",
              backgroundColor: `${
                !showFeeReminder ? "rgb(244, 244, 244)" : "#FFEDE8"
              }`,
              marginLeft: "0px",
            }}
            size="md"
            onClick={(e) => setShowFeeReminder(true)}
          >
            <div
              style={{
                display: "flex",
                gap: "2px",
              }}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  color: "black",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Fee Reminder
              </span>
            </div>
          </Button>

          <Button
            variant="primary"
            style={{
              border: "1px solid",
              borderRadius: "50px",
              backgroundColor: `${
                showFeeReminder ? "rgb(244, 244, 244)" : "#FFEDE8"
              }`,
              borderColor: "#FD5821",
              marginLeft: "10px",
            }}
            size="md"
            onClick={(e) => setShowFeeReminder(false)}
          >
            <div
              style={{
                display: "flex",
                gap: "2px",
              }}
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  color: "black",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Notifications
              </span>
            </div>
          </Button>
        </div>

        {showFeeReminder && (
          <Row>
            <Col xs={12} md={6} lg={6}>
              <div style={{ paddingTop: 1.5 + "rem", marginBottom: 2 + "rem" }}>
                <div
                  style={{
                    border: "2px solid #EDEDED",
                    borderRadius: "8px",
                    gap: "30px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "2px solid #EDEDED",
                      padding: "10px",
                      gap: "10px",
                      marginBottom: "15px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        color: "#F14810",
                      }}
                    >
                      <h6 style={{ fontWeight: "600", marginTop: "5px" }}>
                        {isEditing
                          ? "Edit & Send Fee Reminders"
                          : "Send Fee Reminders"}
                      </h6>
                    </div>

                    {userAuthorizedPages?.find(
                      (page) => page?.action === "VIEW_REMINDERS"
                    ) && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          cursor: "pointer",
                        }}
                        onClick={handleViewMessages}
                      >
                        <p
                          style={{
                            color: "#F14810",
                            fontWeight: "600",
                            marginBottom: "0px",
                          }}
                        >
                          View Sent Reminders
                        </p>
                      </div>
                    )}
                  </div>

                  <div>
                    <p style={{ padding: "0 10px" }}>
                      This is where you can structure your fee reminder
                      templates based on how you want them to be sent. You can
                      select either Email & SMS, or Email Only, or SMS Only.
                    </p>

                    <div
                      style={{
                        flexBasis: "60%",
                        padding: "10px 15px",
                        minWidth: "150px",
                        overflowY: "auto",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="formClasslabel">
                            Invoice
                          </Form.Label>

                          <Form.Select
                            size="md"
                            className="custom-focus"
                            style={{
                              fontFamily: "jost, sans-serif",
                              backgroundColor: "#EDEDED",
                              fontSize: 0.8 + "rem",
                            }}
                            name="invoiceId"
                            value={
                              isEditing
                                ? invoiceId
                                : feeMessageObject?.invoiceId
                            }
                            onChange={(e) => handleOnChange(e)}
                          >
                            <option value="" defaultValue disabled>
                              Select Invoice
                            </option>
                            {invoiceList &&
                              invoiceList?.length > 0 &&
                              invoiceList?.map(({ id, title }) => (
                                <option value={id} key={id}>
                                  {title}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>

                        <p
                          style={{
                            marginBottom: "5px",
                            fontWeight: "500",
                          }}
                        >
                          Message Title{" "}
                          <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                            *
                          </span>
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Message Title"
                          name="messageTitle"
                          value={messageTitle}
                          //value={editingElement ? editingValue : name}
                          onChange={(e) => handleOnChange(e)}
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#ededed",
                            width: "100%",
                            marginBottom: "15px",
                            padding: "8px",
                            outline: "none",
                            border: "none",
                            fontFamily: "jost, sans-serif",
                            fontSize: 0.8 + "rem",
                          }}
                        />

                        <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                          Message Body{" "}
                          <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                            *
                          </span>
                        </p>
                        <textarea
                          //type="textarea"
                          rows={5}
                          placeholder="Enter Message Body"
                          name="messageBody"
                          value={messageBody}
                          // value={editingElement ? editingValue : name}
                          onChange={(e) => handleOnChange(e)}
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#ededed",
                            width: "100%",
                            marginBottom: "15px",
                            padding: "8px",
                            outline: "none",
                            border: "none",
                            fontFamily: "jost, sans-serif",
                            fontSize: 0.8 + "rem",
                          }}
                        />
                        <div
                          style={{
                            fontFamily: "jost, sans-serif",
                            fontSize: 0.8 + "rem",
                            marginTop: 0,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          Characters: {messageBody?.length} | Pages: {pageCount}
                        </div>
                        <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                          Message Type{" "}
                          <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                            *
                          </span>
                        </p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "15px",
                            }}
                          >
                            <Form.Check
                              inline
                              name="emailSMS"
                              onChange={() => {
                                setSelectedMessageType("EMAIL_AND_SMS");
                                feeMessageObject.messageType = "EMAIL_AND_SMS";
                                refetchFeeMessagePreview();
                              }}
                              checked={selectedMessageType === "EMAIL_AND_SMS"}
                              //   checked={emailSMS}
                              type="radio"
                              id={`inline-radio-2`}
                              size={25}
                              style={{ marginRight: "10px" }}
                            />
                            <p style={{ marginBottom: "0" }}>Email & SMS</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "15px",
                            }}
                          >
                            <Form.Check
                              inline
                              name="sms"
                              //value="sms"
                              //   onChange={(e) => handleMessageTypeRadioChange(e)}
                              //   checked={sms}
                              onChange={() => {
                                setSelectedMessageType("SMS");
                                feeMessageObject.messageType = "SMS";
                                refetchFeeMessagePreview();
                              }}
                              checked={selectedMessageType === "SMS"}
                              defaultChecked
                              type="radio"
                              id={`inline-radio-2`}
                              size={25}
                              style={{ marginRight: "10px" }}
                            />
                            <p style={{ marginBottom: "0" }}>SMS Only</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Form.Check
                              inline
                              name="email"
                              onChange={() => {
                                setSelectedMessageType("EMAIL");
                                feeMessageObject.messageType = "EMAIL";
                                refetchFeeMessagePreview();
                              }}
                              checked={selectedMessageType === "EMAIL"}
                              type="radio"
                              id={`inline-radio-2`}
                              size={25}
                              style={{ marginRight: "10px" }}
                            />
                            <p style={{ marginBottom: "0" }}>Email Only</p>
                          </div>
                        </div>

                        <div
                          className="calender-switch-container"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "15px",
                          }}
                        >
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={scheduleMessage}
                              name="messageSchedule"
                              onChange={() =>
                                setScheduleMessage(!scheduleMessage)
                              }
                              className="toggle"
                            />
                            <span className="slider round"></span>
                          </label>
                          <label className="toggle-label">
                            Schedule Reminder
                          </label>
                        </div>

                        {scheduleMessage && (
                          <input
                            type="datetime-local"
                            placeholder="Enter Date and Time"
                            name="messageScheduledDate"
                            value={messageScheduledDate}
                            onChange={(e) => handleOnChange(e)}
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#ededed",
                              width: "100%",
                              marginBottom: "15px",
                              padding: "8px",
                              outline: "none",
                              border: "none",
                              fontFamily: "jost, sans-serif",
                              fontSize: 0.8 + "rem",
                              marginTop: "15px",
                            }}
                          />
                        )}

                        <div style={{ float: "left" }}>
                          {userAuthorizedPages?.find(
                            (page) => page?.action === "SET_REMINDER"
                          ) && (
                            <Button
                              className="button"
                              size="md"
                              style={{
                                backgroundColor: "#F14810",
                                color: "#ffffff",
                                border: "none",
                                outline: "none",
                                fontSize: "14px",
                                padding: "8px 20px",
                                marginTop: "20px",
                                marginLeft: "auto",
                              }}
                              onClick={handleCreateFeeReminderMessage}
                            >
                              {isEditing
                                ? "Send Edited Message"
                                : "Send Message"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} md={6} lg={6}>
              <div style={{ paddingTop: 1.5 + "rem", marginBottom: 2 + "rem" }}>
                <div
                  style={{
                    border: "2px solid #EDEDED",
                    borderRadius: "8px",
                    gap: "30px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "2px solid #EDEDED",
                      padding: "10px",
                      gap: "10px",
                      marginBottom: "15px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        color: "#F14810",
                      }}
                    >
                      <h6 style={{ fontWeight: "600", marginTop: "5px" }}>
                        Preview
                      </h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 5,
                      }}
                    ></div>
                  </div>

                  {feeMessagePreviewLoading ? (
                    <OnboardingLoader />
                  ) : (
                    <div style={{ padding: "15px 15px" }}>
                      <Row>
                        <Col>
                          <p style={{ fontWeight: "600" }}>
                            Invoice Title: <br />
                            <span
                              style={{ fontWeight: "400", fontStyle: "italic" }}
                            >
                              {feeMessagePreviewData?.invoice?.title}
                            </span>
                          </p>
                        </Col>
                        <Col>
                          <p style={{ fontWeight: "600" }}>
                            Invoice Due Date: <br />
                            <span
                              style={{ fontWeight: "400", fontStyle: "italic" }}
                            >
                              {feeMessagePreviewData?.invoice?.dueDate}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{ fontWeight: "600" }}>
                            Expected Amount: <br />
                            <span
                              style={{ fontWeight: "400", fontStyle: "italic" }}
                            >
                              ₦{" "}
                              {addTrailingCommas(
                                feeMessagePreviewData?.expectedAmount
                              )}
                            </span>
                          </p>
                        </Col>
                        <Col>
                          <p style={{ fontWeight: "600" }}>
                            Outstanding Amount: <br />
                            <span
                              style={{ fontWeight: "400", fontStyle: "italic" }}
                            >
                              ₦{" "}
                              {addTrailingCommas(
                                feeMessagePreviewData?.outstandingAmount
                              )}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <p style={{ fontWeight: "600" }}>
                        Total No. of Students: <br />
                        <span
                          style={{ fontWeight: "400", fontStyle: "italic" }}
                        >
                          {feeMessagePreviewData?.totalNoOfStudents}
                        </span>
                      </p>
                      <Row>
                        <Col>
                          <p style={{ fontWeight: "600" }}>
                            No of Student Paid: <br />
                            <span
                              style={{ fontWeight: "400", fontStyle: "italic" }}
                            >
                              {feeMessagePreviewData?.noOfStudentPaidStatus}
                            </span>
                          </p>
                        </Col>
                        <Col>
                          <p style={{ fontWeight: "600" }}>
                            No of Students Unpaid: <br />
                            <span
                              style={{ fontWeight: "400", fontStyle: "italic" }}
                            >
                              {feeMessagePreviewData?.noOfStudentUnPaidStatus}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <div
                          style={{
                            border: "2px solid #EDEDED",
                            borderRadius: "8px",
                            gap: "30px",
                            backgroundColor: "#EDEDED",
                            margin: "10px",
                            //marginTop: "25px",
                            //display: "flex",
                            width: "96%",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "600",
                              color: "#F14810",
                              padding: 10,
                            }}
                          >
                            Message Title: <br />
                            <span
                              style={{
                                fontWeight: "400",
                                color: "black",
                                textJustify: "inter-word",
                                textAlignLast: "justify",
                              }}
                            >
                              {messageTitle}
                            </span>
                          </p>
                          {/* <br /> */}
                          {/* <hr /> */}
                          <p
                            style={{
                              fontWeight: "600",
                              color: "#F14810",
                              padding: 10,
                              marginTop: 5,
                            }}
                          >
                            Message Body: <br />
                            <span
                              style={{
                                fontWeight: "400",
                                color: "black",
                                textJustify: "inter-word",
                                textAlignLast: "justify",
                              }}
                            >
                              {messageBody}
                            </span>
                          </p>
                        </div>
                      </Row>
                      <hr />
                      <p style={{ fontWeight: "600", color: "#F14810" }}>
                        Service Account Balance: <br />
                        <span
                          style={{
                            fontWeight: "400",
                            fontStyle: "italic",
                            color: "black",
                          }}
                        >
                          ₦{" "}
                          {addTrailingCommas(
                            feeMessagePreviewData?.serviceAccountBalance
                          )}
                        </span>
                      </p>
                      <p style={{ fontWeight: "600", color: "#F14810" }}>
                        Email & SMS Charges: <br />
                        <span
                          style={{
                            fontWeight: "400",
                            fontStyle: "italic",
                            color: "black",
                          }}
                        >
                          ₦{" "}
                          {addTrailingCommas(
                            feeMessagePreviewData?.chargesPerMessage
                          )}
                        </span>
                      </p>
                      <p style={{ fontWeight: "600", color: "#F14810" }}>
                        Total Charges: <br />
                        <span
                          style={{
                            fontWeight: "400",
                            fontStyle: "italic",
                            color: "black",
                          }}
                        >
                          ₦{" "}
                          {addTrailingCommas(
                            feeMessagePreviewData?.totalCharges
                          )}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}

        {!showFeeReminder && (
          <Row>
            <Col xs={12} md={6} lg={6}>
              <div style={{ paddingTop: 1.5 + "rem", marginBottom: 2 + "rem" }}>
                <div
                  style={{
                    border: "2px solid #EDEDED",
                    borderRadius: "8px",
                    gap: "30px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "2px solid #EDEDED",
                      padding: "10px",
                      gap: "10px",
                      marginBottom: "15px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        color: "#F14810",
                      }}
                    >
                      <h6 style={{ fontWeight: "600", marginTop: "5px" }}>
                        {/* Send Other Reminders */}
                        {isEditing
                          ? "Edit & Send Notifications"
                          : "Send Notifications"}
                      </h6>
                    </div>

                    {userAuthorizedPages?.find(
                      (page) => page?.action === "VIEW_NOTIFICATION"
                    ) && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          cursor: "pointer",
                        }}
                        onClick={handleViewMessages}
                      >
                        <p style={{ color: "#F14810", fontWeight: "600" }}>
                          View Sent Reminders & Notifications
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <p style={{ marginLeft: "15px" }}>
                      This is where you can structure other reminder types
                      especially holiday reminders based on how you want them to
                      be sent. You can select either Email & SMS, or Email Only,
                      or SMS Only.
                    </p>
                    <div
                      style={{
                        flexBasis: "60%",
                        padding: "10px 15px",
                        minWidth: "150px",
                        overflowY: "auto",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p
                          style={{
                            marginBottom: "5px",
                            fontWeight: "500",
                            marginTop: "2px",
                          }}
                        >
                          Message Title{" "}
                          <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                            *
                          </span>
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Message Title"
                          name="otherMessageTitle"
                          value={otherMessageTitle}
                          // value={editingElement ? editingValue : name}
                          onChange={(e) => handleOtherMessageOnChange(e)}
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#ededed",
                            width: "100%",
                            marginBottom: "15px",
                            padding: "8px",
                            outline: "none",
                            border: "none",
                            fontFamily: "jost, sans-serif",
                            fontSize: 0.8 + "rem",
                          }}
                        />

                        <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                          Message Body{" "}
                          <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                            *
                          </span>
                        </p>
                        <textarea
                          //type="textarea"
                          rows={5}
                          placeholder="Enter Message Body"
                          name="otherMessageBody"
                          value={otherMessageBody}
                          // value={editingElement ? editingValue : name}
                          onChange={(e) => handleOtherMessageOnChange(e)}
                          onKeyDown={(e) => handleOtherMessageOnChange(e)} // This line adds the keydown event listener
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#ededed",
                            width: "100%",
                            marginBottom: "15px",
                            padding: "8px",
                            outline: "none",
                            border: "none",
                            fontFamily: "jost, sans-serif",
                            fontSize: 0.8 + "rem",
                          }}
                        />
                        <div
                          style={{
                            fontFamily: "jost, sans-serif",
                            fontSize: 0.8 + "rem",
                            marginTop: 0,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          Characters: {otherMessageBody?.length} | Pages:{" "}
                          {otherPageCount}
                        </div>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.CheckboxInput"
                        >
                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="formClasslabel">
                              Holiday{" "}
                            </Form.Label>

                            <Form.Select
                              size="md"
                              className="custom-focus"
                              style={{
                                fontFamily: "jost, sans-serif",
                                backgroundColor: "#EDEDED",
                                fontSize: 0.8 + "rem",
                              }}
                              name="selectedPublicHolidayId"
                              value={selectedPublicHolidayId}
                              onChange={(e) => handleOtherMessageOnChange(e)}
                            >
                              <option value="" defaultValue>
                                Select Holiday
                              </option>
                              {holidaysData &&
                                holidaysData?.length > 0 &&
                                holidaysData?.map(
                                  ({ id, name, startDate, endDate }) => (
                                    <option value={id} key={id}>
                                      {name}: ({startDate} - {endDate})
                                    </option>
                                  )
                                )}
                            </Form.Select>
                          </Form.Group>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.CheckboxInput"
                        >
                          <Form.Check
                            size="md"
                            className="custom-focus"
                            style={{
                              //border: "1px solid",
                              borderColor: "#F14810",
                              marginRight: "10px",
                              //padding: "10px"
                            }}
                            type="checkbox"
                            name="applytostudent"
                            //value={lastname}
                            onChange={handleApplyToBranchCheckbox}
                            placeholder="Select Branch"
                            inline={true}
                          />
                          <Form.Label className="formClasslabel">
                            Select Branch
                          </Form.Label>

                          <Form.Check
                            size="md"
                            className="custom-focus"
                            style={{
                              //border: "1px solid",
                              borderColor: "#F14810",
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                            type="checkbox"
                            name="applytostudent"
                            //value={lastname}
                            onChange={handleApplyToClassCheckbox}
                            placeholder="Select Class"
                            inline={true}
                          />
                          <Form.Label className="formClasslabel">
                            Select Class
                          </Form.Label>

                          <Form.Check
                            size="md"
                            className="custom-focus"
                            style={{
                              //border: "1px solid",
                              borderColor: "#F14810",
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                            type="checkbox"
                            name="applytostudent"
                            //value={lastname}
                            onChange={handleApplyToStudentCheckbox}
                            placeholder="Select Student"
                            inline={true}
                          />
                          <Form.Label className="formClasslabel">
                            Select Student
                          </Form.Label>
                        </Form.Group>

                        {showApplyToBranch && (
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="formClasslabel">
                              Branch
                            </Form.Label>
                            <MultiSelectDropdown
                              selectedItems={selectedBranchOptions}
                              options={branchesData || []}
                              onSelect={handleOnBranchChange}
                            />
                          </Form.Group>
                        )}

                        {showApplyToClass && (
                          <Form.Group
                            className="mb-3"
                            controlId="formMultiSelect"
                          >
                            <Form.Label className="formClasslabel">
                              Class
                            </Form.Label>
                            <MultiSelectDropdown
                              selectedItems={selectedClassOptions}
                              options={classesOptions || []}
                              onSelect={handleOnClassChange}
                            />
                          </Form.Group>
                        )}

                        {showApplyToStudent && (
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="formClasslabel">
                              Student
                            </Form.Label>

                            <MultiSelectDropdown
                              selectedItems={selectedStudentOptions}
                              options={studentsOptions || []}
                              onSelect={handleStudentsOptionsChange}
                            />
                          </Form.Group>
                        )}

                        <p style={{ marginBottom: "5px", fontWeight: "500" }}>
                          Message Type{" "}
                          <span style={{ color: "#FD0E0E", fontSize: "18px" }}>
                            *
                          </span>
                        </p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "15px",
                            }}
                          >
                            <Form.Check
                              inline
                              name="emailSMS"
                              // onChange={(e) =>
                              //   handleOtherMessageTypeRadioChange(e)
                              // }
                              // checked={otherEmailSMS}
                              onChange={() => {
                                setSelectedOtherMessageType("EMAIL_AND_SMS");
                                otherMessageObject.messageType =
                                  "EMAIL_AND_SMS";
                              }}
                              checked={
                                selectedOtherMessageType === "EMAIL_AND_SMS"
                              }
                              type="radio"
                              id={`inline-radio-1`}
                              size={25}
                              style={{ marginRight: "10px" }}
                            />
                            <p style={{ marginBottom: "0" }}>Email & SMS</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "15px",
                            }}
                          >
                            <Form.Check
                              inline
                              name="sms"
                              // onChange={(e) =>
                              //   handleOtherMessageTypeRadioChange(e)
                              // }
                              // checked={otherSms}
                              onChange={() => {
                                setSelectedOtherMessageType("SMS");
                                otherMessageObject.messageType = "SMS";
                              }}
                              checked={selectedOtherMessageType === "SMS"}
                              type="radio"
                              id={`inline-radio-2`}
                              size={25}
                              style={{ marginRight: "10px" }}
                            />
                            <p style={{ marginBottom: "0" }}>SMS Only</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Form.Check
                              inline
                              name="email"
                              onChange={() => {
                                setSelectedOtherMessageType("EMAIL");
                                otherMessageObject.messageType = "EMAIL";
                              }}
                              checked={selectedOtherMessageType === "EMAIL"}
                              type="radio"
                              id={`inline-radio-3`}
                              size={25}
                              style={{ marginRight: "10px" }}
                            />
                            <p style={{ marginBottom: "0" }}>Email Only</p>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "15px",
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          <Form.Check
                            inline
                            name="rate-type"
                            checked={scheduleMessage}
                            onChange={() =>
                              setScheduleMessage(!scheduleMessage)
                            }
                            type="switch"
                            id={`inline-radio-2`}
                            size={25}
                            style={{ marginRight: "10px" }}
                          />
                          <p style={{ marginBottom: "0" }}>
                            Schedule Notification
                          </p>
                        </div>

                        {scheduleMessage && (
                          <input
                            type="datetime-local"
                            placeholder="Enter Date and Time"
                            name="otherMessageScheduledDate"
                            value={otherMessageScheduledDate}
                            //value={editingElement ? editingValue : name}
                            onChange={(e) => handleOtherMessageOnChange(e)}
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#ededed",
                              width: "100%",
                              marginBottom: "15px",
                              padding: "8px",
                              outline: "none",
                              border: "none",
                              fontFamily: "jost, sans-serif",
                              fontSize: 0.8 + "rem",
                            }}
                          />
                        )}

                        <div style={{ float: "left" }}>
                          {userAuthorizedPages?.find(
                            (page) => page?.action === "SET_NOTIFICATION"
                          ) && (
                            <Button
                              className="button"
                              size="md"
                              style={{
                                backgroundColor: "#F14810",
                                color: "#ffffff",
                                border: "none",
                                outline: "none",
                                fontSize: "14px",
                                padding: "8px 20px",
                                marginTop: "20px",
                                marginLeft: "auto",
                              }}
                              onClick={(e) =>
                                handleCreateOtherReminderMessage(e)
                              }
                            >
                              {isEditing
                                ? "Send Edited Message"
                                : "Send Message"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <div style={{ paddingTop: 1.5 + "rem", marginBottom: 2 + "rem" }}>
                <div
                  style={{
                    border: "2px solid #EDEDED",
                    borderRadius: "8px",
                    gap: "30px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "2px solid #EDEDED",
                      padding: "10px",
                      gap: "10px",
                      marginBottom: "15px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        color: "#F14810",
                      }}
                    >
                      <h6 style={{ fontWeight: "600", marginTop: "5px" }}>
                        Preview
                      </h6>
                    </div>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 5 }}
                    ></div>
                  </div>

                  {otherMessagePreviewLoading ? (
                    <OnboardingLoader />
                  ) : (
                    <div style={{ padding: "5px 15px" }}>
                      <div
                        style={{
                          border: "2px solid #EDEDED",
                          borderRadius: "8px",
                          gap: "30px",
                          backgroundColor: "#EDEDED",
                          //margin: "5px",
                          //marginTop: "25px",
                          //display: "flex",
                          //width: "95%",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "600",
                            color: "#F14810",
                            padding: 10,
                          }}
                        >
                          Message Title: <br />
                          <span
                            style={{
                              fontWeight: "400",
                              color: "black",
                              textJustify: "inter-word",
                              textAlignLast: "justify",
                            }}
                          >
                            {otherMessageTitle}
                          </span>
                        </p>
                        {/* <br /> */}
                        {/* <hr /> */}
                        <p
                          style={{
                            fontWeight: "600",
                            color: "#F14810",
                            padding: 10,
                            marginTop: 5,
                          }}
                        >
                          Message Body: <br />
                          <span
                            style={{
                              fontWeight: "400",
                              color: "black",
                              textJustify: "inter-word",
                              textAlignLast: "justify",
                            }}
                          >
                            {otherMessageBody}
                          </span>
                        </p>
                      </div>
                      <hr></hr>
                      <p style={{ fontWeight: "600", color: "#F14810" }}>
                        Service Account Balance: <br />
                        <span
                          style={{
                            fontWeight: "400",
                            fontStyle: "italic",
                            color: "black",
                          }}
                        >
                          ₦{" "}
                          {addTrailingCommas(
                            otherMessagePreviewData?.serviceAccountBalance
                          )}
                        </span>
                      </p>
                      <p style={{ fontWeight: "600", color: "#F14810" }}>
                        Email & SMS Charges: <br />
                        <span
                          style={{
                            fontWeight: "400",
                            fontStyle: "italic",
                            color: "black",
                          }}
                        >
                          ₦{" "}
                          {addTrailingCommas(
                            otherMessagePreviewData?.chargesPerMessage
                          )}
                        </span>
                      </p>
                      <p style={{ fontWeight: "600", color: "#F14810" }}>
                        Total Charges: <br />
                        <span
                          style={{
                            fontWeight: "400",
                            fontStyle: "italic",
                            color: "black",
                          }}
                        >
                          ₦{" "}
                          {addTrailingCommas(
                            otherMessagePreviewData?.totalCharges
                          )}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}

        <br />
        <br />
      </Container>
    </Container>
  );
};

export default ReminderManagementPage;
