import { useParams, useNavigate } from "react-router-dom";
// import DashboardTopSection from "../../dashboardTopSection";
// import DashboardSidebar from "../../dashboardSideBar/sidebar";
import backArrow from "../../../../../../../assets/svgs/backArrow.svg";
import alertIcon from "../../../../../../../assets/svgs/alertIcon.svg";
// import editIcon from "../../../../../assets/svgs/editIcon.svg";
import moneyVector from "../../../../../../../assets/images/moneySettlementVector.png";
import ButtonX from "../../../../../../../component/button/button";
import { useState } from "react";
import TableComponent from "../../../../../../../component/table/table";
import { addTrailingCommas } from "../../../../../../../utils/utils";
import { Offcanvas, Button } from "react-bootstrap";
import successGIF from "../../../../../../../assets/svgs/successGIF.gif";
import EditCommissionCanvas from "./editCommissionCanvas";
import {
  useGetSuperSettlementHistoryQuery,
  useGetSuperBalanceInquiryQuery,
  useGetInstitutionSettlementDetailsQuery,
  useSettleInstitutionMutation,
} from "../../../../../../../redux/features/superAdmin/adminApiSlice";
import OnboardingLoader from "../../../../../../../component/onboardingLoader/onboardingLoader";
import ToastComponent from "../../../../../../../component/toast/toast";
import ModalComponent from "../../../../../../../component/modal/modal";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const SettlementDetailsPage = ({
  settlementDetailsView,
  setIsSettlementDetailViewed,
}) => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [historyViewed, setHistoryViewed] = useState(false);
  const [showEditRateCanvas, setShowEditRateCanvas] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // const { id } = useParams();
  const id = settlementDetailsView?.id;
  const navigate = useNavigate();

  const {
    data: bankDetails,
    isLoading: balanceloading,
    refetch: refetchBalance,
  } = useGetSuperBalanceInquiryQuery(id);
  // console.log(bankDetails);

  const {
    data: settlementDetails,
    isLoading: detailsloading,
    refetch: refetchDetails,
  } = useGetInstitutionSettlementDetailsQuery(id);
  // console.log(settlementDetails, 99999999999);

  const [settleInstitution, { isLoading: settlementLoading }] =
    useSettleInstitutionMutation();

  const {
    data: settlementHistory,
    isLoading: historyloading,
    refetch: refetchHistory,
  } = useGetSuperSettlementHistoryQuery(id);
  console.log(settlementHistory, 99999999999);

  const handleClose = () => {
    setShowSuccessModal(false);
    setHistoryViewed(true);
  };

  useEffect(() => {
    if (bankDetails) {
      refetchBalance();
    }
    if (settlementDetails) {
      refetchDetails();
    }
    if (settlementHistory) {
      refetchHistory();
    }
  }, [showSuccessModal]);

  const columns = [
    { name: "id", label: "ID" },
    {
      name: "date",
      label: "Date",
      options: {
        customBodyRender: (date) => (date ? date.split("T")[0] : "N/A"),
      },
    },
    {
      name: "settlementAmount",
      label: "Settlement Amount",
      options: {
        customBodyRender: (amount) => (amount ? addTrailingCommas(amount) : 0),
      },
    },
    {
      name: "commission",
      label: "Processing Fee",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { commission, commissionType } =
            settlementHistory.settlements[tableMeta.rowIndex];
          return `${
            commissionType === "RATE"
              ? `${commission} %`
              : `₦ ${addTrailingCommas(commission)}`
          }`;
        },
      },
    },
    {
      name: "commissionAmount",
      label: "Total Processing Fee",
      options: {
        customBodyRender: (commission) =>
          commission ? addTrailingCommas(commission) : 0,
      },
    },
    { name: "bankName", label: "Bank Name" },
    { name: "accountNumber", label: "Account Number" },
    { name: "accountName", label: "Account Name" },
  ];

  // console.log()

  const handleSettleInstitution = async () => {
    setShowConfirmDelete(false);
    const body = {
      institutionId: id,
    };

    try {
      const res = await settleInstitution(body).unwrap();
      console.log(res);
      setShowSuccessModal(true);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  if (balanceloading || detailsloading || historyloading || settlementLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="schools-page no-scrollbar">
      {/* <div>
        <DashboardSidebar />
      </div> */}

      <div>
        {/* <DashboardTopSection /> */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            gap: "5px",
            cursor: "pointer",
          }}
          // onClick={() => navigate(-1)}
          onClick={() => setIsSettlementDetailViewed(false)}
        >
          <img src={backArrow} alt="back arrow" />
          <h6 style={{ marginBottom: "0px" }}>
            {settlementDetails?.institutionName}
          </h6>
        </div>

        <section
          style={{
            backgroundColor: "white",
            padding: `${historyViewed ? "0" : "20px 10%"}`,
            borderRadius: "10px",
          }}
        >
          {historyViewed ? (
            <>
              <div
                style={{
                  display: "flex",
                  padding: "10px",
                  gap: "10px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h5
                    style={{
                      marginBottom: "0px",
                      fontWeight: "500",
                    }}
                  >
                    ₦ {settlementHistory?.totalSettlementAmount}
                  </h5>
                  <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                    Total Settlement Amount
                  </p>
                </div>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h5
                    style={{
                      marginBottom: "0px",
                      fontWeight: "500",
                    }}
                  >
                    ₦ {settlementHistory?.totalCommissionAmount}
                  </h5>
                  <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                    Total Processing Fee Amount
                  </p>
                </div>
              </div>
              <TableComponent
                data={settlementHistory.settlements}
                title="Settlement History"
                columns={columns}
                hasAuthority={userAuthorizedPages?.find(
                  (page) => page?.action === "VIEW_SETTLEMENT"
                )}
              />
            </>
          ) : (
            <>
              <div
                style={{
                  border: "1px solid #F5F5F5",
                  borderRadius: "8px",
                  padding: "15px",
                  height: "95%",
                }}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#FFEBE4",
                    border: "1px solid #C8C7C7",
                    padding: "10px 20px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span
                      style={{
                        backgroundColor: "#6B7A81",
                        padding: "5px 15px",
                        borderRadius: "15px",
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      Virtual Account
                    </span>
                    <h4
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                        fontWeight: "600",
                      }}
                    >
                      ₦ {addTrailingCommas(bankDetails?.balance)}
                    </h4>
                    <p style={{ marginBottom: "0px", color: "#5F5F5F" }}>
                      Wallet Balance
                    </p>
                  </div>
                  <img src={moneyVector} alt="money vector" height="100px" />
                </div>

                <div style={{ marginTop: "20px" }}>
                  <h6
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Settlement For {settlementDetails?.institutionName}
                  </h6>
                  <p style={{ marginBottom: "10px", color: "#5F5F5F" }}>
                    Manage the settlement details for{" "}
                    {settlementDetails?.institutionName}. You can also view the
                    history of settlements for this institution by clicking on
                    the View History.
                  </p>
                  <span
                    style={{
                      color: "#F14810",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => setHistoryViewed(true)}
                  >
                    View History
                  </span>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "10px",
                          fontWeight: "500",
                        }}
                      >
                        ₦{" "}
                        {addTrailingCommas(settlementDetails?.settlementAmount)}
                      </h5>
                      <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                        Settlement Amount
                      </p>
                    </div>
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "10px",
                          fontWeight: "500",
                        }}
                      >
                        ₦{" "}
                        {addTrailingCommas(settlementDetails?.commissionAmount)}
                      </h5>
                      <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                        Total Processing Fee
                      </p>
                    </div>
                    <div style={{ display: "flex", gap: "50px" }}>
                      <div>
                        <h5
                          style={{
                            marginBottom: "0px",
                            marginTop: "10px",
                            fontWeight: "500",
                          }}
                        >
                          {settlementDetails?.commissionType === "RATE"
                            ? settlementDetails?.commission + " %"
                            : "₦" +
                              addTrailingCommas(settlementDetails?.commission)}
                        </h5>
                        <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                          Processing Fee
                        </p>
                      </div>
                      {/* <img
                      src={editIcon}
                      alt="edit Icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowEditRateCanvas(true)}
                    /> */}
                    </div>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <h5
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                        fontWeight: "500",
                      }}
                    >
                      {settlementDetails?.bankName}
                    </h5>
                    <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                      Bank Name
                    </p>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <h5
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                        fontWeight: "500",
                      }}
                    >
                      {settlementDetails?.accountNumber}
                    </h5>
                    <p style={{ marginBottom: "0px", color: "#6A6A6A" }}>
                      Account Number
                    </p>
                  </div>

                  <ButtonX onClick={() => setShowConfirmDelete(true)}>
                    Settle Institution
                  </ButtonX>
                </div>
              </div>
            </>
          )}
        </section>
      </div>

      <Offcanvas
        show={showEditRateCanvas}
        onHide={() => setShowEditRateCanvas(false)}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Commission</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <EditCommissionCanvas />
        </Offcanvas.Body>
      </Offcanvas>

      <ToastComponent
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <ModalComponent showModal={showSuccessModal} closeModal={handleClose}>
        <div style={{ textAlign: "center" }}>
          <img
            src={successGIF}
            alt="success Gif"
            width="150px"
            style={{ margin: "0 auto" }}
          />
          <h3 style={{ color: "#F14810", fontWeight: "600" }}>Successful!!!</h3>
          <p style={{ fontSize: "18px" }}>
            Institution has been settled successfully
          </p>
        </div>
      </ModalComponent>

      <ModalComponent
        showModal={showConfirmDelete}
        closeModal={() => setShowConfirmDelete(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img src={alertIcon} alt="alert Icon" style={{ margin: "0 auto" }} />
          <p
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: 1.0 + "rem",
            }}
          >
            Are you sure you want to settle this institution?{" "}
          </p>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              marginLeft: "30%",
            }}
          >
            <Button
              onClick={() => setShowConfirmDelete(false)}
              type="button"
              variant="outline-primary"
              style={{ borderColor: "#F14810", color: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                No
              </span>
            </Button>
            <Button
              onClick={handleSettleInstitution}
              type="button"
              variant="primary"
              style={{ backgroundColor: "#F14810", borderColor: "#F14810" }}
              className="button"
              size="md"
            >
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.5 + "rem",
                  marginRight: 0.5 + "rem",
                }}
              >
                Yes
              </span>
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default SettlementDetailsPage;
