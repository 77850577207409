import React from "react";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import DashboardTopSection from "../../../components/dashboardTopSection/index";
import DashboardSidebar from "../../../components/dashboardSidebar/sidebar";
import backArrow from "../../../../../../assets/svgs/backArrow.svg";
import { Row, Col, Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./institutionReportGroup.css";
import OnboardingLoader from "../../../../../../component/onboardingLoader/onboardingLoader";

import {
  useGetInstitutionQuery,
  useGetCategoriesSummaryQuery,
} from "../../../../../../redux/features/institution/institutionApiSlice";

import LevelSummaryDetailPage from "./levelSummaryDetail";
import {
  addTrailingCommas,
  returnCategoryName,
} from "../../../../../../utils/utils";

const CategorySummaryPage = () => {
  const navigate = useNavigate();

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const schoolTypes = useSelector(
    (state) => state?.auth?.userInfo?.institutionTypes
  );
  // console.log(instId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);

  const {
    data: categorySummary,
    // refetch: refetchBranches,
    isLoading: categorySummaryLoading,
  } = useGetCategoriesSummaryQuery(instId, { skip: !instId });

  console.log(categorySummary);

  if (categorySummaryLoading) {
    return <OnboardingLoader />;
  }

  //Navigate to Level Summary Detail Page
  const handleViewCategorySummaryDetail = (id) => {
    // console.log(details)
    navigate(`/reporting/categorySummary/${id}`);
  };

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <>
          <div className="reportGroup-page">
            <section
              style={{
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  // alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <h5 style={{ marginBottom: "10px" }}>Reporting</h5>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    cursor: "pointer",
                    marginTop: "30px",
                    // marginBottom: "10px",
                  }}
                  onClick={() => navigate(-1)}
                >
                  <img src={backArrow} alt="back arrow" />
                  <h5 style={{ marginBottom: 0 }}>Categories</h5>
                </div>
              </div>

              <>
                <Row style={{ marginTop: 0.4 + "rem" }}>
                  <Col xs={12} md={12} lg={12} className="otherbox">
                    <Row>
                      {categorySummary &&
                        categorySummary.length > 0 &&
                        categorySummary.map(
                          (
                            {
                              levelName,
                              institutionCategory,
                              totalStudent,
                              expectedRevenue,
                              revenue,
                              pendingPayment,
                              totalWalletAmount,
                            },
                            i
                          ) => (
                            <Col
                              xs={4}
                              md={4}
                              lg={4}
                              id="reportGroupsidecard-x1"
                              key={institutionCategory?.id}
                            >
                              <div
                                className="reportGroupsidecard-x1"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleViewCategorySummaryDetail(
                                    institutionCategory.id
                                  )
                                }
                              >
                                <div className="card-inner-value">
                                  <h6
                                    style={{
                                      marginTop: 2 + "rem",
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <b>
                                      {levelName} - {institutionCategory?.name}
                                    </b>
                                  </h6>
                                  <p
                                    className="card-inner-text"
                                    style={{
                                      marginTop: -0.61 + "rem",
                                      color: "#989898",
                                    }}
                                  >
                                    {totalStudent} students
                                  </p>

                                  <div className="revenue-container">
                                    <div>
                                      <h3>
                                        ₦ {addTrailingCommas(expectedRevenue)}
                                      </h3>
                                      <p>Expected Revenue</p>
                                    </div>

                                    <div>
                                      <h3>₦ {addTrailingCommas(revenue)}</h3>
                                      <p>Generated Revenue</p>
                                    </div>
                                  </div>
                                  <div className="revenue-container">
                                    <div>
                                      <h3>
                                        ₦ {addTrailingCommas(pendingPayment)}
                                      </h3>
                                      <p>Outstanding Payment</p>
                                    </div>

                                    <div>
                                      <h3>
                                        ₦ {addTrailingCommas(totalWalletAmount)}
                                      </h3>
                                      <p>Total Wallet Amount</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )
                        )}
                    </Row>
                  </Col>
                </Row>
              </>
            </section>
          </div>
        </>

        <br />
        <br />
      </Container>
    </Container>
  );
};

export default CategorySummaryPage;
