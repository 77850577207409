// import MUIDataTable from "mui-datatables";
// import { useEffect, useState } from "react";
// import Paper from "@mui/material/Paper";
// import "./table.css";

// function TableComponent({
//   data,
//   title,
//   columns,
//   selectableRows,
//   onRowSelectionChange,
//   filter,
//   search,
//   download,
//   viewColumns,
//   pagination,
//   clearSelectedRows,
//   hasAuthority = false,
// }) {
//   const [selectedRows, setSelectedRows] = useState([]);

//   const options = {
//     filter: filter,
//     search: search,
//     download: download,
//     viewColumns: viewColumns,
//     pagination: pagination ? pagination : true,
//     filterType: "textField",
//     responsive: "standard",
//     selectableRows: `${selectableRows ? "multiple" : "none"}`,
//     rowsPerPageOptions: [10, 50, 100],
//     print: false,
//     textLabels: {
//       body: {
//         noMatch: hasAuthority
//           ? "Sorry, no matching records found"
//           : "You do not have permission to view this data.",
//       },
//     },

//     onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
//       const selectedRowData = allRowsSelected.map(
//         (rowInfo) => data[rowInfo.dataIndex]
//       );
//       setSelectedRows(selectedRowData);
//       if (onRowSelectionChange) {
//         onRowSelectionChange(selectedRowData);
//       }
//     },
//   };

//   // Function to clear selected rows
//   const handleClearSelectedRows = () => {
//     setSelectedRows([]);
//   };

//   useEffect(() => {
//     if (clearSelectedRows) {
//       setSelectedRows([]);
//     }
//   }, [clearSelectedRows]);

//   return (
//     <div className="my-table">
//       <Paper>
//         <MUIDataTable
//           title={title}
//           data={hasAuthority ? data : []}
//           columns={columns}
//           options={options}
//         />
//       </Paper>
//     </div>
//   );
// }

// export default TableComponent;

// import MUIDataTable from "mui-datatables";
// import { useEffect, useState } from "react";
// import Paper from "@mui/material/Paper";
// import CircularProgress from "@mui/material/CircularProgress"; // For loading spinner
// import "./table.css";

// function TableComponent({
//   data,
//   title,
//   columns,
//   selectableRows,
//   onRowSelectionChange,
//   filter,
//   search,
//   download,
//   viewColumns,
//   pagination = true,
//   customPagination = false,
//   clearSelectedRows,
//   hasAuthority = false,
//   onPageChange,
//   itemsPerPage = 10,
//   totalPages, // Pass total pages as a prop
//   loading = false, // New prop for loading state
// }) {
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);

//   console.log(loading);

//   const options = {
//     filter: filter,
//     search: search,
//     download: download,
//     viewColumns: viewColumns,
//     pagination: !customPagination ? pagination : false, // Disable MUI pagination if customPagination is true
//     filterType: "textField",
//     responsive: "standard",
//     selectableRows: `${selectableRows ? "multiple" : "none"}`,
//     rowsPerPageOptions: [10, 50, 100],
//     print: false,
//     textLabels: {

//       body: {
//         noMatch: loading ? (
//           <CircularProgress />
//         ) : hasAuthority ? (
//           "Sorry, no matching records found"
//         ) : (
//           "You do not have permission to view this data."
//         ),
//       },
//     },
//     onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
//       const selectedRowData = allRowsSelected.map(
//         (rowInfo) => data[rowInfo.dataIndex]
//       );
//       setSelectedRows(selectedRowData);
//       if (onRowSelectionChange) {
//         onRowSelectionChange(selectedRowData);
//       }
//     },
//   };

//   // Handle pagination for custom pagination
//   const paginatedData = customPagination
//     ? data?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
//     : data;

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//     onPageChange(page);
//   };

//   // Function to clear selected rows
//   const handleClearSelectedRows = () => {
//     setSelectedRows([]);
//   };

//   useEffect(() => {
//     if (clearSelectedRows) {
//       setSelectedRows([]);
//     }
//   }, [clearSelectedRows]);

//   return (
//     <div className="my-table">
//       <Paper>
//         {/* Show loading spinner if loading is true */}

//         <MUIDataTable
//           title={title}
//           data={hasAuthority ? paginatedData : []}
//           columns={columns}
//           options={options}
//         />

//         {customPagination && (
//           <CustomPaginationComponent
//             currentPage={currentPage}
//             totalPages={totalPages}
//             onPageChange={handlePageChange}
//           />
//         )}
//       </Paper>
//     </div>
//   );
// }

// export default TableComponent;

// function CustomPaginationComponent({ currentPage, totalPages, onPageChange }) {
//   const handlePageClick = (page) => {
//     onPageChange(page);
//   };

//   return (
//     <div
//       style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
//     >
//       {Array.from({ length: totalPages }, (_, index) => index + 1).map(
//         (page) => (
//           <button
//             key={page}
//             onClick={() => handlePageClick(page)}
//             style={{
//               padding: "8px 16px",
//               margin: "0 4px",
//               backgroundColor: page == currentPage ? "#1976d2" : "#fff",
//               color: page === currentPage ? "#fff" : "#1976d2",
//               border: "1px solid #1976d2",
//               borderRadius: "4px",
//               cursor: "pointer",
//             }}
//           >
//             {page}
//           </button>
//         )
//       )}
//     </div>
//   );
// }

import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "./table.css";

function TableComponent({
  data,
  title,
  columns,
  selectableRows,
  onRowSelectionChange,
  filter,
  search,
  download,
  viewColumns,
  pagination = true,
  customPagination = false,
  clearSelectedRows,
  hasAuthority = false,
  onPageChange,
  itemsPerPage = 10,
  totalPages,
  loading = false, // New prop for loading state
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const options = {
    filter: filter,
    search: search,
    download: download,
    viewColumns: viewColumns,
    pagination: !customPagination ? pagination : false,
    filterType: "textField",
    responsive: "standard",
    selectableRows: `${selectableRows ? "multiple" : "none"}`,
    rowsPerPageOptions: [10, 50, 100],
    print: false,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : hasAuthority ? (
          "Sorry, no matching records found"
        ) : (
          "You do not have permission to view this data."
        ),
      },
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      const selectedRowData = allRowsSelected.map(
        (rowInfo) => data[rowInfo.dataIndex]
      );
      setSelectedRows(selectedRowData);
      if (onRowSelectionChange) {
        onRowSelectionChange(selectedRowData);
      }
    },
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    onPageChange && onPageChange(page);
  };

  useEffect(() => {
    if (clearSelectedRows) {
      setSelectedRows([]);
    }
  }, [clearSelectedRows]);

  return (
    <div className="my-table">
      <Paper
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            position: "relative",
            transition: "opacity 0.5s ease",
            opacity: loading ? 0.5 : 1, // Adjust opacity when loading
            pointerEvents: loading ? "none" : "auto", // Disable interactions when loading
          }}
        >
          <MUIDataTable
            title={title}
            data={hasAuthority ? data : []}
            columns={columns}
            options={options}
          />
        </div>

        {customPagination && (
          <Stack
            spacing={2}
            style={{
              alignItems: "center",
              padding: "16px",
              marginTop: "-30px",
              alignSelf: "end",
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
              color="primary"
              sx={{
                "& .Mui-selected": {
                  backgroundColor: "#FD5821",
                  color: "#fff",
                  fontWeight: "bold",
                },
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#fef5f1",
                  },
                },
              }}
            />
          </Stack>
        )}

        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10,
              // width: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Paper>
    </div>
  );
}

export default TableComponent;
