import { Container, Form, Row, Col, Button } from "react-bootstrap";
import DashboardSidebar from "../../../../dashboardSideBar/sidebar";
import DashboardTopSection from "../../../../dashboardTopSection/index";
import backArrow from "../../../../../../../assets/svgs/backArrow.svg";
import { useNavigate } from "react-router-dom";
import "./generateReport.css";
import alertIcon from "../../../../../../../assets/svgs/alertIcon.svg";

// import { useGetInstitutionQuery } from "../../../../redux/features/institution/institutionApiSlice";
// import { useSelector } from "react-redux";
// import { useState } from "react";
// import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";

const GenerateReportPage = () => {
  const navigate = useNavigate();

  // const [activeStage, setActiveStage] = useState("Institution Details");
  // const [showMenu, setShowMenu] = useState(false);

  // console.log(showMenu);

  // const id = useSelector((state) => state?.auth?.userInfo?.institutionId);
  // const { data: institutionDetails, isLoading: detailsLoading } =
  //     useGetInstitutionQuery(id);

  // if (detailsLoading) {
  //     return <OnboardingLoader />
  // }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <Container style={{ height: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              gap: "5px",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            <img src={backArrow} alt="back arrow" />
            <h6 style={{ marginBottom: "0px" }}>Generate Reports</h6>
          </div>

          <Container className="reportbody">
            <label style={{ marginBottom: "5px" }}>Report Title</label>
            <Container>
              <Row
                style={{
                  border: "1px solid #EDEDED",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <Col xs={12} md={6} lg={6}>
                  <div style={{ flexBasis: "100%" }}>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">Title</Form.Label>

                      <Form.Control
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        type="text"
                        name="reportTitle"
                        // value={reportTitle}
                        // onChange={handleOnChange}
                        placeholder="Enter Report Title"
                        required
                      />
                    </Form.Group>
                  </div>
                </Col>

                <Col xs={12} md={6} lg={6}>
                  <div style={{ flexBasis: "100%" }}>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="formClasslabel">
                        Report Type
                      </Form.Label>

                      <Form.Select
                        size="md"
                        className="custom-focus"
                        style={{
                          fontFamily: "jost, sans-serif",
                          backgroundColor: "#EDEDED",
                          fontSize: 0.8 + "rem",
                        }}
                        name="reportTypeId"
                        // value={reportTypeId}
                        // onChange={handleOnChange}
                        required
                      >
                        <option value="" defaultValue>
                          Select Report Type
                        </option>
                        {/* {schoolYears &&
                                                schoolYears.length > 0 &&
                                                schoolYears.map(({ id, name }) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))} */}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </Container>

            <Row>
              <Col xs={12} md={6} lg={6}>
                <div style={{ paddingTop: 1.5 + "rem" }}>
                  <label style={{ marginBottom: "5px" }}>
                    Institution Branch
                  </label>
                  <div
                    style={{
                      border: "1px solid #EDEDED",
                      borderRadius: "8px",
                      padding: "15px",
                      gap: "30px",
                    }}
                  >
                    <div>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Branch
                        </Form.Label>

                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="branchId"
                          // value={branchId}
                          // onChange={handleOnChange}
                          required
                        >
                          <option value="" defaultValue>
                            Select Branch
                          </option>
                          {/* {schoolYears &&
                                                schoolYears.length > 0 &&
                                                schoolYears.map(({ id, name }) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))} */}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div style={{ paddingTop: 1.5 + "rem" }}>
                  <label style={{ marginBottom: "5px" }}>Calendar</label>
                  <div
                    style={{
                      border: "1px solid #EDEDED",
                      borderRadius: "8px",
                      padding: "15px",
                      gap: "30px",
                    }}
                  >
                    <div>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          School Year
                        </Form.Label>

                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="schoolYearId"
                          // value={schoolYearId}
                          // onChange={handleOnChange}
                          required
                        >
                          <option value="" defaultValue>
                            Select Year
                          </option>
                          {/* {schoolYears &&
                                                schoolYears.length > 0 &&
                                                schoolYears.map(({ id, name }) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))} */}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Calendar
                        </Form.Label>

                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="calendarId"
                          // value={calendarId}
                          // onChange={handleOnChange}
                          required
                        >
                          <option value="" defaultValue>
                            Select Calendar
                          </option>
                          {/* {periods &&
                                                periods.length > 0 &&
                                                periods.map(({ id, periodName }) => (
                                                    <option value={id} key={id}>
                                                        {periodName}
                                                    </option>
                                                ))} */}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div style={{ paddingTop: 1.5 + "rem" }}>
                  <label style={{ marginBottom: "5px" }}>
                    Level and Category
                  </label>
                  <div
                    style={{
                      border: "1px solid #EDEDED",
                      borderRadius: "8px",
                      padding: "15px",
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <div style={{ flexBasis: "100%" }}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Institution Level
                        </Form.Label>

                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="levelId"
                          // value={levelId}
                          // onChange={handleOnChange}
                          required
                        >
                          <option value="" defaultValue>
                            Select Level
                          </option>
                          {/* {schoolYears &&
                                                schoolYears.length > 0 &&
                                                schoolYears.map(({ id, name }) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))} */}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Institution Category
                        </Form.Label>

                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="categoryId"
                          // value={categoryId}
                          // onChange={handleOnChange}
                          required
                        >
                          <option value="" defaultValue>
                            Select Category
                          </option>
                          {/* {periods &&
                                                periods.length > 0 &&
                                                periods.map(({ id, periodName }) => (
                                                    <option value={id} key={id}>
                                                        {periodName}
                                                    </option>
                                                ))} */}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={12} md={6} lg={6}>
                <div style={{ paddingTop: 1.5 + "rem" }}>
                  <label style={{ marginBottom: "5px" }}>
                    Student / Parent
                  </label>
                  <div
                    style={{
                      border: "1px solid #EDEDED",
                      borderRadius: "8px",
                      padding: "15px",
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <div style={{ flexBasis: "100%" }}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Student Name
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="text"
                          name="studentName"
                          // value={studentName}
                          // onChange={handleOnChange}
                          placeholder="Search for a student"
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Parent Name
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="text"
                          name="parentName"
                          // value={parentName}
                          // onChange={handleOnChange}
                          placeholder="Search for a parent"
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div style={{ paddingTop: 1.5 + "rem" }}>
                  <label style={{ marginBottom: "5px" }}>Date Range</label>
                  <div
                    style={{
                      border: "1px solid #EDEDED",
                      borderRadius: "8px",
                      padding: "15px",
                      gap: "30px",
                    }}
                  >
                    <div style={{ flexBasis: "100%" }}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Start Date
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="date"
                          name="startDate"
                          // value={startDate}
                          // onChange={handleCalendarOnChange}
                          placeholder="Pick a Start Date"
                          required
                        />
                      </Form.Group>
                    </div>

                    <div style={{ flexBasis: "100%" }}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          End Date
                        </Form.Label>

                        <Form.Control
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          type="date"
                          name="endDate"
                          // value={endDate}
                          // onChange={handleCalendarOnChange}
                          placeholder="Pick an End Date"
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div style={{ paddingTop: 1.5 + "rem" }}>
                  <label style={{ marginBottom: "5px" }}>Report Interval</label>
                  <div
                    style={{
                      border: "1px solid #EDEDED",
                      borderRadius: "8px",
                      padding: "15px",
                      gap: "30px",
                    }}
                  >
                    <div>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="formClasslabel">
                          Interval
                        </Form.Label>

                        <Form.Select
                          size="md"
                          className="custom-focus"
                          style={{
                            fontFamily: "jost, sans-serif",
                            backgroundColor: "#EDEDED",
                            fontSize: 0.8 + "rem",
                          }}
                          name="interval"
                          // value={interval}
                          // onChange={handleOnChange}
                          required
                        >
                          <option value="" defaultValue>
                            Select an interval for this report to regenerate
                          </option>
                          {/* {schoolYears &&
                                                schoolYears.length > 0 &&
                                                schoolYears.map(({ id, name }) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))} */}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#F14810",
                    borderColor: "#F14810",
                    float: "right",
                    marginTop: 2 + "rem",
                  }}
                  className="button generateReportbtn"
                  size="md"
                  // onClick={(e) => handleCreateUpdatePeriod()}
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.5 + "rem",
                      marginRight: 0.5 + "rem",
                    }}
                  >
                    Generate Report
                  </span>
                </Button>
              </Col>
            </Row>
          </Container>

          {/* <ModalComponent
                        // title="Give Feedback"
                        showModal={showVerifyModal}
                        closeModal={handleCloseVerifyModal}
                    >
                        <form onSubmit={handleVerifyInstitution}>
                            <div style={{ marginBottom: "15px" }}>
                                <center>
                                    <div>
                                        <img src={alertIcon} alt="Alert Icon" />
                                    </div>

                                    <div style={{ marginTop: "10px", alignContent: "center" }}>
                                        <p
                                            style={{
                                                marginBottom: "5px",
                                                fontWeight: "500",
                                                fontSize: 1.0 + "rem",
                                            }}
                                        >
                                            Are you sure you want to verify this institution?{" "}
                                        </p>
                                    </div>
                                </center>
                            </div>
                        </form>
                    </ModalComponent> */}
        </Container>
      </Container>
    </Container>
  );
};

export default GenerateReportPage;
