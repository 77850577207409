import { useState, useEffect } from "react";
import eyeActionIcon from "../../../../assets/svgs/eyeActionIcon.svg";
import ViewInstitution from "./viewInstitution/viewInstitution";
import DashboardTopSection from "../dashboardTopSection";
import DashboardSidebar from "../dashboardSideBar/sidebar";
import { useGetAllInstitutionListQuery } from "../../../../redux/features/superAdmin/adminApiSlice";
import TableComponent from "../../../../component/table/table";
import "../dashboard/dashboard.css";
import "./schools.css";
import { Button, Offcanvas } from "react-bootstrap";
import RegisterSchoolStepper from "./registerSchool/registerSchoolStepper";
import SchoolDashboard from "./viewSchool/schoolDashboard";
import { useSelector } from "react-redux";

const SchoolPage = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [isInstitionViewed, setIsInstitutionViewed] = useState(false);
  const [viewedInstitutionDetails, setViewedInstitutionDetails] =
    useState(null);
  const [institutionList, setInstitutionList] = useState(null);

  const [showCanvas, setShowCanvas] = useState(false);

  const { data: institutions } = useGetAllInstitutionListQuery();

  console.log(institutions);

  useEffect(() => {
    setInstitutionList(institutions);
  }, [institutionList, institutions]);

  const columns = [
    // { name: "id", label: "ID" },
    { name: "institutionName", label: "School Name" },
    // { name: "institutionType", label: "Type" },
    { name: "state", label: "State" },
    // {
    //   name: "createdAt",
    //   label: "Date Submitted",
    //   options: {
    //     customBodyRender: (date) => (date ? date?.split("T")[0] : "N/A"),
    //   },
    // },
    { name: "status", label: "Status" },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <img
            src={eyeActionIcon}
            alt="eye icon"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsInstitutionViewed(!isInstitionViewed);
              setViewedInstitutionDetails(institutions[tableMeta.rowIndex]);
            }}
          />
        ),
      },
    },
  ];

  return (
    <div className="super-admin-dashboard schools-page no-scrollbar">
      <div>
        <DashboardSidebar />
      </div>

      <div>
        <DashboardTopSection />

        {isInstitionViewed ? (
          <SchoolDashboard
            institutionDetails={viewedInstitutionDetails}
            setIsInstitutionViewed={setIsInstitutionViewed}
          />
        ) : (
          <>
            <section className="">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "20px 0 20px",
                }}
              >
                <h6 style={{ fontSize: "18px", fontWeight: "600" }}>Schools</h6>
              </div>

              <div
                className="applications"
                style={{
                  margin: "20px 0",
                }}
              >
                <TableComponent
                  data={institutions}
                  title=""
                  columns={columns}
                  pagination={10}
                  search={true}
                  hasAuthority={userAuthorizedPages?.find(
                    (page) => page?.action === "ADMIN_MANAGE_SCHOOLS"
                  )}
                />
              </div>
            </section>

            <br />
            <br />
          </>
        )}
      </div>

      <Offcanvas
        show={showCanvas}
        onHide={() => setShowCanvas(false)}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px", padding: "0 15px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Register School</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <RegisterSchoolStepper />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SchoolPage;
