import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import SchoolBranchIcon from "../../../../../assets/svgs/schoolbranch.svg";
import Button from "../../../../../component/button/button";
import totalStudentsIcon from "../../../../../assets/svgs/totalStudentsIcon.svg";
import maleStudentIcon from "../../../../../assets/svgs/maleStudentIcon.svg";
import femaleStudentIcon from "../../../../../assets/svgs/femaleStudentIcon.svg";
import { Offcanvas } from "react-bootstrap";
import BranchInfoPage from "./components/branchInfo";
import {
  useGetAllBranchesQuery,
  useGetAllSchoolAdminUsersQuery,
} from "../../../../../redux/features/institution/institutionApiSlice";
import { useSelector } from "react-redux";
import "./branches.css";
import { useEffect } from "react";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import SuccessScreen from "../../../../../component/successScreen/successScreen";
import { capitalizeWords } from "../../../../../utils/utils";
import RegisterBranchStepper from "./components/registerBranchStepper";

const Branches = ({ institutionDetails, institutionLogo }) => {
  // const [branches, setBranches] = useState([0]);
  const [showCreateBranchCanvas, setShowCreateBranchCanvas] = useState(false);

  const handleCloseBranch = () => {
    setShowCreateBranchCanvas(false);
  };
  const handleShowBranch = () => {
    setShowCreateBranchCanvas(true);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const {
    data: branches,
    refetch: refetchBranches,
    isLoading: branchesLoading,
    isSuccess: branchSuccess,
  } = useGetAllBranchesQuery(instId);

  const navigate = useNavigate();

  const handleViewBranchStudentDetail = (id) => {
    navigate(`/branch/${id}`);
  };

  useEffect(() => {
    if (branches) {
      refetchBranches();
    }
  }, [showCreateBranchCanvas]);

  if (branchesLoading) {
    return <OnboardingLoader />;
  }

  return (
    <div className="branches-page">
      {branches && branches.length > 0 ? (
        <section
          style={{
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h5>School Branch</h5>

            {userAuthorizedPages?.find(
              (page) => page?.action === "CREATE_BRANCH"
            ) && (
              <Button
                backgroundColor="#f14810"
                border="#f14810"
                onClick={handleShowBranch}
              >
                Create Branch
              </Button>
            )}
          </div>

          <>
            <Row style={{ marginTop: 0.4 + "rem" }}>
              <Col xs={12} md={12} lg={12} className="otherbox">
                <Row>
                  {branches?.map(({ id, name, address }, i) => (
                    <Col xs={4} md={4} lg={4} id="branchsidecard-x1" key={id}>
                      <div
                        className="branchsidecard-x1"
                        style={{ cursor: "pointer", height: 6 + "rem" }}
                        onClick={() => handleViewBranchStudentDetail(id)}
                      >
                        <div className="card-inner-value">
                          <h6
                            style={{
                              marginTop: 2 + "rem",
                              marginBottom: "3px",
                            }}
                          >
                            <b>{capitalizeWords(name)}</b>
                          </h6>
                          <p
                            className="card-inner-text"
                            style={{ marginTop: -0.61 + "rem" }}
                          >
                            {capitalizeWords(address)}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            <br />
            <br />
          </>
        </section>
      ) : (
        <div className="create-branch-container">
          <img src={SchoolBranchIcon} alt="School Branch image" />
          <h3>Create Branches</h3>

          <p>
            For institutions that have branches, the branches will be created on
            the platform for them to perform activities pertaining to them,
            simply click on "Create Branch" button to create branch{" "}
          </p>

          <div className="btns">
            {userAuthorizedPages?.find(
              (page) => page?.action === "CREATE_BRANCH"
            ) && (
              <Button
                backgroundColor="#f14810"
                border="#f14810"
                onClick={handleShowBranch}
              >
                Create Branch
              </Button>
            )}
          </div>
        </div>
      )}

      <Offcanvas
        show={showCreateBranchCanvas}
        onHide={handleCloseBranch}
        placement={"end"}
        backdrop={true}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Branch</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px" }}>
          <RegisterBranchStepper
            setShowCreateBranchCanvas={setShowCreateBranchCanvas}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Branches;
