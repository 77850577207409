import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EyeActionIcon from "../../../../../assets/svgs/eyeActionIcon.svg";
import ActionIcon from "./actionbutton/ActionIcon";
import Button from "../../../../../component/button/button";
import ModalComponent from "../../../../../component/modal/modal";
import backArrow from "../../../../../assets/svgs/backArrow.svg";
import successGIF from "../../../../../assets/svgs/successGIF.gif";
import { useSelector } from "react-redux";
import TableComponent from "../../../../../component/table/table";
import { useGetInstitutionQuery } from "../../../../../redux/features/institution/institutionApiSlice";
import {
  useGetStudentInvoicesQuery,
  useSendInvoiceMutation,
  useNotifyOneStudentMutation,
  useSendInvoiceReminderMutation,
} from "../../../../../redux/features/institution/studentApiSlice";
import OnboardingLoader from "../../../../../component/onboardingLoader/onboardingLoader";
import { addTrailingCommas } from "../../../../../utils/utils";
import ToastComponent from "../../../../../component/toast/toast";
import { Form, Container } from "react-bootstrap";
import ButtonX from "../../../../../component/button/button";
import DashboardSidebar from "../../components/dashboardSidebar/sidebar";
import DashboardTopSection from "../../components/dashboardTopSection";
import { useParams } from "react-router-dom";

const InvoicingBranchStudentList = () => {
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [isFormNotFilled, setIsFormNotFilled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newspecificFee, setNewspecificFee] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedStudentsIds, setSelectedStudentsIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [notificationData, setNotificationData] = useState({
    sendOnlyEmail: false,
    sendOnlySms: false,
    studentInvoiceIds: [],
  });

  const { invoiceId } = useParams();
  const { sendOnlyEmail, sendOnlySms } = notificationData;

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const instId = useSelector((state) => state?.auth?.userInfo?.institutionId);

  const {
    data: studentInvoicingDetail,
    isLoading: fetchLoading,
    refetch,
  } = useGetStudentInvoicesQuery(invoiceId);

  const { data: institutionDetails, isLoading: detailsLoading } =
    useGetInstitutionQuery(instId);

  const [sendInvoice, { isLoading: notifyLoading }] = useSendInvoiceMutation();
  const [notifyOneStudent, { isLoading: notifyOneLoading }] =
    useNotifyOneStudentMutation();

  const [sendInvoiceReminder, { isLoading: sendReminderLoading }] =
    useSendInvoiceReminderMutation();

  useEffect(() => {
    if (studentInvoicingDetail) {
      refetch();
    }
  }, [studentInvoicingDetail]);

  const handleCloseModal = () => {
    setShowModal(false);
    // setEditingValue("");
    // setEditingElement(null);
  };

  const navigate = useNavigate();

  const handleViewStudentInvoiceDetail = (details) => {
    console.log(details);
    navigate(`/invoicing/${invoiceId}/${details.id}`);
  };

  const columns = [
    // { name: "id", label: "ID" },
    {
      name: "student",
      label: "Student Name",
      options: {
        customBodyRender: (data) =>
          data ? data?.firstname + " " + data?.lastname : "N/A",
      },
    },
    {
      name: "class",
      label: "Class",
      options: {
        customBodyRender: (data) => (data ? data?.name : "N/A"),
      },
    },
    // {
    //   name: "institutionCategory",
    //   label: "Category",
    //   options: {
    //     customBodyRender: (data) =>
    //       data
    //         ? data?.name : "N/A",
    //   },
    // },
    {
      name: "totalCompulsoryAmount",
      label: "Total Compulsory Amount",
      options: {
        customBodyRender: (value) => (value ? addTrailingCommas(value) : 0),
      },
    },
    {
      name: "totalOptionalAmount",
      label: "Total Optional Amount",
      options: {
        customBodyRender: (value) => (value ? addTrailingCommas(value) : 0),
      },
    },
    {
      name: "compulsoryAmount",
      label: "Compulsory Amount Selected",
      options: {
        customBodyRender: (value) => (value ? addTrailingCommas(value) : 0),
      },
    },

    {
      name: "optionalAmount",
      label: "Optional Amount Selected",
      options: {
        customBodyRender: (value) => (value ? addTrailingCommas(value) : 0),
      },
    },
    // {
    //   name: "specificFeeTotal",
    //   label: "Specific Fee Amount",
    //   options: {
    //     customBodyRender: (value) => (value ? addTrailingCommas(value) : 0),
    //   },
    // },
    {
      name: "totalAmount",
      label: "Expected Amount",
      options: {
        customBodyRender: (value) => (value ? addTrailingCommas(value) : 0),
      },
    },
    {
      name: "student",
      label: "Wallet Balance",
      options: {
        customBodyRender: (data) =>
          data ? addTrailingCommas(data?.walletBalance) : 0,
      },
    },
    {
      name: "paid",
      label: "Status",
      options: {
        customBodyRender: (status) => (status ? "Paid" : "Not Paid"),
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <ActionIcon
            actionsArr={[
              // {
              //   label: "Send Invoice",
              //   action: () =>
              //     handleNotifyOneStudent(
              //       studentInvoicingDetail[tableMeta.rowIndex]
              //     ),
              // },
              {
                label: "View",
                action: () =>
                  handleViewStudentInvoiceDetail(
                    studentInvoicingDetail[tableMeta.rowIndex]
                  ),
              },
            ]}
          />
        ),
      },
    },
  ];

  // console.log(invoicingBranchDetails?.id);
  // console.log(studentInvoicingDetail);

  const handleShowNotificationType = () => {
    setShowModal(true);
  };

  const handleOnNotificationTypeChange = (e) => {
    const { name, checked } = e.target;
    setNotificationData({ ...notificationData, [name]: checked });

    // const { sendOnlyEmail, sendOnlySms } = notificationData;

    // if (sendOnlyEmail && sendOnlySms) {
    //   setNotificationData({
    //     ...notificationData,
    //     sendOnlyEmail: false,
    //     sendOnlySms: false,
    //   });
    // }
  };

  // console.log(notificationData);

  const handleOnSendInvoiceReminder = async () => {
    try {
      // const res = await sendInvoice(invoicingBranchDetails.id).unwrap();
      const body = {
        invoiceId: invoiceId,
        body: {
          studentInvoiceIds: selectedStudentsIds,
          sendOnlyEmail: sendOnlyEmail && sendOnlySms ? false : sendOnlyEmail,
          sendOnlySms: sendOnlyEmail && sendOnlySms ? false : sendOnlySms,
        },
      };
      const res = await sendInvoiceReminder(body).unwrap();
      console.log(res);
      setShowModal(false);
      setShowSuccessModal(true);
      setSelectedRows([]);
      setNotificationData({
        sendOnlyEmail: false,
        sendOnlySms: false,
        studentInvoiceIds: [],
      });
      refetch();
    } catch (error) {
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleNotify = async () => {
    try {
      // const res = await sendInvoice(invoicingBranchDetails.id).unwrap();
      const body = {
        studentIds: selectedStudentsIds,
      };
      const res = await sendInvoice(body).unwrap();
      console.log(res);
      setShowSuccessModal(true);
      setSelectedRows([]);
    } catch (error) {
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleNotifyOneStudent = async (obj) => {
    console.log(obj);
    try {
      const params = {
        invoiceId: obj.id,
        invoiceDetailId: invoiceId,
      };
      const res = await notifyOneStudent(params).unwrap();
      console.log(res);
      setShowSuccessModal(true);
    } catch (error) {
      // alert(error.data.detail);
      setShowToast(true);
      if (error?.data?.detail) {
        setToastMessage(error.data.detail);
      } else {
        // other errors
        setToastMessage(error.error);
      }
    }
  };

  const handleSelectedRows = (selectedRowsData) => {
    setSelectedRows(selectedRowsData);
  };

  // console.log(selectedRows);

  useEffect(() => {
    // get all the ids of the items selected
    const selectedStudentsIds = selectedRows.map((row) => row.id);
    console.log(selectedStudentsIds);
    setSelectedStudentsIds(selectedStudentsIds);
  }, [selectedRows]);

  if (fetchLoading || notifyLoading || notifyOneLoading) {
    return <OnboardingLoader />;
  }

  return (
    <Container fluid style={{ display: "flex" }} className="dashboardContainer">
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />
        <>
          <ToastComponent
            showToast={showToast}
            setShowToast={setShowToast}
            toastMessage={toastMessage}
          />

          <ModalComponent
            showModal={showSuccessModal}
            closeModal={handleCloseSuccessModal}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={successGIF}
                alt="success Gif"
                width="150px"
                style={{ margin: "0 auto" }}
              />
              <h3 style={{ color: "#F14810", fontWeight: "600" }}>
                Successful!!
              </h3>
              <p style={{ fontSize: "18px" }}>
                Invoice has been sent successfully
              </p>
            </div>
          </ModalComponent>

          <ModalComponent
            title="Select Notification Type"
            showModal={showModal}
            closeModal={() => {
              setShowModal(false);
            }}
          >
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.CheckboxInput"
              >
                <Form.Check
                  size="md"
                  className="custom-focus"
                  style={{
                    //border: "1px solid",
                    borderColor: "#F14810",
                  }}
                  type="checkbox"
                  name="sendOnlySms"
                  value={sendOnlySms}
                  onChange={(e) => handleOnNotificationTypeChange(e)}
                  inline={true}
                />
                <Form.Label className="formClasslabel">SMS</Form.Label>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.CheckboxInput"
              >
                <Form.Check
                  size="md"
                  className="custom-focus"
                  style={{
                    //border: "1px solid",
                    borderColor: "#F14810",
                  }}
                  type="checkbox"
                  name="sendOnlyEmail"
                  value={sendOnlyEmail}
                  onChange={(e) => handleOnNotificationTypeChange(e)}
                  //placeholder="Apply to Student(s)"
                  inline={true}
                />
                <Form.Label className="formClasslabel">Email</Form.Label>
              </Form.Group>
              <ButtonX
                onClick={
                  notificationData?.sendOnlyEmail == false &&
                  notificationData?.sendOnlySms == false
                    ? null
                    : () => handleOnSendInvoiceReminder()
                }
                backgroundColor={`${
                  notificationData?.sendOnlyEmail == false &&
                  notificationData?.sendOnlySms == false
                    ? "rgb(255, 202, 184)"
                    : "#F14810"
                }`}
                overlay={true}
                overlayText="Send Reminder to selected student"
              >
                Send Reminder
              </ButtonX>
            </Form>
          </ModalComponent>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                // marginBottom: "20px",
              }}
            >
              <div
                className="back-arrow"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  // marginBottom: "10px",
                  float: "left",
                }}
                onClick={() => navigate(-1)}
              >
                <img src={backArrow} alt="Go back" />
                <h6
                  style={{
                    marginBottom: 0,
                  }}
                >
                  Invoicing Branch Student List
                </h6>
              </div>

              <div style={{ display: "flex", gap: "5px", float: "right" }}>
                <Button
                  onClick={
                    !selectedRows || selectedRows?.length === 0
                      ? null
                      : () => handleShowNotificationType()
                  }
                  backgroundColor={`${
                    !selectedRows || selectedRows?.length === 0
                      ? "#FFCAB8"
                      : "#F14810"
                  }`}
                  overlay={true}
                  overlayText="Select students to send invoices to then click this button to send the invoice"
                >
                  Send Invoices
                </Button>
              </div>
            </div>

            <section
              style={{
                marginTop: "20px",
              }}
            >
              <TableComponent
                data={studentInvoicingDetail}
                title=""
                selectableRows="true"
                onRowSelectionChange={handleSelectedRows}
                columns={columns}
                pagination={10}
                search={true}
                clearSelectedRows={showSuccessModal}
                hasAuthority={userAuthorizedPages?.find(
                  (page) => page?.action === "VIEW_INVOICE"
                )}
              />

              <br />
              <br />
            </section>
          </div>
        </>
      </Container>
    </Container>
  );
};

export default InvoicingBranchStudentList;
