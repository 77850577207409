import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import DashboardTopSection from "../components/dashboardTopSection";
import DashboardSidebar from "../components/dashboardSidebar/sidebar";
import {
  useGetInstitutionQuery,
  useGetInstitutionLevelsQuery,
} from "../../../../redux/features/institution/institutionApiSlice";
import { useSelector, useDispatch } from "react-redux";
import "./classesPage.css";
import OnboardingLoader from "../../../../component/onboardingLoader/onboardingLoader";
import { useNavigate } from "react-router-dom";
import { storeSelectedClassName } from "../../../../redux/features/institution/institutionSlice";
import CreateClass from "../components/classes/createClass/createClass";
import Button from "../../../../component/button/button";

const ClassesPage = () => {
  const id = useSelector((state) => state?.auth?.userInfo?.institutionId);
  const userAuthorizedPages = useSelector(
    (state) => state?.auth?.userInfo?.permissions
  );

  const [showModal, setShowModal] = useState(false);

  const { data: institutionDetails, isLoading: loadingDetails } =
    useGetInstitutionQuery(id);
  const { data: institutionLevels, isLoading: loadingLevels } =
    useGetInstitutionLevelsQuery(id);

  console.log(institutionLevels);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectClass = (id, name) => {
    dispatch(storeSelectedClassName(name));
    navigate(`/classes/${id}`);
  };

  if (loadingDetails || loadingLevels) {
    return <OnboardingLoader />;
  }

  return (
    <Container
      fluid
      style={{ display: "flex", minHeight: "100vh" }}
      className="dashboardContainer"
    >
      <DashboardSidebar institutionDetails={institutionDetails} />

      <Container fluid className="bodycontainer">
        <DashboardTopSection />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "30px",
            // marginBottom: "20px",
          }}
        >
          <h5>Classes</h5>

          {userAuthorizedPages?.find(
            (page) => page?.action === "CREATE_CLASS"
          ) && (
            <Button onClick={() => setShowModal(true)}>Create New Class</Button>
          )}
        </div>

        <CreateClass showModal={showModal} setShowModal={setShowModal} />

        <Row style={{ marginTop: 1 + "rem" }}>
          <Col xs={12} md={12} lg={12} className="otherbox">
            <Row>
              {institutionLevels &&
                // institutionLevels.length > 0 &&
                Object.keys(institutionLevels).map((key, i) =>
                  institutionLevels[key].map(({ id, name }) => (
                    <Col
                      xs={4}
                      md={4}
                      lg={4}
                      id="branchsidecard-x1"
                      key={id}
                      // onClick={() => navigate(`/classes/${id}`)}
                      onClick={() => handleSelectClass(id, name)}
                    >
                      <div
                        className="branchsidecard-x1"
                        style={{ cursor: "pointer", minHeight: "80px" }}
                        onClick={() => navigate(`/classes/${id}`)}
                      >
                        <div className="card-inner-value">
                          <h6 style={{ marginTop: 1.5 + "rem" }}>
                            <b>{name}</b>
                          </h6>
                        </div>
                      </div>
                    </Col>
                  ))
                )}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ClassesPage;
